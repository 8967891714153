import { Dialog, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from "./CancelSubmitFormDialogActions";
import { FormMaskedTextField } from "./FormMaskedTextField";
import { FormTextField } from "./FormTextField";
import { handleValidateResponse } from "./utils/handleValidateResponse";
import { useFormSubmit } from "../hooks/useFormSubmit";
import { useSnackbarNotify } from "../hooks/useSnackbarNotify";
import { Try } from "../Try";
import { IValidateResponse } from "gen/ApiClient";
import { IStrings } from "localization/IStrings";
import { useLocalization } from "localization/useLocalization";

interface IModel {
    value: string;
}

const createSchema = (strings: IStrings, label: string) => {
    return yup.string().required(strings.formRequired(label));
}

interface IProps extends DialogProps {
    formTitle: string,
    label: string,
    notifyMessage?: string,
    initialValue: string | undefined,
    mask?: (string | RegExp)[],
    confirm: () => void,
    cancel: () => void,
    submitFunction: (value: string) => Promise<Try<IValidateResponse>>,
    multiline?: boolean;
    submitText: string;
    cancelText?: string;
}

export const StringModelForm = ({ cancelText, notifyMessage, formTitle, initialValue, submitFunction, label, confirm, cancel, mask, multiline = false, submitText, ...rest }: IProps) => {
    const strings = useLocalization();
    const notify = useSnackbarNotify();
    const [update, isSubmitting] = useFormSubmit(submitFunction);

    const handleSubmit = async (value: IModel, helpers: FormikHelpers<IModel>) => {
        const updateResult = await update(value.value);
        if (handleValidateResponse(updateResult, helpers, 'value')) {
            if (notifyMessage) {
                notify(notifyMessage);
            }
            confirm();
        }
    }

    return (
        <Formik
            validateOnMount
            validationSchema={createSchema(strings, label)}
            initialValues={{ value: initialValue ?? '' }}
            onSubmit={handleSubmit}>
            <Form>
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    {...rest}>
                    <DialogTitle>{formTitle}</DialogTitle>
                    <DialogContent
                        dividers
                        className="df-col">
                        {mask
                            ? <FormMaskedTextField<IModel> mask={mask} name="value" label={label} />
                            : <FormTextField<IModel> name="value" label={label} multiline={multiline} />}
                    </DialogContent>
                    <CancelSubmitFormDialogActions
                        submitText={submitText}
                        cancelText={cancelText}
                        isSubmitting={isSubmitting}
                        cancel={cancel} />
                </Dialog>
            </Form>
        </Formik>
    );
}
