import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { BillingAccountsPrefilter, BillingAccountsPrefilters, IBillingAccountsQueryMetadata } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { createBillingAccountsPrefilterBadgeChipXRecord } from './createBillingAccountsPrefilterBadgeChipXRecord';

interface IProps {
	selectedPrefilter: BillingAccountsPrefilter;
	setSelectedPrefilter: (key: BillingAccountsPrefilter) => void;
	metadata: IBillingAccountsQueryMetadata;
	style?: React.CSSProperties;
}

const BillingAccountsPrefilterRecord: Record<BillingAccountsPrefilter, string> = {
	All: 'All',
	AlsoHamsClient: 'Also HAMS client',
	NotAllFielsFilledIn: 'W/ missing data',
	WithOutstandingBalance: 'W/ outst. balance',
};

export const BillingAccountsListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, metadata, style }: IProps) => {
	const endIconRecord = useMemo(() => createBillingAccountsPrefilterBadgeChipXRecord(metadata), [metadata]);

	return (
		<div
			className='df-col'
			style={style}>
			{BillingAccountsPrefilters.map(prefilterKeyType => (
				<RadioIsSelectedButton
					key={prefilterKeyType}
					isSelected={prefilterKeyType === selectedPrefilter}
					onClick={() => setSelectedPrefilter(prefilterKeyType)}
					endIcon={endIconRecord[prefilterKeyType]}>
					{BillingAccountsPrefilterRecord[prefilterKeyType]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
