import React from 'react';
import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { IUpdatedScheduledTaskDto } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';
import { QuickFilter } from '../UpdateScheduledTasksListPage';

interface IViewContext extends ITableViewContext<SelectableColumn, IUpdatedScheduledTaskDto>, IQuickFilterContext<QuickFilter> {}

export const ViewContext = React.createContext<IViewContext>(undefined as any);
