import { FormControlLabel, FormControlLabelProps, Typography, useTheme } from "@material-ui/core";
import React from 'react';

interface IProps extends FormControlLabelProps {
    onClick: () => void;
    caption?: string;
}

export const FormControlLabelWithCaption = ({ onClick, caption, label, ...rest }: IProps) => {
    const theme = useTheme();
    return (
        <div style={{ marginBottom: theme.spacing(1), cursor: rest.disabled ? 'not-allowed' : 'pointer' }} onClick={rest.disabled ? undefined : onClick}>
            <FormControlLabel {...rest} label={rest.disabled === true ? label : <b>{label}</b>} />
            {caption && <Typography variant="body2" style={{ marginTop: '-10px', marginLeft: '32px', whiteSpace: 'pre-line', color: rest.disabled === true ? theme.palette.text.secondary : 'inherit' }}>{caption}</Typography>}
        </div>
    );
}