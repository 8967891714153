import { Divider, ListSubheader, Radio } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { IOption } from '../IOption';
import { useAnchorElement } from '../hooks/useAnchorElement';
import { BadgeVariant } from './BadgeVariant';
import { ClearFilterTextLinkButton } from './ClearFilterTextLinkButton';
import { FilterButton } from './FilterButton';
import { FilterMenu } from './FilterMenu';
import { FilterMenuItem } from './FilterMenuItem';
import { FilterMenuTextFromOption } from './FilterMenuTextFromOption';
import { ListFilterSearch } from './ListFilterSearch';

interface IProps<T> {
	options: IOption<T>[];
	selected: T | undefined;
	setSelected: (selected: T | undefined) => void;
	label: string;
	icon: React.ReactNode;
	disableSearch?: boolean;
	style?: React.CSSProperties;
	badgeVariant?: BadgeVariant;
	disabled?: boolean;
}

export const RadioFilterComponent = <T extends unknown>({
	options,
	selected,
	setSelected,
	label,
	icon,
	disableSearch = false,
	style,
	badgeVariant,
	disabled,
}: IProps<T>) => {
	const [value, setValue] = useState<T | undefined>(selected); //selection
	const [filtered, setFiltered] = useState<IOption<T>[]>(_.orderBy(options, t => t.identifier));
	const [anchor, open, close] = useAnchorElement();
	const [search, setSearch] = useState<string>('');
	const isFiltered = useMemo(() => selected !== undefined, [selected]);
	const isLocallyFiltered = useMemo(() => value !== undefined, [value]);

	useEffect(() => {
		setValue(selected);
		setSearch('');
	}, [selected]);

	useEffect(() => {
		const x = options.filter(t => (t.identifier ?? '').toLowerCase().includes(search.toLowerCase()));
		setFiltered(_.orderBy(x));
	}, [search, options]);

	const handleClose = () => {
		if (value !== selected) {
			setSelected(value);
		}
		close();
	};

	return (
		<>
			<FilterButton
				badgeContent={isFiltered ? 1 : 0}
				label={label}
				handleOpen={open}
				icon={icon}
				style={style}
				badgeVariant={badgeVariant}
				disabled={disabled}
			/>
			<FilterMenu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}>
				<ListSubheader style={{ background: common.white, padding: 0, paddingTop: 8 }}>
					{disableSearch === false && (
						<>
							<ListFilterSearch
								style={{ paddingLeft: 16, paddingBottom: 4 }}
								search={search}
								setSearch={setSearch}
							/>
							<Divider />
						</>
					)}
					<ClearFilterTextLinkButton
						style={{ height: 36, paddingLeft: 16 }}
						isFiltered={isLocallyFiltered}
						onClear={() => setValue(undefined)}
					/>
					<Divider />
				</ListSubheader>
				{filtered.map(option => (
					<FilterMenuItem
						key={(option.id as any).toString()}
						value={(option.id as any).toString()}
						onClick={() => setValue(option.id)}>
						<Radio
							size='small'
							style={{ padding: 0 }}
							value={option.id}
							checked={value === option.id}
						/>
						<FilterMenuTextFromOption option={option} />
					</FilterMenuItem>
				))}
			</FilterMenu>
		</>
	);
};
