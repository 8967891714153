import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageX } from 'framework/components/layout/PageX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { licensesQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useParams } from 'react-router-dom';
import { LicenseChips } from '../LicenseChips';
import { LicensesPageBreadcrumbs } from '../LicensesPageBreadcrumbs';
import { LicenseDetailPageSidebar } from './LicenseDetailPageSidebar';
import { LicenseFeeHistoryCard } from './LicenseFeeHistoryCard';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const LicenseDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [item, reloadSingle] = useApiEffect(licensesQuery_single, id);
	const [trigger, reload] = useTrigger();

	useLazyEffect(() => {
		reloadSingle();
	}, [trigger]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<PageX
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<LicensesPageBreadcrumbs />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={item.name} />
						<LicenseChips
							item={item}
							style={{ marginLeft: 4 }}
						/>
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}
			stickySidebar={
				<LicenseDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<LicenseFeeHistoryCard
					item={item}
					reload={reload}
				/>
				{/* <SubscriptionLicenseAndUsageCard item={item} />
				<SubscriptionDetailAdditionalCostsCardX
					item={item}
					reload={reload}
				/>
				<AnnotationsCard
					contextId={item.id}
					contextType='Subscription'
					reload={reload}
					reloadTrigger={trigger}
				/> */}
			</div>
		</PageX>
	);
};
