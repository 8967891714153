import { IconButton } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import React, { useMemo } from 'react';

interface IProps {
	pageIndex: number;
	countPages: number;
	setPageIndex: (index: number) => void;
	style?: React.CSSProperties;
}

export const PaginationXPreviousNext = ({ pageIndex, setPageIndex, countPages, style }: IProps) => {
	const hasNextPage = useMemo(() => pageIndex < countPages, [pageIndex, countPages]);
	const hasPreviousPage = useMemo(() => pageIndex > 1, [pageIndex]);

	return (
		<div
			className='df-row-ab'
			style={style}>
			<div>
				<IconButton
					size='small'
					disabled={hasPreviousPage === false}
					onClick={() => setPageIndex(pageIndex - 1)}
					style={{ marginTop: -2 }}>
					<ArrowLeftIcon />
				</IconButton>
			</div>
			<span>{`Page: ${pageIndex.toString().toLowerCase()} of ${countPages.toString().toLowerCase()}`}</span>
			<div>
				<IconButton
					size='small'
					disabled={hasNextPage === false}
					onClick={() => setPageIndex(pageIndex + 1)}
					style={{ marginTop: -2 }}>
					<ArrowRightIcon />
				</IconButton>
			</div>
		</div>
	);
};
