import { useTheme } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { IAnnotation, annotationsCommand_delete } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatchAnnotationRequestForm } from './PatchAnnotationRequestForm';

interface IProps {
	item: IAnnotation;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const AnnotationListItem = ({ item, withoutBorder, reload }: IProps) => {
	const theme = useTheme();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatch = () => {
		open(
			<PatchAnnotationRequestForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title='Delete annotation'
				content='Do you want to delete this annotation?'
				acceptText='Yes!'
				rejectText='No, Go back'
				reject={cancel}
				acceptF={() => annotationsCommand_delete({ annotationId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-row w100 gap-8'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div className='df-col gap-2'>
				<SmsOutlinedIcon style={{ fontSize: 22, color: theme.palette.primary.main }} />
				<ColorOnHoverIconButton
					size='small'
					onClick={onPatch}>
					<EditOutlinedIcon fontSize='small' />
				</ColorOnHoverIconButton>
				<ColorOnHoverIconButton
					size='small'
					onClick={onDelete}>
					<DeleteOutlineOutlinedIcon fontSize='small' />
				</ColorOnHoverIconButton>
			</div>
			<div className='df-col fg1 gap-2'>
				<span style={{ fontWeight: 'bold' }}>{`Created: ${formatDateX(item.created, 'dd/MM/yyyy HH:mm')}`}</span>
				<span style={{ fontWeight: 'bold' }}>{`Last updated: ${formatDateX(item.lastUpdated, 'dd/MM/yyyy HH:mm')}`}</span>
				<span style={{ whiteSpace: 'pre-line' }}>{item.content}</span>
			</div>
		</div>
	);
};
