import { CardProps } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IBillingAccountsQueryMetadata, IBillingAccountsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { BillingAccountsListPrefilter } from './BillingAccountsListPrefilter';

// const Exclusions: (keyof IBillingAccountsQueryParams)[] = [];

interface IProps extends CardProps {
	params: IBillingAccountsQueryParams;
	setParams: (params: IBillingAccountsQueryParams) => void;
	metadata: IBillingAccountsQueryMetadata;
}

export const BillingAccountsListFilterCard = ({ params, setParams, metadata, style, ...rest }: IProps) => {
	const strings = useLocalization();
	// const isFilteredX = useMemo(() => isFiltered(params, Exclusions), [params]);
	const onFilter = (pars: IBillingAccountsQueryParams) => setParams(onResetToPage1(pars));
	// const onClear = () => setParams(onResetToPage1(clearParams(params)));

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: 500 }}>{strings.filter}</span>
			</div>
			<BillingAccountsListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
				metadata={metadata}
			/>
			{/* <Divider /> */}
			{/* <TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}></div> */}
		</CardX>
	);
};
