import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterComponent } from 'framework/filter/date/DateFilterComponent';
import { FilterBar } from 'framework/filter/FilterBar';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { INotificationsQueryParams, NotificationTypes } from 'gen/ApiClient';
import { QueryContext } from './context/QueryContext';

const exclusions: (keyof INotificationsQueryParams)[] = [];

interface IProps {
	params: INotificationsQueryParams;
	onFilter: (params: INotificationsQueryParams) => void;
	paddingLeft: number;
}

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const { queryResult } = useContext(QueryContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterComponent
				label='Created'
				after={params.createdAfter}
				before={params.createdBefore}
				setSelected={(after, before) => onFilter({ ...params, createdAfter: after, createdBefore: before })}
			/>
			<StringListFilterComponent
				options={NotificationTypes}
				selected={params.types}
				setSelected={vals => onFilter({ ...params, types: vals })}
				label='Type'
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
