import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { ITemplateDto } from 'gen/ApiClient';
import { TemplateSelectableColumn } from './TemplateSelectableColumn';

export const createTemplateHeaders = (colRecord: Record<TemplateSelectableColumn, string>): Record<TemplateSelectableColumn, IHeader<ITemplateDto>> => {
	return {
		id: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'id', t => t.id),
		standardDocumentId: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'standardDocumentId', t => t.standardDocumentId),
		isStandardDocument: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'isStandardDocument', t =>
			t.isStandardDocument.toString()
		),
		language: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'language', t => t.language),
		mainContext: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'mainContext', t => t.mainContext),
		name: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'name', t => t.name),
		predefinedType: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'predefinedType', t => t.predefinedType),
		created: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'created', t => formatDate(t.created, 'date-and-time')),
		lastUpdated: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'lastUpdated', t => formatDate(t.lastUpdated, 'date-and-time')),
		validityEnd: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'validityEnd', t => formatDate(t.validityEnd, 'date-and-time')),
		validityStart: createSortableHeaderWithKey<ITemplateDto, TemplateSelectableColumn>(colRecord, 'validityStart', t =>
			formatDate(t.validityStart, 'date-and-time')
		),
	};
};
