import { ChipX } from 'framework/components/chips/ChipX';
import { SalesType } from 'gen/ApiClient';
import React from 'react';

interface ISaleX {
	type: SalesType;
}

interface IProps {
	item: ISaleX;
	style?: React.CSSProperties;
}

export const SalesTypeChip = ({ item, style }: IProps) => {
	return (
		<ChipX
			style={style}
			label={item.type.toString()}
			color={item.type === 'Budget' ? 'warning' : item.type === 'Tender' ? 'error' : 'info'}
		/>
	);
};
