import React from 'react';
import { IOption } from '../IOption';
import { ListFilterComponent } from './ListFilterComponent';

interface IProps {
	options: number[];
	selected: number[];
	setSelected: (selected: number[]) => void;
	label: string;
	icon: React.ReactNode;
}

export const NumberListFilterComponent = ({ options, selected, setSelected, label, icon }: IProps) => {
	return (
		<ListFilterComponent<number>
			options={options.map<IOption<number>>(t => ({ identifier: t.toString(), id: t }))}
			selected={selected}
			setSelected={setSelected}
			label={label}
			icon={icon}
		/>
	);
};
