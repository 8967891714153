import { Grid } from '@material-ui/core';
import { GridDivider } from 'framework/components/detailDialog/GridDivider';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { boolToYesNo } from 'shared/utils/boolToString';
import { calculateTotalsFromAmountExVat } from './calculateTotalsFromAmountExVat';
import { ISalesLineRequestX } from './ISalesLineRequestX';

interface IProps {
	reference: string | undefined;
	recipientName: string | undefined;
	lines: ISalesLineRequestX[];
	isShiftVat?: boolean;
}

export const CreateSaleRequestFormConfirmationComponent = ({ reference, recipientName, lines, isShiftVat }: IProps) => {
	const strings = useLocalization();
	const { totalExVat, totalInVat } = useMemo(() => calculateTotalsFromAmountExVat(lines, isShiftVat ?? false), [lines, isShiftVat]);

	return (
		<Grid
			container
			spacing={2}
			style={{ width: 550 }}>
			<Grid
				item
				xs={12}
				className='df-col-ac'>
				<LightBulbNotification>{`This transaction cannot be undone, please check data below`}</LightBulbNotification>
			</Grid>
			<GridDivider />
			<PropertyInGrid
				labelGridSize={6}
				label={strings.invoiceNumber}
				value={reference}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.withRespectToShort}
				value={recipientName}
			/>
			<PropertyInGrid
				labelGridSize={6}
				label={strings.totalInVat}
				value={formatCurrency(totalInVat)}
			/>
			{isShiftVat !== undefined && (
				<PropertyInGrid
					labelGridSize={6}
					label={`Vat shift?`}
					value={boolToYesNo(isShiftVat)}
				/>
			)}
			<PropertyInGrid
				labelGridSize={6}
				label={strings.totalExVat}
				value={formatCurrency(totalExVat)}
			/>
		</Grid>
	);
};
