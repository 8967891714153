import { ISalesLineRequest } from 'gen/ApiClient';
import { ISalesLineRequestX } from './ISalesLineRequestX';

export const mapSalesLineRequest = (line: ISalesLineRequestX, index: number): ISalesLineRequest => {
	return {
		description: line.description,
		quantity: line.quantity,
		unitListPriceInVat: line.unitListPrice,
		unitPriceInVat: line.unitPrice,
		vatPercentage: line.vatPercentage,
		zeroBasedIndexNumber: index,
	};
};
