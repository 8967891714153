import { Button, Checkbox, Collapse, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { TableCardX } from 'framework/components/cards/TableCardX';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageSettingsButtonX } from 'framework/components/layout/PageSettingsButtonX';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { PageX } from 'framework/components/layout/PageX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarXWithMode } from 'framework/components/search/SearchbarXWithMode';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { SalesSettingsRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SalesPageBreadcrumbs } from '../SalesPageBreadcrumbs';
import { CreateSaleForm } from '../create/CreateSaleForm';
import { SalesListExportButtonMenu } from './SalesListExportButtonMenu';
import { SalesListFilterCard } from './SalesListFilterCard';
import { SalesListOrderBySelect } from './SalesListOrderBySelect';
import { SalesListQueryContext } from './SalesListQueryContext';
import { SalesListStats } from './SalesListStats';
import { SalesSearchModeRecord } from './SalesSearchModeRecord';
import { SalesTable } from './SalesTable';
import { SalesListFilterChips } from './SalesListFilterChips';
import { isFiltered } from 'framework/utils/isFiltered';
import { ISalesQueryParams } from 'gen/ApiClient';
import { clearParams } from 'framework/utils/clearParams';

const Exclusions: (keyof ISalesQueryParams)[] = ['includeExceptionalInStats'];

export const SalesListPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(SalesListQueryContext);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [expandedId, setExpandedId] = useAccordionState<string>();
	const [expandAll, toggleExpandAll] = useStateToggleBoolean(false);
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);
	const onFilter = (pars: ISalesQueryParams) => setParams(onResetToPage1(pars));
	const isFilteredX = useMemo(() => isFiltered(params, Exclusions), [params]);
	const onClear = () => setParams(onResetToPage1(clearParams(params, Exclusions)));

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<CreateSaleForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<PageX
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<SalesPageBreadcrumbs />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageStatsButtonX
								showStats={showStats}
								toggleShowStats={toggleShowStats}
							/>
							<PageSettingsButtonX route={SalesSettingsRoute} />
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
					<Collapse in={showStats}>
						<SalesListStats
							metadata={queryResult.metadata}
							params={params}
							onFilter={onFilter}
							onToggleShowExceptionalSalesInStats={() => setParams({ ...params, includeExceptionalInStats: !params.includeExceptionalInStats })}
							style={{ paddingTop: 16 }}
						/>
					</Collapse>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 h100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<div className='df-col gap-8'>
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							onClick={onNew}
							style={{ borderRadius: 12 }}>
							{`New`}
						</Button>
					</div>
					<SalesListFilterCard
						params={params}
						onFilter={onFilter}
						onClear={onClear}
						isFiltered={isFilteredX}
						metadata={queryResult.metadata}
					/>
					<div className='fg1'></div>
					<Divider />
					<SalesListExportButtonMenu
						params={params}
						queryResult={queryResult}
					/>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<SearchbarXWithMode
					placeholder={`${strings.searchVerb}...`}
					value={params.searchString}
					mode={params.searchMode}
					modeRecord={SalesSearchModeRecord}
					onSearch={val => onFilter({ ...params, searchString: val })}
					onSetMode={mode => onFilter({ ...params, searchMode: mode })}
				/>
				{isFilteredX && (
					<SalesListFilterChips
						params={params}
						onFilter={onFilter}
					/>
				)}
				<TableCardX
					header={
						<div className='df-col-ae'>
							<Button
								size='small'
								endIcon={
									<Checkbox
										checked={expandAll}
										style={{ padding: 0 }}
									/>
								}
								style={{ marginRight: -2 }}
								onClick={toggleExpandAll}>
								{`Show all content`}
							</Button>
							<SalesListOrderBySelect
								sortParams={params.sortParams}
								onFilter={sp => onFilter({ ...params, sortParams: sp })}
							/>
						</div>
					}
					table={
						<SalesTable
							queryResult={queryResult}
							sortParams={params.sortParams}
							onFilter={sp => onFilter({ ...params, sortParams: sp })}
							expandAll={expandAll}
							expandedId={expandedId}
							toggle={setExpandedId}
						/>
					}
					footer={
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							flexEnd
						/>
					}
				/>
			</div>
		</PageX>
	);
};
