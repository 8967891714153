import { addDays, addWeeks, addMonths, parseISO, addSeconds, addMinutes, addHours } from "date-fns";
import { TimeUnit } from "../../forms/TimeUnit";

// TODO make this complete for all time units
export const addUnitsToDate = (date: Date, val: number, unit: TimeUnit): Date => {
    const parsed = parseDateOrNow(date);
    if (unit === 'Seconds') {
        return addSeconds(parsed, val);
    } else if (unit === 'Minutes') {
        return addMinutes(parsed, val);
    } else if (unit === 'Hours') {
        return addHours(parsed, val);
    } else if (unit === 'Days') {
        return addDays(parsed, val);
    } else if (unit === 'Weeks') {
        return addWeeks(parsed, val);
    } else if (unit === 'Months') {
        return addMonths(parsed, val);
    } else {
        return date;
    }
}

const parseDateOrNow = (date: Date): Date => {
    if (date === undefined || date === null) {
        return new Date();
    } else if (date instanceof Date) {
        return date;
    } else {
        return parseISO(date as any);
    }
}