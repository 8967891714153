import { enApiErrorStrings } from 'framework/context/localization/enApiErrorStrings';
import { IStrings } from './IStrings';

export const enStrings: IStrings = {
    ...enApiErrorStrings,
    accessToFunctions: `Access to functions`,
    accessToPages: `Access to pages`,
    accountNumber: `Account number`,
    accountPayable: `Account payable`,
    accountReceivable: `Account receivable`,
    accountsPayable: `Accounts payable`,
    accountsPayableShort: `Accounts pay.`,
    accountsReceivable: `Accounts receivable`,
    accountsReceivableShort: `Account rec.`,
    action: `Actions`,
    actions: `Actions`,
    actionsWillBeDeletedFromThisList: `Actions will be deleted from this list`,
    actionsWillBeVisibleAgainInThisList: `Actions will be visible again in this list`,
    active: `Active`,
    activeAndExpired: `Active & Expired`,
    activeNow: `Active now`,
    actual: `Actual`,
    actualDeliveryDate: `Actual delivery date`,
    add: `Add`,
    addALine: `Add a line`,
    addALineForExampleDiscount: `Add a line (f.e. discount)`,
    addDevicesFromNoah: `Add devices from Noah`,
    addedWhat: (what: string | number) => `Added ${what.toString().toLowerCase()}`,
    addItemForRepair: `Add item for repair`,
    addItems: `Add items`,
    additionalNomenclatures: `Additional nomenclatures`,
    addLocation: `Add location`,
    addOffice: `Add office`,
    addRemark: `Add remark`,
    address: `Address`,
    addressData: `Address data`,
    addressLine: `Street and number`,
    addressUpdated: `Address updated`,
    addToExistingRepair: `Add to existing repair`,
    addToExistingRepairCaption: `Article will be added to a not sent repair`,
    addToSettlementProposal: `Add to settlement proposal`,
    addWhat: (what: string | number) => `Add ${what.toString().toLowerCase()}`,
    adjustWhat: (what: string | number) => `Adjust ${what.toString().toLowerCase()}`,
    administratively: `Adminstrative`,
    advance: `Advance`,
    advancedFilters: `Advanced filters`,
    advancedInventoryFilters: `Advanced inventory filters`,
    advanceInvoice: `Advance invoice`,
    advanceInvoices: `Advance invoices`,
    advisoryPhysician: `Advisory physician`,
    afterDate: `After date`,
    age: `Age`,
    agenda: `Schedule`,
    all: `All`,
    allAfter: `All after`,
    allBefore: `All before`,
    allSales: `All sales`,
    allUsers: `All users`,
    allWhat: (what: string | number) => `All ${what.toString().toLowerCase()}`,
    almostAlways: `Almost always`,
    almostNever: `Almost never`,
    alreadyPaid: `Already paid`,
    alreadyProformaAttachmentPresentDeleteFirstQuestion: `Proforma attachment 12 already created\n\nDo you want to delete this proforma attachment 12?`,
    alsoChangeWithPatient: `Also change data with patient?`,
    alsoCreatePatientInNoah: `Also create patient in NOAH?`,
    alsoExistsInNoah: `Also exists in Noah`,
    alsoShowDeleted: `Also show deleted`,
    alsoShowRemarksOfPatient: `Also show remarks of patient`,
    alsoUpdateOnProcesses: `Also update on processes`,
    alwaysImport: `Import always`,
    ambulatory: `Ambulatory`,
    amount: `Amount`,
    amountFromSale: `Amount from sale`,
    amountMinusContributions: `Amount minus contributions`,
    amountPaid: `Amount paid`,
    amountToPay: `Amount to pay`,
    anamnesis: `Anamnesis`,
    anExample: `An example`,
    anItem: `An item`,
    annotation: `Annotation`,
    annotations: `Annotations`,
    applicable: `Applicable`,
    appointment: `Appointment`,
    appointmentPlanned: `Appointment planned`,
    appointments: `Appointments`,
    approval: `Approval`,
    approvalAdvisoryPhysicianCaption: `Approval advisory physician received and signed`,
    approvalAdvisoryPhysicianReceived: `Approval adv. phys. received`,
    approvalDate: `Approval date`,
    approvalEntPhysicianCaption: `Medical prescription received and signed by ENT physician.`,
    approved: `Approved`,
    approvedByAdvisoryPhysicianShort: `Approved by adv. phys.`,
    aRemoteDevice: `A remote device`,
    areYouSureYouWantToDeleteThisEntPhysician: `Are you sure you want to delete this ENT physician?`,
    articleNumber: `Article #`,
    atPatient: `At patient`,
    atSupplier: `At supplier`,
    atSupplierForRepair: `At supplier for repair`,
    attachment: `Attachment`,
    attachments: `Attachments`,
    attendingAudiologistWillAlsoBeChangedOnOtherProcesses: `Attending audiologist will also be changed on active processes`,
    attendingAudiologistWillAlsoBeChangedWithPatient: `Attending audiologist will also be changed at patient`,
    attendingAudiologistWillNotBeChangedWithPatient: `Attending audiologist will not be changed at patient`,
    attendingAudiologistWillOnlyBeChangedWithThisPatientAndNotOnProcesses: `Attending audiologist will only be changed on patient and not on active processes`,
    attendingDoctors: `Attending physicians`,
    audiogram: `Audiogram`,
    audiograms: `Audiograms`,
    audiologist: `Audiologist`,
    autoSelectIndexNumberQuestion: `Automatically select index number?`,
    autoSelectPaymentReferenceQuestion: `Automatically select payment reference?`,
    autoSetOrderNumber: `Automatically set ordernumber`,
    available: `Available`,
    availableForWhat: (what: string | number) => `Available for ${what.toString().toLowerCase()}`,
    availableInCalendar: `Available in calendar`,
    availableInCalendarCaption: `Appointments can be planned on this location`,
    average: `Average`,
    averageNumberOfHoursPerDayWearingHearingAids: `Average wearing time`,
    averagePurchasePrice: `Average purchase price`,
    balance: `Balance`,
    bancontact: `Bancontact`,
    bankData: `Bank data`,
    bankTransfer: `Bank transfer`,
    base: `Base`,
    basedOn: `Based on`,
    batteries: `Batteries`,
    beidMiddlewareIsNotInstalled: `BEID middleware is not installed`,
    better: `Better`,
    birthDateUnknown: `Date of birth unknown`,
    block: `Block`,
    blocked: `Blocked`,
    blockUser: `Block user`,
    blockUserContent: `A blocked user won't be able to login.`,
    blockUserQuestion: `Block user?`,
    boneAnchorage: `Bone anchorage`,
    brand: `Brand`,
    brandAndModel: `Brand and model`,
    brandName: `Brand name`,
    brands: `Brands`,
    bulkImport: `Bulk import`,
    bulkRepair: `Bulk repair`,
    busyUploadingDocument: `Busy uploading document`,
    calculation: `Calculation`,
    calculations: `Calculations`,
    calendar: `Calendar`,
    canBeUsedForWhat: (what: string | number) => `Can be used for ${what.toString().toLowerCase()}`,
    cancel: `Cancel`,
    cancellationReason: `Reason of cancellation`,
    cancelled: `Cancelled`,
    cancelledAfterPartlyDelivery: `Cancelled after partlial delivery`,
    cancelledAfterShipment: `Cancelled (after shipment)`,
    cancelledBeforeShipment: `Cancelled (before shipment)`,
    cancelledWhat: (what: string | number) => `${what} cancelled`,
    cancelLoan: `Cancel loan`,
    cancelSomething: (something: string | number) => `Cancel ${something.toString().toLowerCase()}`,
    cancelWhat: (what: string | number) => `Cancel ${what.toString().toLowerCase()}`,
    cannotReachServerNoInternetConnection: `Cannot reach server, no internet connection`,
    canReturn: `Can return`,
    canViewInAppOrBrowser: `Can view in browser or app`,
    cash: `Cash`,
    cashRegister: `Cash register`,
    cashRegisterLocation: `Uses cash register`,
    cashSale: `Cash sale`,
    cashSaleForPatient: `Cash sale for patient`,
    cashSales: `Cash sales`,
    catalogNumber: `Catalog number`,
    categories: `Categories`,
    category: `Category`,
    change: `Change`,
    changeColumns: `Change columns`,
    changeDefaultDeliveryTime: `Change default delivery time`,
    changeDetailsEntPhysician: `Change details ENT physician`,
    changedWhat: (what: string | number) => `${what} changed`,
    changeEmail: `Change email`,
    changeName: `Change name`,
    changePassword: `Change password`,
    changePhone: `Change phone number`,
    changePrices: `Change prices`,
    changeRemark: `Change remarks`,
    changeRizivNumber: `Change riziv number`,
    changeSettings: `Change settings`,
    changeSomething: (something: string | number) => `Change ${something.toString().toLowerCase()}`,
    changeStartModule: `Change (start module)`,
    changeSupplierContactPerson: `Change supplier contact person`,
    changeWhat: (what: string | number) => `Change ${what.toString().toLowerCase()}`,
    charger: `Charger`,
    checkAgain: `Check again`,
    checkoutVerb: `Checkout`,
    city: `City`,
    clearFilter: `Clear filter`,
    clearLink: `Clear link`,
    clearRizivContent: `User will be marked as 'no' audiologist`,
    clearRizivQuestion: `Clear riziv link?`,
    click: `Click`,
    clickHereToViewDocumentation: `Click here to view documentation`,
    clickToViewDetails: `Click here to view details`,
    clickToViewItemWithSerialNumber: `Click to view item with this serial number`,
    clickToViewProductCatalog: `Click to view product catalog`,
    close: `Close`,
    closeSearch: `Close search`,
    cltTest: `CLT test`,
    code: `Code`,
    codesLinkedToRequest: (codes: string | number) => `${codes} linked to request`,
    codesSingularOrPlural: `Code(s)`,
    color: `Color`,
    column: `Column`,
    combinationOfBrandAndModelExists: `This combination of model and brand already exists.`,
    companyData: `Company data`,
    companyName: `Name of company`,
    comparisonAll: `Comparison all`,
    comparisonStereo: `Comparison stereo`,
    compensation: `Compensation`,
    completed: `Completed`,
    completelyDelivered: `Completely delivered`,
    configuration: `Configuration`,
    confirm: `Confirm`,
    confirmation: `Confirmation`,
    confirmDelivery: `Confirm delivery`,
    confirmed: `Confirmed`,
    confirmNewPassword: `Confirm new password`,
    confirmPassword: `Confirm password`,
    confirmUserHeader: `Fill in a password`,
    connection: `Connection`,
    connectReader: `Connect a card reader`,
    consignment: `Consignment`,
    consignmentData: `Consignment data`,
    consignmentWarning: `Consigment warning`,
    contact: `Contact`,
    contactAHamsAdminUserForMoreInformation: `Contact a HAMS admin user for more information`,
    contactData: `Contact data`,
    contactPerson: `Contact person`,
    contacts: `Contacts`,
    contactWho: (who: string | number) => `Contact ${who}`,
    contents: `Contents`,
    continue: `Continue`,
    contribution: `Contribution`,
    contributionCodeForPatient: (code: string | number, patient: string | number) => `Contributation ${code.toString().toLowerCase()} for ${patient.toString().toLowerCase()}`,
    contributions: `Contributions`,
    conventionedEqualsPrecededByOne: `Conventioned = preceded by '1'`,
    conventionedRizivNumber: `Conventioned riziv number`,
    conventionedRizivNumberShort: `Conv. RIZI #`,
    convertedToSales: `Converted to sales`,
    cosiNeed: `COSI need`,
    cosiQuestionnaire: `COSI questionnaire`,
    countDeliveries: `Count deliveries`,
    countOrders: `Count orders`,
    countPurchases: `Count purchases`,
    country: `Country`,
    create: `Create`,
    created: `Created`,
    createdAt: `Created at`,
    createdBracketsHow: (how: string | number) => `Create (${how.toString().toLowerCase()})`,
    createdFromUploadWithSignature: `Created (upload) with signature`,
    createDocumentFromPersonalisedVersion: `Create document from a personalised version`,
    createDocumentFromStandardisedVersion: `Create document from a standardised version`,
    createInHamsAndNoah: `Create in HAMS and NOAH`,
    createNew: `Create new`,
    createNewEvent: `Create new event`,
    createNewPatient: `Create new patient`,
    createNewRule: `Create new rule`,
    createOnlyInHams: `Create only in HAMS, NOT in NOAH`,
    createPatientFromFormCaption: `Manually fill in all data`,
    createPatientFromIdentityCardCaption: `Create patient from EID card`,
    createPatientFromNhaxCaption: `Import patient from another NOAH installation\n(.nhax or .enhax)`,
    createPatientInNoah: `Create patient in Noah`,
    createProformaAttachment12Content: `Via this method it's possible to create an attachment 12 BEFORE definitive handover\n\nThis document is not final\n\nFinal document will be created at handover`,
    createServiceOrGoods: `Create a new product or service`,
    createSomething: (something: string | number) => `Create ${something.toString().toLowerCase()}`,
    createStrCommaWhat: (str: string | number, what: string | number) => `Create ${str.toString().toLowerCase()}, ${what.toString().toLowerCase()}`,
    createTenderAndOrderForm: `Create tender (and orderform)`,
    createWhat: (what: string | number) => `Create ${what.toString().toLowerCase()}`,
    creditNote: `Credit note`,
    creditNotePurchase: `Purchase credit note`,
    creditNoteSale: `Sales credit note`,
    creditStatus: `Credit status`,
    currentFeePersonalShare: `Current personal share`,
    currentFeeValue: `Current fee value`,
    currentLocation: `Current location`,
    currentlyLoggedInOnDevices: (count: string | number) => `Currently logged in on ${count.toString().toLowerCase()} devices`,
    currentlyLoggedInOnOneDevice: `Currently logged in on 1 device`,
    currentlyNotLoggedIn: `Currently not logged in`,
    currentPrice: `Current price`,
    customer: `Customer`,
    customerIsAPatient: `Customer is a patient`,
    customerIsAPatientCaption: `Optionally tender can be directed to a VAT customer`,
    customerIsNotAPatient: `Customer is not a patient`,
    customerIsNotAPatientCaption: `Data of receiver must be filled in manually\nOptionally tender can be directed to a VAT customer`,
    customWorkflows: `Custom workflows`,
    dashboard: `Dashboard`,
    data: `Data`,
    dataNotReadViaEid: `Data not read via Eid card`,
    dataPaging: (fc: string | number, ufc: string | number, what: string | number, index: string | number, total: string | number) => `${fc}/${ufc.toString().toLowerCase()} ${what.toString().toLowerCase()}. Page ${index.toString().toLowerCase()} of ${total.toString().toLowerCase()}`,
    date: `Date`,
    dateApprovalAdvisoryPhysician: `Date approval advisory physician`,
    dateBinFormat: `BIN date formt (year-month-day)`,
    dateCreated: `Date created`,
    dateDayMonthYear: `Date (day/month/year)`,
    dateLastAudiogram: `Last audiogram's date`,
    dateLastChangedInNoah: `Date last changed in Noah`,
    dateLastHandover: `Last handover's date`,
    dateLastSelection: `Last selection's date`,
    dateOfBirth: `Date of birth`,
    dateOfDispatch: `Dispatch date`,
    datePlusTimeDayMonthYearHourMinutes: `Date + time (day/month/year hours:minutes)`,
    dateReturnBefore: `Date return before`,
    dateStartLoan: `Date start loan`,
    dateWhat: (what: string | number) => `Date ${what.toString().toLowerCase()}`,
    day: `Day`,
    dayOrDays: `Day(s)`,
    days: `Days`,
    dBAgainstVocalIndex: `dB against vocal index`,
    deactivePaymentMethod: `Deactivate payment method?`,
    deactivePaymentMethodContent: `This payment method is subsequently not applicable anymore`,
    deactivePaymentMethodForWhatContent: (what: string | number) => `This payment method is subsequently not applicable anymore for ${what.toString().toLowerCase()}`,
    deactivePaymentMethodForWhatQuestion: (what: string | number) => `Deactivate payment method for ${what.toString().toLowerCase()}?`,
    deceased: `Deceased`,
    defaultDeliveryTime: `Default delivery time`,
    defaultDuration: `Default duration`,
    defaultInvoiceProcessSteps: `Default invoice process steps`,
    defaultOffice: `Default office`,
    defaultRepairTime: `Default repair time`,
    defaultWhat: (what: string | number) => `Default ${what.toString().toLowerCase()}`,
    degreeOfChange: `Degree of change`,
    delete: `Delete`,
    deleted: `Deleted`,
    deletedWhat: (what: string | number) => `${what} deleted`,
    deleteEntPhysician: `Delete ENT physician`,
    deleteEntPhysicianQuestion: `Delete ENT physician?`,
    deleteFromRequest: `Delete from request`,
    deleteGeneralPractitioner: `Delete general practitioner`,
    deleteGeneralPractitionerQuestion: `Delete general practitioner?`,
    deleteItemFromProductCatalog: `Delete item from product catalog`,
    deleteItemFromProductCatalogContent: `By deleting this product it's no longer selectable\nat a loan, tender, sale, …`,
    deleteLocation: `Delete location`,
    deleteLocationQuestion: `Delete location?`,
    deleteOffice: `Are you sure you want to delete this office?`,
    deleteProduct: `Delete product`,
    deleteProductQuestion: `Are you sure you want to delete this product?`,
    deleteSupplier: `Delete supplier`,
    deleteUser: `Delete user`,
    deleteUserContent: `This action cannot be undone! All user data will be lost.`,
    deleteUserQuestion: `Delete user?`,
    deleteWhat: (what: string | number) => `Delete ${what.toString().toLowerCase()}`,
    deleteWhatQuestion: (what: string | number) => `Delete ${what.toString().toLowerCase()}?`,
    delivered: `Delivered`,
    deliveredToPatient: `Delivered to patient`,
    deliveries: `Deliveries`,
    deliverToPatient: `Deliver to patient`,
    delivery: `Delivery`,
    deliveryDate: `Delivery date`,
    deliveryHelperText: `The location where the delivery was received`,
    deliveryNote: `Delivery note`,
    deliveryOverDue: `Delivery overdue`,
    deliveryRegistered: `Delivery registered`,
    deliverySingularOrPlural: `Delivery(-ies)`,
    deliveryTime: `Delivery time`,
    demo: `Demo`,
    department: `Department`,
    description: `Description`,
    descriptions: `Descriptions`,
    detail: `Details`,
    details: `Details`,
    devicesFromNoah: `Devices from Noah`,
    differenceWithNorm: `Difference with norm`,
    differentReadOuts: `Different readouts`,
    directedToVatCustomer: `Directed to a VAT customer`,
    disableCashRegisterResource: `Cash register not active`,
    disableCashRegisterResourceQuestion: `Disable cash register module for this location?`,
    disableExternalLogin: `Disable external login`,
    disableExternalLoginContent: `User will no longer be able to use google services to log in.`,
    disableExternalLoginQuestion: `Disable external login?`,
    disablePlannable: `Not plannable anymore`,
    disablePlannableQuestion: `Make location no longer plannable?`,
    disableStockManagementResource: `Not usable as  stock location anymore`,
    disableStockManagementResourceQuestion: `Enable stock management for this location?`,
    disApprovalAdvisoryPhysicianCaption: `Feedback received without approval\nThis step will be duplicated for retry`,
    disApprovalEntPhysicianCaption: `Feedback received without approval\nThis step will be duplicated for retry`,
    disApproved: `Disapproved`,
    discount: `Discount`,
    discountCannotBeGreaterThanListPrice: `Discount cannot be greater than list price`,
    discountsCanBeAddedAfterwards: `Discounts can be added afterwards`,
    dispatchDate: `Dispatch date`,
    dispatchInfo: `Dispatch info`,
    dispatchReference: `Dispatch reference`,
    displayName: `Displayed name`,
    distortion: `Distortion`,
    distributedBrands: `Distributed brands`,
    distributor: `Distributor`,
    doctorWithRizivNumberExists: `A practitioner with this RIZIV number already exists`,
    document: `Document`,
    documents: `Documents`,
    documentsForWho: (who: string | number) => `Documents for ${who}`,
    doesNotExistsInNoah: `Does not exists in Noah`,
    doesNotHaveRequestToInsuranceAllowance: `Does not have a request to insurance allowance`,
    doNotChangeWithPatient: `Do not change with patient`,
    doNotMention: `Do not mention`,
    doNotMentionCaption: `This line will not be mentioned on attachment 12`,
    doNotMentionOnAttachment12: `Do not mention on attachment 12`,
    doNotUpdateOnProcesses: `Do not update on processes`,
    dottedLine: `Dotted line`,
    download: `Download`,
    downloadDocument: `Download document`,
    downloadMiddleware: `Download middleware`,
    downloadWhat: (what: string | number) => `Download ${what.toString().toLowerCase()}`,
    doYouWantToCreateATenderWithoutContributionsQuestion: `Do you want to create a tender without contributions?`,
    doYouWantToDeleteRuleForWhatQuestion: (what: string | number) => `Do you want to delete rule: ${what.toString().toLowerCase()}?`,
    doYouWantToRepairRuleForWhatQuestion: (what: string | number) => `Do you want to restore rule: ${what.toString().toLowerCase()}?`,
    doYouWantToRepairTemplateWithName: (template: string | number) => `Do you want to restore template: ${template.toString().toLowerCase()}?`,
    draft: `Draft`,
    draftCosiCaption: `Fill in specific needs, evaluate later`,
    dropDocumentHere: `Drop document here`,
    dutch: `Dutch`,
    earCanalLength: `Ear length canal`,
    earMould: `Ear mould`,
    earMoulds: `Ear moulds`,
    edit: `Edit`,
    editable: `Editable`,
    editAddress: `Change address`,
    editConfirmAddress: `Edit/confirm address`,
    editName: `Edit name`,
    editVerb: `Edit`,
    editWhat: (what: string | number) => `Edit ${what.toString().toLowerCase()}`,
    eidData: `Eid data`,
    eidDataLastReadDate: `Date last read out EID`,
    eidDataRead: `Data eid read`,
    eidDataUpdatedToPatient: `Eid data read, patient updated`,
    eidReadInfo: `The patient's data is read via the Eid card.\n\nThe social security number registered on the card will be compared with the social security number of existing patients, \nin case of a match, de patient's data is updated with the data from Eid card.\n\nIf no match found, you will receive a popup screen to confirm before a new patient is created.\n\nAre you sure that the patient is already created, but the social security number isn't filled in:\nclose this window, find the patient and update the Eid data from the detail screen.`,
    electronics: `Electronic`,
    email: `Email`,
    enableCashRegisterResource: `Cash register active`,
    enableCashRegisterResourceQuestion: `Enable cash register module for this location?`,
    enableExternalLogin: `Enable external login`,
    enableExternalLoginContent: `User will be able to use google services to log in.`,
    enableExternalLoginQuestion: `Enable external login?`,
    enablePlannable: `Make plannable`,
    enablePlannableQuestion: `Make location plannable?`,
    enableStockManagementResource: `Usable as stock location`,
    enableStockManagementResourceQuestion: `Disable stock management for this location?`,
    end: `End`,
    endAllSessions: `End all sessions`,
    endAllSessionsForUser: `End all sessions for user`,
    endDate: `End date`,
    ended: `Ended`,
    endLoan: `End loan`,
    endResult: `End result`,
    endSession: `End session`,
    endSessionForUser: `End session for user`,
    endThisSession: `End this session`,
    endTrial: `End trial`,
    endTrialAdministratively: `End trial (administratively)`,
    endTrialAdministrativelyCaption: `End trial administratively\nFill in a date for administrative end`,
    endTrialCaption: `End loan\nDevices will return to stock, this step is not necessary, loan will end automatically at handover`,
    endTrialPositively: `End trial (positive)`,
    endVerb: `End`,
    english: `English`,
    enterDiscountAbsolute: `Fill in discount in EURO`,
    enterDiscountInPercentage: `Fill % discount`,
    enterSerialNumber: `Enter serialnumber`,
    entPhysician: `Ear Nose Throat Physician`,
    entPhysicianCreated: `New ENT physician created`,
    entPhysicians: `ENT physicians`,
    entPhysicianShort: `ENT physician`,
    entPhysicianUpdated: `ENT physician updated`,
    equation: `Equation`,
    equipmentTestTitle: `Test equipment (trial)`,
    error: `Error`,
    errorCannotStartExternalModules: `Error, cannot start external modules`,
    errorDownloadingConfiguration: `Error retrieving configuration`,
    evaluateCosi: `Evaluate COSI`,
    evaluateCosiCaption: `Fill in specific needs and evaluate immediately`,
    evaluatedNegatively: `Negative evaluation`,
    evaluateVerb: `Evaluate`,
    evaluation: `Evaluation`,
    evaluationDate: `Evaluation date`,
    everythingCapital: `Everything captital`,
    everythingLower: `Everything to lower`,
    exact: `Exact`,
    example: `Example`,
    exampleOrEmptyDocument: `Example or empty document`,
    expected: `Expected`,
    expectedDeliveryDate: `Expected delivery date`,
    expectedEndDate: `Expected end date`,
    expectedHandoverDate: `Expected handover date`,
    expectedPaymentBefore: `Expected payment before`,
    expectedWhat: (what: string | number) => `Expected ${what.toString().toLowerCase()}`,
    expectedWhatExpired: (what: string | number) => `Expected ${what.toString().toLowerCase()} expired`,
    expense: `Expense`,
    expenseOrIncome: `Expense or income`,
    expired: `Expired`,
    exportAllResultsCountInXlsxFormat: (count: string | number, format: string | number) => `Export all results (${count.toString().toLowerCase()}) in ${format.toString().toLowerCase()} format`,
    exportFilteredResultsCountInFormat: (count: string | number, format: string | number) => `Export ${count.toString().toLowerCase()} filtered results in ${format.toString().toLowerCase()} format`,
    exportVerb: `Export`,
    exportWhat: (what: string | number) => `Export ${what.toString().toLowerCase()}`,
    extension: `Extension`,
    external: `External`,
    externalAuthentication: `External authentication`,
    externalLoginDisabled: `User cannot log in via google services`,
    externalLoginEnabled: `User can log in via google services`,
    externalReference: `External reference`,
    exVatShort: `ex. VAT`,
    failed: `Failed`,
    failedTrial: `Failed trial`,
    failedTrialCaption: `End trial negative\nPatient is not satisfied, devices will return to stock`,
    fault: `Fault`,
    faultSingularOrPlural: `Fault(s)`,
    faultStartNoahModules: `Fout starting Noah modules`,
    female: `Female`,
    file: `File`,
    filledInWhat: (what: string | number) => `Filled in ${what.toString().toLowerCase()}`,
    fillInMissingData: `Fill in missing data`,
    fillMfrModelInYourself: `Fill in mfr/model manually`,
    fillWhat: (what: string | number) => `Fill in ${what.toString().toLowerCase()}`,
    filter: `Filter`,
    filteredResults: `Filtered results`,
    financial: `Financial`,
    firstDeliveryDate: `First delivery date`,
    firstName: `First name`,
    firstUsageDate: `Date first usage`,
    fitted: `Fitted`,
    fitting: `Fitting`,
    fittingDate: `Fitting date`,
    followUpVerb: `Follow up`,
    forAPatientQuestion: `For a patient?`,
    forExampleHearingAid: `F.e. hearing aid`,
    forExampleShort: `f.e.`,
    forgotPasswordHeaderText: `Fill in your email address.\nYou will receive instruction on how to reset your password afterwards.`,
    forgotPasswordQuestion: `Forgot password?`,
    form: `Form`,
    formattingOptions: `Formatting options`,
    formRequired: (field: string | number) => `${field} is a required field`,
    forPatient: `For patient`,
    forWho: (who: string | number) => `For ${who}`,
    french: `French`,
    frequencyRange: `Frequency range`,
    from: `From`,
    fromArrowTo: `From -> to`,
    fromCompleteList: `From complete list`,
    fromLimitativeListNoahOrManual: `From limitative list, Noah or manual`,
    fromLocation: `From location`,
    fromPreviousReadOutsFromNoah: `From previous readouts from Noah`,
    fromProductCatalog: `From product catalog`,
    fromSupplier: `From supplier`,
    fromTemplate: `From template`,
    fromTo: `From / To`,
    fullDay: `Full day`,
    fullyCredited: `Fully credited`,
    fullyPaid: `Fully paid`,
    function: `Function`,
    functions: `Functions`,
    futureAppointments: `Future appointments`,
    gender: `Gender`,
    general: `General`,
    generalData: `General data`,
    generalPractitioner: `General practitioner`,
    generalPractitionerCreated: `New general practitioner created`,
    generalPractitioners: `General Practitioners`,
    generalPractitionerUpdated: `General Practitioner updated`,
    goBack: `Go back`,
    graph: `Graphic`,
    graphs: `Graphics`,
    groupedByMfrModel: `Grouped (by brand/model)`,
    groupedPayments: `Grouped payments`,
    halfOfTime: `Halft of time`,
    hamsIsNotRegisteredAsMgmtSoftware: `HAMS is not registered as the 'MGMT' software for Noah.`,
    handedOver: `Handed over`,
    handedOverAndPaid: `Handed over and paid`,
    handled: `Handled`,
    handover: `Handover`,
    handoverCertificate: `Handover certificate`,
    handoverDate: `Handover date`,
    handoverOfEquipment: `Handover of equipment`,
    handoverOverDue: `Handover overdue`,
    handoverRegistered: `Handover registered`,
    handovers: `Handovers`,
    handoverToPatient: `Handover to patient`,
    hardware: `Hardware`,
    hardwareExplanation: `Something tangible (e.g. An earpiece, hearing aid or hearing protection)`,
    hasBatteries: `Has batteries`,
    hasBatteriesExplanation: `This device contains batteries, so BEBAT applies.`,
    hasDeliveries: `Has deliveries`,
    hasNoBatteries: `Has no batteries`,
    hasNoDeliveries: `Does not have deliveries`,
    hasNoOrders: `Does not have orders`,
    hasNoPurchases: `Does not have purchases`,
    hasNoSerialNumber: `Does not have serial number`,
    hasOrders: `Has orders`,
    hasPurchases: `Has purchases`,
    hasRequestToInsuranceAllowance: `Has request to insurance allowance`,
    hasSerialNumber: `Has a serial number`,
    hasVaryingPrice: `Has a varying price`,
    heading: `Heading`,
    headPhones: `Headphones`,
    headPhonesLeftRight: `Headphones L/R`,
    headPhonesShort: `HP`,
    healthInsuranceFund: `Health insurance fund`,
    healthInsuranceFunds: `Health insurance funds`,
    healthInsuranceFundShort: `Health ins. Fund`,
    hearingAid: `Hearing aid`,
    hearingAids: `Hearing aids`,
    hearingAidsShort: `HA`,
    hearingCentre: `Hearing centre`,
    hearingImprovementInWhat: (what: string | number) => `Hearing improvement in ${what.toString().toLowerCase()}`,
    hearingLossLessOrMoreThan40dB: `Hearing loss more/less than 40dB`,
    hearingLossLessThen40dBWithAverages: `Hearing loss less than 40dB (avg. 1000/2000/4000Hz)`,
    hearingLossMoreThan40dBWithAverages: `Hearing loss more than 40dB (avg. 1000/2000/4000Hz) or exception (2.1.2)`,
    hearingProtection: `Hearing protection`,
    here: `Here`,
    hideSplitUpPerVatPercentage: `Hide split up per VAT%`,
    hideWhat: (what: string | number) => `Hide ${what.toString().toLowerCase()}`,
    hifWhatDoesNotExistAnymore: (what: string | number) => `Ins. comp ${what.toString().toLowerCase()} does not exist anymore`,
    history: `History`,
    home: `Home`,
    homePhone: `Home phone #`,
    hospitalized: `Hospitalized`,
    hour: `Hour`,
    hourOrHours: `Hour(s)`,
    hours: `Hours`,
    hoursAgo: (hours: string | number) => `${hours} hour ago`,
    hoursPerDay: `hours/day`,
    howDoesEidReadWorks: `How does Eid card reading works?`,
    icoHifsPrefixIsCodeHif: `In case of health insurance fund, prefix will be the health insurance fund's code`,
    id: `Id`,
    identityCard: `Identity card`,
    ifNoPatientSelectedItsObligatedToSelectAVatCustomer: `If no patient selected, select a VAT customer`,
    ignore: `Ignore`,
    ignored: `Ignored`,
    ignoreThisDevice: `Ignore this device`,
    ignoreThisReadOut: `Ignore this readout`,
    ignoreVerb: `Ignore`,
    ignoreWhatQuestion: (what: string | number) => `Ignore ${what.toString().toLowerCase()}?`,
    image: `Image`,
    imageFormattingHelperText: (key: string | number) => `An image can optionally be formatted with dimension\nUse {{${key.toString().toLowerCase()}:WxH}} with W = width, H = height (in pixels)\nAlternative it's possible to use this key inside a table. The dimensions of the cell will be used as width and height for this image`,
    import: `Import`,
    importCountDevices: (count: string | number) => `Import ${count.toString().toLowerCase()} devices`,
    importDeviceInStock: `Import device in stock`,
    imported: `Imported`,
    importedFromNoah: `Imported from Noah`,
    importIfNewerAction: `Import if new action`,
    importMultipleDevicesToStock: `Import multiple devices to stock`,
    importMultipleDevicesToStockContentCount: (count: string | number) => `Based on the filter: ${count.toString().toLowerCase()} devices found\n\nDo you want to import?`,
    importThisDeviceInStock: `Import this device in stock`,
    importVerb: `Import`,
    importWhat: (what: string | number) => `Import ${what.toString().toLowerCase()}`,
    improvement: `Improvement`,
    inactive: `Inactive`,
    inBackOrder: `Backorder`,
    inBrowser: `In browser`,
    income: `Income`,
    inComparisonWithPreviousPeriod: `In comparion with previous period`,
    incorrectlyLinked: `Incorrectly linked`,
    indexNumber: `Index number`,
    individualPerSerialNumber: `Individual (per serial number)`,
    individualReadOutDevice: `Individual readout device`,
    inForRepair: `In for repair`,
    initials: `Initials`,
    inLoan: `In loan`,
    inOrder: `In order`,
    inRepair: `In repair`,
    insertCard: `Insert a valid EID card`,
    installation: `Installation`,
    installationAndConfiguration: `Installation and configuration`,
    installMiddleware: `Install middleware`,
    inStock: `In stock`,
    inStockOnALocation: `In stock on a location`,
    insufficientInformation: `Insufficient information`,
    insurance: `Insurance`,
    insurancesAndProlongedWarranties: `Insurances and prolonged warranties`,
    intake: `Intake`,
    intensity: `Intensity`,
    internal: `Internal`,
    internalReference: `Internal reference`,
    inTest: `In test`,
    invalidCombinationEmailPassword: `Email/password combination incorrect`,
    invalidDateFormat: `Invalid date format`,
    invalidLicenseContactHams: `Invalid license, contact HAMS`,
    inVatShort: `in VAT`,
    inventoryItem: `Inventory item`,
    inventoryItems: `Inventory items`,
    inventoryManagement: `Inventory management`,
    inventoryManagementShortened: `Inventory mgmt`,
    invoice: `Invoice`,
    invoiceAndCash: `Invoice and cash`,
    invoiced: `Invoiced`,
    invoiceNumber: `Invoice number`,
    invoiceProcess: `Invoice process`,
    invoices: `Invoices`,
    invoiceVerb: `Invoice`,
    isElectronics: `Is electronics`,
    isElectronicsExplanation: `This device contains electronics, so taxes apply.`,
    isNotElectronics: `Not electronics`,
    isNotTailorMade: `Not tailor-made`,
    isTailorMade: `Tailor-made`,
    item: `Item`,
    itemAddedToRepair: `Item added to repair`,
    itemAlreadyInList: `Item already in list`,
    itemFromProductCatalog: `Item from product catalog`,
    itemReserved: `Item reserved`,
    items: `Items`,
    itemsReturned: `Items returned`,
    itemsToDeliver: `Items to deliver`,
    itemsToLoan: `Items to loan`,
    itemsToOrder: `Items to order`,
    kpiCashRegister: `Using cash register module`,
    kpiPlannable: `Plannable`,
    kpiStockResource: `Using stock module`,
    language: `Language`,
    lastActivity: `Last Activity`,
    lastHandover: `Last handover`,
    lastLinkWasWhat: (what: string | number) => `This device was last linked to:\n${what.toString().toLowerCase()}`,
    lastLoggedIn: `Last logged in`,
    lastLoggedInDate: `Last logged in date`,
    lastModifiedAt: `Last modified on`,
    lastMonth: `Last month`,
    lastName: `Last name`,
    lastNameCommaFirstName: `Lastname, Firstname`,
    lastPayments: `Last payments`,
    lastReadOutDate: `Last readout date`,
    lastReadOutDevicesFromNoah: `Last readout devices (from Noah)`,
    lastSpsMetaRefHeader: `Last settl. prop. meta-ref`,
    lastSpsRefHeader: `Last settl. prop. ref.`,
    lastUpdated: `Last updated`,
    lastUsageDate: `Last usage date`,
    lastWeek: `Last week`,
    lastWhat: (what: string | number) => `Last ${what.toString().toLowerCase()}`,
    lastWhatDays: (what: string | number) => `Last ${what.toString().toLowerCase()} days`,
    lastYear: `Last year`,
    left: `Lef`,
    leftEar: `Left ear`,
    lent: `Lent`,
    less: `Less`,
    letter: `Letter`,
    letterToAdvisoryPhysician: `Letter to advisory physician`,
    letterToEntPhysician: `Letter to ENT physician`,
    license: `License`,
    line: `Line`,
    lines: `Lines`,
    link: `Link`,
    linked: `Linked`,
    linkedToMultiple: `Linked to multiple`,
    linkHealthInsuranceFund: `Link health insurance fund`,
    linkItemWithThisWhat: (what: string | number) => `Link item with this ${what.toString().toLowerCase()}`,
    linkRizivFromProductCatalogCaption: `Multiple links exist for this item\nMake a choice`,
    linkWithPatient: `Link with a patient`,
    linkWithSameItemFromProductCatalogAsLastTime: `Link with same item from product catalog as last time`,
    list: `List`,
    listPrice: `List price`,
    loading: `Loading…`,
    loan: `Loan`,
    loanedOutItems: `Loaned out items`,
    loanEnded: `Loan ended`,
    loans: `Loans`,
    loanSlip: `Loan slip`,
    loanStarted: `Loan started`,
    location: `Location`,
    locationCashAndStockResourcesBothNeedToBeEnabled: `A location where the cashregister is enabled, should also be a stock location.`,
    locationCashRegister: `Cash register location`,
    locationCashRegisterResourceWillBeDisabled: `The cash register module will be disabled for this location`,
    locationCashRegisterResourceWillBeEnabled: `The cash register module will be activated for this location`,
    locationCreated: `Location created`,
    locationDetails: `Location details`,
    locationPlannable: `Plannable`,
    locationPlannableWillBeDisabled: `This location can't be used to schedule appointments anymore.`,
    locationPlannableWillBeEnabled: `This location can be used to schedule appointments.`,
    locations: `Locations`,
    locationsCurrentlyOnStock: `Locations currently in stock`,
    locationSlashRoom: `Location/Room`,
    locationStockManagement: `Stock location`,
    locationStockManagementResourceWillBeDisabled: `The stock management module will be disabled for this location`,
    locationStockManagementResourceWillBeEnabled: `The stock management module will be activated for this location`,
    locationUpdated: `Location updated`,
    locationWhereProductsWillBeReceived: `Location where products will be received`,
    locationWillAlsoBeChangedOnOtherProcesses: `Location will also be changed at active processes`,
    locationWillAlsoBeChangedWithPatient: `Location will also be changed at patient`,
    locationWillBeDeleted: `This location will be deleted permanently.`,
    locationWillNotBeChangedWithPatient: `Location will not be changed at patient`,
    locationWillOnlyBeChangedWithThisPatientAndNotOnProcesses: `Location will only be changed on patient and not on active processes`,
    locationWithAddressExists: `A location with this address already exists.`,
    locationWithNameExists: `A location with this name already exists.`,
    loggedIn: `Logged in`,
    loggedInDevices: `Logged in devices`,
    login: `Login`,
    loginHeaderText: `Log in with your email and password`,
    logo: `Logo`,
    logout: `Logout`,
    logoutAllSessions: `Logout all sessions`,
    lost: `Lost`,
    lowerCase: `Lowercase`,
    lumpSum: `Lump sum`,
    lumpSumContribution: `Lump sum contribution`,
    mainNomenclatureCode: `Main nomenclature code`,
    mainUser: `Main user`,
    makeAChoice: `Make a choice`,
    makeAChoiceFromCompleteList: `Make a choice from complete list`,
    makeDefault: `Make default`,
    makeDefaultOffice: `Make this the default office`,
    makeLocationNoLongerPlannableContent: `Make location no longer plannable on this location (and spaces)\nDo you want to continue`,
    makeLocationNoLongerPlannableQuestion: `Make location no longer plannable?`,
    makeLocationPlannableAgainContent: `By confirming it's possible again to plan appointments on this location (and spaces)\nDo you want to continue?`,
    makeLocationPlannableAgainQuestion: `Make location plannable again?`,
    makeUserNoLongerPlannableContent: `By confirming it's no longer possible to plan appointments for this user\nDo you want to continue?`,
    makeUserNoLongerPlannableQuestion: `Make use no longer plannable?`,
    makeUserPlannableAgainContent: `By confirming it's possible again to plan appointments for this user\nDo you want to continue?`,
    makeUserPlannableAgainQuestion: `Make user plannable again?`,
    male: `Male`,
    manual: `Manual`,
    manualHandoverDateEntryContent: `Manual entry date of last handover\n|(*if handover not registered through HAMS)`,
    manualSelectADate: `Manually select a date`,
    manufacturer: `Manufacturer`,
    manufacturerReference: `Manufacturers reference`,
    manufacturers: `Manufacturers`,
    markItemAsDemoQuestion: `Mark item as DEMO?`,
    markItemAsNonDemoQuestion: `Mark item as non demo?`,
    material: `Material`,
    measured: `Measured`,
    measureVerb: `Measure`,
    medicalPrescription: `Medical prescription`,
    medicalPrescriptionApprovedTitle: `Medical prescription received (approval ENT)`,
    medicalPrescriptionDisApprovedTitle: `Medical prescription (NOT approved by ENT)`,
    medicalPrescriptionReceived: `Medical prescription received`,
    medicalPrescriptionReceivedNotSentToAdvPhys: `Medical prescription received, not sent to adv. phys.`,
    medicalTreatmentNomenclature: `Nomenclature`,
    method: `Method`,
    minimalRequiredDocumentsAccordingToRiziv: `Minimal required documents (RIZIV)`,
    minute: `Minute`,
    minuteOrMinutes: `Minute(s)`,
    minutes: `Minutes`,
    minutesAgo: (minutes: string | number) => `${minutes} minute(s) ago`,
    missingDocuments: `Missing documents`,
    mobile: `Mobile`,
    mobilePhone: `Mobile phone #`,
    mode: `Model`,
    model: `Model`,
    modules: `Modules`,
    moduleStarted: `Module started`,
    mono: `Mono`,
    month: `Month`,
    monthHandover: `Month handover`,
    monthOrMonths: `Month(s)`,
    months: `Months`,
    more: `More`,
    moreActions: `More actions`,
    moreInformation: `More information`,
    moreThanOneDayAgo: `> 1 day ago`,
    mostOfTime: `Most of time`,
    mostRecentTonalAudiogramOfDate: `Most recent tonal audiogram of`,
    muchBetter: `Much better`,
    multipleLines: `Multiple lines`,
    multipleModulesCanOpenThisActionSelectOne: `Multiple modules can open this action, select one`,
    multipleNomenclaturesSelectedThisIsNotPossible: `Multiple nomenclatures selected, this is not possible`,
    multipleProductsNotSpecificForAPatient: `Multiple products, not specifically for a patient`,
    multipleRooms: `Multiple rooms`,
    mustBeLargerThanWhat: (what: string | number) => `Must be larger than ${what.toString().toLowerCase()}`,
    mustUpdateText: `An update is available, \nclick below to update!`,
    myProfile: `My profile`,
    name: `Name`,
    navigateToWhat: (what: string | number) => `Navigate to ${what.toString().toLowerCase()}`,
    navigation: `Navigation`,
    need: `Need`,
    needed: `Needed`,
    needs: `Needs`,
    needsASignature: `Needs a signature`,
    needsASignatureQuestion: `Needs a signature?`,
    negative: `Negative`,
    negativeEvaluationTrial: `Negative evaluation trial?`,
    neverImport: `Never import`,
    new: `New`,
    newAnnotation: `New annotation`,
    newEntPhysician: `New ENT physician`,
    newGeneralPractitioner: `New general practitioner`,
    newHardware: `New hardware type 'other'`,
    newHearingAid: `New hearing aid`,
    newItem: `New item`,
    newItemChoice: `New item: make a choice`,
    newLocation: `New Location`,
    newOffice: `New office`,
    newOrder: `New order`,
    newPassword: `New password`,
    newPatient: `New patient`,
    newProduct: `new Product`,
    newRemark: `New remark`,
    newRequestForInsuranceAllowance: `New request for insurance allowance`,
    newRfiaShort: `New request for IA`,
    newSerialNumber: `New serial number`,
    newService: `New service`,
    newSupplier: `New supplier`,
    newTailorMadeProduct: `New tailor made product`,
    newWhat: (what: string | number) => `New ${what.toString().toLowerCase()}`,
    next: `Next`,
    nextAppointments: `Upcoming appointments`,
    nhaxDataReadAndAddedToThisPatient: `NHAX data read and added to this patient`,
    no: `No`,
    noAccess: `No access`,
    noAccessToAllPages: `No access to all pages`,
    noAccessToThisFunction: `You have insufficient rights to execute this function`,
    noAccessToThisPageContactHamsAdmin: `No access to this page\nContact a HAMS admin user for more information`,
    noAddressInformation: `No adress information`,
    noahActions: `Noah actions`,
    noahActionSingularOrPlural: `Noah action(s)`,
    noahData: `Noah data`,
    noahExternalModules: `Noah external module`,
    noahImportAlwaysCaption: `Automatically import all devices to stock\nThis method means less manual word, but older devices will also appear in stock`,
    noahImportIfNewerActionCaption: `Only import devices on a 'new' Noah action\nOld devices will not be imported to stock`,
    noahNeverImportCaption: `Never automatically import devices to stock\nThis option results in most control over stock\nDevices must be added stock manually`,
    noahSessions: `Noah sessions`,
    noAppointmentsFound: `No appointments found`,
    noAppointmentsPlanned: `No appointments planned`,
    noBeidMiddlewareInstalled: `No BEID middleware installed`,
    noCardReaderAttached: `No card reader attached`,
    noCloseThisScreen: `No, close this screen`,
    noCodesLinkedYet: `No codes linked yet`,
    noCommaGoBack: `No, go back`,
    noCommaWhat: (what: string | number) => `No, ${what.toString().toLowerCase()}`,
    noConnectionCouldBeMadeToEidSoftware: `No connection could be made to EID software\nIf you're sure it's installed, start it first`,
    noConnectionToClientSoftware: `No connection with Hams.Client software`,
    noConnectionToClientSoftwareText: `No connection could be made to client software\nIf you're sure it's installed, start it first`,
    noDataInHams: `No data in HAMS yet`,
    noDataToCompare: `No data to compare`,
    noDecimals: `No decimals`,
    noDefaultOfficeSelected: `No default office assigned`,
    noDemoRegular: `No demo (regular)`,
    noDevicesReadOutByNoahYet: `No devices read out by Noah`,
    noDifference: `No difference`,
    noEarMouldsDefined: `No ear moulds defined, click '+' to create a new`,
    noEidCardInserted: `No ID card inserted`,
    noEmail: `No email`,
    noEndDate: `No end date`,
    noFollowUpProcesses: `No follow up processes`,
    noGeneralPractitionersDefined: `No general practitioners defined, click '+' to create a new`,
    noItDoesntFit: `No, it does not fit`,
    noItemsFound: `No items found`,
    noItemsFoundForSupplier: (supplier: string | number) => `No items found for supplier ${supplier.toString().toLowerCase()}`,
    noItemsInStockContent: `No items in stock, add an order, repair, sales to view items`,
    noLinesSelectedYet: `No lines selected yet`,
    noLinkedAccountsReceivableForCashSales: `No linked accounts receivable for cash sales`,
    noLinkWithHealthInsuranceFund: `No link with a health insurance fund`,
    noLocationsDefined: `No locations defined, click '+' to create a new`,
    noLogoPresent: `No logo present`,
    noLongerActive: `No (longer) active`,
    nomenclature: `Nomenclature`,
    nomenclatureCode: `Nomenclature code`,
    nomenclatureSingularOrPlural: `Nomenclature(s)`,
    none: `None`,
    noNomenclaturesSelected: `No nomenclatures selected`,
    noOffices: `No offices`,
    noOfficesDefined: `No offices have been defined yet.`,
    noPatientFoundWithThisSsnrCreateNewQuestion: `No patient found with this social security number\nCreate new?`,
    noPatientsDefined: `No patients defined, click '+' to create a new patient`,
    noPhoneNumber: `No phone number`,
    noProductsDefined: `No products defined, click '+' to create a new product`,
    noResults: `No results`,
    noResultsCommaChangeFilterParams: `No results, change filter params.`,
    noRfiaDefinedText: `No requests registered, select a patient to create a new one`,
    noRizivNumberLinkAction: `Click here to link`,
    noRizivNumberLinkedText: `No riziv number linked to this user`,
    normal: `Normal`,
    normScore: `Norm score`,
    normValue: `Norm value`,
    normValueForThisList: (list: string | number) => `Norm value for list: ${list.toString().toLowerCase()}`,
    noSignatureIsNeededForThisDocument: `This document must never be signed`,
    noSignatureNeeded: `No signature needed`,
    noSocialSecurityNumberDefined: `No social security number defined`,
    noSpeechTestRecordedCreateOneFirst: `No speech tests recordes\nCreate a speech test first via a Noah module.`,
    noSuppliersDefined: `No suppliers defined, click '+' to create a new`,
    noSupplierWithPurchasesRegisteredYet: `No suppliers (with purchases) registered`,
    notACashRegisterLocation: `Does not use cash register`,
    notActive: `Not active`,
    notAllNomenclaturesAreValidOnThisDate: `Not alle nomenclatures are valid on this date\nIn request`,
    notAllRizivCodesAreSelectedDoYouWantToProceed: `No all riziv codes selected\nAre you sure you want to continue?`,
    notAllUserVisibleHelperText: `Not all users visible? Only audiologist are shown\nAn audiologist is a user with RIZIV number`,
    notApplicable: `Not applicable`,
    notApplicableAbbreviation: `n/a`,
    notASingleDeviceRegisteredInProductCatalog: `Not a single device registered in product catalog`,
    notAStockLocation: `Location without stock`,
    notAvailable: `Not available`,
    notAvailableForWhat: (what: string | number) => `Not available for ${what.toString().toLowerCase()}`,
    notAvailableInCalendar: `Not available in calendar`,
    notAvailableInCalendarCaption: `It's not possible to create appointments on this location`,
    notCompleted: `Not completed`,
    notConfirmed: `Not confirmed`,
    notConfirmedTooltip: `User has not yet opened his/her email nor created a profile.`,
    notDeliveredOrdersSingularOrPlural: `Not linked orders`,
    notDeliveredYet: `Nog delivered yet`,
    notDirectedToVatCustomer: `Not directed to VAT customer`,
    note: `Note`,
    notEditable: `Not editable`,
    noTemplatesForThisTypeOrSubject: `No templates for this type/subject`,
    notFilledIn: `Not filled in`,
    notForPatient: `Not for patient`,
    nothingCredited: `Nothing credited`,
    nothingFoundCommaChangeSearchParams: `Nothing found, change search params`,
    nothingPaid: `Nothing paid`,
    notifications: `Notifications`,
    notImportedNotIgnored: `Not imported nor ignored`,
    notInventoried: `Not inventoried`,
    notLinked: `Not linked`,
    notLinkedInNoah: `Not linked to Noah`,
    notLinkedNotIgnored: `Not linked, not ignored`,
    notPlannable: `Not plannable`,
    noTransactionsForThisItem: `No transactions for this item`,
    notRefundable: `Not refundable`,
    notRefundableCaption: `Line is not refundable`,
    noTrialStartedYet: `No trial started yet`,
    notSendToSupplierYet: `Not send to supplier yet`,
    notSet: (info: string | number) => `${info} not set`,
    notTangible: `Not tangible`,
    notTangibleCaption: `Not a tangible product\nService, insurance or prolonged warranty`,
    notViewableInApp: `Not possible to view/edit in app`,
    notWhat: (what: string | number) => `Not ${what.toString().toLowerCase()}`,
    notYetRepaired: `Not yet repaired`,
    noTypesAnnotationDefined: `No annotation types defined yet`,
    noTypesAppointmentsDefined: `No appointment types defined yet`,
    noVatNumber: `No VAT number found`,
    noWhat: (what: string | number) => `No ${what.toString().toLowerCase()}`,
    noWhatDefined: (what: string | number) => `No ${what.toString().toLowerCase()} defined`,
    noWhatFoundClickPlusToCreateNew: (what: string | number) => `No ${what.toString().toLowerCase()} found, click '+' to create new`,
    noWhatRegistered: (what: string | number) => `No ${what.toString().toLowerCase()} registered`,
    noWhatSelected: (what: string | number) => `No ${what.toString().toLowerCase()} selected`,
    nowNotWhat: (what: string | number) => `Not now ${what.toString().toLowerCase()}`,
    nowWhat: (what: string | number) => `Now ${what.toString().toLowerCase()}`,
    number: `Number`,
    numbering: `Numbering`,
    numberingWhat: (what: string | number) => `Numbering ${what.toString().toLowerCase()}`,
    office: `Office`,
    offices: `Offices`,
    ofPreviousStep: `Of previous step`,
    oldPassword: `Old password`,
    oneDecimal: `1 significant digit`,
    oneOrMoreOffices: `One or multiple offices`,
    onLocation: `On location`,
    onlyActive: `Only active`,
    onlyAdminUserCanPerformThisAction: `Only admin users can perform this action`,
    onlyHifPaid: `Only I.C. paid`,
    onlyInCaseOfNegativeEvaluation: `Only in case of negative evaluation`,
    onlyInFuture: `Only in future`,
    onlyInPast: `Only in past`,
    onlyPatientPaid: `Only patient paid`,
    onRemote: `On remote`,
    open: `Open`,
    openConfigurationScreen: `Open configuration screen`,
    optional: `Optional`,
    options: `Options`,
    order: `Order`,
    orderCancelled: `Order cancelled`,
    orderDate: `Order date`,
    ordered: `Ordered`,
    orderForm: `Order form`,
    orderFormToWho: (who: string | number) => `Order form for ${who}`,
    orderNumber: `Order number`,
    orderPlaced: `Order placed`,
    orders: `Orders`,
    orientation: `Orientation`,
    orientationTest: `Orientation test`,
    origin: `Origin`,
    other: `Other (tailor-made)`,
    otherData: `Other data`,
    otherDocuments: `Other documents`,
    otherExplanation: `With / without serial number (e.g. charger, …)µ`,
    otherItemFromProductCatalog: `Another item from product catalog`,
    otherPatientWithThisSsNumberAlreadyExists: `Other patient with same social security number already exists`,
    otherTailorMade: `Other (tailor-made)`,
    otherWithoutSerialNumber: `Other without serialnumber`,
    otherWithSerialNumber: `Other (with serial number)`,
    outstanding: `Outstanding`,
    outstandingBalance: `Outstanding balance`,
    outstandingBalanceNotOnSps: `Outstanding balance not on settl. prop.`,
    outstandingTenders: `Outstanding tenders`,
    overdue: `Overdue`,
    overDue: `Overdue`,
    overview: `Overview`,
    overviewDeliveredToDeliver: `Overview delivered/to deliver`,
    ownProperty: `Own property`,
    padNumbers: `Pad numbers`,
    padNumbersExplanation: `F.e. to get 0001, select 4. To get 001, select 3`,
    page: `Page`,
    paid: `Paid`,
    partialDelivered: `Partially delivered`,
    partialDelivery: `Partial delivery`,
    partlyDelivered: `Partially delivered`,
    partlyPaid: `Partly paid`,
    partOfRfiaClickToNavigate: `Part of request for insurance allowance, click to navigate`,
    partPatient: `Part patient`,
    passportPhotograph: `Passport photograph`,
    password: `Password`,
    passwordChanged: `Password changed`,
    passwordComplexityRule: `Password should be at least 6 character long, one caption [A-Z], one lowercase [a-z], one number [0-9] and one symbol  [!@#$%]`,
    passwordDoesNotMatch: `Passwords do not match`,
    passwordIncorrect: `Password incorrect`,
    patient: `Patient`,
    patientActiveCaption: `Patient is an active patient`,
    patientAlsoExistsInNoah: `Patient also exists in Noah`,
    patientCannotChangeIfEidRead: `It's not possible to change name/date of birth/gender, \nbecause eid data was read`,
    patientCreated: `Patient created`,
    patientDeceasedCaption: `Patient is deceased`,
    patientInactiveCaption: `Patient is no longer active`,
    patients: `Patients`,
    patientUpdated: `Patient updated`,
    payment: `Payment`,
    paymentDate: `Payment date`,
    paymentMethod: `Payment method`,
    paymentMethods: `Payment methods`,
    paymentReference: `Payment reference`,
    payments: `Payments`,
    paymentStatus: `Payment status`,
    payVerb: `Pay`,
    personalInformationData: `Personal information`,
    personalisedVersion: `Personalized version`,
    personalShare: `Personal share`,
    phone: `Phonenumber`,
    place: `Place`,
    placeDigitalSignature: `Place digital signature`,
    placeOrder: `Place order`,
    planAppointment: `Plan appointment`,
    plannable: `Plannable`,
    plannableLocations: `Plannable locations`,
    plannableUsers: `Plannable users`,
    plannedAppointmentIsAfterExpectedRedeliveryDate: `Planned appointments is AFTER expected redelivery date!`,
    pleaseAddOffice: `Please add an office for this physician first`,
    point: `Point`,
    points: `Points`,
    positive: `Positive`,
    predefined: `Predefined`,
    predefinedType: `Predefined type`,
    preferences: `Preferences`,
    preferredLocation: `Preferred location`,
    preferredLocationFor: `Preferred location for`,
    prefix: `Prefix`,
    prescription: `Prescription`,
    prescriptionAttachment17: `Prescription (attachment 17)`,
    prescriptionDate: `Prescription date`,
    prescriptionForTrial: `Prescription for trial`,
    prescriptionForTrialDate: `Prescription for trial date`,
    prescriptionForTrialReceived: `Prescription for trial received`,
    prescriptionReceived: `Prescription received`,
    prescriptionStep1Caption: `Prescription for trial\nFilled in by ENT physician`,
    prescriptionStep2Caption: `Request by audiologist\nFilled in by audiologist`,
    prescriptionStep3Caption: `Prescription\nFilled in by ENT physician`,
    prescriptionStep4Caption: `Approval advisory physician\nFilled in by health insurance fund`,
    pressEnterToSearch: `Press enter to search`,
    previous: `Previous`,
    price: `Price`,
    priceExVat: `Price (ex VAT)`,
    priceForPatient: `Price for patient`,
    priceIncludesAllContributionsRecupelAndBebat: `Price includes all contributions (recupel & bebat)`,
    priceInVat: `Price (in VAT)`,
    priceMustBeLowerThanListPrice: `Amount must be larger than list price`,
    prices: `Prices`,
    pricing: `Pricing`,
    priority: `Priority`,
    privateLabel: `Private label`,
    privateLabelData: `Private label data`,
    proceed: `Proceed`,
    procentSpeechImprovementAccordingToBIAPMethod: `% speech intelligibility acc. BIAP method`,
    procentSpeechImprovementAccordingToICAMethod: `% speech intelligibility acc. SRT method`,
    procentSpeechImprovementAtSrtPoint: `% speech intelligibility at SRT point`,
    process: `Process`,
    processAdvanceInvoice: `Process advance invoice`,
    processes: `Processes`,
    product: `Product`,
    productCatalog: `Product catalog`,
    productCatalogItem: `Product catalog item`,
    productCreated: `Product created`,
    productInfo: `Product information`,
    productName: `Product name`,
    productUpdated: `Product updated`,
    productWithoutSerialNumber: `Product without serialnumber`,
    productWithSerialNumber: `Product with serialnumber`,
    productWithThisIntRefExists: `A product with this internal reference already exists.`,
    productWithThisManRefExists: `A product with this manufacturing reference already exists.`,
    productWithThisNameExists: `A product with this name already exists.`,
    profile: `Profile`,
    proforma: `Proforma`,
    proformaAttachment12: `Proforma attachment 12`,
    prolongedWarranty: `Prolonged warranty`,
    properties: `Properties`,
    propertiesOfTemplateNotRecursiveOnDocuments: `Properties of earlier created documents will not be changed\nThis change will only be reflected on documents created afterwards`,
    propertiesOfWhat: (what: string | number) => `Properties of ${what.toString().toLowerCase()}`,
    propertyOf: `Property of`,
    providePassword: `Provider password`,
    purchase: `Purchase`,
    purchaseCredited: `Purchase credited`,
    purchaseDate: `Purchase date`,
    purchaseListPrice: `Purchase list price`,
    purchaseListPriceShort: `Purchase list price`,
    purchaseOrderCustomer: `Purchase order customer`,
    purchaseOrderSupplier: `Purchase order supplier`,
    purchasePrice: `Purchase price`,
    purchasePrices: `Purchase prices`,
    purchases: `Purchases`,
    purchaseStatus: `Purchase status`,
    purchaseVerb: `Purchase`,
    qtyMoreThanAvailableAtLocation: (available: string | number, location: string | number) => `Quantity more than quantity available (${available.toString().toLowerCase()}) on location ${location.toString().toLowerCase()}, stock will become negative`,
    qualificationCodes: `Qualification code(s)`,
    quantity: `Quantity`,
    quantityInStock: `Quantity in stock`,
    rate: `Rate`,
    reactivatePaymentMethod: `Reactivate payment method?`,
    reactivatePaymentMethodContent: `This payment method will be available again.`,
    reactivatePaymentMethodForWhatContent: (what: string | number) => `This payment method will be available again for ${what.toString().toLowerCase()}`,
    reactivatePaymentMethodForWhatQuestion: (what: string | number) => `Reactivate payment method for ${what.toString().toLowerCase()}?`,
    readData: `Read data`,
    readDataFromEid: `Read data form Eid card`,
    readEid: `Read EID`,
    readOn: `Read on`,
    reason: `Reason`,
    reasons: `Reasons`,
    rebuildFromTemplate: `Rebuild from template`,
    receive: `Receive`,
    receiver: `Receiver`,
    reception: `Reception`,
    receptionDate: `Reception date`,
    receptionDateOfDocuments: `Reception date documents`,
    receptionFeedbackAdvisoryPhysician: `Reception feedback adv. physician`,
    receptionTermOfDocuments: `Term reception documents`,
    recommended: `Recommended`,
    recommendedSalesprice: `Recommended sales price`,
    redelivered: `Redelivered`,
    redeliverVerb: `Redeliver`,
    redelivery: `Redelivery`,
    redeliveryDate: `Redelivery date`,
    reference: `Reference`,
    referenceDate: `Reference date`,
    referenceMustNotContainAYear: `Reference must not contain a year`,
    references: `References`,
    referenceShort: `Ref.`,
    refund: `Refund`,
    refundable: `Refundable`,
    refundableCaption: `Line is refundable`,
    refunds: `Refunds`,
    regenerateDocumentFromTemplate: `Regenerate document from template`,
    regenerateDocumentFromTemplateQuestion: `Regenerate document from template?`,
    register: `Register`,
    registerANewRepair: `Register a new repair`,
    registerANewRepairCaption: `Do not add to an existing, create a new`,
    registerDelivery: `Register delivery`,
    registered: `Registered`,
    registeredWhat: (what: string | number) => `${what} registered`,
    registerHandover: `Register handover`,
    registerLetterDispatch: `Register dispatch of letter`,
    registerLumpSumContribution: `Register lump sum contribution`,
    registerMultipleSales: `Register multiple sales`,
    registerMultipleSalesCaption: `A sale will be registered for patient\nRefund will be invoiced to health insurance fund via a separate sale`,
    registerNewUser: `Register a new user`,
    registerOneSale: `Register one sale`,
    registerOneSaleCaption: `One sale will be registered\nRefund is registered to health insurance fund via the same sale`,
    registerPayment: `Register payment`,
    registerReceptionMedicalPrescription: `Register reception medical prescription`,
    registerThisComputer: `Register this computer`,
    registerWhat: (what: string | number) => `Register ${what.toString().toLowerCase()}`,
    registration: `Registration`,
    registrationReceptionPrescription: `Registration reception of prescription`,
    reimbursementProcedure: `Reimbursement procedure`,
    releaseLeftMouseButtonToUploadFile: `Release left mouse button to upload file`,
    reload: `Reload`,
    remark: `Remark`,
    remarks: `Remarks`,
    remote: `Remote`,
    removeLinkWithPatient: `Remove link with patient`,
    removeRepairLinkWithPatientContent: `This repair will no longer be linked with a patient\nDo you want to continue?`,
    removeThis: `Remove this`,
    renewal: `Renewal`,
    renewalDate: `Renewal date`,
    renewRegistrationToken: `Renew registration token`,
    repair: `Repair`,
    repairCancelContent: `By cancelling a repair, it's impossible to process any further steps`,
    repairFormToWho: (who: string | number) => `Repair form to ${who}`,
    repairIsSpecificForAPatient: `Repair is specific for a patient`,
    repairs: `Repairs`,
    repairSentToSupplier: `Repair sent to supplier`,
    repairUndoDelete: `Repair (undo delete)`,
    repairVerb: `Repair`,
    repairWhatQuestion: (what: string | number) => `${what} repair?`,
    replacedBy: `Replaced by`,
    replacementDevices: `Replacement devices`,
    replacementDevicesSelected: `Replacement devices selected`,
    replaceWhat: (what: string | number) => `Replace ${what.toString().toLowerCase()}`,
    reports: `Reports`,
    request: `Request`,
    requestConfiguration: `Request configuration`,
    requestForInsuranceAllowance: `Request for insurance allowance`,
    requestForInsuranceAllowances: `Requests for insurance allowance`,
    requestForInsuranceAllowanceShort: `Request for IA`,
    requestForInsuranceAllowancesShortened: `Requests for IA`,
    requestNew: `Request new`,
    requestToInsuranceAllowance: `Request to insurance allowance`,
    resendRegistrationTokenQuestion: `Resend registration token?`,
    reservation: `Reservation`,
    reservationCleared: `Reservation cleared`,
    reserved: `Reserved`,
    reset: `Reset password`,
    resetPassword: `Reset password`,
    resetPasswordMailSent: `Mail sent with instruction to reset password, check inbox`,
    restrictions: `Restrictions`,
    results: `Results`,
    resultsHearingImprovementIn: `Results hearing improvement in`,
    retailPrice: `Retail price`,
    retry: `Retry`,
    return: `Return`,
    returnDate: `Return date`,
    returnedToSupplier: `Returned to supplier`,
    returnNote: `Return note`,
    returns: `Returns`,
    returnSlip: `Return slip`,
    returnToSupplier: `Return to supplier`,
    revenues: `Revenues`,
    right: `Right`,
    rightEar: `Right ear`,
    rightForRenewal: `Right for renewal`,
    rightForRenewalTermInfoContent: `Right for renewal after 'x' years\n- >18 year at time of handover -> 5 jaar\n- <18 year at time of handover -> 3 jaar`,
    riziv: `Riziv`,
    rizivCode: `Riziv code`,
    rizivCoding: `Riziv coding`,
    rizivNumber: `Riziv number`,
    rizivRefundNomenclatureCode: (code: string | number) => `Riziv refund nomenclature code`,
    role: `Role`,
    room: `Room`,
    rooms: `Rooms`,
    roomsAreOnlyUsedInCalendar: `Rooms are only used in calendar`,
    rule: `Rule`,
    ruleForWhatDelete: (what: string | number) => `Rule for ${what.toString().toLowerCase()} delete`,
    ruleForWhatRepair: (what: string | number) => `Rule for ${what.toString().toLowerCase()} repair`,
    rules: `Rules`,
    rulesForReadOuts: `Rules for readouts`,
    runningLoanSingularOrPlural: `Active loan(s)`,
    runningRepairSingularOrPlural: `Active repair(s)`,
    runningRequestSingularOrPlural: `Active request(s)`,
    saldoBetween: `Saldo between`,
    saldoSettlementProposalsShort: `Saldo settl. prop.`,
    sale: `Sale`,
    sales: `Sales`,
    salesCredited: `Sale credited`,
    salesData: `Sales data`,
    salesDate: `Sales date`,
    salesListPrice: `Sales list price`,
    salesPrice: `Sales price`,
    salesStatus: `Sales status`,
    salutation: `Salutation`,
    save: `Save`,
    saveChangesToDocumentQuestion: `Do you want to save changes?`,
    saveChangesToTemplateQuestion: `Do you want to save changes?`,
    scan: `Scan`,
    scanned: `Scanned`,
    scannedDocumentIsWithoutSignature: `Scanned document is without signature`,
    scannedDocumentIsWithSignature: `Scanned document is with signature`,
    scannedOrUploaded: `Scanned or uploaded`,
    scanOrUploadDocument: `Scan or upload document`,
    scanSignatureLater: `Scan signature later`,
    schedule: `Schedule`,
    scope: `Scope`,
    score: `Score`,
    scoreWith: `Score with`,
    scoreWithEquipment: `Score with equipment`,
    scoreWithEquipmentAtSNROfSRT: `Score with equipment at SNR of SRT`,
    scoreWithout: `Score without`,
    scoreWithoutEquipment: `Score without equipment`,
    search: `Search`,
    searchAnAddress: `Search an address`,
    searchAndSelectSerialNumberFor: `Search and select serial number for`,
    searchAppointmentsAndEvents: `Search appointments and events`,
    searchASerialNumberFor: `Search serialnumber for`,
    searchASerialNumberFromNoahReadOuts: `Search serialnumber from Noah readouts`,
    searchFromProductCatalog: `Search from product catalog`,
    searchInProductCatalogOrCreateNewItem: `Search in product catalog or create new item`,
    searchOnName: `Search on name`,
    searchOnRizivNumber: `Search on riziv number`,
    searchOnSerialNumber: `Search on serial number`,
    searchOnWeb: `Search on web`,
    searchOnWhat: (what: string | number) => `Search on ${what.toString().toLowerCase()}`,
    searchPatients: `Search on name, address, date of birth, tel. #...`,
    searchUsers: `Search on name/email/…`,
    searchVerb: `Search`,
    second: `Second`,
    secondNomenclatureCodes: `2nd nomenclature code`,
    secondOrSeconds: `Second(s)`,
    seconds: `Seconds`,
    security: `Security`,
    securityToken: `Security token`,
    securityTokenIsExpired: `Security token is expired`,
    select: `Select`,
    selectADeviceFromRizivLimitativeList: `Select a device from RIZIV limitative list`,
    selectAll: `Select all`,
    selectAllBelow: `Select all below`,
    selectAModule: `Select a module`,
    selectAnExistingSerialNumber: `Select an existing serial number`,
    selectATestWithAndWithoutHearingAids: `Select a test with hearing aids and a test without`,
    selectATestWithSpeechAudiograms: `Select a test with speech audiograms`,
    selectAtLeastOneWhat: (what: string | number) => `Select at least 1 ${what.toString().toLowerCase()}`,
    selectCodeFor: (what: string | number) => `Select code for: ${what.toString().toLowerCase()}`,
    selectCodesFirst: `Select codes first`,
    selectColumns: `Select columns`,
    selectColumnsToShowBelow: `Select showable columns below`,
    selectDefaultDeliveryTimeFromSupplier: `Select default delivery time from supplier`,
    selectDeliveryLocationHelperText: `Select the location where the delivery will be made`,
    selectedProduct: `Selected product`,
    selectedWhat: (what: string | number) => `${what} selected`,
    selectExportableFieldsBelow: `Select exportable fields below`,
    selectFromComputer: `Select from computer`,
    selectFromWhat: (what: string | number) => `Select from ${what.toString().toLowerCase()}`,
    selectHealthInsuranceFund: `Select a health insurance fund`,
    selection: `Selection`,
    selectItemsToAssure: `Select items to assure`,
    selectItemWithSerialNumberClickHereToLink: `Select a serialnumber, click here to link`,
    selectLocationAtWhereDeliveryWillBeReceived: `Select location where delivery will be received`,
    selectLocationAtWhereYouAreCurrentlyWorking: `Select location where you're currently working`,
    selectMinMax: `Select min / max`,
    selectNhaxOrEnhaxFile: `Select nhax or enhax file`,
    selectNhaxOrEnhaxFileCaption: `Select a .nhax or .enhax file\nIf patient already exists, actions and sessions will be merged\nIf patient does not exists, a new patient will be created`,
    selectNomenclatureCodeFirst: `Select a main nomenclature code first`,
    selectNomenclatureCodeFirstYouCanDeleteItAfterwards: `Select a nomanclature code\nYou can delete it afterwards`,
    selectNone: `Select none`,
    selectOneOrMultipleSuppliers: `Select one or multiple suppliers`,
    selectOnlyNecessaryFields: `Select only necessary fields`,
    selectOtherProductQuestion: `Select another product`,
    selectPriceRange: `Select a price range between min / max`,
    selectSerialNumbersForSelectedProducts: `Select serialnumbers for selected products`,
    selectStep: (what: string | number) => `Select ${what.toString().toLowerCase()}`,
    selectSuppliers: `Select suppliers`,
    selectThis: `Select this`,
    selectWhat: (what: string | number) => `Select ${what.toString().toLowerCase()}`,
    send: `Send`,
    sendRepairToSupplier: `Send repair to supplier`,
    sendToSupplier: `Send to supplier`,
    sentToAdvisoryPhysicianShort: `Sent to adv. phys.`,
    sentToEntPhysicianShort: `Sent to ENT`,
    sentToSupplier: `Sent to supplier`,
    sentToSupplierForRepair: `Sent to supplier for repair`,
    separator: `Separator`,
    separatorExamples: `f.e. '/' or '-' or '.'`,
    serialNumber: `Serial number`,
    serialNumbers: `Serialnumbers`,
    service: `Service`,
    serviceExplanation: `A service offered to your customers (e.g. audiometry)`,
    serviceName: `Service name`,
    session: `Session`,
    sessions: `Sessions`,
    setPassword: `Set Password`,
    setStatusPatientToStatuses: `Set status of patient (deceased or no longer active)`,
    settings: `Settings`,
    settingsAndPreferences: `Settings and preferences`,
    settlementProposal: `Settlement proposal`,
    settlementProposals: `Settlement proposals`,
    shipment: `Shipment`,
    shortExternalReference: `Ext. ref.`,
    shortInternalReference: `Int. Ref.`,
    shortManufacturerReference: `Man. Ref.`,
    showAll: `Show all`,
    showAllWhat: (what: string | number) => `Show all ${what.toString().toLowerCase()}`,
    showLess: `Show less`,
    showOnlyActive: `Show only active`,
    showOnlyNotPaid: `Show only not paid`,
    showOnlyOutstanding: `Show only outstanding`,
    showOnlyWhat: (what: string | number) => `Show only ${what.toString().toLowerCase()}`,
    showStockPerLocation: `Show stock per location`,
    showWhat: (what: string | number) => `Show ${what.toString().toLowerCase()}`,
    signature: `Signature`,
    signatureNeeded: `Signature needed`,
    signatureScanned: `Signature scanned`,
    signatureScannedOrUploaded: `Signagure scanned or uploaded`,
    signDigitally: `Sign digitally`,
    signVerb: `Sign`,
    singleLine: `Single line`,
    singlePayments: `Single payments`,
    slightlyBetter: `Slightly better`,
    slip: `Slip`,
    small: `Small`,
    socialSecurityNumber: `Social security number`,
    socialSecurityNumberAbbreviation: `ssnr`,
    softwareNotActiveOnThisComputer: `Software is not active on this computer`,
    solveProblems: `Solve problems`,
    someFunctionsAreNotAccessible: `Some functions are not accessible`,
    somethingExport: (something: string | number) => `${something}_export`,
    somethingOrSomething: (first: string | number, second: string | number) => `${first} or ${second.toString().toLowerCase()}`,
    somethingQuestionmark: (something: string | number) => `${something}?`,
    sometimes: `Sometimes`,
    speechAudiograms: `Speech audiograms`,
    speechAudiometries: `Speech audiometries`,
    speechAudiometryNotPossible: `Speech audiometry not possible`,
    speechAudiometryPossibleOrNotPossible: `Speech audiometry possible`,
    speechAudiometryPossibleWithException: `Speech audiometry possible (2.2.2)`,
    speechGraphs: `Speech graphics`,
    speechInNoise: `Speech in noise`,
    speechIntelligibilityImprovement: `Speech intelligibility improvement`,
    speechTest: `Speech test`,
    ssNumbersDoNotMatch: `Social security numbers do not match`,
    standardDocumentWarning: (what: string | number) => `${what} is a standardised document\nbut HAMS detected a personalised version\nMake a choice`,
    standardisedVersion: `Standardised version`,
    standardWhat: (what: string | number) => `Default ${what.toString().toLowerCase()}`,
    start: `Start`,
    startComparisonTrial: `Start comparison trial`,
    startComparisonTrialCaption: `Current devices in loan stay with patient\nNew hearing aids will be added to loan`,
    startDate: `Start date`,
    started: `Started`,
    startFromAnExample: `Start from an example`,
    startFromATemplate: `Start from a template`,
    startLoan: `Start loan`,
    startNewTrial: `Start new trial`,
    startNewTrialCaption: `Current devices in loan will return to stock\nA new trial is started`,
    startRepair: `Start repair`,
    startSoftware: `Start software`,
    startup: `Startup`,
    startVerb: `Start`,
    status: `Status`,
    statusCredited: `Credited`,
    statusNotCredited: `Not credited`,
    statusNotPurchased: `Not purchased`,
    statusNotReserved: `Not reserved`,
    statusNotSold: `Not sold`,
    statusPartlyCredited: `Partly credited`,
    statusPurchased: `Purchased`,
    statusReserved: `Reserved`,
    statusSold: `Sold`,
    step: `Step`,
    steps: `Steps`,
    stepsChanged: `Steps changed`,
    stereo: `Stereo`,
    stereoWith: `Stereo with`,
    stereoWithout: `Stereo without`,
    stillCountToDeliver: (count: string | number) => `Still ${count.toString().toLowerCase()} to deliver`,
    stock: `Stock`,
    stockInfo: `Stock info`,
    stockLocation: `Location with stock`,
    stockMovement: `Stock movement`,
    stockMovements: `Stock movements`,
    stockReplentishment: `Stock replentishment`,
    stockTransactions: `Stock transactions`,
    stockValue: `Stock value`,
    stop: `Stop`,
    stopDate: `Stop date`,
    style: `Style`,
    subArticle: `Subarticle`,
    subcategories: `Subcategories`,
    subcategory: `Subcategory`,
    subject: `Subject`,
    subProcess: `Subprocess`,
    subProcesses: `Subprocesses`,
    summary: `Summary`,
    supplier: `Supplier`,
    supplierDetails: `Supplier details`,
    supplierIsNotRegisteredOnThisProduct: (supplier: string | number) => `Supplier ${supplier.toString().toLowerCase()} is not registered on this product`,
    supplierName: `Supplier name`,
    supplierNameIsTaken: `A supplier with this name already exists.`,
    suppliers: `Suppliers`,
    supplierSingularOrPlural: `Supplier(s)`,
    supplierUpdated: `Supplier updated`,
    supplierWillBeDeleted: `This supplier will be deleted permanently.`,
    tableFormattingHelperText: `Create a table like example below\nBeneath heading in first colum fill in key`,
    tailorMadeHearingAid: `Hearing aid (tailor-made)`,
    tailorMadeHearingProtection: `Hearing protection (tailor-made)`,
    tailorMadeProduct: `Tailor made product`,
    tailorMadeProductExplanation: `Is tailor made for one of your customers (e.g. earpiece, tailor made hearing aid)`,
    tailorMadeProducts: `Tailor made products`,
    tailorMadeProductType: `Type tailor made product`,
    tailorMadeProductValidated: `Tailor made product validated`,
    tangible: `Tangible`,
    tangibleArticleWithoutSerialNumber: `Tangible product without a serial number`,
    tangibleArticleWithSerialNumber: `Tangible product with a serial number`,
    tangibleCaption: `A tangible product\nF.e. earmould, hearing aid, hearing protection,…`,
    technicalData: `Technical data`,
    template: `Template`,
    templateCanEditCaption: `This document can be changed`,
    templateCannotEditCaption: `This document cannot be changed`,
    templateNoSignatureNeededCaption: `A document created from this template doesn't need to be signed`,
    templateNotViewableInAppCaption: `This documents is appropriate to view online\n(not all WORD features are supported)`,
    templatePredefinedTypeCaption: `Create a template based on a predefined type`,
    templates: `Templates`,
    templateScanSignatureLaterCaption: `A document created from this template must be changed later on\nwith a signed one`,
    templateStartFromEmptyCaption: `Start from an example or empty document`,
    templateStartFromUploadCaption: `Start from a document on computer`,
    templateSubjectCaption: `Create a template based on a subject\nF.e. patient, request for IA, …`,
    templateViewableInAppCaption: `This document can be viewed online`,
    tender: `Tender`,
    tendered: `Tender created`,
    tenderLines: `Tender lines`,
    tenders: `Tenders`,
    term: `Term`,
    termForHandoverAfterApproval: `Term handover after approval adv. phys.`,
    termRenewal: `Term renewal`,
    test: `Test`,
    testEquipmentTitle: `Test equipment (trial)`,
    tests: `Tests`,
    testsAddedOrChangedAfterCreationOfDocument: `Tests added or changed after document was created`,
    thereIsAnUpdateForWhatSoftware: (what: string | number) => `There's an update for ${what.toString().toLowerCase()} software`,
    thereIsNoTestWithAsWelALeftAsARightTonalAudiogramCreateFirst: `There's no test with as wel a left as a right tonal audiogram\nCreate them first in a Noah module`,
    thereIsNoTypeTonalAudiogramCreateFirst: (type: string | number) => `There's no ${type.toString().toLowerCase()} tonal audiogram test\nCreate this first from a Noah module`,
    thirdPayerArrangement: `Third payer`,
    thisDateWillBeUsedToCalculateNomenclaturePrices: `This date will be used to calculate nomenclature prices`,
    thisDeliveryIsLinkedToAnOrder: `This delivery is linked to an order`,
    thisDeliveryIsLinkedToARepair: `This delivery is linked to a repair`,
    thisDeliveryIsLinkedToATailorMadeProduct: `This delivery is linked to a tailor made product`,
    thisDocumentContainsASignature: `This document contains a signature`,
    thisDocumentMustBeReplacedWithAScanWithSignature: `This document must be replaced with a signed one`,
    thisFileExtensionIsNotSupported: `This file extension is not supported`,
    thisFileIsPasswordProtected: `This file is password protected`,
    thisInsuranceCompanyDoesNotExistAnymore: `This ins. comp. does not exist anymore`,
    thisMonth: `This month`,
    thisOrderIsCancelled: `This order is cancelled`,
    thisOrderShouldAlreadyBeDelivered: `This order should already have been delivered`,
    thisProcesIsPartOfARfia: `This proces is part of a request for insurance allowance`,
    thisProcessIsCancelled: `This proces is cancelled`,
    thisRepairShouldAlreadyBeRedelivered: `This repair should have already been delivered`,
    thisWeek: `This week`,
    thisYear: `This year`,
    title: `Title`,
    to: `To`,
    toAnotherLocation: `To another location`,
    toCreateNewProduct: `To create a new product`,
    today: `Today`,
    toLocation: `To location`,
    tonalAudiogram: `Tonal audiograms`,
    tonalAudiogramOfDate: `Tonal audiogram of`,
    tooltipIsACashRegisterResource: `Can be used in cash register module`,
    tooltipIsAPlannableResource: `Can be used to plan meetings`,
    tooltipIsAStockManagementResource: `Can be used for stock`,
    tooltipIsNotACashRegisterResource: `Can NOT be used in cash register module`,
    tooltipIsNotAPlannableResource: `Can NOT be used to plan meetings`,
    tooltipIsNotAStockManagementResource: `Can NOT be used for stock`,
    total: `Total`,
    totalAmount: `Total amount`,
    totalExVat: `Total ex VAT`,
    totalInVat: `Total in VAT`,
    totalOfContributions: `Total contributions`,
    transactions: `Transactions`,
    trial: `Trial`,
    trialAdministrativeEndDate: `Administrative trial end date`,
    trialDuration: `Trial duration`,
    trialEnded: `Trial ended`,
    trialReport: `Trial report`,
    trialRunning: `Trial running`,
    trialsExpiredNoDocumentsSentForMedicalPrescription: `Trials expired, no documents sent to ENT phys.`,
    troubleShoot: `Troubleshoot`,
    twoDecimals: `Two decimals`,
    type: `Type`,
    typeAnnotation: `Type annotation`,
    typeAppointment: `Type appointment`,
    types: `Types`,
    typesAnnotation: `Annotation types`,
    typesAppointments: `Appointment types`,
    typicalForHardwareWithWithoutSerialNumber: `Typical for hardware (with/without serialnumber)`,
    unblock: `Unblock`,
    unBlockUser: `Unblock user`,
    unblockUserContent: `This user will be able to login again.`,
    unBlockUserQuestion: `Unblock user?`,
    undefined: `Undefined`,
    understood: `Understood`,
    undoFailedTrail: `Undo failed trial`,
    undoFailedTrailContent: `Errata; this request for insurance allowance is not failed.\nDo you want to continue?`,
    undoFailedTrailShort: `Undo failed trial`,
    unhandled: `Unhandled`,
    uniqueReference: `Unique reference`,
    unit: `Unit`,
    unitDiscount: `Unit discount`,
    unitListPrice: `Unit list price`,
    unitPrice: `Unit price`,
    unitVatValue: `Unit VAT value`,
    unknown: `Unknown`,
    unknownDateFormat: `Unknown data format`,
    unlimited: `Unlimited`,
    unpaid: `Unpaid`,
    unPaidSales: `Unpaid sales`,
    unsavedChanges: `Unsaved changes`,
    update: `Update`,
    updated: `Updated`,
    updateRizivNumber: `Update Riziv number`,
    upload: `Upload`,
    uploadADocument: `Upload a document`,
    uploadDocument: `Upload document`,
    uploadReplaceDocument: `Upload/replace document`,
    uploadWhat: (what: string | number) => `Upload ${what.toString().toLowerCase()}`,
    uploadWrittenOrSignedDocument: `Upload a (handwritten and/or signed) document`,
    upperCase: `First letter in capital`,
    useAPrefix: `Use a prefix`,
    usedFrequencies: `Used frequencies`,
    usedList: `Used list`,
    user: `User`,
    userRegisteredCheckEmail: (email: string | number) => `User ${email} registered, please check email.`,
    users: `Users`,
    userUpdated: `User updated`,
    userWillBeLoggedOutOfAllSessions: `User will be logged out of all devices and browsers`,
    userWillBeLoggedOutOfSpecificSession: `User will be logged out of specific device or browser`,
    useYear: `Use year`,
    validated: `Validated`,
    validateData: `Validate data`,
    validatedNegativeResult: `Validated negative`,
    validateVerb: `Validate`,
    validFrom: `Valid from`,
    validityFrom: `Validity from`,
    validityTo: `Validity to`,
    validTo: `Valid to`,
    vat: `VAT`,
    vatAmount: `VAT amount`,
    vatCustomer: `VAT customer`,
    vatCustomers: `VAT customers`,
    vatNumber: `VAT number`,
    vatPercentage: `VAT %`,
    ventDiameterInMm: `Vent diameter (in mm)`,
    verzamelstaat: `Settlement proposal`,
    view: `View`,
    viewableInApp: `Viewable in app`,
    viewAll: `View all`,
    viewAllCountAppointments: (count: string | number) => `View all ${count.toString().toLowerCase()} appointments`,
    viewAllDetails: `View all details`,
    viewDocument: `View document`,
    viewEditDocument: `View/edit document`,
    viewEditWhat: (what: string | number) => `View/edit ${what.toString().toLowerCase()}`,
    viewMore: `View more`,
    viewRizivDocumentation: `View RIZIV documentation`,
    viewSplitUpPerVatPercentage: `View split up per VAT%`,
    warning: `Warning`,
    warningOnlyThingsLinkedToAttachment12CanBeAltered: `Warning; only attributes for attachment 12 can be changed (refundable & riziv-coding)`,
    warningOnlyUnlinkedAndNotIgnoredDevicesAreShownHere: `Warning: only not linked nor ignored devices are shown heren`,
    warningSingularOrPlural: `Warning(s)`,
    wasOverDue: `Was overdue`,
    website: `Website`,
    week: `Week`,
    weekOrWeeks: `Week(s)`,
    weeks: `Weeks`,
    welcomeBack: `Welcome back`,
    whatAdded: (what: string | number) => `${what} added`,
    whatSoftwareNotInstalled: (what: string | number) => `${what} software is not installed`,
    who: `Who`,
    willBeDefaultOffice: `This will become the default office`,
    with: `With`,
    withOrWithoutTerm: `With/without term`,
    withOrWithoutWhat: (what: string | number) => `With/without ${what.toString().toLowerCase()}`,
    without: `Without`,
    withoutPrescriptionForTrialCaption: `Patient did not yet receive a prescription for trial`,
    withOutstandingBalance: `With outstanding balance`,
    withoutTerm: `Without term`,
    withoutThirdPayerArrangementCaption: `Patient will pay the full amount`,
    withoutWhat: (what: string | number) => `Without ${what.toString().toLowerCase()}`,
    withPrescriptionForTrialCaption: `Attachment 17 at hand, fill in prescription for trial date and select ENT physician`,
    withRespectToShort: `Wrt`,
    withThirdPayerArrangementCaption: `Patient will only pay allowance`,
    withWhat: (what: string | number) => `With ${what.toString().toLowerCase()}`,
    wizardAndHelp: `Wizard & Help`,
    work: `Work`,
    workflow: `Workflow`,
    workPhone: `Work phone #`,
    workWeek: `Work week`,
    worse: `Worse`,
    writeAnAddressHere: `Write an address here`,
    wrongRizivNumberFormat: `Wrong format: enter a RIZIV number with format 'x-xxxx-xx-xxx'`,
    year: `Year`,
    yearComesAfterPrefixAndBeforeNumber: `Year comes before 'prefix' and after 'index number'`,
    yearOrYears: `Year(s)`,
    years: `Years`,
    yes: `Yes`,
    yesCommaContinue: `Yes, continue`,
    yesCommaCreateNewPatient: `Yes, create new patient`,
    yesCommaDelete: `Yes, detele`,
    yesCommaSave: `Yes, save`,
    yesItFits: `Yes, it fits`,
    yesterday: `Yesterday`,
    yesWhatExclamation: (what: string | number) => `Yes, ${what.toString().toLowerCase()}!`,
    youCanAlsoDragAFileToHere: `You can also drag a file here`,
    zip: `Zip`,
}
