import React from 'react';
import { IHeader } from 'framework/table/IHeader';
import { useLocalization } from 'localization/useLocalization';
import { createSmallPrimaryTextButtonHeader } from './createSmallPrimaryTextButtonHeader';

export const createDetailsHeader = <T extends unknown>(onClick: (val: T, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void, onlyShowOnHover: boolean = false): IHeader<T> => {
    return createSmallPrimaryTextButtonHeader<T>('details-header', <Content />, onClick, undefined, onlyShowOnHover);
}

const Content = () => {
    const strings = useLocalization();
    return <span>{strings.details}</span>;
}

