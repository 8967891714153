import { Paper, useScrollTrigger, useTheme } from '@material-ui/core';
import React, { useMemo, useRef, useState } from 'react';
import { IPageXProps } from './IPageXProps';

const useStickyStyle = (): React.CSSProperties => {
	const theme = useTheme();
	return {
		position: 'sticky',
		top: 0,
		left: 0,
		backgroundColor: theme.palette.background.default,
		zIndex: 3,
	};
};

export const PageX = ({ stickyHeader, stickySidebar, children, style, spacing, maxWidth, renderRaw }: IPageXProps) => {
	const xMaxWidth = useMemo(() => maxWidth ?? 1280, [maxWidth]);
	const xSpacing = useMemo(() => spacing ?? 2, [spacing]);
	const theme = useTheme();
	const [scrollTarget, setScrollTarget] = useState<HTMLDivElement | undefined>(undefined);
	const trigger = useScrollTrigger({ target: scrollTarget, disableHysteresis: true, threshold: 20 });
	const stickyStyle = useStickyStyle();
	const stickyTopRef = useRef<HTMLDivElement>(null);
	const leftColRef = useRef<HTMLDivElement>(null);

	return (
		<div
			className='df-col'
			style={{ height: '100%', ...style }}>
			{stickyHeader && (
				<Paper
					square
					elevation={trigger ? 4 : 1}
					style={{
						...stickyStyle,
						padding: theme.spacing(xSpacing),
						paddingBottom: 0,
						borderRight: `5px solid ${trigger ? '#f1f1f1' : '#888'}`,
					}}
					ref={stickyTopRef}>
					{stickyHeader}
				</Paper>
			)}
			<div
				className='df-row fg1'
				style={{
					overflowY: 'scroll',
					height: `calc(100% - ${stickyTopRef.current?.clientHeight ?? 0}px)`,
				}}
				ref={node => setScrollTarget(node ?? undefined)}>
				{stickySidebar && (
					<div
						ref={leftColRef}
						style={{
							position: 'sticky',
							top: '0px',
							height: '100%',
							overflowY: 'auto',
						}}>
						{stickySidebar}
					</div>
				)}
				{renderRaw === true && children}
				{renderRaw !== true && (
					<div
						className='df-col fg1 h100'
						style={{
							paddingTop: 1,
							// maxWidth: maxWidth ? `calc(${maxWidth}px - ${leftColRef.current?.clientWidth ?? 0}px)` : 'unset',
							maxWidth: `calc(${xMaxWidth}px - ${leftColRef.current?.clientWidth ?? 0}px)`,
						}}>
						{children}
					</div>
				)}
			</div>
		</div>
	);
};
