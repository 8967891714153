import { Chip, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ColorX } from '../color/ColorX';
import { useThemeColor } from '../color/useThemeColor';

interface IProps {
	label: string;
	fill?: boolean;
	color?: ColorX;
	icon?: React.ReactElement;
	style?: React.CSSProperties;
	onClick?: VoidFunction;
}

export const ChipX = ({ label, color = 'default', fill, icon, style, onClick }: IProps) => {
	const theme = useTheme();
	const tColor = useThemeColor(color);
	// eslint-disable-next-line
	const cColor = useMemo(() => (color === 'success' ? theme.palette.primary.contrastText : theme.palette.getContrastText(tColor)), [color, tColor]);

	return (
		<Chip
			size='small'
			variant={fill === true ? 'default' : 'outlined'}
			icon={icon}
			style={{
				color: fill === true ? cColor : tColor,
				borderColor: tColor,
				backgroundColor: fill === true ? tColor : 'inherit',
				fontSize: '12px',
				height: '22px',
				...style,
			}}
			onClick={onClick}
			label={<div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{label}</div>}
		/>
	);
};
