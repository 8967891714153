import { useTheme } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ChipX } from 'framework/components/chips/ChipX';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { IClientDto } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calcClientDetailRoute, calculateSubscriptionDetailRoute } from 'routes';
import StorageIcon from '@material-ui/icons/Storage';
import LanguageIcon from '@material-ui/icons/Language';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IClientDto;
	isLoadedWithDetails: boolean;
	withoutBorder?: boolean;
}

export const ClientListItem = ({ item, isLoadedWithDetails, withoutBorder }: IProps) => {
	const theme = useTheme();
	const { push } = useHistory();
	const openInNewTab = useOpenInNewTab();

	return (
		<div
			className='df-row w100 gap-8'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div className='df-col'>
				<UpdateOutlinedIcon style={{ fontSize: 22, color: item.isRunningLatestBuild ? theme.palette.success.main : theme.palette.warning.main }} />
				{isLoadedWithDetails && (
					<>
						{item.isStarted === true && <PlayArrowIcon style={{ fontSize: 22, color: theme.palette.success.main }} />}
						{item.isStarted !== true && <PauseIcon style={{ fontSize: 22, color: theme.palette.error.main }} />}
						<SubscriptionsOutlinedIcon style={{ fontSize: 22, color: item.hasActiveSubscription ? theme.palette.success.main : theme.palette.error.main }} />
					</>
				)}
			</div>
			<div className='df-col fg1 gap-2'>
				<div className='df-row-ac'>
					<TextLinkButton
						onClick={() => push(calcClientDetailRoute(item.id))}
						style={{ fontSize: MajorFontSize }}>
						{item.name}
					</TextLinkButton>
				</div>
				<div className='df-row-ac gap-4'>
					<ChipX
						label={item.buildNumber.toString()}
						color={item.isRunningLatestBuild ? 'success' : 'warning'}
					/>
					{isLoadedWithDetails && (
						<ChipX
							label={item.activeSubscription?.licenseName ?? 'No sub!'}
							color={item.hasActiveSubscription ? 'success' : 'error'}
							onClick={item.hasActiveSubscription ? () => push(calculateSubscriptionDetailRoute(item.activeSubscription.id)) : undefined}
						/>
					)}
				</div>
				<div className='df-col'>
					<LabelledPropertyX
						caption
						icon={<StorageIcon />}>
						<span style={{ fontSize: MinorFontSize }}>{item.ravenDbName}</span>
					</LabelledPropertyX>
					<LabelledPropertyX
						caption
						icon={<LanguageIcon />}
						link={() => openInNewTab(item.siteUrl!, true)}>
						<span style={{ fontSize: MinorFontSize }}>{item.siteUrl}</span>
					</LabelledPropertyX>
					{isLoadedWithDetails && (
						<>
							<LabelledPropertyX
								caption
								label={`Event-store pos`}>
								<span style={{ fontSize: MinorFontSize }}>{item.eventStorePos?.toString()}</span>
							</LabelledPropertyX>
							<LabelledPropertyX
								caption
								label={`Last non auth event`}>
								<span style={{ fontSize: MinorFontSize }}>{formatDateX(item.lastNonAuthEvent, 'dd/MM/yyyy HH:mm:ss')}</span>
							</LabelledPropertyX>

							{Boolean(item.usageStats) === true && (
								<>
									<LabelledPropertyX
										caption
										label={`# users`}>
										<span style={{ fontSize: MinorFontSize }}>{item.usageStats.registeredUsers.toString()}</span>
									</LabelledPropertyX>
									<LabelledPropertyX
										caption
										label={`# HA Y-2 | Y-1 | Y0:`}>
										<span
											style={{
												fontSize: MinorFontSize,
											}}>
											{`${item.usageStats.soldHearingAidsYearMinus2.toString()} | ${item.usageStats.soldHearingAidsYearMinus1.toString()} | ${item.usageStats.soldHearingAidsThisYear.toString()}`}
										</span>
									</LabelledPropertyX>
								</>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
