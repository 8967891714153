import { useTheme } from '@material-ui/core';
import { CircleX } from 'framework/components/CircleX';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ILabelDto } from 'gen/ApiClient';
import React from 'react';
import { useMapLabelColor } from './useMapLabelColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';

interface IProps {
	item: ILabelDto;
	isSelected: boolean;
	onOr: VoidFunction;
	onAnd: VoidFunction;
	style?: React.CSSProperties;
}

export const FilterLabelListItem = ({ item, isSelected, style, onOr, onAnd }: IProps) => {
	const theme = useTheme();
	const xColor = useMapLabelColor(item);
	const [isHovered, enter, leave] = useStateBoolean(false);

	return (
		<div
			onMouseEnter={enter}
			onMouseLeave={leave}
			className='df-row'
			style={{
				cursor: isSelected ? 'unset' : 'pointer',
				borderBottom: `1px solid ${theme.palette.divider}`,
				backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.04)' : 'unset',
				padding: '4px 10px',
				...style,
			}}
			onClick={isSelected ? undefined : onOr}>
			<div className='df-col fg1'>
				<div className='df-row-ac gap-4'>
					<CircleX
						color={xColor}
						size={12}
					/>
					<span style={{ fontSize: 14 }}>{item.name}</span>
				</div>
				<span style={{ fontSize: 13, color: theme.palette.text.secondary }}>{item.caption ?? <em>{`n/a`}</em>}</span>
			</div>
			<div className='fg1'></div>
			<div className='df-row-ac gap-4'>
				<TextLinkButton
					color='primary'
					disabled={isSelected}
					onClick={e => {
						e.stopPropagation();
						onOr();
					}}>
					{`OR`}
				</TextLinkButton>
				<TextLinkButton
					color='secondary'
					hoverColor='secondary'
					disabled={isSelected}
					onClick={e => {
						e.stopPropagation();
						onAnd();
					}}>
					{`AND`}
				</TextLinkButton>
			</div>
		</div>
	);
};
