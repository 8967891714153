import { CacheProvider as FrameworkCacheProvider } from 'framework/context/CacheProvider';
import { licensesQuery_categories, licensesQuery_concurrencyToken } from 'gen/ApiClient';
import React from 'react';
import { LicenseCategoriesCacheContext } from './LicenseCategoriesCacheContext';

export const LicenseCategoriesCacheProvider = ({ children }: any) => {
	return (
		<FrameworkCacheProvider<string[]>
			concurrencyF={licensesQuery_concurrencyToken}
			loadF={licensesQuery_categories}
			context={LicenseCategoriesCacheContext}>
			{children}
		</FrameworkCacheProvider>
	);
};
