import { makeStyles, Typography, useTheme, Theme } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalization } from 'localization/useLocalization';
import { IBreadcrumb } from './IBreadcrumb';

interface IProps {
    title: string | undefined;
    secondaryTitle?: React.ReactNode;
    breadcrumbs?: IBreadcrumb[];
    showHome?: boolean;
    style?: React.CSSProperties;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    iconStyle: {
        "& > svg": {
            fontSize: '1.25rem',
            color: theme.palette.text.secondary,
            height: '1.25rem'
        }
    }
}));

export const Breadcrumbs = ({ title, breadcrumbs, secondaryTitle, showHome = false, style }: IProps) => {
    const strings = useLocalization();
    const { push } = useHistory();
    const theme = useTheme();
    const styles = useStyles(theme);
    return (
        <div className="df-row-ac" style={{ height: '28px', ...style }}>
            {showHome &&
                <>
                    <div className={styles.iconStyle} style={{ height: '1.25rem', marginRight: '2px' }}><HomeIcon /></div>
                    <Typography variant="h2">{strings.start}</Typography>
                    <Typography variant="h2" style={{ margin: '0px 4px' }}>/</Typography>
                </>
            }
            {breadcrumbs && breadcrumbs.map(t =>
                <div className="df-row-ac" key={t.text}>
                    {t.icon && <div className={styles.iconStyle} style={{ height: '1.25rem', marginRight: '2px' }}>{t.icon}</div>}
                    <Typography variant="h2" style={{ cursor: t.route ? 'pointer' : 'inherit' }} onClick={() => t.route ? push(t.route) : {}}>{t.text}</Typography>
                    <Typography variant="h2" style={{ margin: '0px 4px' }}>/</Typography>
                </div>
            )}
            <div style={{ marginBottom: '5px' }}>
                <Typography variant="h1">{title}</Typography>
            </div>
            {secondaryTitle && <div style={{ display: 'flex', marginBottom: '5px', marginLeft: theme.spacing(1) }}>{secondaryTitle}</div>}
        </div>
    );
};
