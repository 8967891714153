import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { useTheme } from '@material-ui/core';

interface IProps {
    error: string | undefined;
    style?: React.CSSProperties;
}

export const ErrorTextWithIcon = ({ error, style }: IProps) => {
    const theme = useTheme();
    
    return (
        <div className="df-row-ac" style={{ color: theme.palette.error.main, ...style }}>
            <ErrorIcon style={{ fontSize: '1rem', marginRight: '4px' }} />
            {error}
        </div>
    );
}