import { Dialog, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectField } from 'framework/forms/FormSelectField';
import { FormSelectFieldFromStrings } from 'framework/forms/FormSelectFieldFromStrings';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IOption } from 'framework/IOption';
import { IUploadExampleTemplateRequest, LanguageOptions, TemplateMainContexts, templatesCommand_newExampleTemplate, TemplateTypes } from 'gen/ApiClient';
import { LanguageOption } from 'localization/LanguageOption';
import { fileToBase64 } from 'shared/upload/fileToBase64';
import { UploadFile } from 'shared/upload/UploadFile';

const createSchema = (isFromPredefinedType: boolean) => {
	return yup
		.object<IUploadExampleTemplateRequest>({
			canEdit: yup.bool().defined(),
			contentAsBase64: yup.string(),
			name: yup.string().required(),
			predefinedType: isFromPredefinedType ? yup.string().required() : yup.string(),
			mustSign: yup.bool().defined(),
			canViewOnline: yup.bool().defined(),
			language: yup.string().required(),
			userDefinedMainContext: isFromPredefinedType ? yup.string() : yup.string().required(),
			validityEnd: yup.date().nullable() as any,
			validityStart: yup.date().nullable() as any,
			version: yup.string(),
		})
		.defined();
};

const emptyValues: IUploadExampleTemplateRequest = {
	canEdit: true,
	contentAsBase64: '',
	mustSign: false,
	canViewOnline: true,
	name: '',
	predefinedType: '',
	userDefinedMainContext: '',
	language: 'nl' as LanguageOption,
	validityStart: undefined,
	validityEnd: undefined,
	version: '',
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: () => void;
}

const stepsRecord: Record<number, (keyof IUploadExampleTemplateRequest)[]> = {
	0: ['predefinedType', 'userDefinedMainContext', 'name', 'language', 'version'],
	1: ['mustSign', 'canEdit', 'canViewOnline'],
	2: ['validityStart', 'validityEnd'],
	3: ['contentAsBase64'],
};

export const UploadExampleTemplateRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [create, isSubmitting] = useFormSubmit(templatesCommand_newExampleTemplate);
	const [step, setStep] = useState<number>(0);
	const [isFromPredefinedType, setIsFromPredefinedType] = useState<boolean>(true);

	const handleSubmit = async (values: IUploadExampleTemplateRequest, helpers: FormikHelpers<IUploadExampleTemplateRequest>) => {
		const r = await create({
			...values,
			predefinedType: isFromPredefinedType ? values.predefinedType : undefined,
			userDefinedMainContext: isFromPredefinedType ? undefined : values.userDefinedMainContext,
		});
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IUploadExampleTemplateRequest>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema(isFromPredefinedType)}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={createSchema(isFromPredefinedType)}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
					isFromPredefinedType={isFromPredefinedType}
					setIsFromPredefinedType={setIsFromPredefinedType}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<IUploadExampleTemplateRequest>;
	cancel: () => void;
	stepsRecord: Record<number, (keyof IUploadExampleTemplateRequest)[]>;
	isFromPredefinedType: boolean;
	setIsFromPredefinedType: React.Dispatch<React.SetStateAction<boolean>>;
}

const InnerDialog = ({ step, setStep, isSubmitting, schema, cancel, stepsRecord, isFromPredefinedType, setIsFromPredefinedType, ...rest }: IInnerProps) => {
	const props = useFormikContext<IUploadExampleTemplateRequest>();

	const onSelectFile = async (file: File) => {
		const content = await fileToBase64(file);
		setFormValue<IUploadExampleTemplateRequest>('contentAsBase64', content, props);
		props.submitForm();
	};

	return (
		<Dialog
			{...rest}
			maxWidth='lg'>
			<DialogTitleWithFormStepper
				title={`New standard document`}
				step={step}
				labels={[`Type & Name`, `Metadata`, `Upload`]}
			/>
			<OverflowDialogContent dividers>
				{step === 0 && (
					<>
						<FormTextField<IUploadExampleTemplateRequest>
							name='name'
							label={`Name`}
							required
						/>
						<FormTextField<IUploadExampleTemplateRequest>
							name='version'
							label={`Version`}
						/>
						<FormSelectFieldFromStrings<IUploadExampleTemplateRequest>
							name='language'
							label='Language'
							options={LanguageOptions}
						/>
						<ExtendedRadioGroup<boolean>
							selected={isFromPredefinedType}
							setSelected={setIsFromPredefinedType}
							options={[
								{ value: true, label: `From predefined type` },
								{ value: false, label: `From main context` },
							]}
						/>
						{isFromPredefinedType === true && (
							<FormAutocompleteField<IUploadExampleTemplateRequest, IOption<string>>
								name='predefinedType'
								label='Type'
								options={TemplateTypes.map<IOption<string>>(t => ({ id: t, identifier: t }))}
							/>
						)}
						{isFromPredefinedType === false && (
							<FormSelectFieldFromStrings<IUploadExampleTemplateRequest>
								name='userDefinedMainContext'
								label='Context'
								options={TemplateMainContexts}
							/>
						)}
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectField<IUploadExampleTemplateRequest, boolean>
							label='Must Sign'
							name='mustSign'
							options={[true, false]}
							renderValue={t => (t === true ? 'Must sign' : 'No signature needed')}
							getKey={t => t.toString()}
						/>
						<FormSelectField<IUploadExampleTemplateRequest, boolean>
							label='Can edit'
							name='canEdit'
							options={[true, false]}
							renderValue={t => (t === true ? 'Can edit' : 'Cannot edit')}
							getKey={t => t.toString()}
						/>
						<FormSelectField<IUploadExampleTemplateRequest, boolean>
							label='Can view online'
							name='canViewOnline'
							options={[true, false]}
							renderValue={t => (t === true ? 'Can view online' : 'Cannot view online')}
							getKey={t => t.toString()}
						/>
					</>
				)}
				{step === 2 && (
					<>
						<FormDatePicker<IUploadExampleTemplateRequest>
							name='validityStart'
							label={`Validity Start`}
						/>
						<FormDatePicker<IUploadExampleTemplateRequest>
							name='validityEnd'
							label={`Validity End`}
						/>
					</>
				)}
				{step === 3 && (
					<UploadFile
						isUploading={isSubmitting}
						onFileSelected={onSelectFile}
						accept='word'
					/>
				)}
			</OverflowDialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={`Create`}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
