import { SalesChips } from 'app/main/sales/SalesChips';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISubscription, salesQuery_single } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { calculateSalesDetailRoute } from 'routes';

interface IProps {
	subscription: ISubscription;
	salesId: string;
}

export const SubscriptionSaleContent = ({ subscription, salesId }: IProps) => {
	const [item] = useApiEffect(salesQuery_single, salesId);
	const { push } = useHistory();
	const hasError = useMemo(() => subscription.calculatedFee - (item?.priceExVat ?? 0) > 0.01, [item, subscription]);

	if (item === undefined) {
		return <></>;
	}

	return (
		<div className='df-col gap-4'>
			<TextLinkButton onClick={() => push(calculateSalesDetailRoute(salesId))}>{item.referenceOrId}</TextLinkButton>
			<SalesChips item={item} />
			<div className='df-row-ac gap-2'>
				<StatusIcon
					fontSize='small'
					status={hasError ? 'error' : 'success'}
				/>
				<div>{formatCurrency(item.priceExVat)}</div>
			</div>
		</div>
	);
};
