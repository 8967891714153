import React from 'react';
import { IQuickFilterContext } from 'framework/IQuickFilterContext';
import { ITableViewContext } from 'framework/table/ITableViewContext';
import { ITemplateDto } from 'gen/ApiClient';
import { TemplateSelectableColumn } from './TemplateSelectableColumn';
import { TemplatesQuickFilter } from './TemplatesQuickFilter';

interface IViewContext extends ITableViewContext<TemplateSelectableColumn, ITemplateDto>, IQuickFilterContext<TemplatesQuickFilter> {}

export const TemplatesViewContext = React.createContext<IViewContext>(undefined as any);
