import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import { IQueryResult, ITemplateDto, ITemplatesQueryParams, templatesQuery_concurrencyToken, templatesQuery_query } from 'gen/ApiClient';
import { templatesQueryParamsLsKey } from '../../../localStorageKeys';
import { TemplatesQueryContext } from './TemplatesQueryContext';

const DefaultParams: ITemplatesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'created' },
	pageParams: { index: 1, size: 10 },
	mainContexts: [],
	predefinedTypes: [],
	rootTemplateType: undefined,
};

export const TemplatesQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<ITemplateDto>, ITemplatesQueryParams>
			defaultParams={DefaultParams}
			queryF={templatesQuery_query}
			concurrencyF={templatesQuery_concurrencyToken}
			localStorageKey={templatesQueryParamsLsKey}
			context={TemplatesQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
