import { ColorX } from 'framework/components/color/ColorX';
import { ISubscription } from 'gen/ApiClient';

export const mapSubscriptionToColor = (item: ISubscription): ColorX => {
	if (item.hasExceededMaxSoldHearingAids) {
		return 'error';
	} else if (item.type === 'Future') {
		return 'info';
	} else if (item.isActive) {
		return 'primary';
	} else {
		return 'default';
	}
};
