import { createOptions, FileTabItemId, RibbonTabType, RichEditUnit, ViewTabItemId, ViewType } from 'devexpress-richedit';

export const createRteOptions = () => {
        // default stuff
        const options = createOptions();
        if (options.confirmOnLosingChanges && options.fields && options.view && options.view.simpleViewSettings) {
            options.confirmOnLosingChanges.enabled = false;
            // options.confirmOnLosingChanges.message = 'Are you sure you want to perform the action? All unsaved document data will be lost.';

            options.fields.updateFieldsBeforePrint = true;
            options.fields.updateFieldsOnPaste = true;

            options.view.viewType = ViewType.PrintLayout;
            options.view.simpleViewSettings.paddings = {
                left: 15,
                top: 15,
                right: 15,
                bottom: 15,
            };
        }
        // events
        options.events.activeSubDocumentChanged = () => { };
        options.events.autoCorrect = () => { };
        options.events.calculateDocumentVariable = () => { };
        options.events.characterPropertiesChanged = () => { };
        options.events.contentInserted = () => { };
        options.events.contentRemoved = () => { };
        options.events.documentChanged = () => { };
        options.events.documentFormatted = () => { };
        options.events.documentLoaded = () => { };
        options.events.gotFocus = () => { };
        options.events.hyperlinkClick = () => { };
        options.events.keyDown = () => { };
        options.events.keyUp = () => { };
        options.events.paragraphPropertiesChanged = () => { };
        options.events.lostFocus = () => { };
        options.events.pointerDown = () => { };
        options.events.pointerUp = () => { };
        options.events.saving = (s, e) => {
            e.handled = true;
        };
        options.events.saved = () => {
        };
        options.events.selectionChanged = () => { };
        options.unit = RichEditUnit.Centimeter;
        options.readOnly = false;
        options.width = '100%';
        options.height = "100%";

        //customized stuff
        options.ribbon.removeTab(RibbonTabType.References);
        options.ribbon.removeTab(RibbonTabType.MailMerge);
        options.ribbon.getTab(RibbonTabType.File)?.removeItem(FileTabItemId.CreateNewDocument);
        options.ribbon.getTab(RibbonTabType.File)?.removeItem(FileTabItemId.OpenDocument);
        options.ribbon.getTab(RibbonTabType.File)?.removeItem(FileTabItemId.ExportDocument);
        options.ribbon.getTab(RibbonTabType.View)?.removeItem(ViewTabItemId.ToggleFullScreen);
        options.ribbon.activeTabIndex = 1; // by default on HOME
        return options;
    }