import { CreateLicenseSubscriptionRequestForm } from 'app/main/subscriptions/CreateLicenseSubscriptionRequestForm';
import { NavigateIconRecord } from 'app/NavigateIconRecord';
import { ActionCardButton } from 'framework/components/ActionCardButton';
import { IClientDetailDto } from 'gen/ApiClient';
import React from 'react';
import { DefaultActionsCard } from 'shared/DefaultActionsCard';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: IClientDetailDto;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const ClientDetailActionsCard = ({ item, reload, style }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onCreateSubscription = () => {
		open(
			<CreateLicenseSubscriptionRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				clientId={item.id}
			/>
		);
	};

	return (
		<DefaultActionsCard style={style}>
			<ActionCardButton
				startIcon={NavigateIconRecord['subscriptions']}
				onClick={onCreateSubscription}>
				{`Create subscription`}
			</ActionCardButton>
		</DefaultActionsCard>
	);
};
