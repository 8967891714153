import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IOption } from 'framework/IOption';
import { clearParams } from 'framework/utils/clearParams';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { FilterBar } from 'framework/filter/FilterBar';
import { RadioFilterComponent } from 'framework/filter/RadioFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { AgeRestrictionMode, AgeRestrictionModes, IMedicalTreatmentNomenclaturesQueryParams } from 'gen/ApiClient';
import React, { useContext } from 'react';
import { QueryContext } from './context/QueryContext';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';

const exclusions: (keyof IMedicalTreatmentNomenclaturesQueryParams)[] = ['isCurrentlyValid', 'searchMode'];

interface IProps {
	params: IMedicalTreatmentNomenclaturesQueryParams;
	onFilter: (params: IMedicalTreatmentNomenclaturesQueryParams) => void;
	paddingLeft: number;
}

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const { queryResult } = useContext(QueryContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<BooleanRadioFilterComponent
				selected={params.hasCurrentFee}
				setSelected={val => onFilter({ ...params, hasCurrentFee: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				label='Has current fee?'
				trueText='True'
			/>
			<BooleanRadioFilterComponent
				label='Main?'
				selected={params.isMain}
				setSelected={val => onFilter({ ...params, isMain: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
			<BooleanRadioFilterComponent
				label='Lump sum?'
				selected={params.isLumpSum}
				setSelected={val => onFilter({ ...params, isLumpSum: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
			<BooleanRadioFilterComponent
				label='Ambulant?'
				selected={params.isAmbulant}
				setSelected={val => onFilter({ ...params, isAmbulant: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
			<BooleanRadioFilterComponent
				label='Hearing loss > 40dB?'
				selected={params.isHearingLossMoreThan40dB}
				setSelected={val => onFilter({ ...params, isHearingLossMoreThan40dB: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
			<BooleanRadioFilterComponent
				label='Speech possible?'
				selected={params.isSpeechAudiometryPossible}
				setSelected={val => onFilter({ ...params, isSpeechAudiometryPossible: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
			<BooleanRadioFilterComponent
				label='Stereo?'
				selected={params.isStereo}
				setSelected={val => onFilter({ ...params, isStereo: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
			<BooleanRadioFilterComponent
				label='Mono?'
				selected={params.isMono}
				setSelected={val => onFilter({ ...params, isMono: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
			<BooleanRadioFilterComponent
				label='Bone anchorage?'
				selected={params.isBoneAnchorage}
				setSelected={val => onFilter({ ...params, isBoneAnchorage: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
			<RadioFilterComponent<AgeRestrictionMode>
				options={AgeRestrictionModes.map<IOption<AgeRestrictionMode>>(t => ({ id: t, identifier: t }))}
				selected={params.ageRestrictionMode as AgeRestrictionMode}
				setSelected={val => onFilter({ ...params, ageRestrictionMode: val })}
				label='Leeftijd'
				icon={<HelpOutlineIcon />}
			/>
			<ListFilterComponent<string>
				options={queryResult.metadata.subArticles.map<IOption<string>>(t => ({
					id: t.article!,
					identifier: `${t.article} (${t.count.toString()})`,
					caption: t.descriptionNl,
				}))}
				label='Subarticle'
				icon={<HelpOutlineIcon />}
				selected={params.subArticles}
				setSelected={vals => onFilter({ ...params, subArticles: vals })}
			/>
			<BooleanRadioFilterComponent
				label='Has V.END?'
				selected={params.hasVEnd}
				setSelected={val => onFilter({ ...params, hasVEnd: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
			<BooleanRadioFilterComponent
				label='Pseudo-code?'
				selected={params.isPseudoCode}
				setSelected={val => onFilter({ ...params, isPseudoCode: val })}
				icon={<HelpOutlineIcon />}
				falseText='False'
				trueText='True'
			/>
		</FilterBar>
	);
};
