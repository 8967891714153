import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterComponent } from 'framework/filter/date/DateFilterComponent';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { IUpdateScheduledTaskForClientQueryParams, UpdateScheduledTaskStatus, UpdateScheduledTaskStatuses } from 'gen/ApiClient';
import { UpdateScheduledTaskStatusRecord } from '../../updateScheduledTasks/UpdateScheduledTaskStatusRecord';

const exclusions: (keyof IUpdateScheduledTaskForClientQueryParams)[] = [];

interface IProps {
	params: IUpdateScheduledTaskForClientQueryParams;
	onFilter: (params: IUpdateScheduledTaskForClientQueryParams) => void;
	paddingLeft: number;
}

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterComponent
				label='Execute After'
				after={params.executeAfterAfter}
				before={params.executeAfterBefore}
				setSelected={(after, before) => onFilter({ ...params, executeAfterAfter: after, executeAfterBefore: before })}
			/>
			<RecordListFilterComponent<UpdateScheduledTaskStatus>
				record={UpdateScheduledTaskStatusRecord}
				options={UpdateScheduledTaskStatuses}
				selected={params.statuses as any}
				setSelected={vals => onFilter({ ...params, statuses: vals })}
				icon={<HelpOutlineIcon />}
				label={'Status'}
			/>
		</FilterBar>
	);
};
