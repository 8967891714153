import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SigmaIcon = ({ viewBox = "0 0 24 24", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <path d="M18,6H8.83L14.83,12L8.83,18H18V20H6V18L12,12L6,6V4H18V6Z" />
        </SvgIcon>
    );
}
