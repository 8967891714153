import { ColorX } from 'framework/components/color/ColorX';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { CircleX } from 'framework/components/CircleX';
import { RippleCircle } from 'framework/components/rippleCircle/RippleCircle';
import React from 'react';
import { StepXStatus } from './StepXStatus';

interface IProps {
	children: React.ReactNode;
	color?: ColorX;
	style?: React.CSSProperties;
	isLast?: boolean;
	status: StepXStatus;
}

export const StepX = ({ children, style, isLast, color = 'primary', status }: IProps) => {
	const tColor = useThemeColor(color);

	return (
		<div
			className='df-row gap-8'
			style={style}>
			<div
				className='df-col'
				style={{ paddingTop: 4 }}>
				<div>
					{status === 'active' && (
						<RippleCircle
							size={12}
							color={color}
						/>
					)}
					{status !== 'active' && (
						<CircleX
							size={12}
							color={color}
							variant={status === 'done' ? 'filled' : 'outlined'}
						/>
					)}
				</div>
				{isLast !== true && (
					<div
						style={{
							marginLeft: 6,
							marginTop: 4,
							marginBottom: -8,
							borderLeft: `1px solid ${tColor}`,
							height: '100%',
						}}></div>
				)}
			</div>
			<div
				className='df-col'
				style={{ minHeight: 50 }}>
				{children}
			</div>
		</div>
	);
};
