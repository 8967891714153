
import { SelectableColumn } from "../SelectableColumn"

export const ColumnRecord: Record<SelectableColumn, string> = {
    clientName: 'Client name',
    created: 'Created',
    endExecution: 'End execution',
    executeAfter: 'Execute after',
    executeBefore: 'Execute before',
    executionTimeWindowStr: 'Time window',
    id: 'Id',
    nextWindowAfter: 'Next window After',
    nextWindowBefore: 'Next window Before',
    startExecution: 'Start Execution',
    toBuild: 'To build'
}
