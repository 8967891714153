import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IAnnotation, IPatchAnnotationRequest, annotationsCommand_patch } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IPatchAnnotationRequest>({
			content: yup.string().required(),
			annotationId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	item: IAnnotation;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchAnnotationRequestForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(annotationsCommand_patch);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IPatchAnnotationRequest, helpers: FormikHelpers<IPatchAnnotationRequest>) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`Annotation patched`, 'success');
		}
	};

	return (
		<Formik<IPatchAnnotationRequest>
			validateOnMount
			initialValues={{
				annotationId: item.id,
				content: item.content,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Patch annotation`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IPatchAnnotationRequest>
							name='content'
							label='Content'
							multiline
							rows={20}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IPatchAnnotationRequest>
						cancel={cancel}
						submitText='Patch'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
