import { useCallback, useContext, useState } from "react";
import { ApiContext } from "../context/ApiContext";
import { Try } from "../Try";
import { useApiErrorHandling } from "./useApiErrorHandling";

export function useFormSubmit<TArgs extends any[], TResult>(callback: (...args: TArgs) => Promise<Try<TResult>>, setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>): [(...args: TArgs) => Promise<Try<TResult>>, boolean, React.Dispatch<React.SetStateAction<boolean>>];
export function useFormSubmit<TArgs extends any[], TResult>(callback: (...args: TArgs) => Promise<Try<TResult>>): [(...args: TArgs) => Promise<Try<TResult>>, boolean, React.Dispatch<React.SetStateAction<boolean>>];

export function useFormSubmit<TArgs extends any[], TResult>(callback: (...args: TArgs) => Promise<Try<TResult>>, setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>): 
[(...args: TArgs) => Promise<Try<TResult>>, boolean, React.Dispatch<React.SetStateAction<boolean>>] {

    const [isLoading, setInternalLoading] = useState<boolean>(false);
    const apiContext = useContext(ApiContext);
    const handleError = useApiErrorHandling(apiContext.strings, apiContext.redirect, apiContext.showError);
    const cbSetIsLoading = useCallback(setIsLoading ?? setInternalLoading, [setIsLoading]);

    return [useCallback(async (...args: TArgs) => {
        cbSetIsLoading(true);
        const r = await callback(...args);
        if (r.isFailure) {
            handleError(r.error);
        }
        cbSetIsLoading(false);
        return r;
    // missing deps handleError -> is a function
    // eslint-disable-next-line
    }, [callback, cbSetIsLoading]), isLoading, cbSetIsLoading];
}