import { DialogContent, DialogProps } from '@material-ui/core';
import { BillingAccountsCacheContext } from 'app/main/billingAccounts/BillingAccountsCacheContext';
import { addDays } from 'date-fns';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { getNow } from 'framework/utils/getNow';
import {
	IBillingAccount,
	ICreateSaleRequest,
	ISalesSettings,
	ISubscription,
	salesCommand_create,
	salesQuery_nextReference,
	salesQuery_settings,
} from 'gen/ApiClient';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { mapSubscriptionToSalesLineRequestX } from './mapSubscriptionToSalesLineRequestX';
import { CreateSaleRequestFormConfirmationComponent } from './CreateSaleRequestFormConfirmationComponent';
import { CreateSaleRequestFormLinesComponent } from './CreateSaleRequestFormLinesComponent';
import { ISalesLineRequestX } from './ISalesLineRequestX';
import { mapSalesLineRequest } from './mapSalesLineRequest';
import { useConvertSubscriptionToLine } from './useConvertSubscriptionToLine';

const DefaultValues: ICreateSaleRequest = {
	billingAccountId: '',
	date: getNow(),
	isAutoSelectReference: true,
	lines: [],
	payBefore: addDays(getNow(), 14),
	reference: '',
	subscriptionId: undefined,
};

const Schema = yup
	.object<ICreateSaleRequest>({
		billingAccountId: yup.string().required(),
		date: yup.date().required(),
		isAutoSelectReference: yup.bool(),
		lines: yup.mixed(),
		payBefore: yup.date().required(),
		reference: yup.string().required(),
		subscriptionId: yup.string(),
	})
	.defined();

const stepsRecord: Record<number, (keyof ICreateSaleRequest)[]> = {
	0: ['reference', 'isAutoSelectReference', 'date', 'payBefore', 'billingAccountId'],
	1: ['lines'],
	2: [],
};

interface IProps extends DialogProps {
	billingAccountId: string | undefined;
	subscription: ISubscription | undefined;
	confirm: (id: string) => void;
	cancel: () => void;
}

export const CreateSaleRequestForm = ({ billingAccountId, subscription, confirm, cancel, ...rest }: IProps) => {
	const [create, isSubmitting] = useFormSubmit(salesCommand_create);
	const [step, setStep] = useState<number>(0);
	const notify = useSnackbarNotify();
	const [settings] = useApiEffect(salesQuery_settings);
	const [nextReference] = useApiEffect(salesQuery_nextReference);
	const [billingAccounts] = useCacheContext(BillingAccountsCacheContext);
	const fBillingAccounts = useMemo(() => (billingAccounts ?? []).filter(t => t.areAllFieldsFilledIn), [billingAccounts]);
	const [lines, setLines] = useState<ISalesLineRequestX[]>([]);
	useConvertSubscriptionToLine(subscription, setLines);

	const handleSubmit = async (values: ICreateSaleRequest, helpers: FormikHelpers<ICreateSaleRequest>) => {
		if (lines.length === 0) {
			notify('Select at least one line', 'warning');
			setStep(1);
		} else {
			const r = await create({ ...values, lines: lines.map((t, index) => mapSalesLineRequest(t, index)) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				confirm(r.result.objectId);
				notify(`Sale '${values.reference}' successfully created`, 'success');
			}
		}
	};

	useEffect(() => {
		if (subscription) {
			setLines([mapSubscriptionToSalesLineRequestX(subscription)]);
		}
	}, [subscription]);

	if (settings === undefined || nextReference === undefined || billingAccounts === undefined) {
		return <></>;
	}

	return (
		<Formik<ICreateSaleRequest>
			validateOnMount
			initialValues={{
				...DefaultValues,
				billingAccountId: billingAccountId ?? '',
				subscriptionId: subscription?.id,
			}}
			validationSchema={Schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={Schema}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
					billingAccounts={fBillingAccounts}
					nextReference={nextReference}
					settings={settings}
					lines={lines}
					setLines={setLines}
					billingAccountId={billingAccountId}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<ICreateSaleRequest>;
	cancel: () => void;
	stepsRecord: Record<number, (keyof ICreateSaleRequest)[]>;
	settings: ISalesSettings;
	billingAccounts: IBillingAccount[];
	billingAccountId: string | undefined;
	nextReference: string;
	lines: ISalesLineRequestX[];
	setLines: React.Dispatch<React.SetStateAction<ISalesLineRequestX[]>>;
}

const InnerDialog = ({
	step,
	setStep,
	isSubmitting,
	schema,
	cancel,
	stepsRecord,
	settings,
	billingAccounts,
	nextReference,
	lines,
	setLines,
	billingAccountId,
	...rest
}: IInnerProps) => {
	const props = useFormikContext<ICreateSaleRequest>();

	useEffect(() => {
		if (nextReference) {
			setFormValue<ICreateSaleRequest>('reference', nextReference, props);
		}
		// eslint-disable-next-line
	}, [nextReference]);

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={1}
			title={`New Sale ${props.values.reference}`}
			step={step}
			labels={[`General`, `Lines`, `Confirmation`]}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<ICreateSaleRequest>
							name='isAutoSelectReference'
							label={`Auto select reference?`}
						/>
						<FormTextField<ICreateSaleRequest>
							name='reference'
							label={`Reference (invoice number)`}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormDatePicker<ICreateSaleRequest>
							name='date'
							label='Date'
						/>
						<FormDatePickerWithUnit<ICreateSaleRequest>
							name='payBefore'
							label={`Pay before`}
							required
							units={['Days', 'Weeks', 'Months']}
							defaultUnit='Weeks'
							defaultValue={settings.defaultValidUntil}
						/>
						<FormAutocompleteField<ICreateSaleRequest, IBillingAccount>
							name='billingAccountId'
							label={`Billing account`}
							options={billingAccounts}
							disabled={billingAccountId !== undefined}
						/>
					</>
				)}
				{step === 1 && (
					<CreateSaleRequestFormLinesComponent
						lines={lines}
						setLines={setLines}
					/>
				)}
				{step === 2 && (
					<CreateSaleRequestFormConfirmationComponent
						lines={lines}
						reference={props.values.reference}
						recipientName={billingAccounts.find(t => t.id === props.values.billingAccountId)?.name ?? ''}
						isShiftVat={false}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={`Create`}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
