import { Typography } from '@material-ui/core';
import { Logo } from 'app/logo/Logo';
import React from 'react';

export const PreloadWall = () => {
	return (
		<div className='df-col-ac stretch-ver jc-c'>
			<Logo
				width={400}
				style={{ marginBottom: '20px' }}
			/>
			<Typography variant='h5'>{`Loading...`}</Typography>
		</div>
	);
};
