import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { getNow } from 'framework/utils/getNow';
import { ICreateLicenseFeeRequest, licensesCommand_addFee, LicensePricingMethod } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';
import { PricingMethodFeeDescriptionRecord } from './PricingMethodFeeDescriptionRecord';

const createSchema = () => {
	return yup
		.object<ICreateLicenseFeeRequest>({
			value: yup.number().required(),
			validFrom: yup.date().required(),
			licenseId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	licenseId: string;
	pricingMethod: LicensePricingMethod;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CreateLicenseFeeRequestForm = ({ licenseId, pricingMethod, confirm, cancel, ...rest }: IProps) => {
	const [add, isSubmitting] = useFormSubmit(licensesCommand_addFee);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: ICreateLicenseFeeRequest, helpers: FormikHelpers<ICreateLicenseFeeRequest>) => {
		const r = await add({ ...values, licenseId: licenseId });
		if (handleFormResponse(r, helpers)) {
			confirm(r.result.objectId);
			notify(`License fee succesfully added`, 'success');
		}
	};

	return (
		<Formik<ICreateLicenseFeeRequest>
			validateOnMount
			initialValues={{
				value: 0,
				validFrom: getNow(),
				licenseId: licenseId,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Add fee`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormDatePicker<ICreateLicenseFeeRequest>
							name='validFrom'
							label='Valid From'
						/>
						<FormMoneyField<ICreateLicenseFeeRequest>
							name='value'
							label={PricingMethodFeeDescriptionRecord[pricingMethod]}
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<ICreateLicenseFeeRequest>
						cancel={cancel}
						submitText='Create'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
