import { Chip, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';

interface IProps<T extends string> {
    mode: T;
    setMode: (val: T) => void;
    modes: T[];
    style?: React.CSSProperties;
    variant?: 'default' | 'outlined';
}

export const SelectSearchMode = <T extends string>({ mode, setMode, modes, style, variant = 'outlined' }: IProps<T>) => {
    const [anchor, open, close] = useAnchorElement();

    return (
        <>
            <Chip
                color="primary"
                size="small"
                variant={variant}
                label={
                    <div className="df-row-ac jc-sb">
                        <div>{mode}</div>
                        <ArrowDropDownIcon fontSize="small" style={{ marginRight: -8 }} />
                    </div>
                }
                clickable
                onClick={open}
                style={style} />
            <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={close}
                keepMounted>
                {modes.map(t => <MenuItem key={t} onClick={() => { setMode(t); close(); }} value={t}>{t}</MenuItem>)}
            </Menu>
        </>
    );
}