import { Popover } from '@material-ui/core';
import { FilterButton } from 'framework/filter/FilterButton';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import React, { useEffect, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { FilterOnLabelsPopoverContent } from './FilterOnLabelsPopoverComponent';
import { IMultilistFilter } from './IMultilistFilter';

// TODO complete this function
const isDirty = (values: IMultilistFilter[], selected: string[][]) => {
	if (values.length === 0 && selected.length === 0) {
		return false;
	} else {
		return true;
	}
};

interface IProps {
	selected: string[][];
	setSelected: (selected: string[][]) => void;
	label: string;
	title?: string;
	icon?: React.ReactNode;
}

export const LabelsFilterComponent = ({ selected, setSelected, label, title, icon }: IProps) => {
	const [anchor, open, close] = useAnchorElement();
	const [values, setValues] = useState<IMultilistFilter[]>([]);

	useEffect(() => {
		setValues(selected.map<IMultilistFilter>(t => ({ id: uuid(), ors: t })));
	}, [selected]);

	const handleClose = () => {
		if (isDirty(values, selected)) {
			setSelected(values.map(t => t.ors));
		}
		close();
	};

	return (
		<>
			<FilterButton
				handleOpen={open}
				badgeContent={selected.length}
				label={label}
				icon={icon}
			/>
			<Popover
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}>
				<FilterOnLabelsPopoverContent
					contextType='Sale'
					title={title ?? 'Filter on labels'}
					filter={values}
					setFilter={setValues}
					style={{ width: 400, overflowY: 'scroll' }}
				/>
			</Popover>
		</>
	);
};
