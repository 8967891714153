import React from 'react';

interface IProps {
    value: any;
}

export const CodeBlock = ({ value }: IProps) => {
    return (
        <pre>
            <code>{JSON.stringify(value, null, 1)}</code>
        </pre>
    );
}