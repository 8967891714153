import { Dialog, DialogProps, Grid, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { ExpandCollapseManagedComponent } from 'framework/components/ExpandCollapseManagedComponent';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { medicalTreatmentNomenclaturesQuery_single_app } from 'gen/ApiClient';
import { CodeBlock } from 'shared/CodeBlock';

interface IProps extends DialogProps {
	close: () => void;
	id: string;
}

const RizivUrl = `https://ondpanon.riziv.fgov.be/Nomen/nl/{code}`;

export const MtnDetailDialog = ({ id, close, ...rest }: IProps) => {
	const [detail] = useApiEffect(medicalTreatmentNomenclaturesQuery_single_app, id);
	const openInNewTab = useOpenInNewTab();

	if (detail === undefined) {
		return <div></div>;
	}

	const onViewRiziv = () => {
		openInNewTab(RizivUrl.replace('{code}', detail!.detail.nomenCode!), true);
	};

	return (
		<Dialog
			{...rest}
			onClose={close}
			fullWidth
			maxWidth='xl'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<BusinessIcon />}>
					<Typography variant='h1'>{detail.mappedForAudiology.data.code}</Typography>
					<LabelledProperty
						withoutMargin
						label='Description'
						property={detail.mappedForAudiology.data.descriptionNl}
					/>
					<LabelledProperty
						withoutMargin
						label='Value'
						property={formatCurrency(detail.mappedForAudiology.data.currentFeeValue)}
					/>
					<LabelledProperty
						withoutMargin
						label='Contribution'
						property={formatCurrency(detail.mappedForAudiology.data.currentFeeContribution)}
					/>
					<LabelledProperty
						withoutMargin
						label='Personal Share'
						property={formatCurrency(detail.mappedForAudiology.data.currentFeePersonalShare)}
					/>
					<LabelledProperty
						withoutMargin
						label='Validity Start'
						property={formatDate(detail.mappedForAudiology.data.validityStart)}
					/>
					<LabelledProperty
						withoutMargin
						label='Validity End'
						property={formatDate(detail.mappedForAudiology.data.validityEnd)}
					/>
					<LabelledProperty
						withoutMargin
						label='Count Fees'
						property={`# ${detail.fees.length.toString()}`}
					/>
					<SmallPrimaryTextButton onClick={onViewRiziv}>View Details on RIZIV site</SmallPrimaryTextButton>
				</DialogGridTitle>
				<GridDividerWithCaption caption='Mapped for audiology (IMedical..ForAudiology)' />
				<Grid
					item
					xs>
					<ExpandCollapseManagedComponent>
						<CodeBlock value={detail.mappedForAudiology} />
					</ExpandCollapseManagedComponent>
				</Grid>
				<GridDividerWithCaption caption='Detail' />
				<Grid
					item
					xs>
					<ExpandCollapseManagedComponent>
						<CodeBlock value={detail.detail} />
					</ExpandCollapseManagedComponent>
				</Grid>
				<GridDividerWithCaption caption='Fees' />
				<Grid
					item
					xs>
					<ExpandCollapseManagedComponent>
						<CodeBlock value={detail.fees} />
					</ExpandCollapseManagedComponent>
				</Grid>
			</DialogContentGrid>
		</Dialog>
	);
};
