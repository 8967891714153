import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { PagebleTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { IClientDetailDto, IUpdateScheduledTaskForClientQueryParams, IUpdatedScheduledTaskDto, updateScheduledTasksQuery_queryForClient } from 'gen/ApiClient';
import React, { useCallback, useContext, useState } from 'react';
import { SelectColumnsButtonAndDialog } from 'shared/SelectColumnsButtonAndDialog';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectableColumn } from '../../updateScheduledTasks/SelectableColumn';
import { UstStatusChip } from '../../updateScheduledTasks/UstStatusChip';
import { ColumnRecord } from '../../updateScheduledTasks/context/ColumnRecord';
import { ViewContext } from '../../updateScheduledTasks/context/ViewContext';
import { UstDialog } from '../../updateScheduledTasks/detail/UstDialog';
import { DefaultParams } from './DefaultParams';
import { Filter } from './Filter';

interface IProps {
	client: IClientDetailDto;
	reloadTrigger: Trigger;
}

export const UpdateScheduledTasksPage = ({ client, reloadTrigger }: IProps) => {
	const theme = useTheme();
	const [params, setParams] = useState<IUpdateScheduledTaskForClientQueryParams>(DefaultParams);
	const queryF = useCallback((params: IUpdateScheduledTaskForClientQueryParams) => updateScheduledTasksQuery_queryForClient(client.id, params), [client.id]);
	const [queryResult, reloadQr] = useApiEffect(queryF, params);
	const { columns, setColumns, headers } = useContext(ViewContext);
	const { open, cancel } = useDialogsContext();

	useLazyEffect(() => {
		reloadQr();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onDetails = (id: string) => {
		open(
			<UstDialog
				open
				id={id}
				close={cancel}
			/>
		);
	};

	return (
		<Card>
			<CardTitleTypography
				title='Update tasks'
				withPadding
			/>
			<Divider />
			<div className='df-row jc-sb'>
				<Filter
					params={params}
					onFilter={p => setParams({ ...p, pageParams: { index: 1, size: params.pageParams.size } })}
					paddingLeft={theme.spacing(1.5)}
				/>
				<div>
					<SelectColumnsButtonAndDialog
						selectedColumns={columns}
						setSelectedColumns={setColumns}
						record={ColumnRecord}
					/>
				</div>
			</div>
			<Divider />
			<CardMedia>
				<PagebleTableWithColumnSelection<IUpdatedScheduledTaskDto, SelectableColumn>
					queryResult={queryResult}
					headers={headers}
					sortParams={params.sortParams}
					pageParams={params.pageParams}
					setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
					getKey={inst => inst.id}
					selectedColumns={columns}
					prependColumn={createEmptyHeader<IUpdatedScheduledTaskDto>('status', t => (
						<UstStatusChip item={t} />
					))}
					appendColumn={createEmptyHeader<IUpdatedScheduledTaskDto>(
						'details-header',
						t => (
							<SmallPrimaryTextButton onClick={() => onDetails(t.id)}>{`Details`}</SmallPrimaryTextButton>
						),
						'right'
					)}
				/>
			</CardMedia>
		</Card>
	);
};
