import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';

export const SmallPrimaryTextButton = ({ style, ...rest }: ButtonProps) => {
	return (
		<Button
			variant='text'
			size='small'
			color='primary'
			style={{ textTransform: 'none', ...style }}
			{...rest}
		/>
	);
};
