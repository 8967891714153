import React from 'react';
import { IPageParams } from '../types/IPageParams';
import { IQueryResult } from '../types/IQueryResult';
import { ISortParams } from '../types/ISortParams';
import { filterHeaders } from './filterHeaders';
import { IHeader } from './IHeader';
import { PageableTable } from './PageableTable';

interface IProps<TData, TSelectable extends string> {
    queryResult: IQueryResult<TData>;
    headers: Record<TSelectable, IHeader<TData>>;
    getKey: (inst: TData) => string;
    sortParams: ISortParams<TData>;
    pageParams: IPageParams;
    setParams: (sortParams: ISortParams<TData>, pageParams: IPageParams) => void;
    padding?: number;
    selectedColumns: TSelectable[];
    appendColumn?: IHeader<TData>;
    prependColumn?: IHeader<TData>;
    expandableContent?: (value: TData) => JSX.Element;
    expandMultiple?: boolean;
    withPaperContainer?: boolean;
}

export const PagebleTableWithColumnSelection = <TData extends unknown, TSelectable extends string>({ selectedColumns, headers, prependColumn, appendColumn, ...rest }: IProps<TData, TSelectable>) => {
    return (
        <PageableTable
            headers={filterHeaders(headers, selectedColumns, appendColumn, prependColumn)}
            {...rest} />
    );
}