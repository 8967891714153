import { CardX } from 'framework/components/cards/CardX';
import React from 'react';
import { LabelsList } from './main/labels/LabelsList';

export const TestPage = () => {
	return (
		<CardX
			className='df-col'
			style={{ margin: 20, width: 400 }}>
			<LabelsList contextType='Sale' />
		</CardX>
	);
};
