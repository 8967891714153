import React from 'react';
import { NoRecordsFound } from 'shared/NoRecordsFound';
import { RowWrappedLabels } from './RowWrappedLabels';

interface IProps {
	labelIds: string[];
	style?: React.CSSProperties;
}

export const RowWrappedLabelsNoRecordsFoundIfEmpty = ({ labelIds, style }: IProps) => {
	return (
		<>
			{labelIds.length > 0 && (
				<RowWrappedLabels
					labelIds={labelIds}
					style={style}
				/>
			)}
			{labelIds.length === 0 && <NoRecordsFound variant='caption'>{`No labels assigned yet`}</NoRecordsFound>}
		</>
	);
};
