import { useTheme } from '@material-ui/core';
import { ClearFilterTextLinkButton } from 'framework/filter/ClearFilterTextLinkButton';
import { FilterChipX } from 'framework/filter/FilterChipX';
import { ListFilterSearch } from 'framework/filter/ListFilterSearch';
import { updateArray } from 'framework/utils/array/updateArray';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { ILabelDto, LinkContextType } from 'gen/ApiClient';
import React, { useEffect, useMemo, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { FilterLabelListItem } from './FilterLabelListItem';
import { IMultilistFilter } from './IMultilistFilter';
import { useLabelsForContextType } from './useLabelsForContext';

interface IProps {
	contextType: LinkContextType;
	filter: IMultilistFilter[];
	setFilter: React.Dispatch<React.SetStateAction<IMultilistFilter[]>>;
	title: string;
	style?: React.CSSProperties;
}

export const FilterOnLabelsPopoverContent = ({ contextType, title, filter, setFilter, style }: IProps) => {
	const [labels] = useLabelsForContextType(contextType);
	const [fLabels, setFLabels] = useState<ILabelDto[]>([]);
	const theme = useTheme();
	const [search, setSearch] = useState<string>('');
	const selectedIds = useMemo(() => filter.flatMap(t => t.ors), [filter]);

	useEffect(() => {
		if (isNullOrUndefined(search)) {
			setFLabels(labels);
		} else {
			setFLabels(labels.filter(t => `${t.name} ${t.caption}`.toLowerCase().includes(search.toLowerCase())));
		}
	}, [search, labels]);

	const onAnd = (id: string) => {
		setFilter([...filter, { id: uuid(), ors: [id] }]);
	};

	const onOr = (id: string) => {
		if (filter.length === 0) {
			setFilter([{ id: uuid(), ors: [id] }]);
		} else {
			const fLast = filter[filter.length - 1];
			setFilter(updateArray(fLast, filter, { id: fLast.id, ors: [...fLast.ors, id] }));
		}
	};

	return (
		<div
			className='df-col'
			style={{
				minWidth: 300,
				...style,
			}}>
			<span
				style={{
					fontSize: 12,
					padding: '8px 10px',
					fontWeight: 'bold',
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				{title}
			</span>
			<ListFilterSearch
				search={search}
				setSearch={setSearch}
				style={{
					padding: '4px 10px',
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}
			/>
			<ClearFilterTextLinkButton
				onClear={() => setFilter([])}
				isFiltered={filter.length > 0}
				style={{
					padding: '8px 10px',
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}
			/>
			{filter.length > 0 && (
				<div
					className='df-row-wrap gap-4'
					style={{
						padding: '8px 10px',
						borderBottom: `1px solid ${theme.palette.divider}`,
					}}>
					{filter.map(t => (
						<FilterChipX
							key={t.id}
							outlined
							value={labels.filter(x => t.ors.indexOf(x.id) > -1).map(t => t.name ?? '')}
							onDelete={() => setFilter(filter.filter(x => x.id !== t.id))}
						/>
					))}
				</div>
			)}
			{fLabels.map(t => (
				<FilterLabelListItem
					item={t}
					key={t.id}
					isSelected={selectedIds.indexOf(t.id) > -1}
					onOr={() => onOr(t.id)}
					onAnd={() => onAnd(t.id)}
				/>
			))}
		</div>
	);
};
