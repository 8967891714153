import React from 'react';
import { WarningIconWithWarningColor } from '../icons/WarningIconWithWarningColor';
import { ILabelledPropertyProps, LabelledProperty } from './LabelledProperty';

interface IProps extends ILabelledPropertyProps {
    warning: string;
    style?: React.CSSProperties;
}

export const WarningLabelledProperty = ({ warning, ...rest }: IProps) => {
    return (
        <LabelledProperty
            property={warning}
            icon={<WarningIconWithWarningColor />}
            color="warning"
            {...rest} />
    );
}