import { Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { ReturnUrlPrefix } from '../../keys';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { FormPasswordField } from 'framework/forms/FormPasswordField';
import { FormTextField } from 'framework/forms/FormTextField';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { auth_login, auth_logout, ILoginRequest } from 'gen/ApiClient';
import * as routes from '../../routes';
import { AuthContext } from './AuthContext';
import { EmptyPageWithLogo } from './EmptyPageWithLogo';

const createSchema = () => {
	return yup.object<ILoginRequest>({
		email: yup.string().email().required(),
		password: yup.string().required(),
	});
};

export const Login = () => {
	const authContext = useContext(AuthContext);
	const [login, isSubmitting, setSubmitting] = useFormSubmit(auth_login);
	const [logout] = useFormSubmit(auth_logout, setSubmitting);
	const { push } = useHistory();
	const location = useLocation();

	useEffect(() => {
		const x = () => {
			logout();
			authContext.logout();
		};
		x();
		// only happens at mount of this screen
		// eslint-disable-next-line
	}, []);

	const handleSubmit = async (values: ILoginRequest, helpers: FormikHelpers<ILoginRequest>) => {
		const r = await login(values);
		if (r.isSuccess) {
			if (r.result.hasError) {
				if (r.result.errorEmail) {
					helpers.setFieldError('email', r.result.errorEmail);
				}
				if (r.result.errorPassword) {
					helpers.setFieldError('password', r.result.errorPassword);
				}
			} else {
				authContext.login(r.result.user);
				if (location.search.indexOf(ReturnUrlPrefix) > -1) {
					const returnUrl = location.search.replace(ReturnUrlPrefix, '');
					push(returnUrl);
				} else {
					push(routes.DashboardRoute);
				}
			}
		}
	};

	return (
		<EmptyPageWithLogo title='Login to HAMS root with e-mail password'>
			<Formik<ILoginRequest>
				validateOnMount
				initialValues={{ email: '', password: '' }}
				validationSchema={createSchema()}
				onSubmit={handleSubmit}>
				{props => (
					<Form
						autoComplete='off'
						className='df-col'
						style={{ minWidth: '400px' }}>
						<FormTextField<ILoginRequest>
							name='email'
							label='Email'
							required
						/>
						<FormPasswordField<ILoginRequest>
							name='password'
							label='Password'
							required
						/>
						<LoaderButton
							style={{ marginTop: '20px' }}
							size='large'
							variant='contained'
							color='primary'
							type='submit'
							isLoading={isSubmitting}
							disabled={isSubmitting || !props.isValid}>
							{`Login to HAMS root`}
						</LoaderButton>
					</Form>
				)}
			</Formik>
		</EmptyPageWithLogo>
	);
};
