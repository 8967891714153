import { Button, DialogActions, PropTypes } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { LoaderButton } from '../components/buttons/LoaderButton';

interface IProps {
    cancel: () => void;
    submitText: string;
    isSubmitting: boolean;
    onSubmit: () => void;
    cancelText?: string;
    colorSubmitButton?: PropTypes.Color;
    submitButtonType?: 'submit' | 'reset' | 'button';
    isValid?: boolean;
    variantSubmitButton?: 'text' | 'outlined' | 'contained';
    previous?: () => void;
    hideSubmitButton?: boolean;
}

export const CancelSubmitDialogActions = ({ previous, cancel, cancelText, submitText, isSubmitting, onSubmit, colorSubmitButton = 'primary', submitButtonType = 'button', isValid = true, variantSubmitButton = 'text', hideSubmitButton = false }: IProps) => {
    const strings = useLocalization();
    return (
        <DialogActions>
            <Button onClick={cancel} color="primary">{cancelText ?? strings.cancel}</Button>
            {previous && <Button onClick={previous} color="primary">{strings.previous}</Button>}
            {hideSubmitButton === false && <LoaderButton type={submitButtonType} disabled={isSubmitting || !isValid} isLoading={isSubmitting} onClick={onSubmit} color={colorSubmitButton} variant={variantSubmitButton}>{submitText}</LoaderButton>}
        </DialogActions>
    );
}

