import { CardProps } from '@material-ui/core';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { ISale } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { SalePaymentListItem } from './SalePaymentListItem';
import { isLast } from 'framework/utils/array/isLast';
import { NoRecordsFound } from 'framework/components/NoRecordsFound';
import _ from 'lodash';

interface IProps extends CardProps {
	item: ISale;
	reload: VoidFunction;
}

export const SaleDetailPaymentsCard = ({ item, reload, ...rest }: IProps) => {
	const ordered = useMemo(() => _.orderBy(item.payments, t => t.paymentDate), [item]);

	return (
		<CardX {...rest}>
			<div
				className='df-row'
				style={{ gap: 8 }}>
				<Typography16pxBold>{`Payments`}</Typography16pxBold>
				<div className='fg1'></div>
			</div>
			{ordered.map(t => (
				<SalePaymentListItem
					item={t}
					salesId={item.id}
					reload={reload}
					key={t.paymentId}
					withoutBorder={isLast(t, ordered)}
				/>
			))}
			{ordered.length === 0 && <NoRecordsFound withPaddingTop>{`No payments received yet...`}</NoRecordsFound>}
		</CardX>
	);
};
