import { ISale } from 'gen/ApiClient';
import React from 'react';
import { SalesPaymentStatusChip } from './components/SalesPaymentStatusChip';
import { SalesTypeChip } from './components/SalesTypeChip';
import { ChipX } from 'framework/components/chips/ChipX';

interface IProps {
	item: ISale;
	style?: React.CSSProperties;
}

export const SalesChips = ({ item, style }: IProps) => {
	return (
		<div
			className='df-row-ac gap-4'
			style={style}>
			<SalesTypeChip item={item} />
			{item.type === 'Invoice' && <SalesPaymentStatusChip item={item} />}
			{item.isExceptional && (
				<ChipX
					label='Exceptional'
					fill
					color='error'
				/>
			)}
		</div>
	);
};
