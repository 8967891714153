
import { createSortableHeaderWithKey } from "framework/table/createSortableHeaderWithKey";
import { IHeader } from "framework/table/IHeader";
import { formatDate } from "framework/utils/date/formatDate";
import { IUpdatedScheduledTaskDto } from "gen/ApiClient";
import { SelectableColumn } from "../SelectableColumn";

export const createHeaders = (colRecord: Record<SelectableColumn, string>): Record<SelectableColumn, IHeader<IUpdatedScheduledTaskDto>> => {
    return {
        executionTimeWindowStr: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'executionTimeWindowStr', s => s.executionTimeWindowStr),
        id: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'id', s => s.id),
        toBuild: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'toBuild', s => s.toBuild.toString()),
        clientName: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'clientName', s => s.clientName),
        created: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'created', s => formatDate(s.created)),
        endExecution: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'endExecution', s => formatDate(s.endExecution, 'date-and-time')),
        startExecution: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'startExecution', s => formatDate(s.startExecution, 'date-and-time')),
        executeAfter: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'executeAfter', s => formatDate(s.executeAfter, 'date-and-time')),
        executeBefore: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'executeBefore', s => formatDate(s.executeBefore, 'date-and-time')),
        nextWindowBefore: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'nextWindowBefore', s => formatDate(s.nextWindowBefore, 'date-and-time')),
        nextWindowAfter: createSortableHeaderWithKey<IUpdatedScheduledTaskDto, SelectableColumn>(colRecord, 'nextWindowAfter', s => formatDate(s.nextWindowAfter, 'date-and-time')),
    }
}