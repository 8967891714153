import { TableRow, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { TableCellX } from 'framework/table/TableCellX';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISubscription } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { calcClientDetailRoute, calcLicenseDetailRoute, calculateSubscriptionDetailRoute } from 'routes';
import { SubscriptionChips } from '../SubscriptionChips';
import { SubscriptionListItemStatusIcon } from './SubscriptionListItemStatusIcon';
import { mapSubscriptionToColor } from './utils';

const MinorFontSize = 13;

interface IProps {
	item: ISubscription;
	hideClient?: boolean;
}

export const SubscriptionTableRow = ({ item, hideClient }: IProps) => {
	const color = useMemo(() => mapSubscriptionToColor(item), [item]);
	const tColor = useThemeColor(color);
	const theme = useTheme();
	const { push } = useHistory();
	const [isHovered, enter, leave] = useStateBoolean(false);

	const onViewDetails = () => push(calculateSubscriptionDetailRoute(item.id));

	return (
		<React.Fragment>
			<TableRow
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<TableCellX>
					<div className='df-row gap-4'>
						<SubscriptionListItemStatusIcon
							item={item}
							style={{ fontSize: 22, color: tColor }}
						/>
						<div className='df-col gap-2'>
							<TextLinkButton
								underline
								style={{ fontSize: 14 }}
								onClick={onViewDetails}>
								{`${formatDate(item.from)} - ${formatDate(item.to)}`}
							</TextLinkButton>
							<SubscriptionChips item={item} />
						</div>
					</div>
				</TableCellX>
				{hideClient !== true && (
					<TableCellX>
						<TextLinkButton onClick={() => push(calcClientDetailRoute(item.clientId))}>{item.clientName ?? ''}</TextLinkButton>
					</TableCellX>
				)}
				<TableCellX>
					<div className='df-col gap-2'>
						<TextLinkButton onClick={() => push(calcLicenseDetailRoute(item.licenseId))}>{item.licenseName ?? ''}</TextLinkButton>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{item.licenseCategory}</span>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{formatCurrency(item.calculatedLicenseFee)}</span>
					</div>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-row-ac jc-e'>
						<ColorOnHoverIconButton
							size='small'
							edge='end'
							color='primary'
							onClick={onViewDetails}>
							<VisibilityOutlinedIcon />
						</ColorOnHoverIconButton>
					</div>
				</TableCellX>
			</TableRow>
		</React.Fragment>
	);
};
