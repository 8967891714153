import { CacheProvider as FrameworkCacheProvider } from 'framework/context/CacheProvider';
import { ILicenseDto, licensesQuery_all, licensesQuery_concurrencyToken } from 'gen/ApiClient';
import React from 'react';
import { LicensesCacheContext } from './LicensesCacheContext';

export const LicensesCacheProvider = ({ children }: any) => {
	return (
		<FrameworkCacheProvider<ILicenseDto[]>
			concurrencyF={licensesQuery_concurrencyToken}
			loadF={licensesQuery_all}
			context={LicensesCacheContext}>
			{children}
		</FrameworkCacheProvider>
	);
};
