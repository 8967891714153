import React from 'react';
import { IFormProps } from './common/IFormProps';
import { FormNumberField } from './FormNumberField';

interface IProps<TModel> extends IFormProps<TModel> {
  allowNegative?: boolean;
  decimalScale?: number;
}

export const FormMoneyField = <TModel extends unknown>({ allowNegative, decimalScale = 2, ...props }: IProps<TModel>) => {
  return (
    <FormNumberField<TModel>
      {...props}
      decimalScale={decimalScale}
      startAdornment={<span>&euro;</span>}
      allowNegative={allowNegative ?? false} />
  );
}

