import { Card, CardContent } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { Application, softwaresQuery_single } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SoftwareForm } from './SoftwareForm';

interface IProps {
	name: Application;
	reloadTrigger: Trigger;
	style?: React.CSSProperties;
}

export const SoftwareCard = ({ name, reloadTrigger, style }: IProps) => {
	const [data, reload] = useApiEffect(softwaresQuery_single, name);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	const onUpdate = () => {
		open(
			<SoftwareForm
				open
				name={name}
				software={data}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Card style={style}>
			<CardTitleTypography
				withPadding
				title={name}
				action={
					<SmallPrimaryTextButton
						onClick={onUpdate}
						startIcon={<EditOutlinedIcon />}>
						Edit
					</SmallPrimaryTextButton>
				}
			/>
			<CardContent style={{ paddingTop: 0 }}>
				<LabelledProperty
					label='Build'
					property={data ? data.build.toString() : undefined}
				/>
				<LabelledProperty
					label='Semantic version'
					property={data ? data.semanticVersion : undefined}
				/>
			</CardContent>
		</Card>
	);
};
