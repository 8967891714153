import React, { useEffect, useState } from 'react';
import { IApiErrorStrings } from './localization/IApiErrorString';
import { useLocalStorage } from '../hooks/useLocalStorage';

// documentation: 
// https://github.com/stefalda/react-localization#readme

// export type LanguageOptions = 'en' | 'nl' | 'fr';

export interface ILocalizationProps<TStrings extends IApiErrorStrings, TLanguageOption> {
    strings: TStrings;
    setLanguage: (lang: TLanguageOption) => void;
    language: TLanguageOption;
}

interface IProps<TStrings extends IApiErrorStrings, TLanguageOption extends string> {
    map: Record<TLanguageOption, TStrings>;
    children: any;
    context: React.Context<ILocalizationProps<TStrings, TLanguageOption>>;
    localStorageKey: string;
    fallbackValue: TLanguageOption;
}

export const LocalizationProvider = <TStrings extends IApiErrorStrings, TLanguageOption extends string>({ children, map, context, localStorageKey, fallbackValue }: IProps<TStrings, TLanguageOption>) => {
    const [language, setLanguage] = useLocalStorage<TLanguageOption>(localStorageKey, fallbackValue);
    const [strings, setStrings] = useState<TStrings>(map[language]);

    useEffect(() => {
        setStrings(map[language]);
    }, [language, map]);

    return (
        <context.Provider
            value={{
                strings: strings,
                setLanguage: setLanguage,
                language: language,
            }}>
            {children}
        </context.Provider>
    );
}
