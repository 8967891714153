import { Card, CardContent, Divider } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { StatusChip } from 'framework/components/StatusChip';
import { SmallPrimaryTextLoaderButton } from 'framework/components/buttons/SmallPrimaryTextLoaderButton';
import { StopCircleOutlineIcon } from 'framework/components/icons/StopCircleOutlineIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { Trigger } from 'framework/hooks/useTrigger';
import { IClientDetailDto, clientsControls_start, clientsControls_stop, clientsQuery_iisInfo } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	client: IClientDetailDto;
	reloadTrigger: Trigger;
	reload: () => void;
}

export const IisPage = ({ client, reloadTrigger, reload }: IProps) => {
	const [data, reloadData] = useApiEffect(clientsQuery_iisInfo, client.id);
	const [start, isStarting] = useFormSubmit(clientsControls_start);
	const [stop, isStopping] = useFormSubmit(clientsControls_stop);
	const openInNewTab = useOpenInNewTab();

	useLazyEffect(() => {
		console.log('reloadTrigger');
		reloadData();
	}, [reloadTrigger]);

	if (data === undefined) {
		return <div></div>;
	}

	const onStart = async () => {
		await start({ clientId: client.id });
		reload();
	};

	const onStop = async () => {
		await stop({ clientId: client.id });
		reload();
	};

	return (
		<div className='df-col'>
			<Card>
				<CardContent>
					<CardTitleTypography
						title='IIS info'
						secondaryTitle={
							<StatusChip
								style={{ marginLeft: 16 }}
								label={data.state!}
								fill
								color={data.isStarted ? 'success' : 'error'}
							/>
						}
					/>
					<LabelledProperty
						label='Site-ID'
						property={data.id}
					/>
					<LabelledProperty
						label='Name'
						property={data.name}
					/>
					<LabelledProperty
						label='Physical Path'
						property={data.physicalPath}
					/>
					<LabelledProperty
						label='State'
						property={data.state}
					/>
					<div className='df-row-ac'>
						<SmallPrimaryTextLoaderButton
							isLoading={isStarting}
							startIcon={<PlayCircleOutlineOutlinedIcon />}
							disabled={data.isStarted}
							onClick={onStart}>
							{`Start`}
						</SmallPrimaryTextLoaderButton>
						<Divider
							orientation='vertical'
							flexItem
							style={{ margin: 8 }}
						/>
						<SmallPrimaryTextLoaderButton
							isLoading={isStopping}
							startIcon={<StopCircleOutlineIcon />}
							color='secondary'
							disabled={data.isStopped}
							onClick={onStop}>
							{`Stop`}
						</SmallPrimaryTextLoaderButton>
					</div>
				</CardContent>
			</Card>
			{data.bindings.map((binding, index) => (
				<Card
					style={{ marginTop: 16 }}
					key={binding.url}>
					<CardContent>
						<CardTitleTypography title={`Binding #${index + 1}`} />
						<LabelledProperty
							label='Protocol'
							property={binding.protocol}
						/>
						<LabelledProperty
							label='Host'
							property={binding.host}
						/>
						<LabelledProperty
							label='URL'
							property={binding.url}
							secondaryActionIcon={<OpenInNewIcon />}
							secondaryAction={() => openInNewTab(binding.url!, true)}
						/>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
