import { Card, CardContent, Divider, LinearProgress } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { IntervalTriggerComponent } from 'framework/components/IntervalTriggerComponent';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { SuccessLabelledProperty } from 'framework/components/labelledProperty/SuccessLabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { formatDate } from 'framework/utils/date/formatDate';
import { IJobInfoDto } from 'gen/ApiClient';
import React from 'react';

interface IMetadata {
	hasChanges: boolean;
}

interface IProps {
	title: string;
	jobInfo: IJobInfoDto | undefined;
	metadata: IMetadata | undefined;
	reload: () => void;
	onCheckAgain: () => void;
	onViewDetails: () => void;
	onUpdateData?: () => void;
	actions?: JSX.Element;
	style?: React.CSSProperties;
}

export const CheckCard = ({ jobInfo, metadata, title, reload, onViewDetails, onCheckAgain, onUpdateData, actions, style }: IProps) => {
	return (
		<Card style={style}>
			{jobInfo !== undefined && jobInfo.isExecuting && (
				<>
					<IntervalTriggerComponent
						reload={reload}
						timeOutMs={2000}
					/>
					<LinearProgress />
				</>
			)}
			<CardTitleTypography
				withPadding
				title={title}
				action={
					<SmallPrimaryTextButton
						onClick={onCheckAgain}
						startIcon={<UpdateIcon />}
						disabled={jobInfo?.isExecuting}>
						Check again
					</SmallPrimaryTextButton>
				}
			/>
			<CardContent style={{ paddingTop: 0 }}>
				{jobInfo === undefined && (
					<LabelledProperty
						label='Latest check'
						property='Loading...'
					/>
				)}
				{jobInfo !== undefined && jobInfo && (
					<LabelledProperty
						label='Latest check'
						property={formatDate(jobInfo.endDate, 'date-and-time')}
					/>
				)}
				{jobInfo !== undefined && !jobInfo && (
					<ErrorLabelledProperty
						label='Latest check'
						error='Never executed'
					/>
				)}
				{jobInfo !== undefined && jobInfo.isExecuted === true && (
					<>
						<LabelledProperty
							label='Duration'
							property={`${jobInfo.durationInSeconds!.toFixed(2)} seconds`}
						/>
						{jobInfo.isFaulted === true && <ErrorLabelledProperty error={`Error checking data`} />}
						{jobInfo.isFaulted === false && metadata && metadata.hasChanges === true && <WarningLabelledProperty warning={`Data not up-to-date`} />}
						{jobInfo.isFaulted === false && metadata && metadata.hasChanges === false && <SuccessLabelledProperty successMessage='Data up-to-date' />}
						<div className='df-row-ac'>
							<SmallPrimaryTextButton onClick={onViewDetails}>View Details</SmallPrimaryTextButton>
							{onUpdateData && (
								<>
									<Divider
										orientation='vertical'
										flexItem
										style={{ margin: '4px' }}
									/>
									<SmallPrimaryTextButton onClick={onUpdateData}>Update Data</SmallPrimaryTextButton>
								</>
							)}
							{actions && actions}
						</div>
					</>
				)}
			</CardContent>
		</Card>
	);
};
