import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface IProps {
    isExpanded: boolean;
    onClick: () => void;
}

export const ShowHideNavigationButton = ({ isExpanded, onClick }: IProps) => {
    return (
        <SmallPrimaryTextButton onClick={onClick} startIcon={isExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}>{isExpanded ? 'Hide navigation' : 'Show navigation'}</SmallPrimaryTextButton>
    );
}