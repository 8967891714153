import { FormikProps } from 'formik'; 
import yup from 'yup';
import { nameOf } from '../../utils/nameOf';

export const validateStepModel = <TModel extends object | null | undefined>(props: FormikProps<TModel>, schema: yup.ObjectSchema<TModel>, keys: (keyof TModel)[]): boolean => {
    keys.forEach(t => props.setFieldTouched(nameOf(t), true, true));
    try {
        schema.validateSync(props.values, { abortEarly: false });
        return true;
    } catch (error) {
        const paths = error.inner.map((t: any) => t.path);
        return keys.map(t => paths.indexOf(nameOf(t)) === -1).reduce((acc, next) => acc === false ? false : next);
    }
}