import { Dialog, DialogProps } from '@material-ui/core';
import { ClientsCacheContext } from 'app/main/clients/ClientsCacheContext';
import { Form, Formik, FormikHelpers } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectFieldFromStrings } from 'framework/forms/FormSelectFieldFromStrings';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { INotification, INotificationModel, notificationsCommand_new, notificationsCommand_update, NotificationTypes } from 'gen/ApiClient';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { ClientsTable } from './ClientsTable';
import { IClientNameWithSelection } from './IClientNameWithSelection';

const Schema = yup
	.object<INotificationModel>({
		description: yup.string().required(),
		readMoreLink: yup.string(),
		title: yup.string().required(),
		type: yup.string().required(),
		forClientNames: yup.mixed(),
		showAfterDate: yup.date(),
	})
	.defined();

const emptyValues: INotificationModel = {
	description: '',
	readMoreLink: '',
	title: '',
	type: '',
	forClientNames: [],
	showAfterDate: undefined,
};

interface IProps extends DialogProps {
	notification?: INotification;
	confirm: (id: string) => void;
	cancel: () => void;
}

const toModel = (n: INotification): INotificationModel => {
	return {
		description: n.description,
		readMoreLink: n.readMoreLink,
		title: n.title,
		type: n.type,
		forClientNames: n.forClientNames ?? [],
		showAfterDate: n.showAfterDate,
	};
};

const stepsRecord: Record<number, (keyof INotificationModel)[]> = {
	0: ['title', 'type', 'showAfterDate'],
	1: ['description', 'readMoreLink'],
	2: ['forClientNames'],
};

export const NotificationForm = ({ notification, confirm, cancel, ...rest }: IProps) => {
	const [create, isCreating] = useFormSubmit(notificationsCommand_new);
	const [update, isUpdating] = useFormSubmit(notificationsCommand_update);
	const isSubmitting = useMemo(() => isCreating || isUpdating, [isCreating, isUpdating]);
	const [step, setStep] = useState<number>(0);
	const [allClients] = useCacheContext(ClientsCacheContext);
	const [clients, setClients] = useState<IClientNameWithSelection[]>([]);

	useEffect(() => {
		if (allClients && notification) {
			setClients(allClients.map(t => ({ isSelected: notification.forClientNames.indexOf(t.name!) > -1, name: t.name! })));
		} else if (allClients) {
			setClients(allClients.map(t => ({ isSelected: false, name: t.name! })));
		}
	}, [allClients, notification]);

	const handleSubmit = async (values: INotificationModel, helpers: FormikHelpers<INotificationModel>) => {
		const forClientNames = clients.filter(t => t.isSelected).map(t => t.name);
		if (notification) {
			const r = await update(notification.id, { ...values, forClientNames: forClientNames });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				confirm(notification.id);
			}
		} else {
			const r = await create({ ...values, forClientNames: forClientNames });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				confirm(r.result.objectId);
			}
		}
	};

	return (
		<Formik<INotificationModel>
			validateOnMount
			initialValues={notification ? toModel(notification) : emptyValues}
			validationSchema={Schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={Schema}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
					notification={notification}
					clients={clients}
					setClients={setClients}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<INotificationModel>;
	cancel: () => void;
	stepsRecord: Record<number, (keyof INotificationModel)[]>;
	notification: INotification | undefined;
	clients: IClientNameWithSelection[];
	setClients: React.Dispatch<React.SetStateAction<IClientNameWithSelection[]>>;
}

const InnerDialog = ({ clients, setClients, step, setStep, isSubmitting, schema, cancel, stepsRecord, notification, ...rest }: IInnerProps) => {
	return (
		<Dialog
			{...rest}
			maxWidth='lg'>
			<DialogTitleWithFormStepper
				title={`New notification`}
				step={step}
				labels={[`Title`, `Content`, `For Clients`]}
			/>
			<OverflowDialogContent
				dividers
				style={{ padding: step === 2 ? '0px' : '8px 24px' }}>
				{step === 0 && (
					<>
						<FormTextField<INotificationModel>
							name='title'
							label={`Title`}
							required
						/>
						<FormSelectFieldFromStrings<INotificationModel>
							name='type'
							label='Type'
							options={NotificationTypes}
						/>
						<FormDatePicker<INotificationModel>
							name='showAfterDate'
							label='Show after date'
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormTextField<INotificationModel>
							name='description'
							label={`Description`}
							multiline
							rows={10}
							required
						/>
						<FormTextField<INotificationModel>
							name='readMoreLink'
							label={`Read more Link (leave empty if no link provided)`}
							required
						/>
					</>
				)}
				{step === 2 && (
					<ClientsTable
						clients={clients}
						setClients={setClients}
					/>
				)}
			</OverflowDialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={notification ? 'Update' : 'Create'}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
