import React from 'react';
import { StatusChip } from 'framework/components/StatusChip';
import { ITemplateDto } from 'gen/ApiClient';

interface IProps {
    item: ITemplateDto,
    style?: React.CSSProperties;
}

export const TemplateInvalidChipOrDefault = ({ item, style }: IProps) => {
    if (item.isValid) {
        return <></>
    } else {
        return (
            <StatusChip
                style={style}
                label={`Invalid`}
                color="error"
                fill />
        );
    }
}