import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const DownloadMultipleIcon = ({ viewBox = "0 0 24 24", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <path d="M9,1V7H5L12,14L19,7H15V1H9M5,16V18H19V16H5M5,20V22H19V20H5Z" />
        </SvgIcon>
    );
}
