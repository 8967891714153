import React from "react";
import { Try } from "framework/Try";
import { ExportFormat, IExportParams, IFileResponse } from "gen/ApiClient";
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ExportButtonAndMenu } from "./ExportButtonAndMenu";
import { ExportSelectPropsDialog } from "./ExportSelectPropsDialog";

interface IProps<TFilterParams, TExportProp extends string> {
    filteredCount: number;
    unfilteredCount: number;
    params: TFilterParams;
    downloadF: (pars: TFilterParams & IExportParams) => Promise<Try<IFileResponse>>;
    fileName: string;
    record: Record<TExportProp, string>;
    necessaryProps?: TExportProp[];
    allProps: TExportProp[];
    children?: any;
    anchor?: [null | HTMLElement, (event: React.MouseEvent<HTMLElement>) => void, () => void, undefined];
}

export const ExportButtonMenuAndDialog = <TFilterParams extends unknown, TExportProp extends string>({ filteredCount, unfilteredCount, params, downloadF, fileName, record, allProps, children, anchor, necessaryProps = [] }: IProps<TFilterParams, TExportProp>) => {
    const { open, cancel } = useDialogsContext();

    const onExportClick = (applyFilter: boolean, format: ExportFormat) => {
        open(<ExportSelectPropsDialog<TFilterParams, TExportProp>
            open
            fileName={fileName}
            close={cancel}
            params={params}
            downloadF={downloadF}
            record={record}
            allProps={allProps}
            necessaryProps={necessaryProps}
            title={fileName}
            format={format}
            mustApplyFilter={applyFilter} />);
    }

    return (
        <ExportButtonAndMenu
            filteredCount={filteredCount}
            unfilteredCount={unfilteredCount}
            onExportClick={onExportClick}
            anchor={anchor}>
            {children}
        </ExportButtonAndMenu>
    );
}
