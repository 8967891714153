import { Card, CardContent } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { IClientDetailDto } from 'gen/ApiClient';
import React from 'react';
import { ErrorOrSuccessIcon } from 'shared/ErrorOrSuccessIcon';

interface IProps extends CardProps {
	client: IClientDetailDto;
}

export const ClientBuildCard = ({ client, ...rest }: IProps) => {
	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography title='Build' />
				<LabelledProperty
					icon={<ErrorOrSuccessIcon val={client.isRunningLatestBuild} />}
					label='Running build'
					property={client.buildNumber}
				/>
			</CardContent>
		</Card>
	);
};
