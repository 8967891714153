import React from 'react';
import { BillingAccountsCacheProvider } from './BillingAccountsCacheProvider';
import { BillingAccountsListQueryProvider } from './list/BillingAccountsListQueryProvider';

export const BillingAccountsProviders = ({ children }: any) => {
	return (
		<BillingAccountsListQueryProvider>
			<BillingAccountsCacheProvider>{children}</BillingAccountsCacheProvider>
		</BillingAccountsListQueryProvider>
	);
};
