import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { IClientUsageStats } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IClientUsageStats;
	style?: React.CSSProperties;
}

export const ClientDetailUsageStatsCard = ({ item, style }: IProps) => {
	return (
		<Card style={style}>
			<CardContent>
				<CardTitleTypography title='Usage info' />
				<LabelledProperty
					label='# users'
					property={item.registeredUsers.toString()}
				/>
				<LabelledProperty
					label='# HA Y0'
					property={item.soldHearingAidsThisYear.toString()}
				/>
				<LabelledProperty
					label='# HA Y-1'
					property={item.soldHearingAidsYearMinus1.toString()}
				/>
				<LabelledProperty
					label='# HA Y-2'
					property={item.soldHearingAidsYearMinus2.toString()}
				/>
			</CardContent>
		</Card>
	);
};
