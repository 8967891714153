import React from 'react';
import { ClientsCacheProvider } from './ClientsCacheProvider';
import { ClientsQueryProvider } from './list/ClientsQueryProvider';

export const ClientsProviders = ({ children }: any) => {
	return (
		<ClientsQueryProvider>
			<ClientsCacheProvider>{children}</ClientsCacheProvider>
		</ClientsQueryProvider>
	);
};
