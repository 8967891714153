import { DialogActions } from '@material-ui/core';
import React from 'react';
import { IPageableFormActionsProps, PageableFormActions } from './PageableFormActions';

export const PageableFormDialogActions = <TModel extends object | null | undefined>(props: IPageableFormActionsProps<TModel>) => {
    return (
        <DialogActions>
            <PageableFormActions {...props} />
        </DialogActions>
    );
}