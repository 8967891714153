import { DialogContent, DialogProps } from '@material-ui/core';
import { BillingAccountsCacheContext } from 'app/main/billingAccounts/BillingAccountsCacheContext';
import { addDays } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { getNow } from 'framework/utils/getNow';
import { IBillingAccount, ICreateBudgetedSaleRequest, ISubscription, salesCommand_createBudget } from 'gen/ApiClient';
import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import { CreateSaleRequestFormLinesComponent } from './CreateSaleRequestFormLinesComponent';
import { ISalesLineRequestX } from './ISalesLineRequestX';
import { mapSalesLineRequest } from './mapSalesLineRequest';
import { useConvertSubscriptionToLine } from './useConvertSubscriptionToLine';

const DefaultValues: ICreateBudgetedSaleRequest = {
	billingAccountId: '',
	date: getNow(),
	lines: [],
	payBefore: addDays(getNow(), 14),
	subscriptionId: undefined,
};

const Schema = yup
	.object<ICreateBudgetedSaleRequest>({
		billingAccountId: yup.string().required(),
		date: yup.date().required(),
		lines: yup.mixed(),
		payBefore: yup.date().required(),
		subscriptionId: yup.string(),
	})
	.defined();

const stepsRecord: Record<number, (keyof ICreateBudgetedSaleRequest)[]> = {
	0: ['date', 'payBefore', 'billingAccountId'],
	1: ['lines'],
};

interface IProps extends DialogProps {
	billingAccountId: string | undefined;
	subscription: ISubscription | undefined;
	confirm: (id: string) => void;
	cancel: () => void;
}

export const CreateBudgetedSaleRequestForm = ({ billingAccountId, subscription, confirm, cancel, ...rest }: IProps) => {
	const [create, isSubmitting] = useFormSubmit(salesCommand_createBudget);
	const [step, setStep] = useState<number>(0);
	const notify = useSnackbarNotify();
	const [billingAccounts] = useCacheContext(BillingAccountsCacheContext);
	const fBillingAccounts = useMemo(() => (billingAccounts ?? []).filter(t => t.areAllFieldsFilledIn), [billingAccounts]);
	const [lines, setLines] = useState<ISalesLineRequestX[]>([]);
	useConvertSubscriptionToLine(subscription, setLines);

	const handleSubmit = async (values: ICreateBudgetedSaleRequest, helpers: FormikHelpers<ICreateBudgetedSaleRequest>) => {
		if (lines.length === 0) {
			notify('Select at least one line', 'warning');
			setStep(1);
		} else {
			const r = await create({ ...values, lines: lines.map((t, index) => mapSalesLineRequest(t, index)) });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				confirm(r.result.objectId);
				notify(`Budgetted sale successfully created`, 'success');
			}
		}
	};

	if (billingAccounts === undefined) {
		return <></>;
	}

	return (
		<Formik<ICreateBudgetedSaleRequest>
			validateOnMount
			initialValues={{
				...DefaultValues,
				billingAccountId: billingAccountId ?? '',
				subscriptionId: subscription?.id,
			}}
			validationSchema={Schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={Schema}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
					billingAccounts={fBillingAccounts}
					lines={lines}
					setLines={setLines}
					billingAccountId={billingAccountId}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<ICreateBudgetedSaleRequest>;
	cancel: () => void;
	stepsRecord: Record<number, (keyof ICreateBudgetedSaleRequest)[]>;
	billingAccounts: IBillingAccount[];
	lines: ISalesLineRequestX[];
	setLines: React.Dispatch<React.SetStateAction<ISalesLineRequestX[]>>;
	billingAccountId: string | undefined;
}

const InnerDialog = ({
	step,
	setStep,
	isSubmitting,
	schema,
	cancel,
	stepsRecord,
	billingAccounts,
	lines,
	setLines,
	billingAccountId,
	...rest
}: IInnerProps) => {
	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={1}
			title={`New Sale`}
			step={step}
			labels={[`General`, `Lines`]}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col'>
				{step === 0 && (
					<>
						<FormDatePicker<ICreateBudgetedSaleRequest>
							name='date'
							label='Date'
						/>
						<FormDatePickerWithUnit<ICreateBudgetedSaleRequest>
							name='payBefore'
							label={`Pay before`}
							required
							units={['Days', 'Weeks', 'Months']}
							defaultUnit='Weeks'
							defaultValue={{ unit: 'Weeks', value: 2 }}
						/>
						<FormAutocompleteField<ICreateBudgetedSaleRequest, IBillingAccount>
							name='billingAccountId'
							label={`Billing account`}
							options={billingAccounts}
							disabled={billingAccountId !== undefined}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<CreateSaleRequestFormLinesComponent
							lines={lines}
							setLines={setLines}
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={`Create`}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
