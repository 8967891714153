import { IconButton, Typography, useTheme } from '@material-ui/core';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { isFirst } from 'framework/utils/array/isFirst';
import { isLast } from 'framework/utils/array/isLast';
import { formatDate } from 'framework/utils/date/formatDate';
import { DocumentExportFormat, IDocumentHistory } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDocumentEditor } from 'shared/dialogs/useDocumentEditor';
import { usePdfViewerWithDocument } from 'shared/dialogs/usePdfViewerWithDocument';

interface IProps {
	id: string;
	history: IDocumentHistory[];
	style?: React.CSSProperties;
	format?: DocumentExportFormat;
}

export const DocumentDetailDialogHistoryTimeline = ({ id, history, style, format = 'Native' }: IProps) => {
	const theme = useTheme();
	const strings = useLocalization();
	const [openEditor] = useDocumentEditor();
	const openPdf = usePdfViewerWithDocument();

	const onView = async (historyId: string) => {
		openEditor(id, historyId);
	};

	return (
		<Timeline style={{ padding: 0, margin: 0, ...style }}>
			{history.map(t => (
				<TimelineItem key={t.id}>
					<TimelineOppositeContent>
						<Typography
							color='textSecondary'
							variant='caption'>
							{formatDate(t.date, 'date-and-time')}
						</Typography>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<TimelineDot
							variant='outlined'
							color='primary'
						/>
						{isLast(t, history) === false && <TimelineConnector />}
					</TimelineSeparator>
					<TimelineContent>
						<div
							className='df-row-ac'
							style={{ marginTop: isFirst(t, history) ? 0 : '-5px' }}>
							<div>{formatDate(t.date)}</div>
							{isFirst(t, history) === false && (
								<div
									className='df-row-ac'
									style={{ marginLeft: theme.spacing(1) }}>
									{t.isDocx && format === 'Native' && (
										<IconButton
											size='small'
											onClick={() => onView(t.id)}>
											<FileSearchOutlineIcon fontSize='small' />
										</IconButton>
									)}
									{(t.isPdf || format === 'Pdf') && (
										<IconButton
											size='small'
											onClick={() => openPdf(strings.history, id, t.id)}>
											<FileSearchOutlineIcon fontSize='small' />
										</IconButton>
									)}
								</div>
							)}
						</div>
					</TimelineContent>
				</TimelineItem>
			))}
		</Timeline>
	);
};
