import React from 'react';
import { Searchbar } from 'framework/components/Searchbar';
import { IRizivHearingAidDefinitionsV2QueryParams, RizivHearingAidDefinitionV2SearchMode, RizivHearingAidDefinitionV2SearchModes } from 'gen/ApiClient';
import { SelectSearchMode } from 'shared/SelectSearchMode';

interface IProps {
	params: IRizivHearingAidDefinitionsV2QueryParams;
	setParams: React.Dispatch<React.SetStateAction<IRizivHearingAidDefinitionsV2QueryParams>>;
}

export const XSearchBar = ({ params, setParams }: IProps) => {
	return (
		<Searchbar
			value={params.searchString}
			onSearch={text => setParams({ ...params, searchString: text, pageParams: { ...params.pageParams, index: 1 } })}
			placeholder={params.searchMode ?? ''}
			style={{ width: '520px' }}
			variant='paper'>
			<SelectSearchMode<RizivHearingAidDefinitionV2SearchMode>
				mode={params.searchMode as any}
				setMode={mode => setParams({ ...params, searchMode: mode, pageParams: { ...params.pageParams, index: 1 } })}
				modes={RizivHearingAidDefinitionV2SearchModes}
			/>
		</Searchbar>
	);
};
