import { IconButton, Tooltip, Button } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { theme } from '../../theme';
import { FilterOffIcon } from '../components/icons/FilterOffIcon';
import { FilterIcon } from '../components/icons/FilterIcon';

interface IProps {
	onClear: () => void;
	isFiltered: boolean;
	paddingLeft?: number;
	label?: string;
	hideLabel?: boolean;
	children: any;
	onMore?: () => void;
	disableWrap?: boolean;
}

export const FilterBar = ({ children, onClear, isFiltered, paddingLeft = 16, label, hideLabel = false, onMore, disableWrap = false }: IProps) => {
	const strings = useLocalization();

	return (
		<div
			className='df-row-ac'
			style={{ paddingLeft: paddingLeft, paddingRight: paddingLeft }}>
			{hideLabel === false && (
				<div style={{ fontWeight: 500, textTransform: 'uppercase', fontSize: '13px', marginRight: isFiltered ? 0 : 16 }}>{label ? label : strings.filter}</div>
			)}
			{isFiltered && (
				<Tooltip title={strings.clearFilter}>
					<IconButton
						color='secondary'
						onClick={() => onClear()}>
						<FilterOffIcon />
					</IconButton>
				</Tooltip>
			)}
			<div
				className={`df-row fg1${disableWrap ? '' : ' fw'}`}
				style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
				{children}
			</div>
			{onMore && (
				<Button
					startIcon={<FilterIcon />}
					size='small'
					color='primary'
					onClick={onMore}>
					Meer
				</Button>
			)}
		</div>
	);
};
