import { AppBar, Dialog, DialogProps, IconButton, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { RichEdit } from 'devexpress-richedit';
import { LoaderButton } from 'framework/components/buttons/LoaderButton';
import { SlideUpTransition } from 'framework/components/transitions/SlideUpTransition';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { ICommonValidateResponse } from 'framework/dialogs/ICommonValidateResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { Try } from 'framework/Try';
import { documentsCommand_save, IDocumentContentDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { Rte } from '../rte/Rte';
import { exportToBase64 } from './exportToBase64';

interface IProps extends DialogProps {
	document: IDocumentContentDto | undefined;
	close: VoidFunction;
	isReadOnly?: boolean;
	hasChanges: boolean;
	setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DocumentEditorDialog = ({ document, close, hasChanges, setHasChanges, isReadOnly = false, ...rest }: IProps) => {
	const strings = useLocalization();
	const [richEdit, setRichEdit] = useState<RichEdit>();
	const { open, confirm } = useDialogsContext(close);
	const [save, isSubmitting] = useFormSubmit(documentsCommand_save);
	const notify = useSnackbarNotify();

	const onClose = () => {
		if (hasChanges === false) {
			close();
		} else if (document) {
			onAlert(document);
		}
	};

	const onSave = async (document: IDocumentContentDto): Promise<Try<ICommonValidateResponse>> => {
		const content = await exportToBase64(richEdit ?? (undefined as any));
		const r = await save({ contentAsBase64: content, documentId: document.id });
		if (r.isSuccess) {
			notify(`Document saved`);
			setHasChanges(false);
		}
		// TODO -> fix as any
		return r as any;
	};

	const onAlert = (document: IDocumentContentDto) => {
		open(
			<AlertDialog
				open
				title={strings.unsavedChanges}
				content={strings.saveChangesToDocumentQuestion}
				acceptText={strings.yesCommaSave}
				rejectText={`No, close without saving`}
				reject={confirm}
				acceptF={() => onSave(document)}
				accept={confirm}
			/>
		);
	};

	return (
		<Dialog
			fullScreen
			TransitionComponent={SlideUpTransition}
			{...rest}>
			{rest.open && document && (
				<>
					<AppBar style={{ position: 'relative' }}>
						<Toolbar className='df-row-ac jc-sb'>
							<Typography>{strings.viewEditDocument}</Typography>
							<div className='df-row-ac'>
								<LoaderButton
									color='inherit'
									startIcon={<SaveIcon />}
									isLoading={isSubmitting}
									onClick={() => onSave(document)}
									disabled={hasChanges === false}>
									{strings.save}
								</LoaderButton>
								<IconButton
									edge='end'
									color='inherit'
									onClick={onClose}
									aria-label='close'>
									<CloseIcon />
								</IconButton>
							</div>
						</Toolbar>
					</AppBar>
					<div className='df-row fg1'>
						<Rte
							data={document.content}
							richEdit={richEdit}
							setRichEdit={setRichEdit}
							setHasChanges={setHasChanges}
							isReadOnly={isReadOnly}
						/>
					</div>
				</>
			)}
		</Dialog>
	);
};
