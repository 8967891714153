import { CacheProvider as FrameworkCacheProvider } from 'framework/context/CacheProvider';
import { clientsQuery_all, clientsQuery_concurrencyToken, IClientDto } from 'gen/ApiClient';
import React from 'react';
import { ClientsCacheContext } from './ClientsCacheContext';

export const ClientsCacheProvider = ({ children }: any) => {
	return (
		<FrameworkCacheProvider<IClientDto[]>
			concurrencyF={clientsQuery_concurrencyToken}
			loadF={clientsQuery_all}
			context={ClientsCacheContext}>
			{children}
		</FrameworkCacheProvider>
	);
};
