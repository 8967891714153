import { Grid, useTheme } from '@material-ui/core';
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { ReactElement, useMemo } from 'react';
import { useStateBoolean } from '../../hooks/useStateBool';

const thisLabelGridStyle: React.CSSProperties = {
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
}

interface IProps {
    labelGridSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    label?: string;
    value: string | number | undefined | JSX.Element;
    edit?: () => void;
    isEmpty?: boolean;
    labelStyle?: React.CSSProperties;
    valueStyle?: React.CSSProperties;
    navigate?: () => void;
    removePadding?: boolean;
    // icon?: React.ReactNode;
    customAction?: () => void;
    customActionIcon?: ReactElement<any, any>;
    labelGridStyle?: React.CSSProperties;
}

const iconFontSize: number = 20;

export const PropertyInGrid = ({ labelGridSize = 4, label, value, edit, isEmpty = false, labelStyle, labelGridStyle, valueStyle, removePadding = false, navigate, customAction, customActionIcon }: IProps) => {
    const [isHover, enter, leave] = useStateBoolean(false);
    const mergedIsEmpty = useMemo(() => value === undefined || value === '' || value === null || isEmpty, [value, isEmpty]);
    const theme = useTheme();

    return (
        <>
            <Grid item xs={labelGridSize} style={{ ...thisLabelGridStyle, paddingTop: removePadding ? 0 : '8px', paddingBottom: removePadding ? 0 : '8px', ...labelGridStyle }}>
                <div style={{ padding: '0px', color: theme.palette.text.secondary, textAlign: 'right', fontSize: '14px', ...labelStyle }}>{label}</div>
                {/* <div style={{ color: theme.palette.text.secondary, ...labelStyle }}>{label}</div> */}
            </Grid>
            <Grid item xs={(12 - labelGridSize) as any} onMouseEnter={enter} onMouseLeave={leave} className="df-row-ac" style={{ paddingTop: removePadding ? 0 : '8px', paddingBottom: removePadding ? 0 : '8px' }}>
                {mergedIsEmpty === false &&
                    <div className="df-row-ac">
                        <div className="df-col" style={{ marginRight: theme.spacing(1), cursor: navigate ? 'pointer' : 'inherit', ...valueStyle }} onClick={navigate && navigate}>{value}</div>
                    </div>
                }
                {customAction && customActionIcon && <div className="df-col-ac icon-wrapper" style={{ marginLeft: theme.spacing(1), fontSize: iconFontSize, color: theme.palette.primary.main, cursor: 'pointer', visibility: isHover ? 'inherit' : 'hidden' }} onClick={customAction}>{customActionIcon}</div>}
                {navigate && <OpenInNewIcon fontSize="small" style={{ cursor: 'pointer', visibility: isHover ? 'inherit' : 'hidden', color: theme.palette.primary.main, marginRight: theme.spacing(1) }} onClick={navigate} />}
                {edit && <EditIcon onClick={edit} fontSize="small" style={{ color: theme.palette.text.secondary, cursor: 'pointer', visibility: (isHover || mergedIsEmpty) ? 'inherit' : 'hidden' }} />}
            </Grid>
        </>
    );
}