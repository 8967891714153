import TollIcon from '@material-ui/icons/Toll';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISalesPaymentData } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatchPaymentRequestForm } from './PatchPaymentRequestForm';
import { ColorX } from 'framework/components/color/ColorX';

// const MajorFontSize = 14;
// const MinorFontSize = 13;

interface IProps {
	salesId: string;
	item: ISalesPaymentData;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const SalePaymentListItem = ({ item, salesId, withoutBorder, reload }: IProps) => {
	const color: ColorX = 'success';
	const tColor = useThemeColor(color);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatch = () => {
		open(
			<PatchPaymentRequestForm
				open
				salesId={salesId}
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<div
			className='df-row-ac w100 gap-8'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<TollIcon style={{ fontSize: 20, color: tColor }} />
			<LabelledPropertyX
				edit={onPatch}
				label={formatDate(item.paymentDate)}>
				{formatCurrency(item.amountPaid)}
			</LabelledPropertyX>
			{/* <span style={{ fontSize: MajorFontSize }}>{`${formatDate(item.paymentDate)} | ${formatCurrency(item.amountPaid)}`}</span> */}
		</div>
	);
};
