import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchPaymentRequest, ISalesPaymentData, salesCommand_patchPayment } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IPatchPaymentRequest>({
			amount: yup.number().required(),
			salesId: yup.string().required(),
			date: yup.date().required(),
			paymentId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	salesId: string;
	item: ISalesPaymentData;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchPaymentRequestForm = ({ item, salesId, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(salesCommand_patchPayment);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IPatchPaymentRequest, helpers: FormikHelpers<IPatchPaymentRequest>) => {
		const r = await patch({ ...values, salesId: salesId, paymentId: item.paymentId });
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`Payment patched`, 'success');
		}
	};

	return (
		<Formik<IPatchPaymentRequest>
			validateOnMount
			initialValues={{
				salesId: salesId,
				paymentId: item.paymentId,
				amount: item.amountPaid,
				date: item.paymentDate,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Patch payment`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormMoneyField<IPatchPaymentRequest>
							name='amount'
							label={`Amount`}
							allowNegative
						/>
						<FormDatePicker<IPatchPaymentRequest>
							name='date'
							label='Payment date'
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IPatchPaymentRequest>
						cancel={cancel}
						submitText='Patch'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
