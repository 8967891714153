import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import React from 'react';

type NavigationItem = 'clients' | 'subscriptions' | 'billing-accounts' | 'dashboard' | 'licenses' | 'sales';

export const NavigateIconRecord: Record<NavigationItem, React.ReactNode> = {
	clients: <ListIcon />,
	subscriptions: <ReceiptOutlinedIcon />,
	'billing-accounts': <BusinessIcon />,
	dashboard: <DashboardIcon />,
	licenses: <VerifiedUserOutlinedIcon />,
	sales: <MonetizationOnOutlinedIcon />,
};
