import { TemplateSelectableColumn } from '../list/TemplateSelectableColumn';

export const ColumnRecord: Record<TemplateSelectableColumn, string> = {
	created: 'Date created',
	id: 'ID',
	isStandardDocument: 'Standard doc?',
	language: 'Language',
	lastUpdated: 'Last updated',
	mainContext: 'Main context',
	name: 'Name',
	predefinedType: '(Pred.) Type',
	validityEnd: 'Validity end',
	validityStart: 'Validity start',
	standardDocumentId: 'Standard doc ID',
};
