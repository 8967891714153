import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { IDocument } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { DocumentDetailDialogHistoryTimeline } from './DocumentDetailDialogHistoryTimeline';

interface IProps {
	item: IDocument;
}

export const DocumentDetailDialogHistory = ({ item }: IProps) => {
	const showHistory = useMemo(() => item.history.length > 1, [item]);
	const strings = useLocalization();

	return (
		<>
			{showHistory && (
				<>
					<GridDividerWithCaption caption={strings.history} />
					<DocumentDetailDialogHistoryTimeline
						id={item.id}
						style={{ marginLeft: '-144px' }}
						history={item.historyOrderedDesc}
					/>
				</>
			)}
		</>
	);
};
