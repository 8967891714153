import { StringFormatting } from "gen/ApiClient"
import { IStrings } from "localization/IStrings"

export const createStringFormattingRecord = (strings: IStrings): Record<StringFormatting, string> => {
    return {
        AL: strings.everythingLower,
        AU: strings.everythingCapital,
        LC: strings.lowerCase,
        UC: strings.upperCase,
    }
}