import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { HeaderTableCellX } from 'framework/table/HeaderTableCellX';
import { NoContentTableHeadX } from 'framework/table/NoContentTableHeadX';
import { SortableHeaderTableCellX } from 'framework/table/SortableHeaderTableCellX';
import { IBillingAccount, IQueryResult, ISortParams } from 'gen/ApiClient';
import React from 'react';
import { BillingAccountTableRow } from './BillingAccountTableRow';

interface IProps {
	queryResult: IQueryResult<IBillingAccount>;
	sortParams: ISortParams<IBillingAccount>;
	onFilter: (sp: ISortParams<IBillingAccount>) => void;
}

export const BillingAccountsTable = ({ queryResult, sortParams, onFilter }: IProps) => {
	return (
		<Table stickyHeader>
			{queryResult.unfilteredCount === 0 && <NoContentTableHeadX>{`No billing accounts registered yet`}</NoContentTableHeadX>}
			{queryResult.unfilteredCount > 0 && (
				<TableHead>
					<TableRow>
						<SortableHeaderTableCellX<IBillingAccount>
							forProperty='name'
							sortParams={sortParams}
							onFilter={onFilter}>
							{`Name`}
						</SortableHeaderTableCellX>
						<HeaderTableCellX>{`VAT number`}</HeaderTableCellX>
						<HeaderTableCellX>{`Address`}</HeaderTableCellX>
						<HeaderTableCellX isLast></HeaderTableCellX>
					</TableRow>
				</TableHead>
			)}
			<TableBody>
				{queryResult.values.map(t => (
					<BillingAccountTableRow
						key={t.id}
						item={t}
					/>
				))}
			</TableBody>
		</Table>
	);
};
