import React from "react";
import { QueryProvider as FrameworkQueryProvider } from "framework/context/QueryProvider";
import { INotification, INotificationsQueryParams, IQueryResult, notificationsQuery_concurrencyToken_app, notificationsQuery_query_app } from "gen/ApiClient";
import { notificationsQueryParamsLsKey } from "../../../localStorageKeys";
import { QueryContext } from "./QueryContext";

const DefaultParams: INotificationsQueryParams = {
    searchString: '',
    sortParams: { direction: 'desc', property: 'created' },
    pageParams: { index: 1, size: 10 },
    createdAfter: undefined,
    createdBefore: undefined,
    types: [],
    forClientNames: []
}

export const QueryProvider = ({ children }: { children: any }) => {
    return (
        <FrameworkQueryProvider<IQueryResult<INotification>, INotificationsQueryParams>
            defaultParams={DefaultParams}
            queryF={notificationsQuery_query_app}
            concurrencyF={notificationsQuery_concurrencyToken_app}
            localStorageKey={notificationsQueryParamsLsKey}
            context={QueryContext}>
            {children}
        </FrameworkQueryProvider>
    );
}
