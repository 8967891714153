import React from 'react';
import { useLocalStorage } from 'framework/hooks/useLocalStorage';
import { IUserInfo } from 'gen/ApiClient';
import { hamsUserInfoLsKey } from '../localStorageKeys';
import { AuthContext } from './AuthContext';

export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useLocalStorage<IUserInfo>(hamsUserInfoLsKey);

    return (
        <AuthContext.Provider
            value={{
                isAuth: user !== undefined,
                user: user,
                login: setUser,
                logout: () =>  setUser(undefined),
                onRedirect: () => setUser(undefined),
            }}>
            {children}
        </AuthContext.Provider>
    );
};
