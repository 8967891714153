import { Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';

export const TooltipWithSpan = ({ children, ...rest }: TooltipProps) => {
	return (
		<Tooltip {...rest}>
			<span>{children}</span>
		</Tooltip>
	);
};
