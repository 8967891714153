import { clearParams } from 'framework/utils/clearParams';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SubscriptionsRoute } from 'routes';
import { SubscriptionsListQueryContext } from '../subscriptions/list/SubscriptionsListQueryContext';

export const useNavigateToSubscriptionsOfLicense = () => {
	const { push } = useHistory();
	const { params, setParams } = useContext(SubscriptionsListQueryContext);

	return (licenseId: string) => {
		setParams({ ...clearParams(params), licenseIds: [licenseId], prefilter: 'Active' });
		push(SubscriptionsRoute);
	};
};
