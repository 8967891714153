import { DialogProps } from '@material-ui/core';
import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { ITemplateDto, templatesCommand_patchName } from 'gen/ApiClient';

interface IProps extends DialogProps {
	template: ITemplateDto;
	confirm: () => void;
	cancel: () => void;
}

export const TemplateNameForm = ({ template, ...rest }: IProps) => {
	return (
		<StringModelForm
			label='Name'
			formTitle='Change template name'
			submitFunction={val => templatesCommand_patchName({ templateId: template.id, name: val })}
			initialValue={template.name}
			submitText='Update'
			{...rest}
			fullWidth
			maxWidth='sm'
		/>
	);
};
