import { QueryProvider as FrameworkQueryProvider } from "framework/context/QueryProvider";
import { IMedicalTreatmentNomenclatureForAudiology, IMedicalTreatmentNomenclatureQueryMetadata, IMedicalTreatmentNomenclaturesQueryParams, IQueryResultWithMetadata, MedicalTreatmentNomenclatureSearchMode, medicalTreatmentNomenclaturesQuery_concurrencyToken_app, medicalTreatmentNomenclaturesQuery_query } from "gen/ApiClient";
import React from "react";
import { mtnQueryParamsLsKey } from "../../../../localStorageKeys";
import { QueryContext } from "./QueryContext";

const DefaultParams: IMedicalTreatmentNomenclaturesQueryParams = {
    searchString: '',
    sortParams: { direction: 'asc', property: 'code' },
    pageParams: { index: 1, size: 10 },
    hasCurrentFee: undefined,
    isCurrentlyValid: undefined,
    searchMode: 'AllFields' as MedicalTreatmentNomenclatureSearchMode,
    ageRestrictionMode: undefined,
    isAmbulant: undefined,
    isBoneAnchorage: undefined,
    isHearingLossMoreThan40dB: undefined,
    isLumpSum: undefined,
    isMain: undefined,
    isSpeechAudiometryPossible: undefined,
    isStereo: undefined,
    subArticles: [],
    isMono: undefined,
    hasVEnd: undefined,
    isPseudoCode: undefined,
}

export const QueryProvider = ({ children }: { children: any }) => {
    return (
        <FrameworkQueryProvider<IQueryResultWithMetadata<IMedicalTreatmentNomenclatureForAudiology, IMedicalTreatmentNomenclatureQueryMetadata>, IMedicalTreatmentNomenclaturesQueryParams>
            defaultParams={DefaultParams}
            queryF={medicalTreatmentNomenclaturesQuery_query}
            concurrencyF={medicalTreatmentNomenclaturesQuery_concurrencyToken_app}
            localStorageKey={mtnQueryParamsLsKey}
            context={QueryContext}>
            {children}
        </FrameworkQueryProvider>
    );
}
