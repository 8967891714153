
import { useCallback } from 'react';
import { IApiErrorStrings } from '../context/localization/IApiErrorString';

export function useApiErrorHandling(strings: IApiErrorStrings, redirect: () => void, onError: (error: string) => void): (error: any) => void  {
    const redirectCallback = useCallback(redirect, []);
    const onErrorCallback = useCallback(onError, []);
    
    return useCallback((error: any) => {
        if (error) {
            onErrorCallback(matchError(error, strings));
            if (needRedirect(error)) {
                redirectCallback();
            }
        }
    }, [onErrorCallback, redirectCallback, strings]);
}

const needRedirect = (error: any): boolean => {
    const status = error?.response?.status ?? undefined;
    return status && status === 401;
}

const matchError = (error: any, strings: IApiErrorStrings): string => {
    const status = error?.response?.status ?? undefined;
    if (error === undefined) {
        return '';
    } else if (status) {
        switch (status) {
            case 401:
                return strings.error401;
            case 403:
                return strings.error403;
            case 500:
                return strings.error500;
            default:
                return strings.errorUnmapped(status);
        }
    } else if (error.toString() === 'Error: Network Error') {
        return strings.errorNoNetwork;
    } else {
        return strings.errorUnknown;
    }
}