import { Dialog, DialogProps, Grid, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { formatDate } from 'framework/utils/date/formatDate';
import { notificationsCommand_delete, notificationsQuery_single_app } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { NotificationForm } from './forms/NotificationForm';
import { NotificationChips } from './NotificationChips';

interface IProps extends DialogProps {
    close: () => void;
    id: string;
}

export const NotificationDialog = ({ id, close, ...rest }: IProps) => {
    const theme = useTheme();
    const [item, reload] = useApiEffect(notificationsQuery_single_app, id);
    const { open, confirm, cancel, isStacked } = useDialogsContext(reload);
    const openInNewTab = useOpenInNewTab();

    if (item === undefined) {
        return <div></div>
    }

    const onEdit = () => {
        open(<NotificationForm
            open
            notification={item}
            cancel={cancel}
            confirm={confirm} />)
    }

    const onDelete = () => {
        open(<AlertDialog
            open
            title="Delete notification?"
            content="Notification will be deleted (this action cannot be undone)"
            acceptText="Delete"
            rejectText="Go back"
            reject={cancel}
            acceptF={() => notificationsCommand_delete(item.id)}
            accept={() => { confirm(); close(); }} />);
    }

    return (
        <Dialog
            {...rest}
            open={rest.open && isStacked === false}
            onClose={close}
            fullWidth
            scroll="paper">
            <DialogContentGrid close={close}>
                <DialogGridTitle icon={<DescriptionIcon />}>
                    <Typography variant="h1" style={{ marginBottom: theme.spacing(1) }}>{item.title}</Typography>
                    <LabelledProperty label="Created" property={formatDate(item.created, 'date-and-time')} withoutMargin />
                    <LabelledProperty label="Last updated" property={formatDate(item.lastUpdated, 'date-and-time')} withoutMargin />
                    <NotificationChips item={item} />
                </DialogGridTitle>
                <GridDividerWithCaption caption="Properties" />
                <PropertyInGrid label="Title" value={item.title} />
                <PropertyInGrid label="Read More Link" value={item.readMoreLink} navigate={() => openInNewTab(item.readMoreLink!, true)}/>
                <PropertyInGrid label="Show after" value={formatDate(item.showAfterDate)} />
                <PropertyInGrid label="Description" value=" " />
                <Grid item xs>
                    <div style={{ whiteSpace: 'pre-line' }}>{item.description}</div>
                </Grid>
                <GridDividerWithCaption caption="Show to clients" />
                <Grid item xs className="df-col">
                    {item.isForAllClients ? 'All' : item.forClientNames.map(t => <div>{t}</div>)}
                </Grid>
                <GridDividerWithCaption caption="Actions" />
                <PropertyInGrid value={
                    <div className="df-col-as">
                        <SmallPrimaryTextButton onClick={onEdit} startIcon={<EditOutlinedIcon />}>{`Edit`}</SmallPrimaryTextButton>
                        <SmallPrimaryTextButton onClick={onDelete} startIcon={<DeleteOutlineIcon />} color="secondary">{`Delete`}</SmallPrimaryTextButton>
                    </div>
                } />
            </DialogContentGrid>
        </Dialog>
    );
}