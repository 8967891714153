import { Dialog, DialogProps, Grid, Typography } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { ExpandCollapseManagedComponent } from 'framework/components/ExpandCollapseManagedComponent';
import { formatDate } from 'framework/utils/date/formatDate';
import { IJobInfoDto, IMedicalTreatmentNomenclatureTaskMetadata } from 'gen/ApiClient';
import React from 'react';
import { CodeBlock } from 'shared/CodeBlock';

interface IProps extends DialogProps {
	item: IJobInfoDto;
	metadata: IMedicalTreatmentNomenclatureTaskMetadata;
	close: VoidFunction;
}

export const MedicalTreatmentNomenclatureCheckDialog = ({ item, metadata, close, ...rest }: IProps) => {
	return (
		<Dialog
			{...rest}
			onClose={close}
			fullWidth>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<LocalShippingIcon />}>
					<Typography variant='h1'>{`Medical Treatment Nomenclatures Check`}</Typography>
				</DialogGridTitle>
				<GridDividerWithCaption caption={`Task Execution Details`} />
				<PropertyInGrid
					label='Date'
					value={formatDate(item.endDate, 'date-and-time')}
				/>
				<PropertyInGrid
					label='Duration'
					value={`${item.durationInSeconds!.toFixed(2)} seconds`}
				/>
				<GridDividerWithCaption caption={`Difference Details`} />
				<PropertyInGrid
					label='Source Count (stored)'
					value={metadata.diffDetails.countSource.toString()}
				/>
				<PropertyInGrid
					label='Target Count (newer)'
					value={metadata.diffDetails.countTarget.toString()}
				/>
				<PropertyInGrid
					label={`Added`}
					value={metadata.diffDetails.added.length.toString()}
				/>
				<PropertyInGrid
					label={`Updated`}
					value={metadata.diffDetails.updated.length.toString()}
				/>
				<PropertyInGrid
					label={`Deleted`}
					value={metadata.diffDetails.deleted.length.toString()}
				/>
				<GridDividerWithCaption caption={`Difference Fees`} />
				<PropertyInGrid
					label='Source Count (stored)'
					value={metadata.diffFees.countSource.toString()}
				/>
				<PropertyInGrid
					label='Target Count (newer)'
					value={metadata.diffFees.countTarget.toString()}
				/>
				<PropertyInGrid
					label={`Added`}
					value={metadata.diffFees.added.length.toString()}
				/>
				<PropertyInGrid
					label={`Updated`}
					value={metadata.diffFees.updated.length.toString()}
				/>
				<PropertyInGrid
					label={`Deleted`}
					value={metadata.diffFees.deleted.length.toString()}
				/>
				<Grid
					item
					xs>
					<ExpandCollapseManagedComponent>
						<CodeBlock value={metadata} />
					</ExpandCollapseManagedComponent>
				</Grid>
			</DialogContentGrid>
		</Dialog>
	);
};
