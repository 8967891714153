import { DialogContent, DialogProps } from '@material-ui/core';
import { BillingAccountsCacheContext } from 'app/main/billingAccounts/BillingAccountsCacheContext';
import { addDays } from 'date-fns';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { FormSingleCheckboxField } from 'framework/forms/FormSingleCheckboxField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { getNow } from 'framework/utils/getNow';
import {
	IBillingAccount,
	IConvertBudgetedSaleToSaleRequest,
	ISale,
	ISalesSettings,
	salesCommand_convertTenderOrBudgetToInvoice,
	salesQuery_nextReference,
	salesQuery_settings,
} from 'gen/ApiClient';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { CreateSaleRequestFormConfirmationComponent } from './CreateSaleRequestFormConfirmationComponent';
import { CreateSaleRequestFormLinesComponent } from './CreateSaleRequestFormLinesComponent';
import { ISalesLineRequestX } from './ISalesLineRequestX';
import { mapSalesLineRequest } from './mapSalesLineRequest';
import { mapSalesLineToSalesLineRequestX } from './mapSalesLineToSalesLineRequestX';

const Schema = yup
	.object<IConvertBudgetedSaleToSaleRequest>({
		date: yup.date().required(),
		isAutoSelectReference: yup.bool(),
		lines: yup.mixed(),
		payBefore: yup.date().required(),
		reference: yup.string().required(),
		budgetedSalesId: yup.string().required(),
		billingAccountId: yup.string().required(),
	})
	.defined();

const stepsRecord: Record<number, (keyof IConvertBudgetedSaleToSaleRequest)[]> = {
	0: ['reference', 'isAutoSelectReference', 'date', 'payBefore', 'billingAccountId'],
	1: ['lines'],
	2: [],
};

interface IProps extends DialogProps {
	item: ISale;
	confirm: () => void;
	cancel: () => void;
}

export const ConvertBudgetedSaleToSaleRequestForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [create, isSubmitting] = useFormSubmit(salesCommand_convertTenderOrBudgetToInvoice);
	const [step, setStep] = useState<number>(0);
	const notify = useSnackbarNotify();
	const [settings] = useApiEffect(salesQuery_settings);
	const [nextReference] = useApiEffect(salesQuery_nextReference);
	const [lines, setLines] = useState<ISalesLineRequestX[]>(item.lines.map(mapSalesLineToSalesLineRequestX));
	const [billingAccounts] = useCacheContext(BillingAccountsCacheContext);
	const fBillingAccounts = useMemo(() => (billingAccounts ?? []).filter(t => t.areAllFieldsFilledIn), [billingAccounts]);
	const now = useMemo(() => getNow(), []);

	const handleSubmit = async (values: IConvertBudgetedSaleToSaleRequest, helpers: FormikHelpers<IConvertBudgetedSaleToSaleRequest>) => {
		if (lines.length === 0) {
			notify('Select at least one line', 'warning');
			setStep(1);
		} else {
			const r = await create({ ...values, lines: lines.map((t, index) => mapSalesLineRequest(t, index)), budgetedSalesId: item.id });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				confirm();
				notify(`Sale '${values.reference}' successfully created`, 'success');
			}
		}
	};

	if (settings === undefined || nextReference === undefined) {
		return <></>;
	}

	return (
		<Formik<IConvertBudgetedSaleToSaleRequest>
			validateOnMount
			initialValues={{
				date: now,
				isAutoSelectReference: true,
				lines: [],
				payBefore: addDays(now, 14),
				reference: '',
				billingAccountId: item.billingAccountId!,
				budgetedSalesId: item.id,
			}}
			validationSchema={Schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={Schema}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
					nextReference={nextReference}
					settings={settings}
					lines={lines}
					setLines={setLines}
					sale={item}
					billingAccountId={item.billingAccountId}
					billingAccounts={fBillingAccounts}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<IConvertBudgetedSaleToSaleRequest>;
	cancel: () => void;
	stepsRecord: Record<number, (keyof IConvertBudgetedSaleToSaleRequest)[]>;
	settings: ISalesSettings;
	nextReference: string;
	lines: ISalesLineRequestX[];
	setLines: React.Dispatch<React.SetStateAction<ISalesLineRequestX[]>>;
	sale: ISale;
	billingAccounts: IBillingAccount[];
	billingAccountId: string | undefined;
}

const InnerDialog = ({
	step,
	setStep,
	isSubmitting,
	schema,
	cancel,
	stepsRecord,
	settings,
	nextReference,
	lines,
	setLines,
	sale,
	billingAccountId,
	billingAccounts,
	...rest
}: IInnerProps) => {
	const props = useFormikContext<IConvertBudgetedSaleToSaleRequest>();

	useEffect(() => {
		if (nextReference) {
			setFormValue<IConvertBudgetedSaleToSaleRequest>('reference', nextReference, props);
		}
		// eslint-disable-next-line
	}, [nextReference]);

	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={1}
			title={`New Sale ${props.values.reference}`}
			step={step}
			labels={[`General`, `Lines`, `Confirmation`]}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col'>
				{step === 0 && (
					<>
						<FormSingleCheckboxField<IConvertBudgetedSaleToSaleRequest>
							name='isAutoSelectReference'
							label={`Auto select reference?`}
						/>
						<FormTextField<IConvertBudgetedSaleToSaleRequest>
							name='reference'
							label={`Reference (invoice number)`}
							disabled={props.values.isAutoSelectReference}
						/>
						<FormDatePicker<IConvertBudgetedSaleToSaleRequest>
							name='date'
							label='Date'
						/>
						<FormDatePickerWithUnit<IConvertBudgetedSaleToSaleRequest>
							name='payBefore'
							label={`Pay before`}
							required
							units={['Days', 'Weeks', 'Months']}
							defaultUnit='Weeks'
							defaultValue={settings.defaultValidUntil}
						/>
						<FormAutocompleteField<IConvertBudgetedSaleToSaleRequest, IBillingAccount>
							name='billingAccountId'
							label={`Billing account`}
							options={billingAccounts}
							disabled={billingAccountId !== undefined && billingAccountId !== null}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<CreateSaleRequestFormLinesComponent
							lines={lines}
							setLines={setLines}
						/>
					</>
				)}
				{step === 2 && (
					<CreateSaleRequestFormConfirmationComponent
						lines={lines}
						reference={props.values.reference}
						recipientName={sale.vatRecipientName}
						isShiftVat={false}
					/>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={`Convert`}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
