import { Card, IconButton, Paper, Tooltip, useScrollTrigger, useTheme } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ReplayIcon from '@material-ui/icons/Replay';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useEffect, useRef, useState } from 'react';
import { useHeightResizeObserver } from 'framework/hooks/useHeightResizeObserver';
import { useLocalization } from 'localization/useLocalization';
import { IBreadcrumb } from 'shared/breadcrumbs/IBreadcrumb';
import { Breadcrumbs } from 'shared/breadcrumbs/Breadcrumbs';
import { FunctionVariantIcon } from '../icons/FunctionVariantIcon';

const useStickyStyle = (): React.CSSProperties => {
	const theme = useTheme();
	return {
		position: 'sticky',
		top: 0,
		left: 0,
		backgroundColor: theme.palette.background.default,
		zIndex: 3,
	};
};

interface IPageProps {
	details?: React.ReactNode;
	nav?: React.ReactNode;
	children: any;
	spacing?: number;
	title: string | undefined;
	secondaryTitle?: React.ReactNode;
	reload?: () => void;
	settings?: () => void;
	info?: () => void;
	onScript?: () => void;
	breadcrumbs?: IBreadcrumb[];
	showHome?: boolean;
	style?: React.CSSProperties;
	detailsFixedWidth?: number;
	fullHeight?: boolean;
	header?: JSX.Element;
	breadCrumbsContainerStyle?: React.CSSProperties;
	styleRightContent?: React.CSSProperties;
	styleDetailsContent?: React.CSSProperties;
	withSubNavigation?: boolean;
}

export const Page = ({
	children,
	spacing = 2,
	title,
	secondaryTitle,
	reload,
	settings,
	info,
	breadcrumbs,
	showHome,
	details,
	nav,
	detailsFixedWidth = 300,
	style,
	fullHeight = false,
	header,
	breadCrumbsContainerStyle,
	styleRightContent,
	styleDetailsContent,
	withSubNavigation = false,
	onScript,
}: IPageProps) => {
	const theme = useTheme();
	const [scrollTarget, setScrollTarget] = useState<HTMLDivElement | undefined>(undefined);
	const trigger = useScrollTrigger({ target: scrollTarget, disableHysteresis: true, threshold: 20 });
	const stickyStyle = useStickyStyle();
	const strings = useLocalization();
	const stickyTopRef = useRef<HTMLDivElement>(null);
	const leftColRef = useRef<HTMLDivElement>(null);
	// eslint-disable-next-line
	const [ref, height] = useHeightResizeObserver();

	useEffect(() => {
		if (leftColRef.current !== null && leftColRef.current !== undefined) {
			leftColRef.current.style.top = `-${leftColRef.current.scrollHeight - leftColRef.current.offsetHeight}px`;
		}
	}, [leftColRef, height]);

	return (
		<div
			className='df-col'
			style={{ height: '100%', ...style }}
			ref={ref}>
			<Paper
				square
				elevation={trigger ? 4 : 0}
				style={{ ...stickyStyle, padding: theme.spacing(spacing), borderRight: `5px solid ${trigger ? '#f1f1f1' : '#888'}` }}
				ref={stickyTopRef}>
				<div className='df-row-ac'>
					{/* <div className="df-row-ac" style={breadCrumbsContainerStyle}>
                <Tooltip title="Terug" style={{ marginTop: '-12px', marginBottom: '-12px' }}>
                    <IconButton onClick={goBack} edge="start">
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>    
            </div> */}
					<Breadcrumbs
						breadcrumbs={breadcrumbs}
						title={title}
						secondaryTitle={secondaryTitle}
						showHome={showHome}
						style={breadCrumbsContainerStyle}
					/>
					<div className='fg1'>{header && header}</div>
					<div className='df-row-ac'>
						{onScript && (
							<Tooltip
								title={`Execute custom scripts`}
								style={{ marginTop: '-12px', marginBottom: '-12px' }}>
								<IconButton
									onClick={onScript}
									edge='end'>
									<FunctionVariantIcon />
								</IconButton>
							</Tooltip>
						)}
						{info && (
							<Tooltip
								title={strings.moreInformation}
								style={{ marginTop: '-12px', marginBottom: '-12px' }}>
								<IconButton
									onClick={info}
									edge='end'>
									<HelpOutlineOutlinedIcon />
								</IconButton>
							</Tooltip>
						)}
						{settings && (
							<Tooltip
								title={strings.changeSettings}
								style={{ marginTop: '-12px', marginBottom: '-12px' }}>
								<IconButton
									onClick={settings}
									edge='end'>
									<SettingsIcon />
								</IconButton>
							</Tooltip>
						)}
						{reload && (
							<Tooltip
								title={strings.reload}
								style={{ marginTop: '-12px', marginBottom: '-12px' }}>
								<IconButton
									onClick={reload}
									edge='end'>
									<ReplayIcon />
								</IconButton>
							</Tooltip>
						)}
					</div>
				</div>
			</Paper>
			<div
				className='df-row fg1'
				style={{ overflowY: 'scroll', height: `calc(100% - ${stickyTopRef.current?.clientHeight ?? 0}px)` }}
				ref={node => setScrollTarget(node ?? undefined)}>
				{(details || nav) && (
					<div
						style={{
							padding: theme.spacing(spacing),
							paddingTop: '1px',
							paddingRight: theme.spacing(spacing / 2),
							flex: `${detailsFixedWidth}px 0 0`,
							position: 'sticky',
							top: '0px',
							height: 'fit-content',
							maxWidth: `${detailsFixedWidth}px`,
							...styleDetailsContent,
						}}
						ref={leftColRef}>
						<>
							{nav && (
								<Card
									style={{ position: 'sticky', top: trigger ? '8px' : 0, marginBottom: 16, zIndex: 2 }}
									raised={trigger}>
									{nav}
								</Card>
							)}
							{details}
						</>
					</div>
				)}
				<div
					className='df-col fg1'
					style={{
						padding: theme.spacing(spacing),
						paddingTop: '1px',
						paddingLeft: details ? theme.spacing(spacing / 2) : theme.spacing(spacing),
						height: fullHeight ? '100%' : 'fit-content',
						...styleRightContent,
					}}>
					{children}
				</div>
			</div>
		</div>
	);
};
