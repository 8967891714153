import { ISalesLine } from 'gen/ApiClient';
import { v1 as uuid } from 'uuid';
import { ISalesLineRequestX } from './ISalesLineRequestX';

export const mapSalesLineToSalesLineRequestX = (line: ISalesLine): ISalesLineRequestX => {
	return {
		canChangeQuantity: true,
		description: line.description ?? '',
		id: uuid(),
		quantity: line.quantity,
		unitListPrice: line.unitListPrice,
		unitPrice: line.unitPrice,
		vatPercentage: line.vatPercentage,
	};
};
