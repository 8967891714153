import { Divider, useTheme } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { PageX } from 'framework/components/layout/PageX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { isLast } from 'framework/utils/array/isLast';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { licensesQuery_response } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calcLicenseDetailRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CreateLicenseRequestForm } from './CreateLicenseRequestForm';
import { LicenseCardContent } from './LicenseCardContent';
import { LicensePricingMethodUnitDescriptionRecord } from './LicensePricingMethodUnitDescriptionRecord';
import { LicensesPageBreadcrumbs } from './LicensesPageBreadcrumbs';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const LicensesPage = () => {
	const [response, reload] = useApiEffect(licensesQuery_response);

	const theme = useTheme();
	// const { params, setParams, queryResult, reload, init } = useContext(LicensesQueryContext);
	// const { headers, columns, setColumns, columnsRecord } = useContext(LicensesViewContext);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();

	if (response === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<CreateLicenseRequestForm
				open
				confirm={id => {
					confirm();
					push(calcLicenseDetailRoute(id));
				}}
				cancel={cancel}
			/>
		);
	};

	return (
		<PageX
			stickyHeader={
				<div
					className='df-col gap-8 fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<LicensesPageBreadcrumbs />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<TextLinkButton
								startIcon={<AddCircleOutlineOutlinedIcon />}
								onClick={onNew}
								color='primary'>
								{`Add new license`}
							</TextLinkButton>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
				}}>
				<div className='df-row gap-16'>
					<div className='df-col gap-16'>
						<CardX className='df-col gap-4 h-fc'>
							<Typography16pxBold style={{ textAlign: 'center', color: theme.palette.error.main }}>{`Demo ${response.countDemo}`}</Typography16pxBold>
							<Divider style={{ backgroundColor: theme.palette.error.main }} />
							<LicenseCardContent
								item={response.demoLicense}
								hidePricing
								hideName
								hideLimits
							/>
						</CardX>
						<CardX className='df-col gap-4  h-fc'>
							<Typography16pxBold style={{ textAlign: 'center', color: theme.palette.success.main }}>{`Free tier (${response.countFree})`}</Typography16pxBold>
							<Divider style={{ backgroundColor: theme.palette.success.main }} />
							<LicenseCardContent
								item={response.freeLicense}
								hidePricing
							/>
						</CardX>
					</div>
					<div className='df-col gap-16'>
						<CardX className='df-col gap-4  h-fc'>
							<Typography16pxBold
								style={{ textAlign: 'center', color: theme.palette.success.main }}>{`Professional tier (${response.countPro})`}</Typography16pxBold>
							<Divider style={{ backgroundColor: theme.palette.success.main }} />
							<div className='df-row gap-16'>
								{response.professionalLicenses.map(t => (
									<React.Fragment key={t.id}>
										<LicenseCardContent item={t} />
										{isLast(t, response.professionalLicenses) === false && <Divider orientation='vertical' />}
									</React.Fragment>
								))}
							</div>
						</CardX>
						<CardX
							className='df-col gap-4  h-fc'
							style={{ width: 'fit-content' }}>
							<Typography16pxBold
								style={{ textAlign: 'center', color: theme.palette.warning.main }}>{`Legacy... (${response.countProLegacy})`}</Typography16pxBold>
							<Divider style={{ backgroundColor: theme.palette.warning.main }} />
							<div className='df-row gap-16'>
								{response.legacyLicenses.map(t => (
									<React.Fragment key={t.id}>
										<LicenseCardContent
											item={t}
											key={t.id}
										/>
										{isLast(t, response.legacyLicenses) === false && <Divider orientation='vertical' />}
									</React.Fragment>
								))}
							</div>
						</CardX>
					</div>
					<CardX className='df-col gap-4 h-fc'>
						<Typography16pxBold
							style={{ textAlign: 'center', color: theme.palette.success.main }}>{`Enterprise tier (${response.countEnterprices})`}</Typography16pxBold>
						<Divider style={{ backgroundColor: theme.palette.success.main, marginBottom: 16 }} />
						<div className='df-col gap-16'>
							{response.enterpriceLicenses.map(t => (
								<CaptionVerticalLabelledPropertyX
									key={t.id}
									label={`${t.name ?? ''} (${t.countActiveSubscriptions})`}
									link={() => push(calcLicenseDetailRoute(t.id))}>
									<div className='df-row-ac gap-2'>
										{t.countActiveSubscriptions === 0 && (
											<StatusIcon
												status='error'
												fontSize='small'
											/>
										)}
										{`${formatCurrency(t.currentFee.value)} (${LicensePricingMethodUnitDescriptionRecord[t.pricingMethod]})`}
									</div>
								</CaptionVerticalLabelledPropertyX>
							))}
						</div>
					</CardX>
				</div>
			</div>
		</PageX>
	);
};
