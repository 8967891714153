import React from 'react';
import { CacheProvider } from 'framework/context/CacheProvider';
import {
	IRizivHearingAidDefinitionV2FilterData,
	rizivHearingAidDefinitionsV2Query_concurrencyToken_app,
	rizivHearingAidDefinitionsV2Query_filterData,
} from 'gen/ApiClient';
import { FilterDataContext } from './FilterDataContext';

export const FilterDataProvider = ({ children }: any) => {
	return (
		<CacheProvider<IRizivHearingAidDefinitionV2FilterData>
			concurrencyF={rizivHearingAidDefinitionsV2Query_concurrencyToken_app}
			loadF={rizivHearingAidDefinitionsV2Query_filterData}
			context={FilterDataContext}>
			{children}
		</CacheProvider>
	);
};
