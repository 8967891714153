import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { SuccessOrErrorLabelledProperty } from 'framework/components/SuccessOrErrorLabelledProperty';
import { IClientDetailDto } from 'gen/ApiClient';
import React from 'react';
import { ClientNamingsCheckComponent } from './ClientNamingsCheckComponent';

interface IProps {
	client: IClientDetailDto;
	style?: React.CSSProperties;
}

export const SanityCheckCard = ({ client, style }: IProps) => {
	return (
		<Card style={style}>
			<CardContent>
				<CardTitleTypography title='Check' />
				<ClientNamingsCheckComponent
					namingsCheck={client.namingsCheck}
					summaryView
				/>
				<SuccessOrErrorLabelledProperty
					isSuccess={client.isRunningLatestBuild}
					property={client.isRunningLatestBuild ? 'Up-to-date' : `New version available`}
				/>
			</CardContent>
		</Card>
	);
};
