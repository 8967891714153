import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { BillingAccountsRoute } from 'routes';

export const BillingAccountsPageBreadcrumbs = () => {
	const strings = useLocalization();

	return (
		<div className='df-row-ac'>
			<PageBreadcrumbTitle
				title={strings.financial}
				icon={<AttachMoneyIcon />}
			/>
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={`Billing accounts`}
				route={BillingAccountsRoute}
			/>
		</div>
	);
};
