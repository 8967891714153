import { Typography, useTheme } from '@material-ui/core';
import React from 'react';

interface IProps {
    children: any;
    style?: React.CSSProperties;
    withPadding?: boolean;
    textColor?: 'primary' | 'secondary';
    variant?: 'body2' | 'caption';
}

export const NoRecordsFound = ({ children, withPadding = false, style, textColor = 'primary', variant = 'body2' }: IProps) => {
    const theme = useTheme();

    return (
        <Typography
            variant={variant}
            style={{ padding: withPadding ? theme.spacing(1.5) : 'none', color: textColor === 'secondary' ? theme.palette.text.secondary : theme.palette.text.primary, ...style }}>
            <em>{children}</em>
        </Typography>
    );
}