import React from 'react';
import { IFormProps } from './common/IFormProps';
import { FormSelectField } from './FormSelectField';

interface IProps<TModel> extends IFormProps<TModel> {
    options: string[];
    withNone?: boolean;
    renderValue?: (option: string) => React.ReactNode;
}

export const FormSelectFieldFromStrings = <TModel extends unknown>({ options, renderValue, ...props }: IProps<TModel>) => {
    return (        
        <FormSelectField<TModel, string>
            {...props}
            options={options}
            getKey={t => t}
            renderValue={renderValue ? (t) => renderValue(t) : t => t} />
    );
}