import { Collapse, useTheme } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { auth_endSession, IAuthSessionDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { CodeBlock } from 'shared/CodeBlock';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { formatLastActivity } from './formatLastActivity';
import { OsIcon } from './OsIcon';

const MajorFontSize = 16;
const MinorFontSize = 13;

interface IProps {
	item: IAuthSessionDto;
	withoutBorder?: boolean;
	reload: VoidFunction;
}

export const AuthSessionListItem = ({ item, withoutBorder, reload }: IProps) => {
	const strings = useLocalization();
	const theme = useTheme();
	const { open, cancel, confirm } = useDialogsContext(reload);
	const lastActivityString = useMemo(() => formatLastActivity(item.lastActivity, strings), [item, strings]);
	const [isExpanded, toggle] = useStateToggleBoolean(false);

	const onEnd = () => {
		open(
			<AlertDialog
				open
				title={'End session'}
				content={`Do you want to end this session?`}
				acceptText={`Yes, end`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => auth_endSession({ authId: item.authId })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-row w100 gap-16'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<OsIcon
				os={item.metadata.os}
				style={{ color: theme.palette.text.secondary }}
			/>
			<div className='df-col fg1'>
				<span style={{ fontSize: MajorFontSize }}>{item.userEmail}</span>
				<span style={{ fontSize: MinorFontSize }}>{`${item.metadata.os} - ${item.metadata.browserVersion}`}</span>
				<span style={{ fontSize: MinorFontSize, color: lastActivityString === strings.activeNow ? theme.palette.success.main : 'unset' }}>
					{lastActivityString}
				</span>
				<span style={{ fontSize: MinorFontSize }}>{`Issued - Expires (UTC): ${formatDateX(item.issuedUtc, 'dd/MM/yyyy HH:mm:ss')} - ${formatDateX(
					item.expiresUtc,
					'dd/MM/yyyy HH:mm:ss'
				)}`}</span>
				<span style={{ fontSize: MinorFontSize }}>{`IP: ${item.metadata.remoteIp}`}</span>
				<TextLinkButtonWithExpandIcon
					toggle={toggle}
					isExpanded={isExpanded}>
					{`Details`}
				</TextLinkButtonWithExpandIcon>
				<Collapse in={isExpanded}>
					<div className='df-col gap-4'>
						<TextLinkButton
							startIcon={<ExitToAppIcon />}
							onClick={onEnd}
							hoverColor='secondary'
							color='primary'>
							{`End this session`}
						</TextLinkButton>
						<CodeBlock value={item} />
					</div>
				</Collapse>
			</div>
		</div>
	);
};
