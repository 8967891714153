import React from 'react';
import { ITextLinkButtonProps } from './ITextLinkButtonProps';
import { Collapse } from '@material-ui/core';
import { TextLinkButton } from './TextLinkButton';

interface IProps extends ITextLinkButtonProps {
	show: boolean;
}

export const CollapseOnDisabledTextLinkButton = ({ show, disabled, ...rest }: IProps) => {
	return (
		<Collapse
			in={show === true || disabled === false}
			unmountOnExit>
			<TextLinkButton
				disabled={disabled}
				{...rest}
			/>
		</Collapse>
	);
};
