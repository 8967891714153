import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FormControlLabelCheckbox } from 'framework/components/FormControlLabelCheckbox';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteFreeSoloTextField } from 'framework/forms/FormAutocompleteFreeSoloTextField';
import { FormIntegerField } from 'framework/forms/FormIntegerField';
import { FormSelectFieldFromTypes } from 'framework/forms/FormSelectFieldFromTypes';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ILicenseDto, IPatchLicenseRequest, licensesCommand_patch, LicensePricingMethod } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { LicenseCategoriesCacheContext } from './LicenseCategoriesCacheContext';
import { LicensePricingMethodRecord } from './LicensePricingMethodRecord';

const createSchema = () => {
	return yup
		.object<IPatchLicenseRequest>({
			name: yup.string().required(),
			maxRegisteredUsers: yup.number(),
			maxSoldHearingAids: yup.number(),
			pricingMethod: yup.mixed<LicensePricingMethod>().required(),
			category: yup.string().required(),
			id: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	license: ILicenseDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchLicenseRequestForm = ({ license, confirm, cancel, ...rest }: IProps) => {
	const [isLimited, setIsLimited] = useState<boolean>(true);
	const [add, isSubmitting] = useFormSubmit(licensesCommand_patch);
	const [categories] = useCacheContext(LicenseCategoriesCacheContext);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IPatchLicenseRequest, helpers: FormikHelpers<IPatchLicenseRequest>) => {
		const r = await add({
			...values,
			id: license.id,
			maxRegisteredUsers: isLimited ? values.maxRegisteredUsers : undefined,
			maxSoldHearingAids: isLimited ? values.maxSoldHearingAids : undefined,
		});
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`License ${values.name} succesfully patched`, 'success');
		}
	};

	if (categories === undefined) {
		return <></>;
	}

	return (
		<Formik<IPatchLicenseRequest>
			validateOnMount
			initialValues={{
				name: license.name,
				id: license.id,
				maxRegisteredUsers: license.maxRegisteredUsers,
				maxSoldHearingAids: license.maxSoldHearingAids,
				pricingMethod: license.pricingMethod,
				category: license.category,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					cancel={cancel}
					isSubmitting={isSubmitting}
					isLimited={isLimited}
					setIsLimited={setIsLimited}
					license={license}
					categories={categories}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	isLimited: boolean;
	setIsLimited: React.Dispatch<React.SetStateAction<boolean>>;
	license: ILicenseDto;
	categories: string[];
}

const InnerDialog = ({ cancel, isSubmitting, isLimited, setIsLimited, license, categories, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IPatchLicenseRequest>();

	useEffect(() => {
		if (props.values.maxSoldHearingAids === undefined || props.values.maxSoldHearingAids === null) {
			return;
		} else {
			setFormValue<IPatchLicenseRequest>('name', `P${props.values.maxSoldHearingAids.toString()}`, props);
		}
		// eslint-disable-next-line
	}, [props.values.maxSoldHearingAids]);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='xs'
			fullWidth>
			<DialogTitle>
				<div>{`Patch license ${license.name}`}</div>
			</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<FormAutocompleteFreeSoloTextField<IPatchLicenseRequest>
					name='category'
					label={`Category`}
					options={categories}
					required
				/>
				<FormSelectFieldFromTypes<IPatchLicenseRequest, LicensePricingMethod>
					name='pricingMethod'
					label='Pricing method'
					record={LicensePricingMethodRecord}
				/>
				<FormControlLabelCheckbox
					isChecked={isLimited}
					toggle={() => setIsLimited(!isLimited)}
					label={`Limit on sold HA and registered users?`}
				/>
				<FormIntegerField<IPatchLicenseRequest>
					name='maxSoldHearingAids'
					label='MAX. sold HA'
					disabled={isLimited === false}
				/>
				<FormIntegerField<IPatchLicenseRequest>
					name='maxRegisteredUsers'
					label='MAX. registered users'
					disabled={isLimited === false}
				/>
				<FormTextField<IPatchLicenseRequest>
					name='name'
					label='Name'
					required
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions<IPatchLicenseRequest>
				cancel={cancel}
				submitText='Update'
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
