
import { createSortableHeaderWithKey } from "framework/table/createSortableHeaderWithKey";
import { IHeader } from "framework/table/IHeader";
import { formatDate } from "framework/utils/date/formatDate";
import { INotification } from "gen/ApiClient";
import { SelectableColumn } from "../SelectableColumn";

export const createHeaders = (colRecord: Record<SelectableColumn, string>): Record<SelectableColumn, IHeader<INotification>> => {
    return {
        id: createSortableHeaderWithKey<INotification, SelectableColumn>(colRecord, 'id', t => t.id),
        type: createSortableHeaderWithKey<INotification, SelectableColumn>(colRecord, 'type', t => t.type),
        created: createSortableHeaderWithKey<INotification, SelectableColumn>(colRecord, 'created', t => formatDate(t.created, 'date-and-time')),
        lastUpdated: createSortableHeaderWithKey<INotification, SelectableColumn>(colRecord, 'lastUpdated', t => formatDate(t.lastUpdated, 'date-and-time')),    
        title: createSortableHeaderWithKey<INotification, SelectableColumn>(colRecord, 'title', t => t.title),
        readMoreLink: createSortableHeaderWithKey<INotification, SelectableColumn>(colRecord, 'readMoreLink', t => t.readMoreLink),  
    }
}