import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { FormControlLabelCheckbox } from 'framework/components/FormControlLabelCheckbox';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteFreeSoloTextField } from 'framework/forms/FormAutocompleteFreeSoloTextField';
import { FormIntegerField } from 'framework/forms/FormIntegerField';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormSelectFieldFromTypes } from 'framework/forms/FormSelectFieldFromTypes';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ICreateLicenseRequest, ILicenseDto, LicensePricingMethod, licensesCommand_add } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { LicenseCategoriesCacheContext } from './LicenseCategoriesCacheContext';
import { LicensePricingMethodRecord } from './LicensePricingMethodRecord';
import { PricingMethodFeeDescriptionRecord } from './PricingMethodFeeDescriptionRecord';

const createSchema = () => {
	return yup
		.object<ICreateLicenseRequest>({
			name: yup.string().required(),
			fee: yup.number().required(),
			maxRegisteredUsers: yup.number(),
			maxSoldHearingAids: yup.number(),
			pricingMethod: yup.mixed<LicensePricingMethod>().required(),
			category: yup.string().required(),
			isDemo: yup.boolean(),
		})
		.defined();
};

const createDefaultValues = (license: ILicenseDto | undefined): ICreateLicenseRequest => {
	return {
		fee: license?.currentFeeValue ?? 0,
		maxRegisteredUsers: license?.maxRegisteredUsers ?? undefined,
		maxSoldHearingAids: license?.maxSoldHearingAids ?? undefined,
		name: '',
		pricingMethod: license?.pricingMethod ?? 'FixedMonthlyFee',
		category: license?.category ?? '',
		isDemo: false,
	};
};

interface IProps extends DialogProps {
	license?: ILicenseDto;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CreateLicenseRequestForm = ({ license, confirm, cancel, ...rest }: IProps) => {
	const [isLimited, setIsLimited] = useState<boolean>(true);
	const [add, isSubmitting] = useFormSubmit(licensesCommand_add);
	const [categories] = useCacheContext(LicenseCategoriesCacheContext);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: ICreateLicenseRequest, helpers: FormikHelpers<ICreateLicenseRequest>) => {
		const r = await add({
			...values,
			maxRegisteredUsers: isLimited ? values.maxRegisteredUsers : undefined,
			maxSoldHearingAids: isLimited ? values.maxSoldHearingAids : undefined,
		});
		if (handleFormResponse(r, helpers)) {
			confirm(r.result.objectId);
			notify(`License ${values.name} succesfully created`, 'success');
		}
	};

	if (categories === undefined) {
		return <></>;
	}

	return (
		<Formik<ICreateLicenseRequest>
			validateOnMount
			initialValues={createDefaultValues(license)}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					cancel={cancel}
					isSubmitting={isSubmitting}
					isLimited={isLimited}
					setIsLimited={setIsLimited}
					categories={categories}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	isLimited: boolean;
	setIsLimited: React.Dispatch<React.SetStateAction<boolean>>;
	categories: string[];
}

const InnerDialog = ({ cancel, isSubmitting, isLimited, setIsLimited, categories, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<ICreateLicenseRequest>();

	useEffect(() => {
		if (props.values.maxSoldHearingAids === undefined || props.values.maxSoldHearingAids === null) {
			return;
		} else {
			setFormValue<ICreateLicenseRequest>('name', `P${props.values.maxSoldHearingAids.toString().padStart(3, '0')}`, props);
		}
		// eslint-disable-next-line
	}, [props.values.maxSoldHearingAids]);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='xs'
			fullWidth>
			<DialogTitle>
				<div>{`Create new license`}</div>
			</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<FormAutocompleteFreeSoloTextField<ICreateLicenseRequest>
					name='category'
					label={`Category`}
					options={categories}
					required
				/>
				<FormSelectFieldFromTypes<ICreateLicenseRequest, LicensePricingMethod>
					name='pricingMethod'
					label='Pricing method'
					record={LicensePricingMethodRecord}
				/>
				<FormMoneyField<ICreateLicenseRequest>
					name='fee'
					label={PricingMethodFeeDescriptionRecord[props.values.pricingMethod]}
					required
				/>
				<FormControlLabelCheckbox
					isChecked={isLimited}
					toggle={() => setIsLimited(!isLimited)}
					label={`Limit on sold HA and registered users?`}
				/>
				<FormIntegerField<ICreateLicenseRequest>
					name='maxSoldHearingAids'
					label='MAX. sold HA'
					disabled={isLimited === false}
				/>
				<FormIntegerField<ICreateLicenseRequest>
					name='maxRegisteredUsers'
					label='MAX. registered users'
					disabled={isLimited === false}
				/>
				<FormTextField<ICreateLicenseRequest>
					name='name'
					label='Name'
					required
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions<ICreateLicenseRequest>
				cancel={cancel}
				submitText='Create'
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
