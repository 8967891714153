import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { LocalizationProvider as PickerLocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import nlBE from 'date-fns/locale/nl-BE';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ApiProvider } from 'framework/context/ApiProvider';
import { LoaderProvider } from 'framework/context/LoaderProvider';
import { enApiErrorStrings } from 'framework/context/localization/enApiErrorStrings';
import { LocalizationProvider } from 'framework/context/LocalizationProvider';
import { PdfViewerDialogProvider } from 'framework/pdf/context/PdfViewerDialogProvider';
import { ProtectedRoute } from 'framework/ProtectedRoute';
import { IStrings } from 'localization/IStrings';
import { LanguageOption } from 'localization/LanguageOption';
import { SpecificLocalizationContext } from 'localization/SpecificLocalizationContext';
import { LoginRoute } from 'routes';
import { theme } from 'theme';
import { AppUpdateWall } from './AppUpdateWall';
import { AppWrapper } from './AppWrapper';
import { AuthContext } from './auth/AuthContext';
import { AuthProvider } from './auth/AuthProvider';
import { Login } from './auth/Login';
import { Loader } from './loader/Loader';
import { SnackbarWrapper } from './SnackbarWrapper';
import { RecordProvider } from 'shared/records/RecordProvider';
import { languageKey } from 'keys';
import { localizationRecord } from 'localization/localizationRecord';

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <PickerLocalizationProvider dateAdapter={DateFnsAdapter} locale={nlBE}>
        <ThemeProvider theme={theme}>
          <SnackbarWrapper>
            <BrowserRouter>
              <LoaderProvider loader={<Loader />}>
                <PdfViewerDialogProvider>
                  <LocalizationProvider<IStrings, LanguageOption> map={localizationRecord} context={SpecificLocalizationContext} localStorageKey={languageKey} fallbackValue="en">
                    <RecordProvider>
                      <AuthProvider>
                        <AuthContext.Consumer>
                          {({ isAuth, onRedirect }) => (
                            <ApiProvider strings={enApiErrorStrings} onRedirect={onRedirect} >
                              <AppUpdateWall>
                                <Switch>
                                  <Route exact path={LoginRoute} component={Login} />
                                  <ProtectedRoute isAuth={isAuth} redirectRoute={LoginRoute} component={AppWrapper} />
                                  {/* normally won't hit this path */}
                                  <Redirect path="/" to={LoginRoute} />
                                </Switch>
                              </AppUpdateWall>
                            </ApiProvider>
                          )}
                        </AuthContext.Consumer>
                      </AuthProvider>
                    </RecordProvider>
                  </LocalizationProvider>
                </PdfViewerDialogProvider>
              </LoaderProvider>
            </BrowserRouter>
          </SnackbarWrapper>
        </ThemeProvider>
      </PickerLocalizationProvider>
    </>
  );
}

export default App;
