import React from 'react';
import { MtnProviders } from '../mtn/context/MtnProviders';
import { RizivRefundableDevicesProviders } from '../rizivHearingAidDefinitionsV2/list/RizivRefundableDevicesProviders';
import { NavigationProvider } from './NavigationProvider';

export const DataProviders = ({ children }: any) => {
	return (
		<NavigationProvider>
			<MtnProviders>
				<RizivRefundableDevicesProviders>{children}</RizivRefundableDevicesProviders>
			</MtnProviders>
		</NavigationProvider>
	);
};
