import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { INamingCheckDto } from 'gen/ApiClient';
import React from 'react';
import { ErrorOrSuccessIcon } from 'shared/ErrorOrSuccessIcon';

interface IProps {
	check: INamingCheckDto;
	label: string;
	summaryView: boolean;
}

export const NamingCheckLabelledProperty = ({ check, label, summaryView }: IProps) => {
	return (
		<LabelledProperty
			icon={<ErrorOrSuccessIcon val={check.areEqual} />}
			label={summaryView ? '' : label}
			property={summaryView ? label : check.areEqual ? check.actualValue : `expected: ${check.expectedValue} got ${check.actualValue}`}
		/>
	);
};
