import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import HearingIcon from '@material-ui/icons/Hearing';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import ListIcon from '@material-ui/icons/List';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { NavigateIconRecord } from 'app/NavigateIconRecord';
import { IClientDetailDto } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { calculateBillingAccountDetailRoute } from 'routes';
import { IPushNavigationLink } from 'shared/cards/IPushNavigationLink';
import { PushNavigationLinks } from 'shared/cards/PushNavigationLinks';
import { RecordNavigationCardContent } from 'shared/cards/RecordNavigationCardContent';
import { ClientDetailNavigationLink } from './ClientDetailNavigationLink';

const ClientDetailNavigationLinkRecord: Record<ClientDetailNavigationLink, string> = {
	overview: 'Overview',
	iis: 'IIS',
	states: 'Subscription states',
	publish: 'Publish',
	update: 'Update',
	subscriptions: 'Subscriptions',
	soldHearingAids: 'Sold HA',
};

const ClientDetailNavigationLinkToIconRecord: Record<ClientDetailNavigationLink, React.ReactNode> = {
	overview: <DashboardOutlinedIcon />,
	iis: <LanguageOutlinedIcon />,
	states: <ListIcon />,
	publish: <PublishOutlinedIcon />,
	update: <DynamicFeedIcon />,
	subscriptions: NavigateIconRecord['subscriptions'],
	soldHearingAids: <HearingIcon />,
};

const createBillingAccountPushNavigationLink = (item: IClientDetailDto): IPushNavigationLink => {
	return {
		text: 'Navigate to billing account',
		icon: NavigateIconRecord['billing-accounts'],
		route: calculateBillingAccountDetailRoute(item.id),
	};
};

interface IProps {
	item: IClientDetailDto;
	link: ClientDetailNavigationLink;
	setLink: React.Dispatch<React.SetStateAction<ClientDetailNavigationLink>>;
}

export const ClientDetailNavigationCard = ({ item, link, setLink }: IProps) => {
	const billingAccountLink = useMemo(() => createBillingAccountPushNavigationLink(item), [item]);

	return (
		<RecordNavigationCardContent
			record={ClientDetailNavigationLinkRecord}
			iconRecord={ClientDetailNavigationLinkToIconRecord}
			link={link}
			setLink={setLink}>
			<PushNavigationLinks links={[billingAccountLink]} />
		</RecordNavigationCardContent>
	);
};
