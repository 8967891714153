import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import React from 'react';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { IDocumentKeyDto } from 'gen/ApiClient';

interface IProps<TType extends string> {
    documentKey: IDocumentKeyDto;
    labelGridSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    record: Record<TType, string>;    
}

export const FormattingGridComponent = <TType extends string>({ documentKey, labelGridSize, record }: IProps<TType>) => {
    return (
        <>
            {Object.keys(record).map(t =>
                <PropertyInGrid
                    key={t}
                    removePadding
                    labelGridSize={labelGridSize}
                    label={record[t as TType]}
                    value={`{{${documentKey.key}:${t}}}`}
                    customActionIcon={<FileCopyOutlinedIcon />}
                    customAction={() => navigator.clipboard.writeText(`{{${documentKey.key}:${t}}}`)} />)}
        </>
    );
}