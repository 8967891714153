import { Button, ListItem, useTheme } from '@material-ui/core';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

interface IProps {
    text: string;
    startIcon: React.ReactNode;
    route: string;
    onNavigate: (route: string) => void;
}

export const commonNavLinkStyle: React.CSSProperties = {
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    paddingRight: '12px'
}

const borderWidth: number = 4;

export const PrimaryNavLink = ({ text, startIcon, route, onNavigate }: IProps) => {
    const isMatch = useRouteMatch(route);
    const theme = useTheme();

    return (
        <ListItem
            disableGutters
            component="li"
            onClick={() => onNavigate(route)}
            style={{
                padding: '0px',
                borderLeft: isMatch ? `${borderWidth}px solid ${theme.palette.primary.main}` : 'none',
            }}>
            <Button
                fullWidth
                variant="text"
                style={{
                    ...commonNavLinkStyle,
                    paddingLeft: isMatch ? `${24 - borderWidth}px` : '24px',
                }}
                startIcon={startIcon}
                color={isMatch ? 'primary' : 'default'}>
                {text}
            </Button>
        </ListItem>
    );
}
