import React, { useMemo } from 'react';
import { useLabelsCacheContext } from './useLabelsCacheContext';
import { LabelX } from './LabelX';
import { ILabelDto } from 'gen/ApiClient';

interface IProps {
	labelIds: string[];
	style?: React.CSSProperties;
}

export const RowWrappedLabels = ({ labelIds, style }: IProps) => {
	const [labels] = useLabelsCacheContext();
	const fLabels = useMemo<ILabelDto[]>(() => labelIds.map(t => labels.find(x => x.id === t)).filter(t => t !== undefined) as ILabelDto[], [labels, labelIds]);

	return (
		<div
			className='df-row-wrap gap-4'
			style={style}>
			{fLabels.map(t => (
				<LabelX
					item={t}
					key={t?.id}
				/>
			))}
		</div>
	);
};
