import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { DocumentDetailDialog } from 'app/main/documents/dialog/DocumentDetailDialog';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { DetailPageSidebarCaptionAndIcon } from 'framework/components/layout/DetailPageSidebarCaptionAndIcon';
import { ISale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CreateInvoiceDocumentRequestForm } from '../CreateInvoiceDocumentRequestForm';

interface IProps {
	item: ISale;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const SalesDetailDocumentContent = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onNew = () => {
		open(
			<CreateInvoiceDocumentRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				salesId={item.id}
			/>
		);
	};

	const onViewDetail = (documentId: string) => {
		open(
			<DocumentDetailDialog
				open
				close={confirm}
				id={documentId}
			/>
		);
	};

	return (
		<div
			className='df-col gap-6'
			style={style}>
			<DetailPageSidebarCaptionAndIcon
				caption={strings.documents}
				disabled
				icon={<DescriptionOutlinedIcon />}
			/>
			<TextLinkButton
				disabled={item.type !== 'Invoice'}
				startIcon={<DescriptionOutlinedIcon />}
				color={item.hasInvoiceDocument === false ? 'secondary' : undefined}
				onClick={() => (item.hasInvoiceDocument ? onViewDetail(item.invoiceDocumentId!) : onNew())}>
				{item.hasInvoiceDocument === false && <em>{`Create document`}</em>}
				{item.hasInvoiceDocument === true && `View/edit document`}
			</TextLinkButton>
		</div>
	);
};
