import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { useOpenInNewTab } from 'framework/hooks/useOpenInNewTab';
import { IMedicalTreatmentNomenclatureForAudiology } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { MtnDetailDialog } from './MtnDetailDialog';

const RizivUrl = `https://ondpanon.riziv.fgov.be/Nomen/nl/{code}`;

interface IProps {
    mtn: IMedicalTreatmentNomenclatureForAudiology;
}

export const DetailsHeader = ({ mtn }: IProps) => {
    const { open, confirm: close } = useDialogsContext();
    const openInNewTab = useOpenInNewTab();

    const onViewDetail = () => {
        open(<MtnDetailDialog
            open
            id={mtn.code!}
            close={close} />);
    }

    const onViewRiziv = () => {
        openInNewTab(RizivUrl.replace('{code}', mtn.code!), true);
    }

    return (
        <div className="df-row-ac jc-e">
            <SmallPrimaryTextButton onClick={onViewDetail}>{`Details`}</SmallPrimaryTextButton>
            <SmallPrimaryTextButton onClick={onViewRiziv} startIcon={<OpenInNewIcon />}>{`RIZIV`}</SmallPrimaryTextButton>
        </div>
    );
}