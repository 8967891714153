import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { AbsoluteCloseButton } from 'framework/components/detailDialog/AbsoluteCloseButton';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { IJobInfoDto, IRizivHearingAidDefinitionV2TaskMetadata } from 'gen/ApiClient';
import React from 'react';
import { CodeBlock } from 'shared/CodeBlock';

interface IProps extends DialogProps {
	item: IJobInfoDto;
	metadata: IRizivHearingAidDefinitionV2TaskMetadata;
	close: () => void;
}

export const RizivDevicesCheckDialog = ({ item, metadata, close, ...rest }: IProps) => {
	return (
		<Dialog
			{...rest}
			onClose={close}
			fullWidth>
			<AbsoluteCloseButton close={close} />
			<DialogTitle>{`Riziv Devices Check`}</DialogTitle>
			<DialogContent>
				<DividerWithCaption caption='job info' />
				<CodeBlock value={item} />
				<DividerWithCaption caption='metadata' />
				<CodeBlock value={metadata} />
			</DialogContent>
		</Dialog>
	);
};
