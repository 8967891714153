import { Divider } from '@material-ui/core';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calculateBillingAccountDetailRoute, calculateSubscriptionDetailRoute } from 'routes';
import { SaleDetailActions } from './SaleDetailActions';
import { SalesDetailDocumentContent } from './SaleDetailDocumentContent';
import { SaleDetailPageSidebarLabels } from './SaleDetailPageSidebarLabels';

interface IProps {
	item: ISale;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const SalesDetailPageSidebar = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				...style,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.reference}>{item.referenceOrId}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.date}>{formatDate(item.date)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={`Pay before`}>{formatDate(item.payBefore)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.totalExVat}>{formatCurrency(item.priceExVat)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`Billing Account`}
					link={item.isLinkedToBillingAccount ? () => push(calculateBillingAccountDetailRoute(item.billingAccountId!)) : undefined}>
					{item.vatRecipientName}
				</CaptionVerticalLabelledPropertyX>
				{item.hasSubscription && (
					<CaptionVerticalLabelledPropertyX
						label={`Links`}
						link={() => push(calculateSubscriptionDetailRoute(item.subscriptionId!))}>
						{`Subscription`}
					</CaptionVerticalLabelledPropertyX>
				)}
			</div>
			<Divider style={{ marginTop: 16, marginBottom: 8 }} />
			<SalesDetailDocumentContent
				item={item}
				reload={reload}
			/>
			<Divider style={{ marginTop: 16, marginBottom: 8 }} />
			<SaleDetailPageSidebarLabels
				item={item}
				reload={reload}
			/>
			<div className='fg1'></div>
			<DividerWithMargin />
			<SaleDetailActions
				item={item}
				reload={reload}
			/>
		</div>
	);
};
