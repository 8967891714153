import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { SelectField } from 'framework/components/select/SelectField';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectFieldOnId } from 'framework/forms/FormSelectFieldOnId';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ILicenseDto, IPatchLicenseOnSubscriptionRequest, ISubscription, subscriptionsCommand_patchLicense } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { LicenseCategoriesCacheContext } from '../licenses/LicenseCategoriesCacheContext';
import { LicensesCacheContext } from '../licenses/LicensesCacheContext';

const createSchema = () => {
	return yup
		.object<IPatchLicenseOnSubscriptionRequest>({
			licenseId: yup.string().required(),
			subscriptionId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	item: ISubscription;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchLicenseOnSubscriptionRequestForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(subscriptionsCommand_patchLicense);
	const [licenses] = useCacheContext(LicensesCacheContext);
	const [categories] = useCacheContext(LicenseCategoriesCacheContext);
	const [category, setCategory] = useState<string>('');
	const [fLicenses, setFLicenses] = useState<ILicenseDto[]>(licenses ?? []);
	const notify = useSnackbarNotify();

	useEffect(() => {
		if (licenses !== undefined) {
			setFLicenses(licenses.filter(t => t.category === category));
		}
	}, [category, licenses]);

	const handleSubmit = async (values: IPatchLicenseOnSubscriptionRequest, helpers: FormikHelpers<IPatchLicenseOnSubscriptionRequest>) => {
		const r = await patch({ ...values, subscriptionId: item.id });
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`License for ${item.clientName} succesfully patched`, 'success');
		}
	};

	if (licenses === undefined || categories === undefined) {
		return <></>;
	}

	return (
		<Formik<IPatchLicenseOnSubscriptionRequest>
			validateOnMount
			initialValues={{
				licenseId: item.licenseId ?? '',
				subscriptionId: item.id,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Patch license ${item.clientName}`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<SelectField<string>
							options={categories}
							value={category}
							onChange={setCategory}
							getKey={t => t}
							label='Category'
							renderValue={t => t}
							style={{ marginBottom: 10 }}
						/>
						<FormSelectFieldOnId<IPatchLicenseOnSubscriptionRequest, ILicenseDto>
							name='licenseId'
							label={`License`}
							options={fLicenses}
							renderValue={t => t.identifier}
							getKey={t => t.id}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IPatchLicenseOnSubscriptionRequest>
						cancel={cancel}
						submitText='Update'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
