import { useEffect, useState } from "react";
import { Try } from "../Try";
import { useApi } from "./useApi";
import { useTrigger } from "./useTrigger";

export function useApiEffect<TResult>(f: () => Promise<Try<TResult>>): [TResult | undefined, () => void];
export function useApiEffect<TArgs, TResult>(f: (args: TArgs) => Promise<Try<TResult>>, args: TArgs): [TResult | undefined, () => void];
export function useApiEffect<TArgs extends any[], TResult>(f: (...args: TArgs) => Promise<Try<TResult>>, ...args: TArgs): [TResult | undefined, () => void];

export function useApiEffect<TArgs extends any[], TResult>(f: (...args: TArgs) => Promise<Try<TResult>>, ...args: TArgs): [TResult | undefined, () => void] {
    const [setLoading, clearLoading, handleError] = useApi();
    const [data, setData] = useState<TResult>();
    const [trigger, reload] = useTrigger();

    useEffect(() => {
        let cancelled = false;
        const loadX = async () => {
            console.log(`load useApiEffect ${f.name}`);
            const loadId = setLoading();
            const r = await f(...args);
            if (r.isFailure) {
                handleError(r.error);
            } else {
                setData(r.result);
            }
            clearLoading(loadId);
        }
        if (cancelled === false) {
            loadX();
        }
        return () => {
            cancelled = true;
        }
        // missing deps handleError -> is a function
        // eslint-disable-next-line 
    }, [trigger, f, ...args]);

    return [data, reload];
}