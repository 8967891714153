import { ChipX } from 'framework/components/chips/ChipX';
import { IBillingAccount } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IBillingAccount;
	style?: React.CSSProperties;
}

export const BillingAccountChips = ({ item, style }: IProps) => {
	return (
		<div
			className='df-row-ac gap-4'
			style={style}>
			{item.hasMissingFields && (
				<ChipX
					color='error'
					fill
					label='Missing data'
				/>
			)}
			{item.hasOutstandingBalance && (
				<ChipX
					color='warning'
					label='Outst. bal.'
				/>
			)}
			{item.isAlsoHamsClient && (
				<ChipX
					color='primary'
					label='HAMS client'
				/>
			)}
		</div>
	);
};
