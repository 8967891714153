import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { ISubscriptionsQueryMetadata, SubscriptionPrefilter } from 'gen/ApiClient';
import React from 'react';

export const createSubscriptionsPrefilterBadgeChipXRecord = (metadata: ISubscriptionsQueryMetadata): Record<SubscriptionPrefilter, React.ReactNode> => {
	return {
		All: undefined,
		Active: metadata.countActive > 0 && (
			<BadgeChipX
				value={metadata.countActive}
				color='primary'
			/>
		),
		ActiveAndExceedsSoldHearingAids: metadata.countActiveAndExceeds > 0 && (
			<BadgeChipX
				value={metadata.countActiveAndExceeds}
				color='error'
			/>
		),
		ActiveWithoutSale: metadata.countActiveWithoutSale > 0 && (
			<BadgeChipX
				value={metadata.countActiveWithoutSale}
				color='error'
			/>
		),
		Future: metadata.countFuture > 0 && (
			<BadgeChipX
				value={metadata.countFuture}
				color='info'
			/>
		),
		History: metadata.countHistory > 0 && (
			<BadgeChipX
				value={metadata.countHistory}
				color='info'
			/>
		),
	};
};
