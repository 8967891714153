import React, { useMemo } from 'react';
import { typesToOptions } from '../utils/typesToOptions';
import { IFormProps } from './common/IFormProps';
import { FormSelectFieldWithOption } from './FormSelectFieldWithOption';

interface IProps<TModel, TType extends string> extends IFormProps<TModel> {
    record: Record<TType, string>;
    withNone?: boolean;
}

export const FormSelectFieldFromTypes = <TModel extends unknown, TType extends string>({ record, ...props }: IProps<TModel, TType>) => {
    const sorted = useMemo(() => typesToOptions(Object.keys(record) as TType[], record), [record]);

    return (
        <FormSelectFieldWithOption<TModel, TType>
            options={sorted}
            {...props} />
    );
}