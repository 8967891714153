import { Checkbox, Dialog, DialogContent, DialogProps, DialogTitle, Divider, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { DragAndDropList } from 'framework/components/DragAndDropList';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { useLocalization } from 'localization/useLocalization';

interface IProps<TProp extends string> extends DialogProps {
    record: Record<TProp, string>;
    submit: (props: TProp[]) => void;
    cancel: () => void;
    selected: TProp[];
    columns?: TProp[];
    exclusions: TProp[];
}

export const SelectColumnsDialog = <TProp extends string>({ submit, cancel, record, selected, columns, exclusions, ...rest }: IProps<TProp>) => {
    const allProps: TProp[] = useMemo(() => columns ? columns : Object.keys(record).filter(t => exclusions.indexOf(t as TProp) === -1), [record, columns, exclusions]) as any;
    const strings = useLocalization();
    const [ordered, setOrdered] = useState<TProp[]>(allProps);
    const [selectedProps, setSelectedProps] = useState<TProp[]>(selected);

    useEffect(() => {
        setOrdered([...selected, ..._.orderBy(allProps, t => record[t]).filter(t => selected.indexOf(t) === -1)]);
    }, [selected, allProps, record]);

    useEffect(() => {
        setSelectedProps(selected);
    }, [selected]);

    const onSubmit = () => {
        submit(ordered.filter(t => selectedProps.indexOf(t) > - 1));
    }

    return (
        <Dialog
            {...rest}
            scroll="paper"
            fullWidth>
            <DialogTitle>
                <div>{strings.selectColumns}</div>
                <Typography variant="body2">{strings.selectColumnsToShowBelow}</Typography>
            </DialogTitle>
            <DialogContent dividers style={{ padding: '0px' }}>
                <ListItem dense button onClick={() => selectedProps.length === allProps.length ? setSelectedProps([]) : setSelectedProps(allProps)}>
                    <ListItemIcon>
                        <Checkbox
                            indeterminate={selectedProps.length > 0 && selectedProps.length < allProps.length}
                            checked={selectedProps.length === allProps.length} />
                    </ListItemIcon>
                    <ListItemText primary={selectedProps.length === allProps.length ? strings.selectNone : strings.selectAll} />
                </ListItem>
                <Divider variant="middle" />
                <DragAndDropList<TProp>
                    ordered={ordered}
                    setOrdered={setOrdered}
                    selected={selectedProps}
                    setSelected={setSelectedProps}
                    getKey={t => t}
                    getValue={t => record[t]} />
            </DialogContent>
            <CancelSubmitDialogActions
                cancel={cancel}
                onSubmit={onSubmit}
                submitText="Selecteer"
                isSubmitting={false}
                variantSubmitButton="outlined" />
        </Dialog>
    );
}