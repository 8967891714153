import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { billingAccountsCommand_patchAddress, IBillingAccount, IPatchBillingAccountAddressRequest } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IPatchBillingAccountAddressRequest>({
			addressLine: yup.string().required(),
			city: yup.string().required(),
			country: yup.string(),
			id: yup.string().required(),
			zip: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	billingAccount: IBillingAccount;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchBillingAccountAddressRequestForm = ({ billingAccount, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(billingAccountsCommand_patchAddress);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IPatchBillingAccountAddressRequest, helpers: FormikHelpers<IPatchBillingAccountAddressRequest>) => {
		const r = await patch({ ...values, id: billingAccount.id });
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`Address succesfully patched`, 'success');
		}
	};

	return (
		<Formik<IPatchBillingAccountAddressRequest>
			validateOnMount
			initialValues={{
				addressLine: billingAccount.address?.addressLine ?? '',
				city: billingAccount.address?.city ?? '',
				country: billingAccount.address?.country ?? 'BE',
				id: billingAccount.id,
				zip: billingAccount.address?.zip ?? '',
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Patch address ${billingAccount.name}`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IPatchBillingAccountAddressRequest>
							name='addressLine'
							label='Address Line'
							required
						/>
						<FormTextField<IPatchBillingAccountAddressRequest>
							name='zip'
							label='Zip'
							required
						/>
						<FormTextField<IPatchBillingAccountAddressRequest>
							name='city'
							label='City'
							required
						/>
						<FormTextField<IPatchBillingAccountAddressRequest>
							name='country'
							label='Country'
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IPatchBillingAccountAddressRequest>
						cancel={cancel}
						submitText='Update'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
