import { Button, Collapse } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { TableCardX } from 'framework/components/cards/TableCardX';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageStatsButtonX } from 'framework/components/layout/PageStatsButtonX';
import { PageX } from 'framework/components/layout/PageX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { createRecordFromStrings } from 'framework/utils/record/createRecordFromStrings';
import { BillingAccountExportProp, BillingAccountExportProps, IBillingAccountsFilterParams, billingAccountsQuery_export } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { calculateBillingAccountDetailRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { TextLinkExportButtonMenuAndDialog } from 'shared/export/TextLinkExportButtonMenuAndDialog';
import { BillingAccountsPageBreadcrumbs } from '../BillingAccountsPageBreadcrumbs';
import { CreateBillingAccountRequestForm } from '../CreateBillingAccountRequestForm';
import { BillingAccountsListFilterCard } from './BillingAccountsListFilterCard';
import { BillingAccountsListOrderBySelect } from './BillingAccountsListOrderBySelect';
import { BillingAccountsListQueryContext } from './BillingAccountsListQueryContext';
import { BillingAccountsListStats } from './BillingAccountsListStats';
import { BillingAccountsTable } from './BillingAccountsTable';

export const BillingAccountsListPage = () => {
	const { reload, queryResult, params, setParams } = useQueryContext(BillingAccountsListQueryContext);
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [showStats, toggleShowStats] = useStateToggleBoolean(true);
	const { push } = useHistory();
	const exportRecord = useMemo(() => createRecordFromStrings(BillingAccountExportProps), []);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<CreateBillingAccountRequestForm
				open
				confirm={id => {
					confirm();
					push(calculateBillingAccountDetailRoute(id));
				}}
				cancel={cancel}
			/>
		);
	};

	return (
		<PageX
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<BillingAccountsPageBreadcrumbs />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageStatsButtonX
								showStats={showStats}
								toggleShowStats={toggleShowStats}
							/>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
					<Collapse in={showStats}>
						<BillingAccountsListStats
							metadata={queryResult.metadata}
							params={params}
							setParams={setParams}
							style={{ paddingTop: 16 }}
						/>
					</Collapse>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 h100'
					style={{ padding: 16, paddingRight: 8, maxWidth: 400 }}>
					<div className='df-col gap-8'>
						<Button
							variant='contained'
							color='primary'
							startIcon={<AddIcon />}
							onClick={onNew}
							style={{ borderRadius: 12 }}>
							{`New`}
						</Button>
					</div>
					<BillingAccountsListFilterCard
						params={params}
						setParams={setParams}
						metadata={queryResult.metadata}
					/>
					<div className='fg1'></div>
					<TextLinkExportButtonMenuAndDialog<IBillingAccountsFilterParams, BillingAccountExportProp>
						params={params}
						filteredCount={queryResult.filteredCount}
						unfilteredCount={queryResult.unfilteredCount}
						downloadF={billingAccountsQuery_export}
						fileName={`billing-accounts`}
						record={exportRecord}
						allProps={BillingAccountExportProps}
					/>
				</div>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<SearchbarX
					placeholder={`${strings.searchVerb}...`}
					value={params.searchString}
					onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
				/>
				<TableCardX
					header={
						<div className='df-col-ae'>
							<BillingAccountsListOrderBySelect
								sortParams={params.sortParams}
								onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
							/>
						</div>
					}
					table={
						<BillingAccountsTable
							queryResult={queryResult}
							sortParams={params.sortParams}
							onFilter={sp => setParams(onResetToPage1({ ...params, sortParams: sp }))}
						/>
					}
					footer={
						<QueryResultPaginationX
							queryResult={queryResult}
							setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
							flexEnd
						/>
					}
				/>
			</div>
		</PageX>
	);
};
