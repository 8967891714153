import React from 'react';
import { StatusChip } from 'framework/components/StatusChip';
import { ITemplateDto } from 'gen/ApiClient';

interface IProps {
	item: ITemplateDto;
	style?: React.CSSProperties;
}

export const TemplateTypeChip = ({ item, style }: IProps) => {
	return (
		<StatusChip
			style={style}
			label={item.rootTemplateType!}
			color='info'
		/>
	);
};
