import { ChipX } from 'framework/components/chips/ChipX';
import React from 'react';

interface IXSale {
	isFullyPaid: boolean;
	isOverDue?: boolean;
	paymentStatus: string | undefined;
}

interface IProps {
	item: IXSale;
	style?: React.CSSProperties;
}

export const SalesPaymentStatusChip = ({ item, style }: IProps) => {
	return (
		<ChipX
			style={style}
			label={item.paymentStatus?.toString() ?? ''}
			color={item.isFullyPaid ? 'success' : item.isOverDue ? 'error' : 'info'}
		/>
	);
};
