import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchDateWindowOnSubscriptionRequest, ISubscription, subscriptionsCommand_patchToDate } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IPatchDateWindowOnSubscriptionRequest>({
			subscriptionId: yup.string().required(),
			from: yup.date().required(),
			to: yup.date().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	item: ISubscription;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchDateTimeWindowOnSubscriptionRequestForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(subscriptionsCommand_patchToDate);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IPatchDateWindowOnSubscriptionRequest, helpers: FormikHelpers<IPatchDateWindowOnSubscriptionRequest>) => {
		const r = await patch({ ...values, subscriptionId: item.id });
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`License for ${item.clientName} succesfully patched`, 'success');
		}
	};

	return (
		<Formik<IPatchDateWindowOnSubscriptionRequest>
			validateOnMount
			initialValues={{
				subscriptionId: item.id,
				from: item.from,
				to: item.to,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Patch subscription date window ${item.clientName}`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormDatePicker<IPatchDateWindowOnSubscriptionRequest>
							name='from'
							label='From Date'
							required
						/>
						<FormDatePicker<IPatchDateWindowOnSubscriptionRequest>
							name='to'
							label='To Date'
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IPatchDateWindowOnSubscriptionRequest>
						cancel={cancel}
						submitText='Update'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
