import { IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeANumber } from 'framework/utils/makeANumber';
import React, { useEffect, useMemo, useState } from 'react';
import { PositiveOrNegativeType } from './PositiveOrNegativeType';

interface IProps {
	value: number;
	setValue: (val: number) => void;
	error?: boolean;
	helperText?: string | undefined | false;
	placeholder?: string;
	chars?: number;
	style?: React.CSSProperties;
	max?: number;
	mode?: PositiveOrNegativeType;
	disabled?: boolean;
}

export const QuantityField = ({ value, setValue, error, helperText, placeholder, chars, style, max, mode = 'positive', disabled = false }: IProps) => {
	const [localState, setLocalState] = useState<number>(value);
	const width = useMemo(() => (chars ? `${chars}ch` : `4ch`), [chars]);

	useEffect(() => {
		setLocalState(value);
	}, [value]);

	const onBlur = () => {
		if ((mode === 'positive' && localState > 0) || (mode === 'negative' && localState < 0)) {
			setValue(localState);
		} else {
			setLocalState(value);
		}
	};

	return (
		<TextField
			disabled={disabled}
			variant='outlined'
			size='small'
			style={{ ...style, marginLeft: 'auto', marginRight: 'auto' }}
			value={localState}
			onChange={e => setLocalState(makeANumber(e.target.value))}
			onBlur={() => localState !== value && onBlur()}
			placeholder={placeholder}
			error={error}
			helperText={helperText}
			inputProps={{ pattern: '[0-9]*', style: { textAlign: 'center', width: width, paddingTop: 9, paddingBottom: 9 } }}
			InputProps={{
				style: { paddingLeft: 4, paddingRight: 4 },
				startAdornment: (
					<IconButton
						size='small'
						color='primary'
						onClick={() => setValue(value - 1)}
						disabled={disabled === true ? true : mode === 'positive' ? value <= 1 : undefined}>
						<RemoveIcon />
					</IconButton>
				),
				endAdornment: (
					<IconButton
						size='small'
						color='primary'
						onClick={() => setValue(value + 1)}
						disabled={disabled === true ? true : (max !== undefined && value >= max) || (mode === 'negative' && value >= -1)}
						style={{ marginLeft: 4 }}>
						<AddIcon />
					</IconButton>
				),
			}}
		/>
	);
};
