import { StatusChip } from 'framework/components/StatusChip';
import { INotification } from 'gen/ApiClient';
import React from 'react';

interface IProps {
    item: INotification;
}

export const NotificationChips = ({ item }: IProps) => {
    return (
        <div className="df-row-ac">
            {item.mustShow === false && <StatusChip style={{ marginRight: 8 }} fill color="error" label="Delayed" />}
            {item.isForAllClients === false && <StatusChip style={{ marginRight: 8 }} fill color="warning" label="Targeted" />}
        </div>
    );
}