import { useRef, useLayoutEffect, useState, useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface DOMRectReadOnly {
    x: number;
    y: number;
    width: number;
    height: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  
  interface ResizeObserverEntry {
    target: Element;
    contentRect: DOMRectReadOnly;
  };

export const useResizeObserver = (): [any, number, number] => {
    const [observerEntry, setObserverEntry] = useState<ResizeObserverEntry>();
    const [node, setNode] = useState(null);
    const observer = useRef(null) as any;

    const disconnect = useCallback(() => observer.current?.disconnect(), 
    // eslint-disable-next-line
    []);

    const width = useCallback(() => {
        return (observerEntry && observerEntry.contentRect && Math.round(observerEntry.contentRect['width'])) ?? 0;
        // eslint-disable-next-line
    }, [observerEntry?.contentRect]);

    const height = useCallback(() => {
        return (observerEntry && observerEntry.contentRect && Math.round(observerEntry.contentRect['height'])) ?? 0;
        // eslint-disable-next-line
    }, [observerEntry?.contentRect]);

    const observe = useCallback(() => {
        observer.current = new ResizeObserver(([entry]) => setObserverEntry(entry));
        if (node) {
            observer.current.observe(node);
        }
        // eslint-disable-next-line
    }, [node]);

    useLayoutEffect(() => {
        observe();
        return () => disconnect();
        // eslint-disable-next-line
    }, [disconnect, observe]);

    return [setNode, width(), height()];
};