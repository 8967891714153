import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { Searchbar } from 'framework/components/Searchbar';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { PagebleTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { ITemplateDto } from 'gen/ApiClient';
import React, { useContext, useEffect } from 'react';
import { createDetailsHeader } from 'shared/createDetailsHeader';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { Page } from 'framework/components/layout/Page';
import { NoRecordsFound } from 'shared/NoRecordsFound';
import { SelectColumnsButtonAndDialog } from 'shared/SelectColumnsButtonAndDialog';
import { UploadTemplateForm } from '../create/UploadTemplateForm';
import { TemplateChips } from '../TemplateChips';
import { TemplateDialog } from '../TemplateDialog';
import { TemplateSelectableColumn } from './TemplateSelectableColumn';
import { TemplatesFilter } from './TemplatesFilter';
import { TemplatesQueryContext } from './TemplatesQueryContext';
import { TemplatesQuickFilter, TemplatesQuickFilters } from './TemplatesQuickFilter';
import { TemplatesViewContext } from './TemplatesViewContext';

export const TemplatesListPage = () => {
	const theme = useTheme();
	const { params, setParams, queryResult, reload, init } = useContext(TemplatesQueryContext);
	const { headers, columns, setColumns, columnsRecord, quickFilter, setQuickFilter, quickFilterRecord } = useContext(TemplatesViewContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useEffect(() => {
		init();
	}, [init]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onViewDetail = (id: string) => {
		open(
			<TemplateDialog
				open
				close={confirm}
				id={id}
			/>
		);
	};

	const onNew = () => {
		open(
			<UploadTemplateForm
				open
				confirm={id => {
					confirm();
					onViewDetail(id);
				}}
				cancel={cancel}
			/>
		);
	};

	return (
		<Page
			showHome
			title='Templates'
			reload={reload}>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<Searchbar
					value={params.searchString}
					onSearch={text => setParams({ ...params, searchString: text, pageParams: { ...params.pageParams, index: 1 } })}
					placeholder='Search...'
					style={{ width: '400px' }}
					variant='paper'
				/>
				<div>
					<Button
						variant='contained'
						color='primary'
						onClick={onNew}
						startIcon={<AddIcon />}>
						{`Add template`}
					</Button>
				</div>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<Card>
					<div
						className='df-row-ac jc-sb'
						style={{ padding: theme.spacing(1) }}>
						<ButtonGroupWithSelection<TemplatesQuickFilter>
							options={TemplatesQuickFilters}
							selected={quickFilter}
							setSelected={setQuickFilter}
							renderOption={t => quickFilterRecord[t]}
						/>
						<div>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
							/>
						</div>
					</div>
					<Divider />
					<div className='df-row jc-sb'>
						<TemplatesFilter
							params={params}
							onFilter={p => setParams({ ...p, pageParams: { index: 1, size: params.pageParams.size } })}
							paddingLeft={theme.spacing(1.5)}
						/>
					</div>
					<Divider />
					<CardMedia>
						<PagebleTableWithColumnSelection<ITemplateDto, TemplateSelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.id}
							selectedColumns={columns}
							prependColumn={createEmptyHeader<ITemplateDto>('sd-ex-type', t => (
								<TemplateChips item={t} />
							))}
							appendColumn={createDetailsHeader<ITemplateDto>(t => onViewDetail(t.id!))}
						/>
					</CardMedia>
				</Card>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{`No templates found`}</NoRecordsFound>}
		</Page>
	);
};
