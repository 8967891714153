import { TableBody } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { isLast } from '../utils/array/isLast';
import { toggleSelect } from '../utils/array/toggleSelect';
import { IHeader } from './IHeader';
import { TableRowWithDetails } from './TableRowWithDetails';

interface IProps<T> {
    values: T[] | undefined;
    getKey: (inst: T) => string;
    headers: IHeader<T>[];
    padding: number;
    expandableContent?: (value: T) => JSX.Element;
    expandMultiple: boolean;
    expandAll: boolean;
}

export const TableValues = <T extends unknown>({ values, getKey, headers, padding, expandableContent, expandMultiple, expandAll }: IProps<T>) => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    useEffect(() => {
        setExpandedKeys(expandAll ? values?.map(t => getKey(t)) ?? [] : []);
    // eslint-disable-next-line
    }, [values, expandAll]);

    const onToggleExpand = (key: string) => {
        if (expandMultiple) {
            setExpandedKeys(toggleSelect(key, expandedKeys));
        } else if (expandedKeys.indexOf(key) !== -1) {
            setExpandedKeys([]);
        } else {
            setExpandedKeys([key]);
        }
    }

    return (
        <TableBody>
            {values && values.map((value, index) =>
                <TableRowWithDetails<T>
                    key={getKey(value)}
                    value={value}
                    index={index}
                    headers={headers}
                    padding={padding}
                    expandableContent={expandableContent && expandableContent(value)}
                    toggleExpand={() => onToggleExpand(getKey(value))}
                    isExpanded={expandedKeys.indexOf(getKey(value)) !== -1}
                    isLast={isLast(value, values)} />
            )}
        </TableBody>
    );
}
