import { CardProps, Divider } from '@material-ui/core';
import { LabelsFilterComponent } from 'app/main/labels/LabelsFilterComponent';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { DateFilterComponent } from 'framework/filter/date/DateFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { ISalesQueryMetadata, ISalesQueryParams, SalesType, SalesTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SalesFilterDataContext } from '../SalesFilterDataContext';
import { SalesListPrefilter } from './SalesListPrefilter';

interface IProps extends CardProps {
	params: ISalesQueryParams;
	onFilter: (params: ISalesQueryParams) => void;
	onClear: VoidFunction;
	isFiltered: boolean;
	metadata: ISalesQueryMetadata;
}

export const SalesListFilterCard = ({ params, onFilter, onClear, metadata, style, isFiltered, ...rest }: IProps) => {
	const strings = useLocalization();
	const [filterData] = useCacheContext(SalesFilterDataContext);

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: 500 }}>{strings.filter}</span>
			</div>
			<SalesListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
				metadata={metadata}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFiltered === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<DateFilterComponent
					label={strings.date}
					after={params.dateAfter}
					before={params.dateBefore}
					setSelected={(after, before) => onFilter({ ...params, dateAfter: after, dateBefore: before })}
				/>
				<RecordListFilterComponent<SalesType>
					options={SalesTypes}
					selected={params.types as any}
					setSelected={vals => onFilter({ ...params, types: vals })}
					label={strings.type}
				/>
				<BooleanRadioFilterComponent
					trueText={strings.yes}
					falseText={strings.no}
					selected={params.isInvoiceSent}
					setSelected={val => onFilter({ ...params, isInvoiceSent: val })}
					label={`Invoice sent?`}
				/>
				<BooleanRadioFilterComponent
					trueText={strings.yes}
					falseText={strings.no}
					selected={params.isReminderSent}
					setSelected={val => onFilter({ ...params, isReminderSent: val })}
					label={`Reminder sent?`}
				/>
				<LabelsFilterComponent
					selected={params.labelIds}
					setSelected={val => onFilter({ ...params, labelIds: val })}
					label='Labels'
				/>
				<BooleanRadioFilterComponent
					trueText={strings.yes}
					falseText={strings.no}
					selected={params.hasLabels}
					setSelected={val => onFilter({ ...params, hasLabels: val })}
					label={`Has labels?`}
				/>
				{filterData && (
					<>
						<ListFilterComponent
							options={filterData.billingAccounts}
							selected={params.billingAccountIds}
							setSelected={vals => onFilter({ ...params, billingAccountIds: vals })}
							label={`Billing Accounts`}
						/>
					</>
				)}
			</div>
		</CardX>
	);
};
