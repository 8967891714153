import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { clientsQuery_buildSummaries, ICreateUpdateScheduledTaskRequest, updateScheduledTasksCommand_newDefaultWindow } from 'gen/ApiClient';
import { ClientsTable } from './ClientsTable';
import { IClientBuildSummaryWithSelection } from './IClientBuildSummaryWithSelection';
import { FormIntegerField } from 'framework/forms/FormIntegerField';

const createSchema = () => {
	return yup
		.object<ICreateUpdateScheduledTaskRequest>({
			clientIds: yup.mixed(),
			executeAfter: yup.date().required(),
			windowAfterHour: yup.number().required(),
			windowBeforeHour: yup.number().required(),
		})
		.defined();
};

const stepsRecord: Record<number, (keyof ICreateUpdateScheduledTaskRequest)[]> = {
	0: ['executeAfter'],
	1: ['clientIds'],
	2: ['windowAfterHour', 'windowBeforeHour'],
};

interface IProps extends DialogProps {
	confirm: () => void;
	cancel: () => void;
}

export const UpdateScheduledTaskForm = ({ confirm, cancel, ...rest }: IProps) => {
	const notify = useSnackbarNotify();
	const [create, isSubmitting] = useFormSubmit(updateScheduledTasksCommand_newDefaultWindow);
	const [step, setStep] = useState<number>(0);
	const [allClients] = useApiEffect(clientsQuery_buildSummaries);
	const fClients = useMemo(() => allClients?.filter(t => t.isRunningLatestBuild === false), [allClients]);
	const [clients, setClients] = useState<IClientBuildSummaryWithSelection[]>([]);
	const schema = useMemo(() => createSchema(), []);

	useEffect(() => {
		if (fClients !== undefined) {
			setClients(fClients.map<IClientBuildSummaryWithSelection>(t => ({ isSelected: true, ...t })));
		}
	}, [fClients]);

	const handleSubmit = async (values: ICreateUpdateScheduledTaskRequest, helpers: FormikHelpers<ICreateUpdateScheduledTaskRequest>) => {
		const r = await create({ ...values, clientIds: clients.filter(t => t.isSelected).map(t => t.id!) });
		handleValidateResponseBase(
			r,
			e => notify(e, 'error'),
			() => confirm()
		);
	};

	if (fClients === undefined) {
		return <div></div>;
	}

	return (
		<Formik<ICreateUpdateScheduledTaskRequest>
			validateOnMount
			initialValues={{
				clientIds: [],
				executeAfter: new Date(),
				windowAfterHour: 1,
				windowBeforeHour: 4,
			}}
			validationSchema={schema}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					scroll='paper'
					{...rest}>
					<DialogTitleWithFormStepper
						title={`Create new Update TASK`}
						labels={['Date', 'Clients', 'Window']}
						step={step}
					/>
					<DialogContent
						className='df-col'
						dividers
						style={{ padding: step === 1 ? '0px' : '8px 24px' }}>
						{step === 0 && (
							<>
								<FormDatePicker<ICreateUpdateScheduledTaskRequest>
									name='executeAfter'
									label={`Execute after`}
									disablePast
									required
								/>
							</>
						)}
						{step === 1 && (
							<ClientsTable
								clients={clients}
								setClients={setClients}
							/>
						)}
						{step === 2 && (
							<>
								<FormIntegerField<ICreateUpdateScheduledTaskRequest>
									name='windowAfterHour'
									label='Window After Hour'
								/>
								<FormIntegerField<ICreateUpdateScheduledTaskRequest>
									name='windowBeforeHour'
									label='Window Before Hour'
								/>
							</>
						)}
					</DialogContent>
					<PageableFormDialogActions
						step={step}
						setStep={setStep}
						cancel={cancel}
						isSubmitting={isSubmitting}
						submitText={`Clients (${clients.filter(t => t.isSelected).length})`}
						schema={schema}
						stepsRecord={stepsRecord}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
