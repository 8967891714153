import { clearParams } from 'framework/utils/clearParams';
import { DateFilterComponent } from 'framework/filter/date/DateFilterComponent';
import { FilterBar } from 'framework/filter/FilterBar';
import { isFiltered } from 'framework/utils/isFiltered';
import { ISoldHearingAidsForClientQueryParams } from 'gen/ApiClient';
import React from 'react';

const exclusions: (keyof ISoldHearingAidsForClientQueryParams)[] = ['clientId'];

interface IProps {
	params: ISoldHearingAidsForClientQueryParams;
	onFilter: (params: ISoldHearingAidsForClientQueryParams) => void;
	paddingLeft: number;
}

export const SoldHearingAidsFilter = ({ params, onFilter, paddingLeft }: IProps) => {
	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterComponent
				label='Date sold'
				after={params.dateSoldAfter}
				before={params.dateSoldBefore}
				setSelected={(after, before) => onFilter({ ...params, dateSoldAfter: after, dateSoldBefore: before })}
			/>
		</FilterBar>
	);
};
