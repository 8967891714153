import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageX } from 'framework/components/layout/PageX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { salesQuery_singleWithDocuments } from 'gen/ApiClient';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CreateInvoiceDocumentRequestForm } from '../CreateInvoiceDocumentRequestForm';
import { SalesChips } from '../SalesChips';
import { SalesPageBreadcrumbs } from '../SalesPageBreadcrumbs';
import { SalesDetailContentsCard } from './SaleDetailContentsCard';
import { SalesDetailPageSidebar } from './SaleDetailPageSidebar';
import { SalesDetailOverviewHeaderCard } from './SalesDetailOverviewHeaderCard';
import { SaleDetailPaymentsCard } from './SaleDetailPaymentsCard';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const SalesDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [itemWithDocuments, reload] = useApiEffect(salesQuery_singleWithDocuments, id);
	const item = useMemo(() => itemWithDocuments?.sale, [itemWithDocuments]);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [hasRunOnce, setHasRunOnce] = useState<boolean>(false);

	useEffect(() => {
		if (hasRunOnce === false && item !== undefined && item.hasInvoiceDocument === false && item.type === 'Invoice' && item.id === id) {
			setHasRunOnce(true);
			open(
				<CreateInvoiceDocumentRequestForm
					open
					confirm={confirm}
					cancel={cancel}
					salesId={id}
				/>
			);
		}
		// eslint-disable-next-line
	}, [item, hasRunOnce]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<PageX
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<SalesPageBreadcrumbs />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={item.referenceOrId} />
						<SalesChips
							item={item}
							style={{ marginLeft: 4 }}
						/>
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}
			stickySidebar={
				<SalesDetailPageSidebar
					item={item}
					reload={reload}
					style={{ width: 250 }}
				/>
			}>
			<div
				className='df-col gap-16'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<SalesDetailOverviewHeaderCard
					item={item}
					reload={reload}
				/>
				<div className='df-row gap-16'>
					<SalesDetailContentsCard
						item={item}
						className='fg1 h-fc'
					/>
					<SaleDetailPaymentsCard
						item={item}
						reload={reload}
						className='fg1 h-fc'
						style={{ minWidth: 300 }}
					/>
				</div>
			</div>
		</PageX>
	);
};
