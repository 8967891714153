import { Button, Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext, useEffect } from 'react';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { Searchbar } from 'framework/components/Searchbar';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { PagebleTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createType } from 'framework/utils/createType';
import { IUpdatedScheduledTaskDto, updateScheduledTasksCommand_deletePlanned, updateScheduledTasksCommand_forceAutoCheck } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { Page } from 'framework/components/layout/Page';
import { NoRecordsFound } from 'shared/NoRecordsFound';
import { SelectColumnsButtonAndDialog } from 'shared/SelectColumnsButtonAndDialog';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { UstDialog } from './detail/UstDialog';
import { Filter } from './Filter';
import { UpdateScheduledTaskForm } from './forms/UpdateScheduledTaskForm';
import { SelectableColumn } from './SelectableColumn';
import { UstStatusChip } from './UstStatusChip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { AlertDialog } from 'framework/dialogs/AlertDialog';

const QuickFilters = createType('all', 'planned');
export type QuickFilter = (typeof QuickFilters)[number];

export const UpdateScheduledTasksListPage = () => {
	const theme = useTheme();
	const { params, setParams, queryResult, reload, init } = useContext(QueryContext);
	const { headers, columns, setColumns, columnsRecord, quickFilter, setQuickFilter, quickFilterRecord } = useContext(ViewContext);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useEffect(() => {
		init();
	}, [init]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<UpdateScheduledTaskForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDetails = (id: string) => {
		open(
			<UstDialog
				open
				id={id}
				close={cancel}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={'Delete Planned USTS'}
				content={`Do you want to delete ${queryResult.metadata.countPlanned} planned USTS?`}
				acceptText={`Yes, delete ${queryResult.metadata.countPlanned}`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => updateScheduledTasksCommand_deletePlanned({})}
				accept={confirm}
			/>
		);
	};

	const onForceAutoJob = () => {
		open(
			<AlertDialog
				open
				title={'Force AUTO update job'}
				content={`Do you want force auto update job?`}
				acceptText={`Yes`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => updateScheduledTasksCommand_forceAutoCheck()}
				accept={confirm}
			/>
		);
	};

	return (
		<Page
			showHome
			title='Update scheduled tasks'
			reload={reload}>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<Searchbar
					value={params.searchString}
					onSearch={text => setParams({ ...params, searchString: text, pageParams: { ...params.pageParams, index: 1 } })}
					placeholder='Search...'
					style={{ width: '400px' }}
					variant='paper'
				/>
				<div className='df-row-ac'>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onNew}
						style={{ marginRight: 8 }}>
						{`Create new task`}
					</Button>
					<Button
						variant='contained'
						color='secondary'
						// startIcon={<AddIcon />}
						onClick={onForceAutoJob}
						style={{ marginRight: 8 }}>
						{`Force AUTO job`}
					</Button>
					{queryResult.metadata.countPlanned > 0 && (
						<Button
							variant='outlined'
							color='secondary'
							startIcon={<DeleteOutlineIcon />}
							onClick={onDelete}>{`Delete ${queryResult.metadata.countPlanned} planned USTS`}</Button>
					)}
				</div>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<Card>
					<div
						className='df-row-ac jc-sb'
						style={{ padding: theme.spacing(1) }}>
						<ButtonGroupWithSelection<QuickFilter>
							options={QuickFilters}
							selected={quickFilter}
							setSelected={setQuickFilter}
							renderOption={t => quickFilterRecord[t]}
						/>
						<div>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
							/>
						</div>
					</div>
					<Divider />
					<div className='df-row jc-sb'>
						<Filter
							params={params}
							onFilter={p => setParams({ ...p, pageParams: { index: 1, size: params.pageParams.size } })}
							paddingLeft={theme.spacing(1.5)}
						/>
					</div>
					<Divider />
					<CardMedia>
						<PagebleTableWithColumnSelection<IUpdatedScheduledTaskDto, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={inst => inst.id}
							selectedColumns={columns}
							prependColumn={createEmptyHeader<IUpdatedScheduledTaskDto>('status', t => (
								<UstStatusChip item={t} />
							))}
							appendColumn={createEmptyHeader<IUpdatedScheduledTaskDto>(
								'details-header',
								t => (
									<SmallPrimaryTextButton onClick={() => onDetails(t.id)}>{`Details`}</SmallPrimaryTextButton>
								),
								'right'
							)}
						/>
					</CardMedia>
				</Card>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{`No updates scheduled tasks found`}</NoRecordsFound>}
		</Page>
	);
};
