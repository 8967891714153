import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext, useMemo } from "react";
import { RecordNavigationCardContent } from 'shared/cards/RecordNavigationCardContent';
import { NavigationContext } from './context/NavigationContext';
import { NavigationLink } from './NavigationLink';

const IconRecord: Record<NavigationLink, React.ReactNode> = {
    overview: <HelpOutlineIcon />,
    mtns: <HelpOutlineIcon />,
    rizivRefundableDevices: <HelpOutlineIcon />
}

export const NavigationCard = () => {
    const { link, setLink, record } = useContext(NavigationContext);
    const iconRecord = useMemo(() => IconRecord, []);

    return (
        <RecordNavigationCardContent
            record={record}
            iconRecord={iconRecord}
            link={link}
            setLink={setLink} />
    );
}
