import { TableRow, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { TableCellX } from 'framework/table/TableCellX';
import { IBillingAccount } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { calculateBillingAccountDetailRoute } from 'routes';
import { BillingAccountChips } from '../BillingAccountChips';
import { BillingAccountListItemStatusIcon } from './BillingAccountListItemStatusIcon';
import { mapBillingAccountToColor } from './utils';

const MinorFontSize = 13;

interface IProps {
	item: IBillingAccount;
}

export const BillingAccountTableRow = ({ item }: IProps) => {
	const color = useMemo(() => mapBillingAccountToColor(item), [item]);
	const tColor = useThemeColor(color);
	const theme = useTheme();
	const { push } = useHistory();
	const [isHovered, enter, leave] = useStateBoolean(false);

	const onViewDetails = () => push(calculateBillingAccountDetailRoute(item.id));

	return (
		<React.Fragment>
			<TableRow
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<TableCellX>
					<div className='df-row gap-4'>
						<BillingAccountListItemStatusIcon
							item={item}
							style={{ fontSize: 22, color: tColor }}
						/>
						<div className='df-col gap-2'>
							<TextLinkButton
								underline
								style={{ fontSize: 14 }}
								onClick={onViewDetails}>
								{item.name}
							</TextLinkButton>
							{item.alias && <span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{item.alias}</span>}
							<BillingAccountChips item={item} />
						</div>
					</div>
				</TableCellX>
				<TableCellX>
					<span>{item.vatNumber}</span>
				</TableCellX>
				<TableCellX>
					<span style={{ whiteSpace: 'pre-line' }}>{item.formattedAddressMultipleLine}</span>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-row-ac jc-e'>
						<ColorOnHoverIconButton
							size='small'
							edge='end'
							color='primary'
							onClick={onViewDetails}>
							<VisibilityOutlinedIcon />
						</ColorOnHoverIconButton>
					</div>
				</TableCellX>
			</TableRow>
		</React.Fragment>
	);
};
