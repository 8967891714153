import { CardProps } from '@material-ui/core';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { IReloadTriggerProps } from 'framework/components/layout/IReloadTriggerProps';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { IBillingAccount, billingAccountsCommand_importBd, billingAccountsCommand_removeEmail, billingAccountsQuery_businessData } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddBillingAccountEmailRequestForm } from '../AddBillingAccountEmailRequestForm';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';

const Width: number = 220;

interface IProps extends CardProps, IReloadTriggerProps {
	item: IBillingAccount;
	reload: VoidFunction;
}

export const BillingAccountDetailOverviewHeaderCard = ({ item, reload, reloadTrigger, ...rest }: IProps) => {
	const [businessData, reloadBusinessData] = useApiEffect(billingAccountsQuery_businessData, item.id);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadBusinessData();
	}, [reloadTrigger]);

	const onImport = () => {
		open(
			<AlertDialog
				open
				title='Import data from client'
				content='Do you want to import vat number and address from client?'
				acceptText='Yes!'
				rejectText='No, Go back'
				reject={cancel}
				acceptF={() => billingAccountsCommand_importBd({ clientId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onAddEmail = () => {
		open(
			<AddBillingAccountEmailRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				billingAccountId={item.id}
			/>
		);
	};

	const onDeleteEmail = (email: string) => {
		open(
			<AlertDialog
				open
				title='Delete e-mail'
				content={`Do you want to delete e-mail: '${email}' from this billing account?`}
				acceptText='Yes, delete!'
				rejectText='No, Go back'
				reject={cancel}
				acceptF={() => billingAccountsCommand_removeEmail({ email: email, id: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<CardX
			{...rest}
			className='df-row-wrap gap-16'>
			<div
				className='df-col gap-6'
				style={{ width: Width }}>
				<Typography16pxBold>{`Balance`}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX label='Total amount to pay'>{formatCurrency(item.amountToPay)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label='Total amount paid'>{formatCurrency(item.amountPaid)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label='Outstanding balance'>{formatCurrency(item.outstandingBalance)}</CaptionVerticalLabelledPropertyX>
			</div>
			<div className='df-col gap-6'>
				<Typography16pxBold>{`E-mail addresses`}</Typography16pxBold>
				<div className='df-col gap-2'>
					<TextLinkButton
						startIcon={<PersonAddOutlinedIcon />}
						onClick={onAddEmail}
						color='primary'
						hoverColor='primary'>
						{`Add e-mail adress`}
					</TextLinkButton>
					{item.emailAddresses.map(t => (
						<div
							className='df-row-ac gap-2'
							key={t}>
							<span style={{ fontSize: 12 }}>{`- ${t}`}</span>
							<ColorOnHoverIconButton
								edge='end'
								size='small'
								onClick={() => onDeleteEmail(t)}>
								<DeleteOutlineOutlinedIcon fontSize='small' />
							</ColorOnHoverIconButton>
						</div>
					))}
				</div>
			</div>
			{item.isAlsoHamsClient && businessData !== undefined && businessData !== null && (
				<div
					className='df-col gap-6'
					style={{ width: Width }}>
					<Typography16pxBold>{`Business data (info from HAMS)`}</Typography16pxBold>
					<CaptionVerticalLabelledPropertyX label='Company Name'>{businessData.companyName}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label='VAT number'>{businessData.vatNumber}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label='Address'>{businessData.formattedAddress}</CaptionVerticalLabelledPropertyX>
					<TextLinkButton
						startIcon={<ImportExportOutlinedIcon />}
						onClick={onImport}>
						{`Import this data`}
					</TextLinkButton>
				</div>
			)}
		</CardX>
	);
};
