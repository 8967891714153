import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";
import { ExtendedRadioGroup } from "framework/components/ExtendedRadioGroup";
import { useLocalization } from "localization/useLocalization";
import React, { useState } from 'react';
import { Mode } from "./Mode";

interface IProps extends DialogProps {
    cancel: () => void;
    confirm: (mode: Mode) => void;
}

export const ModeDialog = ({ cancel, confirm, ...rest }: IProps) => {
    const strings = useLocalization();
    const [mode, setMode] = useState<Mode>('identityPatch');

    return (
        <Dialog
            {...rest}>
            <DialogTitle>{`Select mode`}</DialogTitle>
            <DialogContent
                dividers>
                <ExtendedRadioGroup<Mode>
                    selected={mode}
                    setSelected={setMode}
                    options={[
                        { label: 'Identity Patch', value: 'identityPatch' },
                        { label: 'Remove invalid indices', value: 'removeIndices' }
                    ]} />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary">{strings.cancel}</Button>
                <Button onClick={() => confirm(mode)} color="primary">{strings.select}</Button>
            </DialogActions>
        </Dialog>
    );
}