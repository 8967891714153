import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { ILicenseDto, licensesCommand_delete } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LicensesRoute, calcLicenseDetailRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CreateLicenseRequestForm } from '../CreateLicenseRequestForm';
import { LicensePricingMethodRecord } from '../LicensePricingMethodRecord';
import { PatchLicenseRequestForm } from '../PatchLicenseRequestForm';
import { useNavigateToSubscriptionsOfLicense } from '../useNavigateToSubscriptionsOfLicense';

interface IProps {
	item: ILicenseDto;
	reload: VoidFunction;
}

export const LicenseDetailPageSidebar = ({ item, reload }: IProps) => {
	const { push } = useHistory();
	const onViewSubscriptions = useNavigateToSubscriptionsOfLicense();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEdit = () => {
		open(
			<PatchLicenseRequestForm
				open
				license={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onCopy = () => {
		open(
			<CreateLicenseRequestForm
				open
				license={item}
				confirm={id => {
					confirm();
					push(calcLicenseDetailRoute(id));
				}}
				cancel={cancel}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={'Delete license'}
				content={`Do you want to delete this license?`}
				acceptText={`Yes, delete`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => licensesCommand_delete({ id: item.id }) as any}
				accept={() => {
					cancel();
					push(LicensesRoute);
				}}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				minWidth: 250,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={`Name`}>{item.name}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={`Category`}>{item.category}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={`Pricing method`}>{LicensePricingMethodRecord[item.pricingMethod]}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={`Max. sold HA`}>{item.maxSoldHADisplay}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={`Max. reg. users`}>{item.maxRegisteredUsersDisplay}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`# Active subs.`}
					link={() => onViewSubscriptions(item.id)}>
					{item.countActiveSubscriptions}
				</CaptionVerticalLabelledPropertyX>
			</div>
			<DividerWithMargin marginTop={16} />
			<div className='fg1'></div>
			<DividerWithMargin />
			<div className='df-col gap-6'>
				<TextLinkButton
					startIcon={<EditOutlinedIcon />}
					onClick={onEdit}>
					{`Edit`}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<FileCopyOutlinedIcon />}
					onClick={onCopy}>
					{`Copy from this`}
				</TextLinkButton>
				<TextLinkButton
					startIcon={<DeleteOutlineOutlinedIcon />}
					onClick={onDelete}
					disabled={item.countActiveSubscriptions > 0}
					color='secondary'>
					{`Delete license`}
				</TextLinkButton>
			</div>
		</div>
	);
};
