import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TemplateSelectableColumn } from './TemplateSelectableColumn';
import { createTemplateHeaders } from './createTemplateHeaders';
import { ColumnRecord } from '../context/ColumnRecord';
import { TemplatesQueryContext } from './TemplatesQueryContext';
import { TemplatesQuickFilterRecord } from './TemplatesQuickFilterRecord';
import { TemplatesViewContext } from './TemplatesViewContext';
import { TemplatesQuickFilterToRootTemplateTypeRecord } from './TemplatesQuickFilterToRootTemplateTypeRecord';
import { TemplatesQuickFilter } from './TemplatesQuickFilter';

// enkel hier nodig
const DefaultColumns: TemplateSelectableColumn[] = ['created', 'name', 'predefinedType', 'mainContext'];

export const TemplatesViewProvider = ({ children }: any) => {
	const [quickFilter, setQuickFilter] = useState<TemplatesQuickFilter>('all');
	const [columns, setColumns] = useState<TemplateSelectableColumn[]>(DefaultColumns);
	const headers = useMemo(() => createTemplateHeaders(ColumnRecord), []);
	const { setParams } = useContext(TemplatesQueryContext);

	useEffect(() => {
		setParams(params => ({
			...params,
			rootTemplateType: TemplatesQuickFilterToRootTemplateTypeRecord[quickFilter],
			pageParams: { ...params.pageParams, index: 1 },
		}));
		// eslint-disable-next-line
	}, [quickFilter]);

	return (
		<TemplatesViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: ColumnRecord,
				headers: headers,
				setColumns: setColumns,
				quickFilter: quickFilter,
				setQuickFilter: setQuickFilter,
				quickFilterRecord: TemplatesQuickFilterRecord,
			}}>
			{children}
		</TemplatesViewContext.Provider>
	);
};
