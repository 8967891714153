import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormIntegerField } from 'framework/forms/FormIntegerField';
import { FormTextField } from 'framework/forms/FormTextField';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ISoftware, ISoftwareModel, softwaresCommand_update } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<ISoftwareModel>({
			build: yup.number().required(),
			semanticVersion: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	name: string;
	software: ISoftware | undefined;
	confirm: () => void;
	cancel: () => void;
}

export const SoftwareForm = ({ name, software, confirm, cancel, ...rest }: IProps) => {
	const [submit, isSubmitting] = useFormSubmit(softwaresCommand_update);

	const handleSubmit = async (values: ISoftwareModel, helpers: FormikHelpers<ISoftwareModel>) => {
		const r = await submit(name, values);
		if (r.isSuccess) {
			confirm();
		}
	};

	return (
		<Formik<ISoftwareModel>
			validateOnMount
			initialValues={{
				build: software?.build ?? 0,
				semanticVersion: software?.semanticVersion ?? '1.0.0',
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>{`Edit software: ${name}`}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormIntegerField<ISoftwareModel>
							name='build'
							label='Build'
							required
						/>
						<FormTextField<ISoftwareModel>
							name='semanticVersion'
							label='Semantic version'
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<ISoftwareModel>
						cancel={cancel}
						submitText='Update'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
