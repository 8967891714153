import { Checkbox, Dialog, DialogContent, DialogProps, DialogTitle, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragAndDropList } from 'framework/components/DragAndDropList';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { Try } from 'framework/Try';
import { ExportFormat, IExportParams, IFileResponse, INamedProperty } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';

interface IProps<TFilterParams, TProp extends string> extends DialogProps {
    close: () => void;
    params: TFilterParams;
    necessaryProps: TProp[];
    downloadF: (args: TFilterParams & IExportParams) => Promise<Try<IFileResponse>>;
    record: Record<TProp, string>;
    allProps: TProp[];
    fileName: string;
    mustApplyFilter: boolean;
    title: string;
    format: ExportFormat;
}

const toNamedProperties = <TProp extends string>(ordered: TProp[], props: TProp[], record: Record<TProp, string>): INamedProperty[] => {
    return ordered.filter(t => props.indexOf(t) > - 1).map<INamedProperty>(t => ({ property: t, text: record[t] }));
}

export const ExportSelectPropsDialog = <TParams, TProp extends string>({ title, close, params, downloadF, record, allProps, necessaryProps, fileName, mustApplyFilter, format, ...rest }: IProps<TParams, TProp>) => {
    const strings = useLocalization();
    const [exportToXlsx, isExporting] = useDownload(downloadF);
    const [ordered, setOrdered] = useState<TProp[]>(allProps);
    const [selectedProps, setSelectedProps] = useState<TProp[]>(allProps);

    useEffect(() => {
        setOrdered(_.orderBy(allProps, t => record[t]));
    }, [allProps, record]);

    const onExport = async () => {
        const r = await exportToXlsx({ ...params, fileName: fileName, headers: toNamedProperties(ordered, selectedProps, record), mustApplyFilter: mustApplyFilter, format: format });
        if (r.isSuccess) {
            close();
        }
    }

    return (
        <Dialog
            {...rest}
            scroll="paper"
            fullWidth>
            <DialogTitle>
                <div>{title}</div>
            </DialogTitle>
            <DialogContent dividers style={{ padding: '0px' }}>
                <ListItem dense button onClick={() => selectedProps.length === allProps.length ? setSelectedProps(necessaryProps) : setSelectedProps(allProps)}>
                    <ListItemIcon>
                        <Checkbox
                            indeterminate={selectedProps.length > 0 && selectedProps.length < allProps.length}
                            checked={selectedProps.length === allProps.length} />
                    </ListItemIcon>
                    <ListItemText primary={selectedProps.length === allProps.length ? strings.selectNone : strings.selectAll} />
                </ListItem>
                <Divider variant="middle" />
                <DragAndDropList<TProp>
                    ordered={ordered}
                    setOrdered={setOrdered}
                    selected={selectedProps}
                    setSelected={setSelectedProps}
                    getKey={t => t}
                    getValue={t => record[t]} />
            </DialogContent>
            <CancelSubmitDialogActions
                cancel={close}
                onSubmit={onExport}
                submitText={strings.exportVerb}
                isSubmitting={isExporting}
                variantSubmitButton="outlined" />
        </Dialog>
    );
}