import React from 'react';
import { IHeader } from './IHeader';

export const calculateCellStyle = <T extends unknown>(padding: number, header: IHeader<T> | undefined = undefined, removeBottomBorder: boolean = false): React.CSSProperties => {
    let x: React.CSSProperties = {
        padding: padding,
    }
    if (header !== undefined) {
        x = {...x, ...header.cellStyle};
    } 
    if (removeBottomBorder) {
        x = {...x, borderBottom: 'none'};
    }
    return x;
    // return removeBottomBorder ? { padding: padding, borderBottom: 'none', ...header?.cellStyle} : { padding: padding, ...header?.cellStyle };
}