import { addYears } from 'date-fns';
import { StatCard } from 'framework/components/StatCard';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { getNow } from 'framework/utils/getNow';
import { ISoldHearingAidsQueryMetadata } from 'gen/ApiClient';
import React from 'react';

const Now = getNow();
const Width: number = 250;

interface IProps {
	item: ISoldHearingAidsQueryMetadata;
}

export const ClientDetailSoldHearingAidsStats = ({ item }: IProps) => {
	return (
		<div
			className='df-row'
			style={{ marginBottom: 16 }}>
			<StatCard
				style={{ width: Width, marginRight: 16 }}
				title={`Previous year ${formatDateX(addYears(Now, -2), 'yyyy')}`}
				description={`# sold hearing aids`}
				value={item.countSoldYearMinus2.toString()}
			/>
			<StatCard
				style={{ width: Width, marginRight: 16 }}
				title={`Previous year ${formatDateX(addYears(Now, -1), 'yyyy')}`}
				description={`# sold hearing aids`}
				value={item.countSoldYearMinus1.toString()}
			/>
			<StatCard
				style={{ width: Width, marginRight: 16 }}
				title={`This year ${formatDateX(Now, 'yyyy')}`}
				description={`# sold hearing aids`}
				value={item.countSoldThisYear.toString()}
			/>
		</div>
	);
};
