import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { ClientsPrefilter, ClientsPrefilters } from 'gen/ApiClient';
import React from 'react';

const TitleRecord: Record<ClientsPrefilter, string> = {
	All: 'All',
	NotLatestBuild: '< latest build',
	NotRunning: 'Not running',
};

interface IProps {
	selectedPrefilter: ClientsPrefilter;
	setSelectedPrefilter: (key: ClientsPrefilter) => void;
	style?: React.CSSProperties;
}

export const ClientsListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, style }: IProps) => {
	return (
		<div
			className='df-col'
			style={style}>
			{ClientsPrefilters.map(c => (
				<RadioIsSelectedButton
					key={c}
					isSelected={selectedPrefilter === c}
					onClick={() => setSelectedPrefilter(c)}>
					{TitleRecord[c]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
