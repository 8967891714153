import { CacheProvider as FrameworkCacheProvider } from 'framework/context/CacheProvider';
import { billingAccountsQuery_all, billingAccountsQuery_concurrencyToken, IBillingAccount } from 'gen/ApiClient';
import React from 'react';
import { BillingAccountsCacheContext } from './BillingAccountsCacheContext';

export const BillingAccountsCacheProvider = ({ children }: any) => {
	return (
		<FrameworkCacheProvider<IBillingAccount[]>
			concurrencyF={billingAccountsQuery_concurrencyToken}
			loadF={billingAccountsQuery_all}
			context={BillingAccountsCacheContext}>
			{children}
		</FrameworkCacheProvider>
	);
};
