import React, { useEffect } from 'react';
import { useIntervalTrigger } from '../hooks/useIntervalTrigger';

interface IReloadTriggerComponentProps {
    reload: () => void;
    timeOutMs?: number;
}

export const IntervalTriggerComponent = ({ reload, timeOutMs }: IReloadTriggerComponentProps) => {
    const intervalTrigger = useIntervalTrigger(timeOutMs);

    useEffect(() => {
        reload();
        // eslint-disable-next-line
    }, [intervalTrigger]);

    return (<div></div>)
}