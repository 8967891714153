import React, { useContext } from 'react';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { useTrigger } from 'framework/hooks/useTrigger';
import { Page } from 'framework/components/layout/Page';
import { NavigationContext } from './context/NavigationContext';
import { MtnsPage } from './mtn/MtnsPage';
import { NavigationCard } from './NavigationCard';
import { DataOverviewPage } from './overview/DataOverviewPage';
import { RizivHearingAidDefinitionsV2ListPage } from './rizivHearingAidDefinitionsV2/list/RizivHearingAidDefinitionsV2ListPage';
import { ShowHideNavigationButton } from './ShowHideNavigationButton';

export const Data = () => {
	const { link, record } = useContext(NavigationContext);
	const [trigger, hitTrigger] = useTrigger();
	const [isExpanded, toggle] = useStateToggleBoolean(true);

	return (
		<Page
			showHome
			title={record[link]}
			secondaryTitle={
				<ShowHideNavigationButton
					onClick={toggle}
					isExpanded={isExpanded}
				/>
			}
			reload={hitTrigger}
			nav={isExpanded ? <NavigationCard /> : undefined}
			detailsFixedWidth={isExpanded ? 300 : 30}>
			<>
				{link === 'overview' && <DataOverviewPage reloadTrigger={trigger} />}
				{link === 'mtns' && <MtnsPage reloadTrigger={trigger} />}
				{link === 'rizivRefundableDevices' && <RizivHearingAidDefinitionsV2ListPage reloadTrigger={trigger} />}
			</>
		</Page>
	);
};
