import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { SuccessLabelledProperty } from 'framework/components/labelledProperty/SuccessLabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { IClientDetailDto } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	client: IClientDetailDto;
	reload: () => void;
}

export const PublishPage = ({ client, reload }: IProps) => {
	// const [publish, isPublishing] = useFormSubmit(publishCommand_copy);

	// const onPublish = async () => {
	// 	await publish({ clientId: client.id });
	// 	reload();
	// };

	return (
		<div className='df-col'>
			<Card style={{ marginBottom: 16 }}>
				<CardContent>
					<CardTitleTypography title='Build info' />
					<LabelledProperty
						label='Installed build'
						property={client.buildNumber!.toString()}
					/>
					{client.isRunningLatestBuild ? <SuccessLabelledProperty successMessage='Up-to-date!' /> : <WarningLabelledProperty warning='New version available' />}
				</CardContent>
			</Card>
			<Card style={{ marginBottom: 16 }}>
				<CardContent>
					<CardTitleTypography title='Publish' />
					<div className='df-row-ac'>
						{/* <SmallPrimaryTextLoaderButton
							isLoading={isPublishing}
							startIcon={<PublishOutlinedIcon />}
							onClick={onPublish}>
							{`Publish! (copy source to target)`}
						</SmallPrimaryTextLoaderButton> */}
					</div>
				</CardContent>
			</Card>
		</div>
	);
};
