import { Button, ListItem, useTheme } from "@material-ui/core";
import React from 'react';

export const commonNavLinkStyle: React.CSSProperties = {
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: 400,
}

interface INavLinkProps {
    onNavigate: () => void;
    isMatch: boolean;
    startIcon: React.ReactNode;
    text: string;
    disabled?: boolean;
    withNotification?: boolean;
}

const borderWidth = 2;

export const CardNavigationLink = ({ isMatch, onNavigate, startIcon, text, disabled = false, withNotification = false }: INavLinkProps) => {
    const theme = useTheme();

    return (
        <ListItem
            disableGutters
            component="li"
            onClick={disabled === true ? undefined : onNavigate}
            disabled={disabled}
            style={{ padding: '0px', borderLeft: isMatch ? `${borderWidth}px solid ${theme.palette.primary.main}` : 'none' }}>
            <Button
                fullWidth
                variant="text"
                style={{
                    ...commonNavLinkStyle,
                    color: isMatch ? theme.palette.primary.main : withNotification ? theme.palette.secondary.main : theme.palette.text.primary,
                    padding: theme.spacing(0.5),
                    paddingLeft: isMatch ? `${18 - borderWidth}px` : '18px'
                }}
                startIcon={startIcon}
                disabled={disabled}>
                {text}
            </Button>
        </ListItem>
    );
}
