import { differenceInHours, differenceInMinutes } from 'date-fns';
import { getNow } from 'framework/utils/getNow';
import { IStrings } from 'localization/IStrings';

export const formatLastActivity = (date: Date, strings: IStrings): string => {
	const now = getNow();
	const diffInMinutes = differenceInMinutes(now, new Date(date));
	const diffInHours = differenceInHours(now, new Date(date));
	if (diffInMinutes < 3) {
		return strings.activeNow;
	} else if (diffInMinutes < 60) {
		return strings.minutesAgo(Math.round(diffInMinutes));
	} else if (diffInHours < 24) {
		return strings.hoursAgo(Math.round(diffInHours));
	} else {
		return strings.moreThanOneDayAgo;
	}
};
