import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { SmallPrimaryTextButton } from './buttons/SmallPrimaryTextButton';

export const ActionCardButton = (props: ButtonProps) => (
	<SmallPrimaryTextButton
		style={{ marginLeft: '-5px' }}
		{...props}
	/>
);
