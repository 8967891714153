import { Divider } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ILicenseDto } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calcLicenseDetailRoute } from 'routes';
import { LicensePricingMethodRecord } from './LicensePricingMethodRecord';
import { LicensePricingMethodUnitDescriptionRecord } from './LicensePricingMethodUnitDescriptionRecord';
import { useNavigateToSubscriptionsOfLicense } from './useNavigateToSubscriptionsOfLicense';

interface IProps {
	item: ILicenseDto;
	hidePricing?: boolean;
	hideName?: boolean;
	hideLimits?: boolean;
}

export const LicenseCardContent = ({ item, hidePricing, hideName, hideLimits }: IProps) => {
	const onViewSubscriptions = useNavigateToSubscriptionsOfLicense();
	const { push } = useHistory();

	return (
		<div
			className='df-col gap-4'
			style={{ minWidth: 120 }}>
			{hideName !== true && (
				<TextLinkButton
					startIcon={<VerifiedUserOutlinedIcon />}
					onClick={() => push(calcLicenseDetailRoute(item.id))}
					// color='primary'
					underline
					style={{ fontSize: 14, marginLeft: 'auto', marginRight: 'auto', marginBottom: 8, marginTop: 16 }}>
					{item.name}
				</TextLinkButton>
			)}
			{hidePricing !== true && (
				<>
					<CaptionVerticalLabelledPropertyX label={`Pricing Method`}>{LicensePricingMethodRecord[item.pricingMethod]}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label={`Fee`}>
						{`${formatCurrency(item.currentFeeValue)} (${LicensePricingMethodUnitDescriptionRecord[item.pricingMethod]})`}
					</CaptionVerticalLabelledPropertyX>
					<Divider />
				</>
			)}
			{hideLimits !== true && (
				<>
					<CaptionVerticalLabelledPropertyX label={`Max. sold HA's`}>{item.maxSoldHADisplay}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label={`Max. reg. users`}>{item.maxRegisteredUsersDisplay}</CaptionVerticalLabelledPropertyX>
					<Divider />
				</>
			)}
			<CaptionVerticalLabelledPropertyX
				label={`# Active subs`}
				link={() => onViewSubscriptions(item.id)}>
				{item.countActiveSubscriptions}
			</CaptionVerticalLabelledPropertyX>
		</div>
	);
};
