import { ChipX } from 'framework/components/chips/ChipX';
import { SubscriptionType } from 'gen/ApiClient';
import React from 'react';

interface ISubscriptionX {
	type: SubscriptionType;
}

interface IProps {
	item: ISubscriptionX;
	style?: React.CSSProperties;
}

export const SubscriptionTypeChip = ({ item, style }: IProps) => {
	return (
		<ChipX
			style={style}
			label={item.type.toString()}
			color={item.type === 'History' ? 'info' : item.type === 'Future' ? 'warning' : 'primary'}
		/>
	);
};
