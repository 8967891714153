import Card, { CardProps } from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import HttpOutlinedIcon from '@material-ui/icons/HttpOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { ExpandCollapseManagedComponent } from 'framework/components/ExpandCollapseManagedComponent';
import { SuccessLabelledProperty } from 'framework/components/labelledProperty/SuccessLabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import {
	clientsSetupCommand_createAzureContainer,
	clientsSetupCommand_createDatabase,
	clientsSetupCommand_createDnsRecord,
	clientsSetupCommand_createEverything,
	clientsSetupCommand_createPublishDirectory,
	clientsSetupCommand_createSettingsFile,
	clientsSetupCommand_createSite,
	clientsSetupCommand_createSslCert,
	clientsSetupQuery_get,
} from 'gen/ApiClient';
import React from 'react';
import { CodeBlock } from 'shared/CodeBlock';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';

interface IProps extends CardProps {
	clientId: string;
	reloadTrigger: Trigger;
	reload: VoidFunction;
}

export const ClientSetupCard = ({ clientId, reloadTrigger, reload, ...rest }: IProps) => {
	const [item, reloadSetup] = useApiEffect(clientsSetupQuery_get, clientId);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadSetup();
	}, [reloadTrigger]);

	const onCreateSite = () => {
		open(
			<AlertDialog
				open
				title={'Create site'}
				content={`Do you want to create site '${item?.siteName}'?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsSetupCommand_createSite({ clientId: clientId })}
				accept={confirm}
			/>
		);
	};

	const onCreateDatabase = () => {
		open(
			<AlertDialog
				open
				title={'Create database'}
				content={`Do you want to create db '${item?.ravenDbName}'?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsSetupCommand_createDatabase({ clientId: clientId })}
				accept={confirm}
			/>
		);
	};

	const onCreateDirectory = () => {
		open(
			<AlertDialog
				open
				title={'Create dir'}
				content={`Do you want to dir '${item?.directory}'?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsSetupCommand_createPublishDirectory({ clientId: clientId })}
				accept={confirm}
			/>
		);
	};

	const onCreateSettingsFile = () => {
		open(
			<AlertDialog
				open
				title={'Create setttings file'}
				content={`Do you want to create settings file?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsSetupCommand_createSettingsFile({ clientId: clientId })}
				accept={confirm}
			/>
		);
	};

	const onCreateARecord = () => {
		open(
			<AlertDialog
				open
				title={'Create A record'}
				content={`Do you want to create A record for ${item?.httpsUrl}?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsSetupCommand_createDnsRecord({ clientId: clientId })}
				accept={confirm}
			/>
		);
	};

	const onCreateAzureContainer = () => {
		open(
			<AlertDialog
				open
				title={'Create Azure Container'}
				content={`Do you want to create azure container: ${item?.azureContainerName}?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsSetupCommand_createAzureContainer({ clientId: clientId })}
				accept={confirm}
			/>
		);
	};

	const onCreateSslCert = () => {
		open(
			<AlertDialog
				open
				title={'Create SSL cert'}
				content={`Do you want to create SSL cert. for: ${item?.httpsUrl}?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsSetupCommand_createSslCert({ clientId: clientId })}
				accept={confirm}
			/>
		);
	};

	const onCreateEverything = () => {
		open(
			<AlertDialog
				open
				title={'Create Everyting'}
				content={`Do you want to create everything at once for ${item?.name}?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsSetupCommand_createEverything({ clientId: clientId })}
				accept={confirm}
			/>
		);
	};

	if (item === undefined) {
		return <></>;
	}

	return (
		<Card {...rest}>
			<CardContent className='df-col-as'>
				<CardTitleTypography title='Setup' />
				<SmallPrimaryTextButton
					startIcon={<DnsOutlinedIcon />}
					onClick={onCreateEverything}>
					{`Create everything at once...`}
				</SmallPrimaryTextButton>
				{item.aRecordCreated && <SuccessLabelledProperty successMessage={`A record for '${item.httpsUrl}' created`} />}
				{item.aRecordCreated === false && (
					<SmallPrimaryTextButton
						startIcon={<DnsOutlinedIcon />}
						onClick={onCreateARecord}>
						{`Create A record`}
					</SmallPrimaryTextButton>
				)}
				{item.databaseCreated && <SuccessLabelledProperty successMessage={`Database '${item.ravenDbName}' created`} />}
				{item.databaseCreated === false && (
					<SmallPrimaryTextButton
						startIcon={<StorageOutlinedIcon />}
						onClick={onCreateDatabase}
						disabled={item.databaseCreated}>
						{`Create database`}
					</SmallPrimaryTextButton>
				)}
				{item.directoryCreated && <SuccessLabelledProperty successMessage={`Dir '${item.directory}' created`} />}
				{item.directoryCreated === false && (
					<SmallPrimaryTextButton
						startIcon={<CreateNewFolderOutlinedIcon />}
						onClick={onCreateDirectory}
						disabled={item.directoryCreated}>
						{`Create directory`}
					</SmallPrimaryTextButton>
				)}
				{item.settingsFileCreated && <SuccessLabelledProperty successMessage={`Settings-file created`} />}
				{item.settingsFileCreated === false && (
					<SmallPrimaryTextButton
						startIcon={<DescriptionOutlinedIcon />}
						onClick={onCreateSettingsFile}
						disabled={item.settingsFileCreated}>
						{`Create settings file`}
					</SmallPrimaryTextButton>
				)}
				{item.siteCreated && <SuccessLabelledProperty successMessage={`Site '${item.siteName}' created`} />}
				{item.siteCreated === false && (
					<SmallPrimaryTextButton
						startIcon={<HttpOutlinedIcon />}
						onClick={onCreateSite}
						disabled={item.siteCreated}>
						{`Create site`}
					</SmallPrimaryTextButton>
				)}
				{item.azureContainerCreated && <SuccessLabelledProperty successMessage={`AZ Container '${item.azureContainerName}' created`} />}
				{item.azureContainerCreated === false && (
					<SmallPrimaryTextButton
						startIcon={<HttpOutlinedIcon />}
						onClick={onCreateAzureContainer}
						disabled={item.azureContainerCreated}>
						{`Create AZ container`}
					</SmallPrimaryTextButton>
				)}
				{item.sslBindingCreated && <SuccessLabelledProperty successMessage={`SSL binding & cert. '${item.httpsUrl}' created`} />}
				{item.sslBindingCreated === false && (
					<SmallPrimaryTextButton
						startIcon={<HttpOutlinedIcon />}
						onClick={onCreateSslCert}
						disabled={item.sslBindingCreated}>
						{`Create SSL binding`}
					</SmallPrimaryTextButton>
				)}
				<ExpandCollapseManagedComponent>
					<CodeBlock value={item} />
				</ExpandCollapseManagedComponent>
			</CardContent>
		</Card>
	);
};
