import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { IBillingAccount } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calcClientDetailRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatchBillingAccountAddressRequestForm } from '../PatchBillingAccountAddressRequestForm';
import { PatchBillingAccountAliasRequestForm } from '../PatchBillingAccountAliasRequestForm';
import { PatchBillingAccountVatNumberRequestForm } from '../PatchBillingAccountVatNumberRequestForm';
import { BillingAccountDetailActionsContent } from './BillingAccountDetailActionsContent';

interface IProps {
	item: IBillingAccount;
	reload: VoidFunction;
}

export const BillingAccountDetailPageSidebar = ({ item, reload }: IProps) => {
	const { push } = useHistory();
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onPatchAddress = () => {
		open(
			<PatchBillingAccountAddressRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				billingAccount={item}
			/>
		);
	};

	const onPatchVatNumber = () => {
		open(
			<PatchBillingAccountVatNumberRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onPatchAlias = () => {
		open(
			<PatchBillingAccountAliasRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={`Name`}>{item.name}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`Alias`}
					edit={onPatchAlias}>
					<StringValueOrNotApplicable>{item.alias}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`VAT number`}
					edit={onPatchVatNumber}>
					<StringValueOrNotApplicable>{item.vatNumber}</StringValueOrNotApplicable>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`Address`}
					edit={onPatchAddress}>
					<span style={{ whiteSpace: 'pre' }}>{item.formattedAddressMultipleLine}</span>
				</CaptionVerticalLabelledPropertyX>
				{item.isAlsoHamsClient && (
					<CaptionVerticalLabelledPropertyX
						label={`Client`}
						link={() => push(calcClientDetailRoute(item.clientId!))}>
						{`HAMS client!`}
					</CaptionVerticalLabelledPropertyX>
				)}
			</div>
			<div className='fg1'></div>
			<BillingAccountDetailActionsContent
				item={item}
				reload={reload}
			/>
		</div>
	);
};
