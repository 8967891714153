import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { LightBulbNotification } from 'framework/components/LightBulbNotification';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectFieldFromTypes } from 'framework/forms/FormSelectFieldFromTypes';
import { handleValidateResponseBase } from 'framework/forms/utils/handleValidateResponseBase';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	billingAccountsQuery_single,
	IBillingAccount,
	InvoiceEmailTemplateMode,
	ISendInvoiceRequest,
	salesCommand_send,
	salesQuery_getInvoiceHtmlBodyForMode,
} from 'gen/ApiClient';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { InvoiceTemplateModeRecord } from './InvoiceTemplateModeRecord';
import { FormTextField } from 'framework/forms/FormTextField';
import { useApiCall } from 'framework/hooks/useApiCall';

const createSchema = () => {
	return yup
		.object<ISendInvoiceRequest>({
			templateMode: yup.mixed<InvoiceEmailTemplateMode>().required(),
			salesId: yup.string().required(),
			htmlContent: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	salesId: string;
	billingAccountId: string;
	confirm: () => void;
	cancel: VoidFunction;
}

export const SendInvoiceRequestForm = ({ salesId, billingAccountId, confirm, cancel, ...rest }: IProps) => {
	const [billingAccount] = useApiEffect(billingAccountsQuery_single, billingAccountId);
	const [send, isSubmitting] = useFormSubmit(salesCommand_send);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: ISendInvoiceRequest, helpers: FormikHelpers<ISendInvoiceRequest>) => {
		const r = await send({ ...values, salesId: salesId });
		handleValidateResponseBase(
			r,
			e => notify(e, 'error'),
			() => {
				confirm();
				notify('Invoice succesfully sent', 'success');
			}
		);
	};

	if (billingAccount === undefined) {
		return <></>;
	}

	return (
		<Formik<ISendInvoiceRequest>
			validateOnMount
			initialValues={{
				templateMode: 'HAMSRecurring',
				salesId: salesId,
				htmlContent: '',
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					cancel={cancel}
					isSubmitting={isSubmitting}
					salesId={salesId}
					billingAccount={billingAccount}
					{...rest}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	salesId: string;
	billingAccount: IBillingAccount;
}

const InnerDialog = ({ cancel, isSubmitting, salesId, billingAccount, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<ISendInvoiceRequest>();
	const getContent = useApiCall(salesQuery_getInvoiceHtmlBodyForMode);

	useEffect(() => {
		console.log('changed mode');
		const load = async (mode: InvoiceEmailTemplateMode) => {
			const r = await getContent(salesId, mode);
			if (r.isSuccess) {
				props.setFieldValue('htmlContent', r.result);
			}
		};
		if (props.values.templateMode) {
			load(props.values.templateMode);
		}
		// eslint-disable-next-line
	}, [props.values.templateMode]);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='md'
			fullWidth>
			<DialogTitle>
				<div>{`Send invoice via email`}</div>
			</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<LightBulbNotification style={{ marginBottom: 16, whiteSpace: 'pre-line' }}>{`Invoice will be sent to: ${billingAccount.emailAddresses.join(
					'\n'
				)}`}</LightBulbNotification>
				<FormSelectFieldFromTypes<ISendInvoiceRequest, InvoiceEmailTemplateMode>
					name='templateMode'
					label='Template (sent via)'
					record={InvoiceTemplateModeRecord}
				/>
				<FormTextField<ISendInvoiceRequest>
					name='htmlContent'
					label='Content'
					multiline
					required
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions<ISendInvoiceRequest>
				cancel={cancel}
				submitText='Send'
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
