import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResultWithMetadata,
	IUpdatedScheduledTaskDto,
	IUpdateScheduledTaskQueryMetadata,
	IUpdateScheduledTaskQueryParams,
	updateScheduledTasksQuery_concurrencyToken,
	updateScheduledTasksQuery_query,
} from 'gen/ApiClient';
import React from 'react';
import { updateScheduledTasksQueryParamsLsKey } from '../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: IUpdateScheduledTaskQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'executeAfter' },
	pageParams: { index: 1, size: 10 },
	clientIds: [],
	createdAfter: undefined,
	createdBefore: undefined,
	executeAfterAfter: undefined,
	executeAfterBefore: undefined,
	keyFilterStatus: undefined as any,
	statuses: [],
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<IUpdatedScheduledTaskDto, IUpdateScheduledTaskQueryMetadata>, IUpdateScheduledTaskQueryParams>
			defaultParams={DefaultParams}
			queryF={updateScheduledTasksQuery_query}
			concurrencyF={updateScheduledTasksQuery_concurrencyToken}
			localStorageKey={updateScheduledTasksQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
