import { PageBreadcrumb } from 'framework/components/breadcrumbs/PageBreadcrumb';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { SalesRoute } from 'routes';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';

export const SalesPageBreadcrumbs = () => {
	const strings = useLocalization();

	return (
		<div className='df-row-ac'>
			<PageBreadcrumbTitle
				title={strings.financial}
				icon={<AttachMoneyIcon />}
			/>
			<PageBreadcrumbDivider />
			<PageBreadcrumb
				text={strings.sales}
				route={SalesRoute}
			/>
		</div>
	);
};
