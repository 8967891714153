import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormSelectFieldOnId } from 'framework/forms/FormSelectFieldOnId';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	documentsCommand_createFromTemplate,
	DocumentType,
	HamsRootLinkViewContextType,
	ICreateDocumentFromTemplateRequest,
	ITemplateDto,
	templatesQuery_rootTemplatesByType,
} from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<ICreateDocumentFromTemplateRequest>({
			documentType: yup.string().required(),
			linkViewContextId: yup.string().required(),
			linkViewContextType: yup.string().required(),
			templateId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	documentType: DocumentType;
	linkViewContextType: HamsRootLinkViewContextType;
	linkViewContextId: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CreateDocumentFromTemplateRequestForm = ({ documentType, linkViewContextType, linkViewContextId, confirm, cancel, ...rest }: IProps) => {
	const [templates] = useApiEffect(templatesQuery_rootTemplatesByType, documentType);
	const [create, isSubmitting] = useFormSubmit(documentsCommand_createFromTemplate);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: ICreateDocumentFromTemplateRequest, helpers: FormikHelpers<ICreateDocumentFromTemplateRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers)) {
			confirm(r.result.objectId);
			notify(`Document succesfully created`, 'success');
		}
	};

	if (templates === undefined) {
		return <></>;
	}

	return (
		<Formik<ICreateDocumentFromTemplateRequest>
			validateOnMount
			initialValues={{
				documentType: documentType,
				linkViewContextId: linkViewContextId,
				linkViewContextType: linkViewContextType,
				templateId: '',
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Create new document from template`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormSelectFieldOnId<ICreateDocumentFromTemplateRequest, ITemplateDto>
							name='templateId'
							label={`Template`}
							options={templates}
							renderValue={t => t.name}
							getKey={t => t.id}
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<ICreateDocumentFromTemplateRequest>
						cancel={cancel}
						submitText='Create'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
