import { Chip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useMemo } from 'react';

interface IProps {
	label?: string;
	value: string | string[];
	onDelete: VoidFunction;
	withIcon?: boolean;
	outlined?: boolean;
}

export const FilterChipX = ({ label, value, onDelete, withIcon, outlined }: IProps) => {
	const valueDisplay = useMemo<string>(() => (Array.isArray(value) ? value.join(', ') : value), [value]);
	const display = useMemo(() => (label ? `${label}: ${valueDisplay}` : valueDisplay), [label, valueDisplay]);

	return (
		<Chip
			size='small'
			label={display}
			color='primary'
			variant={outlined === true ? 'outlined' : 'default'}
			icon={withIcon === true ? <SearchIcon /> : undefined}
			style={{
				width: 'fit-content',
				height: 20,
				fontSize: 12,
			}}
			onDelete={onDelete}
		/>
	);
};
