import { Card, CardMedia, Divider } from '@material-ui/core';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { Searchbar } from 'framework/components/Searchbar';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { IHeader } from 'framework/table/IHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { createType } from 'framework/utils/createType';
import { IClientDetailDto, ISubscriptionState, ISubscriptionStatesQueryParams, subscriptionStatesQuery_query } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NoRecordsFound } from 'shared/NoRecordsFound';
import { DefaultParams } from './DefaultParams';

const QuickFilters = createType('all', 'outdated');
export type QuickFilter = (typeof QuickFilters)[number];

const QuickFilterRecord: Record<QuickFilter, string> = {
	all: 'All',
	outdated: 'Not up-to-date',
};

const createHeaders = (): IHeader<ISubscriptionState>[] => {
	return [createSortableHeader<ISubscriptionState>('Name', 'name', t => t.name), createSortableHeader<ISubscriptionState>('Pos', 'pos', t => t.pos.toString())];
};

interface IProps {
	client: IClientDetailDto;
	reloadTrigger: Trigger;
}

export const SubscriptionStatesPage = ({ client, reloadTrigger }: IProps) => {
	const strings = useLocalization();
	const [params, setParams] = useState<ISubscriptionStatesQueryParams>(DefaultParams);
	const queryF = useCallback((params: ISubscriptionStatesQueryParams) => subscriptionStatesQuery_query(client.id, params), [client.id]);
	const [queryResult, reloadQr] = useApiEffect(queryF, params);
	const [quickFilter, setQuickFilter] = useState<QuickFilter>('all');
	const headers = useMemo(() => createHeaders(), []);

	useEffect(() => {
		setParams(prev => ({ ...prev, isUpToDate: quickFilter === 'outdated' ? false : undefined }));
	}, [quickFilter]);

	useLazyEffect(() => {
		reloadQr();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<div className='fg1'>
			<div
				className='df-row-ac jc-sb'
				style={{ marginBottom: '24px' }}>
				<Searchbar
					value={params.searchString}
					onSearch={text => setParams({ ...params, searchString: text, pageParams: { ...params.pageParams, index: 1 } })}
					placeholder={strings.searchVerb}
					style={{ width: '400px' }}
					variant='paper'
				/>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<>
					<Card>
						<div
							className='df-row'
							style={{ padding: 16 }}>
							<ButtonGroupWithSelection<QuickFilter>
								options={QuickFilters}
								selected={quickFilter}
								setSelected={setQuickFilter}
								renderOption={t => QuickFilterRecord[t]}
							/>
							<div className='fg1'></div>
						</div>
						<Divider />
						<CardMedia>
							<PageableTable<ISubscriptionState>
								queryResult={queryResult}
								headers={headers}
								sortParams={params.sortParams}
								pageParams={params.pageParams}
								setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
								getKey={inst => inst.name!}
							/>
						</CardMedia>
					</Card>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{`Geen states gevonden`}</NoRecordsFound>}
		</div>
	);
};
