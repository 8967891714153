import { CardProps, Divider } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import QueueOutlinedIcon from '@material-ui/icons/QueueOutlined';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISubscription, ISubscriptionAdditionalCost, subscriptionsCommand_deleteAdditionalCost } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AddSubscriptionAdditionalCostRequestForm } from '../AddSubscriptionAdditionalCostRequestForm';
import { PatchSubscriptionAdditionalRequestForm } from '../PatchSubscriptionAdditionalRequestForm';

interface IProps extends CardProps {
	item: ISubscription;
	reload: VoidFunction;
}

export const SubscriptionDetailAdditionalCostsCardX = ({ item, reload, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onAdd = () => {
		open(
			<AddSubscriptionAdditionalCostRequestForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onPatch = (cost: ISubscriptionAdditionalCost) => {
		open(
			<PatchSubscriptionAdditionalRequestForm
				open
				item={cost}
				subscriptionId={item.id}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelete = (cost: ISubscriptionAdditionalCost) => {
		open(
			<AlertDialog
				open
				title={'Delete additional cost'}
				content={`Do you want to delete this additional cost?`}
				acceptText={'Yes'}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => subscriptionsCommand_deleteAdditionalCost({ subscriptionId: item.id, additionalCostId: cost.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<CardX
			{...rest}
			className='df-col gap-6'>
			<Typography16pxBold>{`Additional Costs`}</Typography16pxBold>
			<TextLinkButton
				color='primary'
				hoverColor='primary'
				onClick={onAdd}
				startIcon={<QueueOutlinedIcon />}>
				{`Add additional cost`}
			</TextLinkButton>
			{item.additionalCosts.length > 0 && <Divider />}
			{item.additionalCosts.map(t => (
				<div
					className='df-row-ac gap-4'
					key={t.id}>
					<span>{`${t.description}: ${formatCurrency(t.amountExVat)}`}</span>
					<ColorOnHoverIconButton
						edge='end'
						size='small'
						onClick={() => onPatch(t)}>
						<EditOutlinedIcon fontSize='small' />
					</ColorOnHoverIconButton>
					<ColorOnHoverIconButton
						edge='end'
						size='small'
						onClick={() => onDelete(t)}
						color='secondary'>
						<DeleteOutlineOutlinedIcon fontSize='small' />
					</ColorOnHoverIconButton>
				</div>
			))}
		</CardX>
	);
};
