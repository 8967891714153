import { CircularProgress, IconButton, Paper, TextField } from '@material-ui/core';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import React, { useEffect, useMemo, useState } from 'react';
import { CompactPicker } from 'react-color';
import { useMapLabelColor } from './useMapLabelColor';

interface IValues {
	name: string | undefined;
	caption: string | undefined;
	color: string | undefined;
}

interface IProps {
	item: IValues;
	onSave: (values: IValues) => void;
	onClear: VoidFunction;
	isSubmitting: boolean;
	style?: React.CSSProperties;
}

export const CreateOrPatchLabelComponent = ({ item, onSave, onClear, isSubmitting, style }: IProps) => {
	const [values, setValues] = useState<IValues>(item);
	const xColor = useMapLabelColor(item);
	const isDirty = useMemo<boolean>(() => item.name !== values?.name || item.caption !== values?.caption || xColor !== values?.color, [item, values, xColor]);

	useEffect(() => {
		setValues(item);
	}, [item]);

	return (
		<Paper
			elevation={6}
			className='df-row gap-8'
			style={{
				marginBottom: 8,
				marginRight: -8,
				padding: 16,
				...style,
			}}>
			<CompactPicker
				color={values.color}
				onChange={e => setValues({ ...values, color: e.hex })}
			/>
			<div className='df-col gap-2'>
				<TextField
					size='small'
					variant='filled'
					label='Name'
					value={values.name}
					onChange={e => setValues({ ...values, name: e.target.value })}
				/>
				<TextField
					size='small'
					variant='filled'
					label='Caption'
					value={values.caption}
					onChange={e => setValues({ ...values, caption: e.target.value })}
				/>
			</div>
			<div className='fg1'></div>
			<div className='df-row-ac gap-2'>
				<IconButton
					size='small'
					onClick={() => onSave(values)}
					color={isDirty ? 'secondary' : 'default'}
					disabled={isSubmitting}>
					{isSubmitting === true && (
						<CircularProgress
							style={{ color: 'inherit' }}
							size={18}
						/>
					)}
					{isSubmitting === false && <SaveOutlinedIcon fontSize='small' />}
				</IconButton>
				<IconButton
					size='small'
					onClick={() => {
						setValues(item);
						onClear();
					}}
					disabled={isSubmitting}
					color='primary'>
					<BackspaceOutlinedIcon fontSize='small' />
				</IconButton>
			</div>
		</Paper>
	);
};
