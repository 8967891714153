import { Paper } from '@material-ui/core';
import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import React, { useMemo } from 'react';
import LineIndexDragAndDelete from '../../../../framework/components/dnd/LineIndexDragAndDelete';
import LineIndexDragAndSelect from '../../../../framework/components/dnd/LineIndexDragAndSelect';

interface ILineX {
	isSelected?: boolean;
	description: string | undefined;
	unitPrice?: number;
}

interface ILineXComponentProps<TLine extends ILineX> extends IDraggableItem {
	index: number;
	onDelete: VoidFunction | undefined;
	line: TLine;
	setLine: (val: TLine) => void;
	children: any;
}

export const LineXComponent = <TLine extends ILineX>({ line, setLine, index, onDelete, dragHandleProps, children }: ILineXComponentProps<TLine>) => {
	const xIsSelected = useMemo(() => line.isSelected === undefined || line.isSelected === true, [line.isSelected]);

	return (
		<Paper
			variant='outlined'
			style={{ padding: 8 }}>
			<div className='df-row'>
				{onDelete === undefined && line.isSelected !== undefined && (
					<LineIndexDragAndSelect
						index={index}
						dragHandleProps={dragHandleProps}
						isSelected={line.isSelected}
						toggleSelect={() => setLine({ ...line, isSelected: !line.isSelected })}
						isDiscount={line.unitPrice !== undefined && line.unitPrice < 0}
						hideIndex={line.isSelected === false}
						style={{ marginRight: 16 }}
					/>
				)}
				{onDelete !== undefined && (
					<LineIndexDragAndDelete
						index={index}
						dragHandleProps={dragHandleProps}
						onDelete={onDelete}
						isDiscount={line.unitPrice !== undefined && line.unitPrice < 0}
						style={{ marginRight: 16 }}
					/>
				)}
				{xIsSelected === true && <div className='df-col fg1'>{children}</div>}
				{xIsSelected === false && (
					<div
						className='fg1'
						style={{ fontStyle: 'italic', marginTop: 6 }}>
						{line.description}
					</div>
				)}
			</div>
		</Paper>
	);
};
