import { QueryProvider } from 'framework/context/QueryProvider';
import { IQueryResultWithMetadata, ISale, ISalesQueryMetadata, ISalesQueryParams, salesQuery_concurrencyToken, salesQuery_query } from 'gen/ApiClient';
import React from 'react';
import { salesQueryParamsLsKey } from '../../../localStorageKeys';
import { SalesListQueryContext } from './SalesListQueryContext';

const DefaultSalesQueryParams: ISalesQueryParams = {
	searchString: '',
	sortParams: { direction: 'desc', property: 'date' },
	pageParams: { index: 1, size: 10 },
	dateAfter: undefined,
	dateBefore: undefined,
	isInvoiceSent: undefined,
	isReminderSent: undefined,
	labelIds: [],
	billingAccountIds: [],
	prefilter: 'All',
	searchMode: 'AllFields',
	types: [],
	includeExceptionalInStats: false,
	hasLabels: undefined,
};

export const SalesListQueryProvider = ({ children }: { children: any }) => {
	return (
		<QueryProvider<IQueryResultWithMetadata<ISale, ISalesQueryMetadata>, ISalesQueryParams>
			defaultParams={DefaultSalesQueryParams}
			queryF={salesQuery_query}
			concurrencyF={salesQuery_concurrencyToken}
			localStorageKey={salesQueryParamsLsKey}
			context={SalesListQueryContext}>
			{children}
		</QueryProvider>
	);
};
