import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IAddSubscriptionAdditionalCostRequest, ISubscription, subscriptionsCommand_addAdditionalCost } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IAddSubscriptionAdditionalCostRequest>({
			amountExVat: yup.number().required(),
			description: yup.string().required(),
			subscriptionId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	item: ISubscription;
	confirm: () => void;
	cancel: VoidFunction;
}

export const AddSubscriptionAdditionalCostRequestForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [add, isSubmitting] = useFormSubmit(subscriptionsCommand_addAdditionalCost);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IAddSubscriptionAdditionalCostRequest, helpers: FormikHelpers<IAddSubscriptionAdditionalCostRequest>) => {
		const r = await add(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`Subscription add. cost added`, 'success');
		}
	};

	return (
		<Formik<IAddSubscriptionAdditionalCostRequest>
			validateOnMount
			initialValues={{
				amountExVat: 0,
				description: '',
				subscriptionId: item.id,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Add additional cost to subscription`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IAddSubscriptionAdditionalCostRequest>
							name='description'
							label='Description'
							required
						/>
						<FormMoneyField<IAddSubscriptionAdditionalCostRequest>
							name='amountExVat'
							label='Amount (ex.VAT)'
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IAddSubscriptionAdditionalCostRequest>
						cancel={cancel}
						submitText='Add'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
