import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { IBillingAccount } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IBillingAccount;
	style?: React.CSSProperties;
}

export const BillingAccountListItemStatusIcon = ({ item, style }: IProps) => {
	if (item.hasMissingFields) {
		return <ErrorOutlineOutlinedIcon style={style} />;
	} else if (item.hasOutstandingBalance) {
		return <MonetizationOnOutlinedIcon style={style} />;
	} else {
		return <BlurCircularOutlinedIcon style={style} />;
	}
};
