import { formatDateXLocalized } from 'framework/utils/date/formatDateXLocalized';
import { IStrings } from 'localization/IStrings';
import { LanguageOption } from 'localization/LanguageOption';
import { formatDate } from '../../utils/date/formatDate';
import { formatDateX } from '../../utils/date/formatDateX';
import { DateRangeType } from './DateRangeType';
import { IDateRange } from './IDateRange';
import { LanguageOptionToLocaleRecord } from 'localization/LanguageOptionToLocaleRecord';

export const DateRangeRenderDisplayRecord: Record<DateRangeType, (val: IDateRange, strings: IStrings, language: LanguageOption) => string> = {
	unset: () => ``,
	'all-after': val => `> ${formatDate(val.after)}`,
	'all-before': val => `< ${formatDate(val.before)}`,
	custom: val => `${formatDate(val.after)} - ${formatDate(val.before)}`,
	day: val => formatDateX(val.after, 'd MMMM y'),
	week: (val, strings) => `${strings.week} ${formatDateX(val.after, 'I')} (${formatDateX(val.after)} - ${formatDateX(val.before)})`,
	month: (val, strings, language) => formatDateXLocalized(val.after, LanguageOptionToLocaleRecord[language], 'LLLL y'),
	quarter: val => formatDateX(val.after, 'QQQ y'),
	year: val => formatDateX(val.after, 'yyyy'),
};
