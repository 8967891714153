import { create, RichEdit } from 'devexpress-richedit';
import 'devexpress-richedit/dist/dx.richedit.css';
import { DocumentFormatApi } from 'devexpress-richedit/lib/model-api/formats/enum';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import React, { useEffect, useRef } from 'react';
import { createRteOptions } from './utils/createRteOptions';

interface IProps {
    data: string | undefined;
    richEdit: RichEdit | undefined;
    setRichEdit: React.Dispatch<React.SetStateAction<RichEdit | undefined>>;
    setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
    isReadOnly: boolean;
}

export const Rte = ({ data, richEdit, setRichEdit, setHasChanges, isReadOnly }: IProps) => {
    const ref = useRef<HTMLDivElement>(undefined as any);

    useEffect(() => {
        if (richEdit) {
            if (data === undefined || data === '') {
                richEdit.newDocument();
            } else {
                richEdit.openDocument(data, 'test', DocumentFormatApi.OpenXml);
            }
            richEdit.readOnly = isReadOnly;
        }
    }, [richEdit, data, isReadOnly]);

    useEffect(() => {
        const temp = create(ref.current, createRteOptions());
        temp.events.documentChanged.addHandler((s, e) => {
            if (s?.document.modified) {
                setHasChanges(true);
            }
        });
        setRichEdit(temp);
        // safe functions
        // eslint-disable-next-line
    }, []);

    return (<div ref={ref}></div>)
}