import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { IClientDto, ISortParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	sortParams: ISortParams<IClientDto>;
	onFilter: (sp: ISortParams<IClientDto>) => void;
	isLoadedWithDetails: boolean;
}

export const ClientsListOrderBySelect = ({ sortParams, onFilter, isLoadedWithDetails }: IProps) => {
	const strings = useLocalization();
	const [anchor, open, close] = useAnchorElement();
	const title = React.useMemo(() => {
		if (sortParams.property === 'name') {
			return `${strings.name} (${sortParams.direction === 'asc' ? `A-Z` : `Z-A`})`;
		} else if (sortParams.property === 'buildNumber') {
			return `Build number (${sortParams.direction === 'asc' ? 'lowest -> highest' : 'highest -> lowest'})`;
		} else if (sortParams.property === 'eventStorePos') {
			return `ES pos (${sortParams.direction === 'asc' ? 'lowest -> highest' : 'highest -> lowest'})`;
		} else if (sortParams.property === 'lastNonAuthEvent') {
			return `Last non auth event (${sortParams.direction === 'asc' ? 'oldest -> newest' : 'newest -> oldest'})`;
		} else {
			return strings.unknown;
		}
	}, [strings, sortParams]);

	const onSelect = (property: keyof IClientDto, direction: 'asc' | 'desc') => {
		onFilter({ property: property, direction: direction });
		close();
	};

	return (
		<>
			<Button
				size='small'
				endIcon={<ArrowDropDownIcon />}
				onClick={open}>
				{`Sort on ${title}`}
			</Button>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted>
				<MenuItem onClick={() => onSelect('name', 'asc')}>{`Sort on name (A-Z)`}</MenuItem>
				<MenuItem onClick={() => onSelect('name', 'desc')}>{`Sort on name (Z-A)`}</MenuItem>
				<MenuItem onClick={() => onSelect('buildNumber', 'asc')}>{`Sort on build-number (lowest -> highest)`}</MenuItem>
				<MenuItem onClick={() => onSelect('buildNumber', 'desc')}>{`Sort on build-number (highest -> lowest)`}</MenuItem>
				{isLoadedWithDetails && (
					<>
						<MenuItem onClick={() => onSelect('eventStorePos', 'asc')}>{`ES pos (lowest -> highest)`}</MenuItem>
						<MenuItem onClick={() => onSelect('eventStorePos', 'desc')}>{`ES pos (highest -> lowest)`}</MenuItem>
						<MenuItem onClick={() => onSelect('lastNonAuthEvent', 'asc')}>{`Last non auth event (oldest -> newest)`}</MenuItem>
						<MenuItem onClick={() => onSelect('lastNonAuthEvent', 'desc')}>{`Last non auth event (newest -> oldest)`}</MenuItem>
					</>
				)}
			</Menu>
		</>
	);
};
