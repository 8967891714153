import { build } from '../build';

export const hamsUserInfoLsKey = 'hams-root-auth-user';
export const clientsQueryParamsLsKey = 'hams-root-clients-qp-' + build;
export const salesQueryParamsLsKey = 'hams-root-sales-qp-' + build;
export const licensesQueryParamsLsKey = 'hams-root-licenses-qp-' + build;
export const billingAccountsQueryParamsLsKey = 'hams-root-billing-accounts-qp-' + build;
export const subscriptionsQueryParamsLsKey = 'hams-root-subsriptions-qp-' + build;
export const updateScheduledTasksQueryParamsLsKey = 'hams-root-updated-sched-tasks-qp-' + build;
export const templatesQueryParamsLsKey = 'hams-root-templates-qp-' + build;
export const notificationsQueryParamsLsKey = 'hams-root-notifications-qp-' + build;
export const mtnQueryParamsLsKey = 'hams-root-mt-qp-' + build;
export const rizivRefundableDevicesQueryParamsLsKey = 'hams-root-riziv-ref-dev-qp-' + build;
