import { Radio, RadioGroup } from "@material-ui/core";
import React from 'react';
import { FormControlLabelWithCaption } from "./FormControlLabelWithCaption";
import { ILabelCaptionValue } from "./ILabelCaptionValue";

interface IProps<T> {
    options: ILabelCaptionValue<T>[];
    selected: T;
    setSelected: (val: T) => void;
    disabled?: boolean;
    style?: React.CSSProperties;
}

export const ExtendedRadioGroup = <T extends unknown>({ options, selected, setSelected, disabled = false, style }: IProps<T>) => {

    const onChange = (val: string) => {
        if (val === 'true') {
            setSelected(true as T);
        } else if (val === 'false') {
            setSelected(false as T);
        } else {
            setSelected(val as T)
        }
    }

    return (
        <RadioGroup value={selected} style={style}>
            {options.map(t =>
                <FormControlLabelWithCaption
                    key={t.label}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onChange(t.value as any)}
                    control={<Radio />}
                    value={t.value}
                    label={t.label}
                    caption={t.caption}
                    disabled={disabled || t.disabled} />)}
        </RadioGroup>
    );
}

    //onChange={(_, val) => onChange(val)}