import { ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { FilterOutlineIcon } from 'framework/components/icons/FilterOutlineIcon';
import { FolderZipOutlineIcon } from 'framework/components/icons/FolderZipOutlineIcon';
import { createRecordFromStrings } from 'framework/utils/record/createRecordFromStrings';
import {
	IQueryResult,
	ISale,
	ISalesFilterParams,
	ISalesQueryParams,
	SaleExportProp,
	SaleExportProps,
	salesQuery_export,
	salesQuery_exportLines,
	salesQuery_exportToZip,
} from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import React, { useMemo } from 'react';
import { TextLinkExportButtonMenuAndDialog } from 'shared/export/TextLinkExportButtonMenuAndDialog';

interface IProps {
	queryResult: IQueryResult<ISale>;
	params: ISalesQueryParams;
}

export const SalesListExportButtonMenu = ({ queryResult, params }: IProps) => {
	const [exportToZip] = useDownload(salesQuery_exportToZip);
	const [exportLines] = useDownload(salesQuery_exportLines);
	const exportRecord = useMemo(() => createRecordFromStrings(SaleExportProps), []);

	const onExportToZip = async () => {
		await exportToZip(params);
	};

	const onExportLines = async () => {
		await exportLines();
	};

	return (
		<>
			<TextLinkExportButtonMenuAndDialog<ISalesFilterParams, SaleExportProp>
				params={params}
				filteredCount={queryResult.filteredCount}
				unfilteredCount={queryResult.unfilteredCount}
				downloadF={salesQuery_export}
				fileName={`sales`}
				record={exportRecord}
				allProps={SaleExportProps}>
				<MenuItem onClick={onExportLines}>
					<ListItemIcon>
						<GetAppOutlinedIcon fontSize='small' />
					</ListItemIcon>
					<Typography variant='inherit'>{`Export all lines to .xlsx`}</Typography>
				</MenuItem>
				<MenuItem onClick={onExportToZip}>
					<ListItemIcon>
						<FilterOutlineIcon fontSize='small' />
						<FolderZipOutlineIcon fontSize='small' />
					</ListItemIcon>
					<Typography variant='inherit'>{`Export invoices to .zip`}</Typography>
				</MenuItem>
			</TextLinkExportButtonMenuAndDialog>
		</>
	);
};
