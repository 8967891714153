import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext, useEffect } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { FilterBar } from 'framework/filter/FilterBar';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { IRizivHearingAidDefinitionsV2QueryParams } from 'gen/ApiClient';
import { FilterDataContext } from './FilterDataContext';

const exclusions: (keyof IRizivHearingAidDefinitionsV2QueryParams)[] = ['searchMode'];

interface IProps {
	params: IRizivHearingAidDefinitionsV2QueryParams;
	onFilter: (params: IRizivHearingAidDefinitionsV2QueryParams) => void;
	paddingLeft: number;
}

export const FilterRizivHearingAidDefinitionsV2 = ({ params, onFilter, paddingLeft }: IProps) => {
	const { data, init } = useContext(FilterDataContext);

	useEffect(() => {
		init();
	}, [init]);

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<StringListFilterComponent
				options={data.manufacturers}
				selected={params.manufacturers}
				setSelected={vals => onFilter({ ...params, manufacturers: vals })}
				label='Manufacturers'
				icon={<HelpOutlineIcon />}
			/>
			<StringListFilterComponent
				options={data.distributors}
				selected={params.distributors}
				setSelected={vals => onFilter({ ...params, distributors: vals })}
				label='Distributors'
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
