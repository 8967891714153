import { AppBar, createStyles, Drawer, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as routes from '../routes';
import { theme } from '../theme';
import { AppAuthenticatedWall } from './AppAuthenticatedWall';
import { AppBarContent } from './AppBarContent';
import { AppProviders } from './AppProviders';
import { LoggedInUserProvider } from './auth/LoggedInUserProvider';
import { BillingAccountDetailPageX } from './main/billingAccounts/detail/BillingAccountDetailPageX';
import { BillingAccountsListPage } from './main/billingAccounts/list/BillingAccountsListPage';
import { ClientDetailPage } from './main/clients/detail/ClientDetailPage';
import { ClientsListPage } from './main/clients/list/ClientsListPage';
import { Dashboard } from './main/dashboard/Dashboard';
import { Data } from './main/data/Data';
import { LicenseDetailPage } from './main/licenses/detail/LicenseDetailPage';
import { LicensesPage } from './main/licenses/LicensesPage';
import { Notifications } from './main/notifications/Notifications';
import { SalesDetailPage } from './main/sales/detail/SalesDetailPage';
import { SalesListPage } from './main/sales/list/SalesListPage';
import { SoftwarePage } from './main/software/SoftwarePage';
import { SubscriptionDetailPage } from './main/subscriptions/detail/SubscriptionDetailPage';
import { SubscriptionsListPage } from './main/subscriptions/list/SubscriptionsListPage';
import { TemplatesListPage } from './main/templates/list/TemplatesListPage';
import { UpdateScheduledTasksListPage } from './main/updateScheduledTasks/UpdateScheduledTasksListPage';
import { WhiteListIpsPage } from './main/whiteListIps/WhiteListIpsPage';
import { SideNav } from './sideNav/SideNav';
import { TestPage } from './TestPage';
import { SalesSettingsPage } from './main/sales/SalesSettingsPage';
import { PreloadProvider } from './PreloadProvider';

const drawerWidth = 200;

const appBarStyle: React.CSSProperties = {
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
};

const appBarShiftStyle: React.CSSProperties = {
	...appBarStyle,
	width: `calc(100% - ${drawerWidth}px)`,
	marginLeft: drawerWidth,
};

const contentStyle: React.CSSProperties = {
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	height: 'calc(100% - 64px)',
};

const contentShiftStyle: React.CSSProperties = {
	...contentStyle,
	marginLeft: drawerWidth,
};

const useStyles = makeStyles(() =>
	createStyles({
		drawerPaper: {
			width: drawerWidth,
		},
	})
);

export const AppWrapper = () => {
	const classes = useStyles();
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

	return (
		<AppAuthenticatedWall>
			<LoggedInUserProvider>
				<PreloadProvider>
					<AppProviders>
						<div className='fg1 df-col stretch-ver'>
							<AppBar
								position='sticky'
								style={isDrawerOpen ? appBarShiftStyle : appBarStyle}>
								<AppBarContent
									toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
									isDrawerOpen={isDrawerOpen}
								/>
							</AppBar>
							<Drawer
								anchor='left'
								variant='persistent'
								open={isDrawerOpen}
								style={{ width: drawerWidth }}
								onClose={() => setIsDrawerOpen(false)}
								classes={{ paper: classes.drawerPaper }}>
								<SideNav onLinkClicked={() => {}} />
							</Drawer>
							<main
								style={isDrawerOpen ? contentShiftStyle : contentStyle}
								className='df-col'>
								<Switch>
									<Route
										exact
										path={routes.DashboardRoute}
										component={Dashboard}
									/>
									<Route
										exact
										path={routes.ClientsRoute}
										component={ClientsListPage}
									/>
									<Route
										exact
										path={routes.ClientDetailRoute}
										component={ClientDetailPage}
									/>
									<Route
										exact
										path={routes.UpdateScheduledTasksRoute}
										component={UpdateScheduledTasksListPage}
									/>
									<Route
										exact
										path={routes.DataRoute}
										component={Data}
									/>
									<Route
										exact
										path={routes.TemplatesRoute}
										component={TemplatesListPage}
									/>
									<Route
										exact
										path={routes.NotificationsRoute}
										component={Notifications}
									/>
									<Route
										exact
										path={routes.SoftwareRoute}
										component={SoftwarePage}
									/>
									<Route
										exact
										path={routes.WhiteListedIpsRoute}
										component={WhiteListIpsPage}
									/>
									<Route
										exact
										path={routes.LicensesRoute}
										component={LicensesPage}
									/>
									<Route
										exact
										path={routes.LicenseDetailRoute}
										component={LicenseDetailPage}
									/>
									<Route
										exact
										path={routes.BillingAccountsRoute}
										component={BillingAccountsListPage}
									/>
									<Route
										exact
										path={routes.BillingAccountDetailRoute}
										component={BillingAccountDetailPageX}
									/>
									<Route
										exact
										path={routes.SubscriptionsRoute}
										component={SubscriptionsListPage}
									/>
									<Route
										exact
										path={routes.SubscriptionDetailRoute}
										component={SubscriptionDetailPage}
									/>
									<Route
										exact
										path={routes.SalesRoute}
										component={SalesListPage}
									/>
									<Route
										exact
										path={routes.SalesSettingsRoute}
										component={SalesSettingsPage}
									/>
									<Route
										exact
										path={routes.SalesDetailRoute}
										component={SalesDetailPage}
									/>
									<Route
										exact
										path={`/test`}
										component={TestPage}
									/>
									<Redirect
										path='/'
										to={routes.DashboardRoute}
									/>
								</Switch>
							</main>
						</div>
					</AppProviders>
				</PreloadProvider>
			</LoggedInUserProvider>
		</AppAuthenticatedWall>
	);
};
