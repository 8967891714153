import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

interface IProps {
	text: string | undefined;
	setText: (text: string) => void;
	style?: React.CSSProperties;
}

export const EditableDescription = ({ text, setText, style }: IProps) => {
	const [isEditState, setEditState] = useState<boolean>(false);
	const [localState, setLocalState] = useState<string | undefined>(text);

	useEffect(() => {
		setLocalState(text);
	}, [text]);

	const onBlur = () => {
		if (localState !== text) {
			setText(localState ?? '');
		}
		setEditState(false);
	};

	if (isEditState) {
		return (
			<TextField
				className='fg1'
				style={style}
				value={localState}
				onChange={e => setLocalState(e.target.value)}
				onBlur={onBlur}
				multiline
			/>
		);
	} else {
		return (
			<div
				className='fg1'
				style={{ ...style, fontWeight: 'bold', cursor: 'pointer', whiteSpace: 'pre' }}
				onClick={() => setEditState(true)}>
				{text}
			</div>
		);
	}
};
