import React, { useContext, useEffect, useMemo, useState } from 'react';
import { QuickFilter } from '../MtnsPage';
import { SelectableColumn } from '../SelectableColumn';
import { createHeaders } from '../utils/createHeaders';
import { ColumnRecord } from './ColumnRecord';
import { QueryContext } from './QueryContext';
import { QuickFilterRecord } from './QuickFilterRecord';
import { ViewContext } from './ViewContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = ['code', 'descriptionNl', 'currentFeeValue', 'currentFeeContribution'];

export const ViewProvider = ({ children }: any) => {
    const [qf, setQf] = useState<QuickFilter>('all');    
    const headers = useMemo(() => createHeaders(ColumnRecord), []);
    const { setParams } = useContext(QueryContext);
    const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);

    useEffect(() => {
        setParams(params => ({
            ...params,
            isCurrentlyValid: qf === 'isCurrentlyValid' ? true : undefined,
            pageParams: { ...params.pageParams, index: 1 }
        }));
        // eslint-disable-next-line
    }, [qf]);

    return (
        <ViewContext.Provider
            value={{
                columns: columns,
                columnsRecord: ColumnRecord,
                headers: headers,
                setColumns: setColumns,
                quickFilter: qf,
                setQuickFilter: setQf,
                quickFilterRecord: QuickFilterRecord
            }}>
            {children}
        </ViewContext.Provider>
    );
}

