// auth
export const LoginRoute = '/login';

export const DashboardRoute = '/dashboard';
export const ClientsRoute = '/clients';
export const ClientDetailRoute = `${ClientsRoute}/:id`;
export const calcClientDetailRoute = (id: string) => ClientDetailRoute.replace(':id', id);
export const UpdateScheduledTasksRoute = '/usts';
export const DataRoute = '/data';
export const TemplatesRoute = '/templates';
export const NotificationsRoute = '/notifications';
export const SoftwareRoute = '/software';
export const WhiteListedIpsRoute = '/white-listed-ips';
export const LicensesRoute = '/licenses';
export const LicenseDetailRoute = `${LicensesRoute}/:id`;
export const calcLicenseDetailRoute = (id: string) => LicenseDetailRoute.replace(':id', id);
export const BillingAccountsRoute = '/billing-accounts';
export const BillingAccountDetailRoute = `${BillingAccountsRoute}/:id`;
export const calculateBillingAccountDetailRoute = (id: string) => BillingAccountDetailRoute.replace(':id', id);
export const SubscriptionsRoute = '/subscriptions';
export const SubscriptionDetailRoute = `${SubscriptionsRoute}/:id`;
export const calculateSubscriptionDetailRoute = (id: string) => SubscriptionDetailRoute.replace(':id', id);
export const SalesRoute = '/sales';
export const SalesSettingsRoute = `${SalesRoute}/settings`;
export const SalesDetailRoute = `${SalesRoute}/:id`;
export const calculateSalesDetailRoute = (id: string) => SalesDetailRoute.replace(':id', id);
