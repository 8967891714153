import { useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { ILabelledPropertyProps, LabelledProperty } from './LabelledProperty';

interface IProps extends ILabelledPropertyProps {
	successMessage: string;
}

export const SuccessLabelledProperty = ({ successMessage, ...rest }: IProps) => {
	const theme = useTheme();

	return (
		<LabelledProperty
			property={successMessage}
			icon={<CheckIcon style={{ color: theme.palette.success.main }} />}
			color='success'
			{...rest}
		/>
	);
};
