import { useApiEffectWithoutLoader } from 'framework/hooks/useApiEffectWithoutLoader';
import { labelsQuery_cache } from 'gen/ApiClient';
import React from 'react';
import { PreloadContext } from './PreloadContext';
import { PreloadWall } from './PreloadWall';

export const PreloadProvider = ({ children }: any) => {
	const [labels] = useApiEffectWithoutLoader(labelsQuery_cache);

	if (labels === undefined) {
		return <PreloadWall />;
	}

	return (
		<PreloadContext.Provider
			value={{
				labels: labels,
			}}>
			{children}
		</PreloadContext.Provider>
	);
};
