import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ILicenseSummary, ISubscription, LicensePricingMethod } from 'gen/ApiClient';
import { v1 as uuid } from 'uuid';
import { ISalesLineRequestX } from './ISalesLineRequestX';

export const mapSubscriptionToSalesLineRequestX = (subscription: ISubscription): ISalesLineRequestX => {
	return {
		canChangeQuantity: true,
		description: mapSubscriptionToDescription(subscription, subscription.license.pricingMethod),
		id: uuid(),
		quantity: mapSubscriptionToQuantity(subscription, subscription.license.pricingMethod),
		unitListPrice: subscription.license.fee,
		unitPrice: subscription.license.fee,
		vatPercentage: 21,
	};
};

const mapSubscriptionToQuantity = (subscription: ISubscription, pricingMethod: LicensePricingMethod) => {
	if (pricingMethod === 'FixedMonthlyFee') {
		return subscription.monthsInWindow;
	} else if (pricingMethod === 'PerSoldHearingAid') {
		return subscription.soldHearingAids;
	} else {
		return subscription.registeredUsers;
	}
};

const mapSubscriptionToDescription = (subscription: ISubscription, pricingMethod: LicensePricingMethod): string => {
	const monthStr = subscription.monthsInWindow === 1 ? '1 maand' : `${subscription.monthsInWindow} maanden`;
	return `Maandelijkse huur HAMS\nPeriode: van ${formatDate(subscription.from)} tot ${formatDate(subscription.to)}\n${monthStr}\n${mapLicenseToDescription(
		subscription.license,
		pricingMethod
	)}`;
};

const mapLicenseToDescription = (license: ILicenseSummary, pricingMethod: LicensePricingMethod) => {
	let str = `Licentie: ${license.name}`;
	if (pricingMethod === 'FixedMonthlyFee') {
		if (license.maxSoldHearingAids) {
			str = `${str}\n≤ ${license.maxSoldHearingAids.toString()} HT verkocht/jaarbasis`;
		}
		if (license.maxRegisteredUsers) {
			str = `${str}\n≤ ${license.maxRegisteredUsers.toString()} geregistreerde gebruikers`;
		}
		return str;
	} else if (pricingMethod === 'PerRegisteredUser') {
		return `${str}\nPrijs per gebruiker: ${formatCurrency(license.fee)}`;
	} else if (pricingMethod === 'PerSoldHearingAid') {
		return `${str}\nPrijs per verkocht HT: ${formatCurrency(license.fee)}`;
	}
};
