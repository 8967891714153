import React, { useState } from 'react';
import { useApiCall } from 'framework/hooks/useApiCall';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IDocumentContextKey, templatesQuery_content, templatesQuery_keysForTemplate } from 'gen/ApiClient';
import { TemplateEditorDialog } from './TemplateEditorDialog';
import { TemplateEditorDialogContext } from './TemplateEditorDialogContext';

export const TemplateEditorDialogProvider = ({ children }: any) => {
    const [trigger, hitTrigger] = useTrigger();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const loadContent = useApiCall(templatesQuery_content);
    const loadDocumentKeys = useApiCall(templatesQuery_keysForTemplate);
    const [content, setContent] = useState<string>();
    const [templateId, setTemplateId] = useState<string>();
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [keys, setKeys] = useState<IDocumentContextKey[]>([]);

    const onOpen = async (templateId: string, historyId?: string) => {
        setTemplateId(templateId);
        setIsReadOnly(historyId !== undefined);
        const rContent = await loadContent(templateId);
        const rKeys = await loadDocumentKeys(templateId);
        if (rContent.isSuccess && rKeys.isSuccess) {
            setContent(rContent.result);
            setKeys(rKeys.result);
            setIsOpen(true);
        }
    }

    const confirm = () => {
        hitTrigger();
        setIsOpen(false);
    }

    return (
        <TemplateEditorDialogContext.Provider
            value={{
                isOpen: isOpen,
                open: onOpen,
                trigger: trigger,
            }}>
            <>
                <TemplateEditorDialog
                    open={isOpen}
                    templateId={templateId}
                    content={content}
                    close={confirm}
                    isReadOnly={isReadOnly}
                    setContent={setContent}
                    keys={keys} />
                {children}
            </>
        </TemplateEditorDialogContext.Provider>
    );
};
