import { SelectableColumn } from './SelectableColumn';

export const ColumnRecord: Record<SelectableColumn, string> = {
	identificationCode: 'Id. Code',
	manufacturerCommaName: 'Manufacturer, Name',
	name: 'Name',
	reference: 'Reference',
	version: 'Version',
	distributor: 'Distributor',
	validFrom: 'Valid From',
	validTo: 'Valid To',
};
