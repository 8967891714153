import { Divider, List, Typography } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import PermDataSettingOutlinedIcon from '@material-ui/icons/PermDataSettingOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import { NavigateIconRecord } from 'app/NavigateIconRecord';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { meta_build } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { build } from '../../build';
import * as routes from '../../routes';
import { Logo } from '../logo/Logo';
import { PrimaryNavLink } from './PrimaryNavLink';

interface IProps {
	onLinkClicked: () => void;
}

export const SideNav = ({ onLinkClicked }: IProps) => {
	const history = useHistory();
	const [serverBuild] = useApiEffect(meta_build);

	const nav = (route: string) => {
		history.push(route);
		onLinkClicked();
	};

	return (
		<>
			<div
				className='df-col'
				style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
				<Logo style={{ paddingLeft: '24px', paddingRight: '12px', marginTop: '17px', marginBottom: '17px' }} />

				<Divider style={{ width: '100%' }} />
			</div>
			<List
				className='df-col fg1'
				style={{ padding: '12px 0px' }}>
				<PrimaryNavLink
					startIcon={NavigateIconRecord['dashboard']}
					text='Dashboard'
					onNavigate={nav}
					route={routes.DashboardRoute}
				/>
				<PrimaryNavLink
					startIcon={NavigateIconRecord['clients']}
					text='Clients'
					onNavigate={nav}
					route={routes.ClientsRoute}
				/>
				<PrimaryNavLink
					startIcon={<AssignmentTurnedInOutlinedIcon />}
					text='Update tasks'
					onNavigate={nav}
					route={routes.UpdateScheduledTasksRoute}
				/>
				<PrimaryNavLink
					startIcon={<StorageOutlinedIcon />}
					text='Data'
					onNavigate={nav}
					route={routes.DataRoute}
				/>
				<PrimaryNavLink
					startIcon={<DescriptionOutlinedIcon />}
					text='Templates'
					onNavigate={nav}
					route={routes.TemplatesRoute}
				/>
				<PrimaryNavLink
					startIcon={<NotificationsActiveOutlinedIcon />}
					text='Notifications'
					onNavigate={nav}
					route={routes.NotificationsRoute}
				/>
				<PrimaryNavLink
					startIcon={<AppsIcon />}
					text='Software'
					onNavigate={nav}
					route={routes.SoftwareRoute}
				/>
				<PrimaryNavLink
					startIcon={<PermDataSettingOutlinedIcon />}
					text="White-listed IP's"
					onNavigate={nav}
					route={routes.WhiteListedIpsRoute}
				/>
				<PrimaryNavLink
					startIcon={NavigateIconRecord['licenses']}
					text='Licenses'
					onNavigate={nav}
					route={routes.LicensesRoute}
				/>
				<PrimaryNavLink
					startIcon={NavigateIconRecord['billing-accounts']}
					text='Billing Accounts'
					onNavigate={nav}
					route={routes.BillingAccountsRoute}
				/>
				<PrimaryNavLink
					startIcon={NavigateIconRecord['subscriptions']}
					text='Subscriptions'
					onNavigate={nav}
					route={routes.SubscriptionsRoute}
				/>
				<PrimaryNavLink
					startIcon={NavigateIconRecord['sales']}
					text='Sales'
					onNavigate={nav}
					route={routes.SalesRoute}
				/>
				<div className='fg1'></div>
				<Typography
					variant='caption'
					style={{ marginLeft: 16 }}>{`f/b-build ${build}/${serverBuild ?? 'l...'}`}</Typography>
			</List>
		</>
	);
};
