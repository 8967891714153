import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { ProcessStepX } from 'framework/components/stepper/ProcessStepX';
import { VerticalStepperX } from 'framework/components/stepper/VerticalStepperX';
import { ISubscription, SubscriptionType } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calculateSalesDetailRoute } from 'routes';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ProcessStepXStatus } from 'framework/components/stepper/ProcessStepXStatus';

// const Width: number = 220;

interface IProps {
	item: ISubscription;
}

const mapToStatus = (type: SubscriptionType): ProcessStepXStatus => {
	if (type === 'History') {
		return 'done';
	} else if (type === 'Active') {
		return 'active';
	} else {
		return 'todo';
	}
};

export const SubscriptionHistoryContent = ({ item }: IProps) => {
	const { push } = useHistory();

	return (
		<VerticalStepperX>
			<ProcessStepX
				doneText={`Started`}
				todoText={`Start`}
				date={item.from}
				status={mapToStatus(item.type)}
			/>
			{item.previousLicenses.map(t => (
				<ProcessStepX
					key={t.dateChanged.toString()}
					doneText={`License changed`}
					todoText='n/a'
					date={t.dateChanged}
					status={'done'}>
					<span style={{ fontSize: 12 }}>{`From: ${t.licenseName}`}</span>
					{t.hasSale && (
						<TextLinkButton
							startIcon={<VisibilityOutlinedIcon />}
							color='primary'
							hoverColor='primary'
							onClick={() => push(calculateSalesDetailRoute(t.salesId!))}>
							{`Previous sale`}
						</TextLinkButton>
					)}
				</ProcessStepX>
			))}
			<ProcessStepX
				doneText={`Ended`}
				todoText={`End`}
				date={item.to}
				status={mapToStatus(item.type)}
				isLast
			/>
		</VerticalStepperX>
	);
};
