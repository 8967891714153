import { DialogProps } from '@material-ui/core';
import React from 'react';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { ITemplateDto, templatesCommand_patchVersion } from 'gen/ApiClient';

interface IProps extends DialogProps {
	template: ITemplateDto;
	confirm: () => void;
	cancel: () => void;
}

export const TemplateVersionForm = ({ template, ...rest }: IProps) => {
	return (
		<StringModelForm
			label='Version'
			formTitle='Change template version'
			submitFunction={val => templatesCommand_patchVersion({ templateId: template.id, version: val })}
			initialValue={template.version}
			submitText='Update'
			{...rest}
			fullWidth
			maxWidth='sm'
		/>
	);
};
