import {
	addDays,
	addMonths,
	addQuarters,
	addWeeks,
	addYears,
	endOfDay,
	endOfMonth,
	endOfQuarter,
	endOfYear,
	startOfDay,
	startOfMonth,
	startOfQuarter,
	startOfYear,
} from 'date-fns';
import { mapToIso } from 'framework/utils/date/mapToIso';
import { nlBELocaleEndOfWeek } from 'framework/utils/date/nlBELocaleEndOfWeek';
import { nlBELocaleStartOfWeek } from 'framework/utils/date/nlBELocaleStartOfWeek';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { BrowsableDateRangeType } from './BrowsableDateRangeType';
import { DateRangeType } from './DateRangeType';
import { IDateRange } from './IDateRange';

export const calculateDateRangeType = (val: IDateRange): DateRangeType => {
	if (isNullOrUndefined(val.after) && isNullOrUndefined(val.before)) {
		return 'unset';
	} else if (isNotNullNorUndefined(val.after) && isNullOrUndefined(val.before)) {
		return 'all-after';
	} else if (isNullOrUndefined(val.after) && isNotNullNorUndefined(val.before)) {
		return 'all-before';
	} else {
		const after = new Date(val.after!);
		if (val.after === mapToIso(startOfDay(after)) && val.before === mapToIso(endOfDay(after))) {
			return 'day';
		} else if (val.after === mapToIso(nlBELocaleStartOfWeek(after)) && val.before === mapToIso(nlBELocaleEndOfWeek(after))) {
			return 'week';
		} else if (val.after === mapToIso(startOfMonth(after)) && val.before === mapToIso(endOfMonth(after))) {
			return 'month';
		} else if (val.after === mapToIso(startOfQuarter(after)) && val.before === mapToIso(endOfQuarter(after))) {
			return 'quarter';
		} else if (val.after === mapToIso(startOfYear(after)) && val.before === mapToIso(endOfYear(after))) {
			return 'year';
		}
	}
	return 'custom';
};

export const calculateNextRange = (range: IDateRange, type: BrowsableDateRangeType): { after: Date; before: Date } => {
	return nextRecord[type](new Date(range.after!));
};

const nextRecord: Record<BrowsableDateRangeType, (val: Date) => { after: Date; before: Date }> = {
	day: val => ({ after: mapToIso(startOfDay(addDays(val, 1)))!, before: mapToIso(endOfDay(addDays(val, 1)))! }),
	week: val => ({ after: mapToIso(nlBELocaleStartOfWeek(addWeeks(val, 1)))!, before: mapToIso(nlBELocaleEndOfWeek(addWeeks(val, 1)))! }),
	month: val => ({ after: mapToIso(startOfMonth(addMonths(val, 1)))!, before: mapToIso(endOfMonth(addMonths(val, 1)))! }),
	quarter: val => ({ after: mapToIso(startOfQuarter(addQuarters(val, 1)))!, before: mapToIso(endOfQuarter(addQuarters(val, 1)))! }),
	year: val => ({ after: mapToIso(startOfYear(addYears(val, 1)))!, before: mapToIso(endOfYear(addYears(val, 1)))! }),
};

export const calculatePreviousRange = (range: IDateRange, type: BrowsableDateRangeType): { after: Date; before: Date } => {
	return previousRecord[type](new Date(range.after!));
};

const previousRecord: Record<BrowsableDateRangeType, (val: Date) => { after: Date; before: Date }> = {
	day: val => ({ after: mapToIso(startOfDay(addDays(val, -1)))!, before: mapToIso(endOfDay(addDays(val, -1)))! }),
	week: val => ({ after: mapToIso(nlBELocaleStartOfWeek(addWeeks(val, -1)))!, before: mapToIso(nlBELocaleEndOfWeek(addWeeks(val, -1)))! }),
	month: val => ({ after: mapToIso(startOfMonth(addMonths(val, -1)))!, before: mapToIso(endOfMonth(addMonths(val, -1)))! }),
	quarter: val => ({ after: mapToIso(startOfQuarter(addQuarters(val, -1)))!, before: mapToIso(endOfQuarter(addQuarters(val, -1)))! }),
	year: val => ({ after: mapToIso(startOfYear(addYears(val, -1)))!, before: mapToIso(endOfYear(addYears(val, -1)))! }),
};
