import { useTheme } from '@material-ui/core';
import React from "react";
import { ExpandLessOrMoreIcon } from './ExpandLessOrMoreIcon';

interface IProps {
    toggle: () => void;
    isExpanded: boolean;
    expandText: string;
    collapseText: string;
    style?: React.CSSProperties;
    justifyContent?: 'end';
    textColor?: 'primary' | 'secondary'
}

export const ExpandCollapseComponent = ({ toggle, isExpanded: isShow, expandText, collapseText, style, justifyContent = 'end', textColor = 'primary' }: IProps) => {
    const theme = useTheme();

    return (
        <div
            className={`df-row-ac${justifyContent === 'end' ? ' jc-e' : ''}`}
            onClick={toggle}
            style={{
                textAlign: 'right',
                cursor: 'pointer',
                color: textColor === 'primary' ? theme.palette.text.primary : theme.palette.text.secondary,
                ...style
            }}>
            <div>{isShow ? collapseText : expandText}</div>
            <ExpandLessOrMoreIcon isExpanded={isShow} />
        </div>
    );
}
