import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { HeaderTableCellX } from 'framework/table/HeaderTableCellX';
import { NoContentTableHeadX } from 'framework/table/NoContentTableHeadX';
import { SortableHeaderTableCellX } from 'framework/table/SortableHeaderTableCellX';
import { IQueryResult, ISortParams, ISubscription } from 'gen/ApiClient';
import React from 'react';
import { SubscriptionTableRow } from './SubscriptionTableRow';

interface IProps {
	queryResult: IQueryResult<ISubscription>;
	sortParams: ISortParams<ISubscription>;
	onFilter: (sp: ISortParams<ISubscription>) => void;
}

export const SubscriptionsTable = ({ queryResult, sortParams, onFilter }: IProps) => {
	return (
		<Table stickyHeader>
			{queryResult.unfilteredCount === 0 && <NoContentTableHeadX>{`No subscriptions registered yet`}</NoContentTableHeadX>}
			{queryResult.unfilteredCount > 0 && (
				<TableHead>
					<TableRow>
						<SortableHeaderTableCellX<ISubscription>
							forProperty='from'
							sortParams={sortParams}
							onFilter={onFilter}>
							{`Date windowd (from -> to)`}
						</SortableHeaderTableCellX>
						<SortableHeaderTableCellX<ISubscription>
							forProperty='clientName'
							sortParams={sortParams}
							onFilter={onFilter}>
							{`Client`}
						</SortableHeaderTableCellX>
						<SortableHeaderTableCellX<ISubscription>
							forProperty='licenseName'
							sortParams={sortParams}
							onFilter={onFilter}>
							{`License`}
						</SortableHeaderTableCellX>
						<HeaderTableCellX isLast></HeaderTableCellX>
					</TableRow>
				</TableHead>
			)}
			<TableBody>
				{queryResult.values.map(t => (
					<SubscriptionTableRow
						key={t.id}
						item={t}
					/>
				))}
			</TableBody>
		</Table>
	);
};
