import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import React from "react";
import { TableHeadersEyeIcon } from "framework/components/icons/TableHeadersEyeIcon";
import { useLocalization } from "localization/useLocalization";
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SelectColumnsDialog } from "./SelectColumnsDialog";

interface IProps<TSelectableColumn extends string> {
    selectedColumns: TSelectableColumn[];
    setSelectedColumns: React.Dispatch<React.SetStateAction<TSelectableColumn[]>>;
    record: Record<TSelectableColumn, string>;
    columns?: TSelectableColumn[];
    marginRight?: number;
    exclusions?: TSelectableColumn[];
}

export const SelectColumnsButtonAndDialog = <TSelectableColumn extends string>({ selectedColumns, setSelectedColumns, record, columns, marginRight = 0, exclusions = [] }: IProps<TSelectableColumn>) => {
    const strings = useLocalization();
    const { open, cancel } = useDialogsContext();
    const theme = useTheme();

    const onOpen = () => {
        open(<SelectColumnsDialog
            open
            cancel={cancel}
            submit={(cols) => { setSelectedColumns(cols); cancel(); }}
            record={record}
            selected={selectedColumns}
            columns={columns}
            exclusions={exclusions} />);
    }

    return (
        <Tooltip title={strings.changeColumns}>
            <IconButton color="primary" style={{ marginLeft: theme.spacing(1.5), marginRight: marginRight }} onClick={onOpen}>
                <TableHeadersEyeIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
}