import { DialogProps } from '@material-ui/core';
import { RootTemplateType } from 'gen/ApiClient';
import React, { useState } from 'react';
import { UploadExampleTemplateRequestForm } from './UploadExampleTemplateRequestForm';
import { UploadRootTemplateRequestForm } from './UploadRootTemplateRequestForm';
import { UploadStandardDocumentRequestForm } from './UploadStandardDocumentRequestForm';
import { UploadTemplateModeForm } from './UploadTemplateModeForm';

interface IProps extends DialogProps {
	clientId?: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const UploadTemplateForm = ({ clientId, confirm, ...rest }: IProps) => {
	const [mode, setMode] = useState<RootTemplateType | 'select'>('select');

	if (mode === 'select') {
		return (
			<UploadTemplateModeForm
				{...rest}
				confirm={setMode}
			/>
		);
	} else if (mode === 'ExampleTemplate') {
		return (
			<UploadExampleTemplateRequestForm
				{...rest}
				confirm={confirm}
			/>
		);
	} else if (mode === 'StandardDocument') {
		return (
			<UploadStandardDocumentRequestForm
				{...rest}
				confirm={confirm}
			/>
		);
	} else {
		return (
			<UploadRootTemplateRequestForm
				{...rest}
				confirm={confirm}
			/>
		);
	}
};
