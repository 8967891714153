import { Button, Divider, ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { DownloadMultipleIcon } from "framework/components/icons/DownloadMultipleIcon";
import { ExcelIcon } from "framework/components/icons/ExcelIcon";
import { FileDelimitedOutlineIcon } from "framework/components/icons/FileDelimitedOutlineIcon";
import { FilterOffOutlineIcon } from "framework/components/icons/FilterOffOutlineIcon";
import { FilterOutlineIcon } from "framework/components/icons/FilterOutlineIcon";
import { useAnchorElement } from "framework/hooks/useAnchorElement";
import { ExportFormat } from "gen/ApiClient";
import { useLocalization } from "localization/useLocalization";

interface IProps {
    filteredCount: number;
    unfilteredCount: number;
    onExportClick: (applyFilter: boolean, format: ExportFormat) => void;
    children?: any;
    anchor?: [null | HTMLElement, (event: React.MouseEvent<HTMLElement>) => void, () => void, undefined];
}

export const ExportButtonAndMenu = ({ filteredCount, unfilteredCount, onExportClick, children, anchor }: IProps) => {
    const strings = useLocalization();
    const localAnchor = useAnchorElement();
    const [menuAnchor, openMenu, closeMenu] = useMemo(() => anchor ?? localAnchor, [anchor, localAnchor]);

    const onMenuClick = (applyFilter: boolean, format: ExportFormat) => {
        onExportClick(applyFilter, format);
        closeMenu();
    }

    return (
        <>
            <Button color="primary" size="small" startIcon={<DownloadMultipleIcon />} onClick={openMenu}>{strings.exportVerb}</Button>
            <Menu
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={closeMenu}>
                {filteredCount !== unfilteredCount &&
                    <MenuItem onClick={() => onMenuClick(true, 'Xlsx')}>
                        <ListItemIcon>
                            <FilterOutlineIcon fontSize="small" />
                            <ExcelIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">{strings.exportFilteredResultsCountInFormat(filteredCount, '.xlsx')}</Typography>
                    </MenuItem>
                }
                <MenuItem onClick={() => onMenuClick(false, 'Xlsx')}>
                    <ListItemIcon>
                        <FilterOffOutlineIcon fontSize="small" />
                        <ExcelIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{strings.exportAllResultsCountInXlsxFormat(unfilteredCount, '.xlsx')}</Typography>
                </MenuItem>
                <Divider />
                {filteredCount !== unfilteredCount &&
                    <MenuItem onClick={() => onMenuClick(true, 'Csv')}>
                        <ListItemIcon>
                            <FilterOutlineIcon fontSize="small" />
                            <FileDelimitedOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit">{strings.exportFilteredResultsCountInFormat(filteredCount, '.csv')}</Typography>
                    </MenuItem>
                }
                <MenuItem onClick={() => onMenuClick(false, 'Csv')}>
                    <ListItemIcon>
                        <FilterOffOutlineIcon fontSize="small" />
                        <FileDelimitedOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{strings.exportAllResultsCountInXlsxFormat(unfilteredCount, '.csv')}</Typography>
                </MenuItem>
                {children && <Divider />}
                {children && children}
            </Menu>
        </>
    );
}