import React from 'react';
import { SalesFilterDataProvider } from './SalesFilterDataProvider';
import { SalesListQueryProvider } from './list/SalesListQueryProvider';

export const SalesProviders = ({ children }: any) => {
	return (
		<SalesFilterDataProvider>
			<SalesListQueryProvider>{children}</SalesListQueryProvider>
		</SalesFilterDataProvider>
	);
};
