import { ChipX } from 'framework/components/chips/ChipX';
import { ILicenseDto } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: ILicenseDto;
	style?: React.CSSProperties;
}

export const LicenseChips = ({ item, style }: IProps) => {
	return (
		<div
			className='df-row-ac gap-4'
			style={style}>
			<ChipX
				color='primary'
				label={item.category ?? ''}
			/>
			{item.hasCurrentFee === false && (
				<ChipX
					color='error'
					label='No longer in use'
				/>
			)}
		</div>
	);
};
