

import { createHeaderWithKey } from "framework/table/createHeaderWithKey";
import { createSortableHeaderWithKey } from "framework/table/createSortableHeaderWithKey";
import { IHeader } from "framework/table/IHeader";
import { formatDate } from "framework/utils/date/formatDate";
import { formatCurrency } from "framework/utils/formatCurrency";
import { IMedicalTreatmentNomenclatureForAudiology } from "gen/ApiClient";
import { SelectableColumn } from "../SelectableColumn";

export const createHeaders = (colRecord: Record<SelectableColumn, string>): Record<SelectableColumn, IHeader<IMedicalTreatmentNomenclatureForAudiology>> => {
    return {
        code: createSortableHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'code', t => t.code),
        descriptionNl: createHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'descriptionNl', t => t.data.descriptionNl),
        subArticle: createSortableHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'subArticle', t => t.subArticle),
        subArticleDescriptionNl: createHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'subArticleDescriptionNl', t => t.data.subArticleDescriptionNl),
        currentFeeContribution: createHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'currentFeeContribution', t => formatCurrency(t.data.currentFeeContribution)),
        currentFeePersonalShare: createHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'currentFeePersonalShare', t => formatCurrency(t.data.currentFeePersonalShare)),
        currentFeeValue: createHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'currentFeeValue', t => formatCurrency(t.data.currentFeeValue)),
        validityStart: createSortableHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'validityStart', t => formatDate(t.validityStart)),
        validityEnd: createSortableHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'validityEnd', t => formatDate(t.validityEnd)),
        hasCurrentFee: createHeaderWithKey<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>(colRecord, 'hasCurrentFee', t => t.data.hasCurrentFee.toString()),
    }
}