import { Card, CardContent, Divider } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { SuccessLabelledProperty } from 'framework/components/labelledProperty/SuccessLabelledProperty';
import { WarningLabelledProperty } from 'framework/components/labelledProperty/WarningLabelledProperty';
import { isLast } from 'framework/utils/array/isLast';
import { IDashboardNotification } from 'gen/ApiClient';
import React from 'react';
import { NoRecordsFound } from 'shared/NoRecordsFound';

interface IProps {
	notifications: IDashboardNotification[];
	className?: string;
	style?: React.CSSProperties;
}

export const NotificationsCard = ({ notifications, className, style }: IProps) => {
	return (
		<Card
			className={className}
			style={style}>
			<CardTitleTypography
				title='Notifications'
				withPadding
			/>
			<CardContent style={{ paddingTop: 0, paddingBottom: 16 }}>
				{notifications.length === 0 && <NoRecordsFound>{`No notifications`}</NoRecordsFound>}
				{notifications.map(t => (
					<React.Fragment key={t.message}>
						{t.type === 'Error' && <ErrorLabelledProperty error={t.message!} />}
						{t.type === 'Warning' && <WarningLabelledProperty warning={t.message!} />}
						{t.type === 'Success' && <SuccessLabelledProperty successMessage={t.message!} />}
						{isLast(t, notifications) === false && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
					</React.Fragment>
				))}
			</CardContent>
		</Card>
	);
};
