import React from 'react';
import { DialogsProvider } from 'shared/dialogs/DialogsProvider';
import { ClientsProviders } from './main/clients/ClientsProviders';
import { DataProviders } from '../app/main/data/context/DataProviders';
import { LicensesProviders } from './main/licenses/LicensesProviders';
import { NotificationsProviders } from './main/notifications/context/NotificationsProviders';
import { TemplatesProviders } from './main/templates/TemplatesProviders';
import { UpdateScheduledTasksProviders } from './main/updateScheduledTasks/context/UpdateScheduledTasksProviders';
import { BillingAccountsProviders } from './main/billingAccounts/BillingAccountsProviders';
import { SubscriptionsProviders } from './main/subscriptions/SubscriptionsProviders';
import { SalesProviders } from './main/sales/SalesProviders';
import { LabelsProviders } from './main/labels/LabelsProviders';

export const AppProviders = ({ children }: any) => {
	return (
		<ClientsProviders>
			<LicensesProviders>
				<SalesProviders>
					<BillingAccountsProviders>
						<SubscriptionsProviders>
							<UpdateScheduledTasksProviders>
								<TemplatesProviders>
									<NotificationsProviders>
										<DialogsProvider>
											<DataProviders>
												<LabelsProviders>{children}</LabelsProviders>
											</DataProviders>
										</DialogsProvider>
									</NotificationsProviders>
								</TemplatesProviders>
							</UpdateScheduledTasksProviders>
						</SubscriptionsProviders>
					</BillingAccountsProviders>
				</SalesProviders>
			</LicensesProviders>
		</ClientsProviders>
	);
};
