import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface IProps {
	close: () => void;
	style?: React.CSSProperties;
}

export const AbsoluteCloseButton = ({ close, style }: IProps) => {
	return (
		<IconButton
			onClick={close}
			style={{ position: 'absolute', right: '10px', top: '10px', ...style }}>
			<CloseIcon />
		</IconButton>
	);
};
