import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { SalesType } from 'gen/ApiClient';
import React, { useState } from 'react';

interface IProps extends DialogProps {
	confirm: (isBudget: SalesType) => void;
	cancel: VoidFunction;
}

export const CreateSaleModeForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [isBudget, setIsBudget] = useState<SalesType>('Budget');

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitle>{`Choose mode`}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<SalesType>
					selected={isBudget}
					setSelected={setIsBudget}
					options={[
						{ value: 'Tender', label: 'Tender', caption: 'This is a tender (do not invoice it now)' },
						{ value: 'Budget', label: 'Budget', caption: 'This is a future sale (do not invoice it now)' },
						{ value: 'Invoice', label: 'Invoice', caption: 'Invoice it now!' },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{`Cancel`}
				</Button>
				<Button
					onClick={() => confirm(isBudget)}
					color='primary'>
					{`Confirm choice`}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
