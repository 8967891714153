import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { JobType, jobsCommand_check, jobsCommand_force, jobsQuery_info, jobsQuery_rizivHearingAidDefinitionsV2TaskMetadata } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CheckCard } from './CheckCard';
import { RizivDevicesCheckDialog } from './RizivDevicesCheckDialog';

interface IProps {
	reloadTrigger: Trigger;
	style?: React.CSSProperties;
}

const Jit: JobType = 'RizivHearingAidDefinitionV2WebScrape';

export const CheckRizivHearingAidDefinitionsV2Card = ({ reloadTrigger, style }: IProps) => {
	const [ji, reload] = useApiEffect(jobsQuery_info, Jit);
	const [metadata, reloadMetadata] = useApiEffect(jobsQuery_rizivHearingAidDefinitionsV2TaskMetadata);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reload();
		reloadMetadata();
	}, [reloadTrigger]);

	const onCheckAgain = () => {
		if (ji !== undefined) {
			open(
				<AlertDialog
					open
					title={'Force check again?'}
					content={`Do you want to force a check on Riziv Devices?`}
					acceptText={`Yes, proceed`}
					rejectText={'No, go back'}
					reject={cancel}
					acceptF={() => jobsCommand_check({ jobType: Jit })}
					accept={confirm}
				/>
			);
		}
	};

	const onViewDetails = () => {
		open(
			<RizivDevicesCheckDialog
				open
				item={ji!}
				metadata={metadata!}
				close={cancel}
			/>
		);
	};

	const onUpdateData = () => {
		if (ji !== undefined) {
			open(
				<AlertDialog
					open
					title={'Update data'}
					content={`Do you want to update Riziv Devices?`}
					acceptText={`Yes, update`}
					rejectText={'No, go back'}
					reject={cancel}
					acceptF={() => jobsCommand_force({ jobType: Jit })}
					accept={confirm}
				/>
			);
		}
	};

	return (
		<CheckCard
			title='Riziv Hearing Aid Definitions V2'
			jobInfo={ji}
			metadata={metadata}
			reload={reload}
			onCheckAgain={onCheckAgain}
			onViewDetails={onViewDetails}
			onUpdateData={onUpdateData}
			style={style}
		/>
	);
};
