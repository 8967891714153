import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { ISalesQueryMetadata, SalesPrefilter } from 'gen/ApiClient';
import React from 'react';

export const createSalesPrefilterBadgeChipXRecord = (metadata: ISalesQueryMetadata): Record<SalesPrefilter, React.ReactNode> => {
	return {
		All: undefined,
		Overdue: metadata.countOverdue > 0 && (
			<BadgeChipX
				value={metadata.countOverdue}
				color='warning'
			/>
		),
		Budgeted: metadata.countBudgeted > 0 && (
			<BadgeChipX
				value={metadata.countBudgeted}
				color='info'
			/>
		),
		Tendered: metadata.countTendered > 0 && (
			<BadgeChipX
				value={metadata.countTendered}
				color='info'
			/>
		),
		InvoicedAndNotPaid: metadata.countInvoicedAndNotPaid > 0 && (
			<BadgeChipX
				value={metadata.countInvoicedAndNotPaid}
				color='warning'
			/>
		),
		Invoiced: undefined,
		InvoicedAndPaid: undefined,
	};
};
