
import { SelectableColumn } from "../SelectableColumn"

export const ColumnRecord: Record<SelectableColumn, string> = {
    code: 'Code',
    currentFeeContribution: 'Contribution',
    currentFeePersonalShare: 'Personal share',
    currentFeeValue: 'Value',
    descriptionNl: 'Description',
    hasCurrentFee: 'Has current fee?',
    subArticle: 'Subarticle',
    validityEnd: 'V. End',
    validityStart: 'V. Start',
    subArticleDescriptionNl: 'Subarticle descr.'
}
