import { CardContent, useTheme } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { useLocalization } from 'localization/useLocalization';
import { CardNavigationLink } from './CardNavigationLink';

interface IProps<T extends string> {
	link: T;
	setLink: (val: T) => void;
	record: Record<T, string>;
	iconRecord?: Record<T, React.ReactNode>;
	title?: string;
	isDisabled?: (link: T) => boolean;
	withNotification?: (link: T) => boolean;
	children?: any;
}

export const RecordNavigationCardContent = <T extends string>({
	link,
	setLink,
	record,
	iconRecord,
	title,
	isDisabled,
	withNotification,
	children,
}: IProps<T>) => {
	const theme = useTheme();
	const strings = useLocalization();

	return (
		<CardContent style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 16 }}>
			<CardTitleTypography
				title={title ?? strings.navigation}
				withPadding
				style={{ paddingBottom: theme.spacing(0.5) }}
			/>
			{Object.keys(record).map(t => (
				<CardNavigationLink
					key={t}
					isMatch={link === t}
					disabled={isDisabled ? isDisabled(t as T) : undefined}
					onNavigate={() => setLink(t as T)}
					text={record[t as T]}
					startIcon={iconRecord ? iconRecord[t as T] : <HelpOutlineIcon />}
					withNotification={withNotification ? withNotification(t as T) : undefined}
				/>
			))}
			{children && children}
		</CardContent>
	);
};
