import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { NavigateIconRecord } from 'app/NavigateIconRecord';
import { CreateSaleForm } from 'app/main/sales/create/CreateSaleForm';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { ISubscription, subscriptionsCommand_delete } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SubscriptionsRoute, calculateSubscriptionDetailRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatchDateTimeWindowOnSubscriptionRequestForm } from '../PatchDateTimeWindowOnSubscriptionRequestForm';
import { PatchLicenseOnSubscriptionRequestForm } from '../PatchLicenseOnSubscriptionRequestForm';
import { RenewLicenseSubscriptionRequestForm } from '../RenewLicenseSubscriptionRequestForm';

interface IProps {
	item: ISubscription;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const SubscriptionDetailActionsContent = ({ item, reload, style }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();

	const onEditWindow = () => {
		open(
			<PatchDateTimeWindowOnSubscriptionRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onEditLicense = () => {
		open(
			<PatchLicenseOnSubscriptionRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onCreateSale = () => {
		open(
			<CreateSaleForm
				open
				confirm={confirm}
				cancel={cancel}
				subscriptionId={item.id}
			/>
		);
	};

	const onRenewSubscription = () => {
		open(
			<RenewLicenseSubscriptionRequestForm
				open
				confirm={id => {
					confirm();
					push(calculateSubscriptionDetailRoute(id));
				}}
				cancel={cancel}
				subscriptionId={item.id}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={'Delete subscription'}
				content={`Do you want to delete this subscription for ${item.clientName}?`}
				acceptText={`Yes, delete`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => subscriptionsCommand_delete({ subscriptionId: item.id }) as any}
				accept={() => {
					cancel();
					push(SubscriptionsRoute);
				}}
			/>
		);
	};

	return (
		<div className='df-col gap-6'>
			<TextLinkButton
				startIcon={<EditOutlinedIcon />}
				onClick={onEditWindow}>
				{`Edit date window`}
			</TextLinkButton>
			<TextLinkButton
				startIcon={NavigateIconRecord['licenses']}
				onClick={onEditLicense}>
				{`Change license`}
			</TextLinkButton>
			<TextLinkButton
				startIcon={NavigateIconRecord['sales']}
				onClick={onCreateSale}
				disabled={item.hasSale}>
				{`Create budget/sale`}
			</TextLinkButton>
			<div style={{ fontSize: 10, marginTop: 4, marginBottom: 8 }}>
				<em>{`If has budget -> create sale from that budget`}</em>
			</div>
			<TextLinkButton
				startIcon={<AutorenewIcon />}
				onClick={onRenewSubscription}
				disabled={item.isDemo}>
				{`Renew subscription`}
			</TextLinkButton>
			<TextLinkButton
				startIcon={<DeleteOutlineOutlinedIcon />}
				onClick={onDelete}
				disabled={item.hasSale}
				color='secondary'>
				{`Delete subscription`}
			</TextLinkButton>
		</div>
	);
};
