import { MenuItem, Select } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { IOption } from '../IOption';
import { nameOf } from '../utils/nameOf';
import { FormFilledControl } from './common/FormFilledControl';
import { IFormProps } from './common/IFormProps';

interface IProps<TModel, TOption> extends IFormProps<TModel> {
    options: IOption<TOption>[];
    withNone?: boolean;
    renderValue?: (option: IOption<TOption>) => React.ReactNode;
}

export const FormSelectFieldWithOption = <TModel extends unknown, TOption extends unknown>({ options, withNone = false, renderValue, ...props }: IProps<TModel, TOption>) => {
    const [field, meta] = useField<string>(nameOf(props.name));
    const strings = useLocalization();

    return (
        <FormFilledControl meta={meta}{...props}>
            <Select
                {...field}>
                {withNone && <MenuItem value={undefined}>
                    <em>{strings.none.toLowerCase()}</em>
                </MenuItem>}
                {options.map(t => <MenuItem key={t.identifier} value={t.id as any}>{renderValue ? renderValue(t) : t.identifier}</MenuItem>)}
            </Select>
        </FormFilledControl>
    );
}