import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const FileDownloadOutlineIcon = ({ viewBox = "0 0 24 24", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <path d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z" />
        </SvgIcon>
    );
}
