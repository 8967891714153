import React from 'react';
import { StatusIcon } from '../icons/StatusIcon';
import { TypographyBoldCaption } from '../typography/TypographyBoldCaption';
import { LabelledPropertyX } from './LabelledPropertyX';

interface IProps {
	label: string;
	edit?: VoidFunction;
	link?: VoidFunction;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	info?: VoidFunction;
}

export const CaptionVerticalLabelledPropertyX = ({ label, edit, link, children, style, info }: IProps) => {
	return (
		<div
			className='df-col'
			style={style}>
			<div className='df-row-ac gap-4'>
				<TypographyBoldCaption style={{ marginBottom: 0 }}>{label}</TypographyBoldCaption>
				{info && (
					<StatusIcon
						status='info'
						onClick={info}
						style={{ cursor: 'pointer', fontSize: 18 }}
					/>
				)}
			</div>
			<LabelledPropertyX
				edit={edit}
				link={link}>
				{children}
			</LabelledPropertyX>
		</div>
	);
};
