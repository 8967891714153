import { ColorX } from 'framework/components/color/ColorX';
import { IBillingAccount } from 'gen/ApiClient';

export const mapBillingAccountToColor = (item: IBillingAccount): ColorX => {
	if (item.hasMissingFields) {
		return 'error';
	} else if (item.hasOutstandingBalance) {
		return 'warning';
	} else {
		return 'primary';
	}
};
