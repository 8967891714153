import { BillingAccountsCacheContext } from 'app/main/billingAccounts/BillingAccountsCacheContext';
import { useLabelsCacheContext } from 'app/main/labels/useLabelsCacheContext';
import { FilterChipX } from 'framework/filter/FilterChipX';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { formatDate } from 'framework/utils/date/formatDate';
import { ISalesQueryParams } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	params: ISalesQueryParams;
	onFilter: (params: ISalesQueryParams) => void;
}

export const SalesListFilterChips = ({ params, onFilter }: IProps) => {
	const [labels] = useLabelsCacheContext();
	const [billingAccounts] = useCacheContext(BillingAccountsCacheContext);

	return (
		<div className='df-row-wrap gap-6'>
			{params.dateAfter !== undefined && (
				<FilterChipX
					label={`After`}
					value={formatDate(params.dateAfter)}
					onDelete={() => onFilter({ ...params, dateAfter: undefined })}
				/>
			)}
			{params.dateBefore !== undefined && (
				<FilterChipX
					label={`Before`}
					value={formatDate(params.dateBefore)}
					onDelete={() => onFilter({ ...params, dateBefore: undefined })}
				/>
			)}
			{params.types !== undefined && params.types.length > 0 && (
				<FilterChipX
					label={`Types`}
					value={params.types}
					onDelete={() => onFilter({ ...params, types: [] })}
				/>
			)}
			{params.isInvoiceSent !== undefined && (
				<FilterChipX
					// label={`Is invoice sent?`}
					value={params.isInvoiceSent ? 'Invoice sent' : 'Invoice not sent'}
					onDelete={() => onFilter({ ...params, isInvoiceSent: undefined })}
				/>
			)}
			{params.labelIds !== undefined && params.labelIds.length > 0 && (
				<>
					{params.labelIds.map(t => (
						<FilterChipX
							label='Label'
							key={t.join('-')}
							value={labels.filter(x => t.indexOf(x.id) > -1).map(t => t.name ?? '')}
							onDelete={() => onFilter({ ...params, labelIds: params.labelIds.filter(x => x !== t) })}
						/>
					))}
				</>
			)}
			{billingAccounts !== undefined && params.billingAccountIds !== undefined && params.billingAccountIds.length > 0 && (
				<FilterChipX
					label={`Billing accounts`}
					value={billingAccounts.filter(t => params.billingAccountIds.indexOf(t.id) > -1).map(t => t.identifier)}
					onDelete={() => onFilter({ ...params, billingAccountIds: [] })}
				/>
			)}
		</div>
	);
};
