import { Dialog, DialogProps, Typography, useTheme } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import ScannerIcon from '@material-ui/icons/Scanner';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { FileDocumentEditOutlineIcon } from 'framework/components/icons/FileDocumentEditOutlineIcon';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { formatDate } from 'framework/utils/date/formatDate';
import { templatesCommand_delete, templatesCommand_upload, templatesQuery_download, templatesQuery_single } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useTemplateEditor } from 'shared/dialogs/templates/useTemplateEditor';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { IFile } from 'shared/upload/IFile';
import { UploadFileDialog } from 'shared/upload/UploadFileDialog';
import { TemplateMetadataForm } from './forms/TemplateMetadataForm';
import { TemplateNameForm } from './forms/TemplateNameForm';
import { TemplateVersionForm } from './forms/TemplateVersionForm';
import { TemplateChips } from './TemplateChips';

interface IProps extends DialogProps {
	close: () => void;
	id: string;
}

export const TemplateDialog = ({ id, close, ...rest }: IProps) => {
	const theme = useTheme();
	const [template, reload] = useApiEffect(templatesQuery_single, id);
	const [download] = useDownload(templatesQuery_download);
	const [openEditor, isEditorOpen] = useTemplateEditor(reload);
	const [upload, isUploading] = useFormSubmit(templatesCommand_upload);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (template === undefined) {
		return <div></div>;
	}

	const onViewOrEdit = async () => {
		openEditor(template.id);
	};

	const onUpload = () => {
		open(
			<UploadFileDialog
				open
				title='Upload template'
				cancel={cancel}
				confirmF={onFileSelected}
				confirm={confirm}
				isUploading={isUploading}
				accept='word'
			/>
		);
	};

	const onFileSelected = async (file: IFile) => {
		await upload({ templateId: template.id, content: file.contentAsBase64 });
	};

	const onChangeMeta = () => {
		open(
			<TemplateMetadataForm
				open
				template={template}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	const onChangeName = () => {
		open(
			<TemplateNameForm
				open
				template={template}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	const onChangeVersion = () => {
		open(
			<TemplateVersionForm
				open
				template={template}
				cancel={cancel}
				confirm={confirm}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title='Delete template?'
				content='Template will be deleted (this action cannot be undone)'
				acceptText='Delete'
				rejectText='Go back'
				reject={cancel}
				acceptF={() => templatesCommand_delete(template.id)}
				accept={() => {
					confirm();
					close();
				}}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false && isEditorOpen === false}
			onClose={close}
			fullWidth
			scroll='paper'>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<DescriptionIcon />}>
					<Typography
						variant='h1'
						style={{ marginBottom: theme.spacing(1) }}>
						{template.name}
					</Typography>
					<LabelledProperty
						label='Created'
						property={formatDate(template.created, 'date-and-time')}
						withoutMargin
					/>
					<LabelledProperty
						label='Last updated'
						property={formatDate(template.lastUpdated, 'date-and-time')}
						withoutMargin
					/>
					<TemplateChips item={template} />
				</DialogGridTitle>
				<GridDividerWithCaption caption='Properties' />
				{template.isPredefinedType && (
					<PropertyInGrid
						label='Type'
						value={template.predefinedType}
					/>
				)}
				<PropertyInGrid
					label='Main context'
					value={template.mainContext}
				/>
				<PropertyInGrid
					label='Name'
					value={template.name}
					edit={onChangeName}
				/>
				<PropertyInGrid
					label='Version'
					value={template.version}
					edit={onChangeVersion}
				/>
				<PropertyInGrid
					label={`Editable?`}
					value={template.canEdit.toString()}
					edit={onChangeMeta}
				/>
				<PropertyInGrid
					label={`Signature?`}
					value={template.mustSign.toString()}
					edit={onChangeMeta}
				/>
				<PropertyInGrid
					label={`Can view online?`}
					value={template.canViewOnline.toString()}
					edit={onChangeMeta}
				/>
				<PropertyInGrid
					label={`Validity start?`}
					value={formatDate(template.validityStart)}
					edit={onChangeMeta}
				/>
				<PropertyInGrid
					label={`Validity end?`}
					value={formatDate(template.validityEnd)}
					edit={onChangeMeta}
				/>
				<GridDividerWithCaption caption='Actions' />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								onClick={onViewOrEdit}
								startIcon={<FileDocumentEditOutlineIcon />}>{`Edit`}</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={() => download(template.id)}
								startIcon={<FileDownloadOutlineIcon />}>{`Download`}</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onUpload}
								startIcon={<ScannerIcon />}>{`Upload`}</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								onClick={onDelete}
								startIcon={<DeleteOutlineIcon />}
								color='secondary'>{`Delete`}</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
