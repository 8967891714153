import { Collapse, Divider, TableRow, useTheme } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { RowWrappedLabels } from 'app/main/labels/RowWrappedLabels';
import { ExpandLessOrMoreIcon } from 'framework/components/ExpandLessOrMoreIcon';
import { ColorOnHoverIconButton } from 'framework/components/buttons/ColorOnHoverIconButton';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { TableCellX } from 'framework/table/TableCellX';
import { TableDetailsRowX } from 'framework/table/TableDetailsRowX';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISale } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { calculateBillingAccountDetailRoute, calculateSalesDetailRoute } from 'routes';
import { SalesPaymentStatusChip } from '../components/SalesPaymentStatusChip';
import { SalesTypeChip } from '../components/SalesTypeChip';
import { SalesListItemStatusIcon } from './SalesListItemStatusIcon';
import { mapSaleToColorX } from './utils';

const MinorFontSize = 13;

interface IProps {
	item: ISale;
	isExpanded?: boolean;
	toggle?: VoidFunction;
	hideBillingAccount?: boolean;
}

export const SalesTableRow = ({ item, isExpanded, toggle, hideBillingAccount }: IProps) => {
	const color = useMemo(() => mapSaleToColorX(item), [item]);
	const tColor = useThemeColor(color);
	const theme = useTheme();
	const { push } = useHistory();
	const [isHovered, enter, leave] = useStateBoolean(false);

	const onViewDetails = () => push(calculateSalesDetailRoute(item.id));

	return (
		<React.Fragment>
			<TableRow
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<TableCellX>
					<div className='df-row gap-4'>
						<SalesListItemStatusIcon
							item={item}
							style={{ fontSize: 22, color: tColor }}
						/>
						<div className='df-col gap-2'>
							<TextLinkButton
								underline
								style={{ fontSize: 14 }}
								onClick={onViewDetails}>
								{item.referenceOrId}
							</TextLinkButton>
							<div className='df-row-ac gap-4'>
								<SalesTypeChip item={item} />
							</div>
						</div>
					</div>
				</TableCellX>
				<TableCellX>
					<div className='df-col gap-2'>
						<span>{formatDate(item.date)}</span>
						<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{`Pay before: ${formatDate(item.payBefore)}`}</span>
					</div>
				</TableCellX>
				{hideBillingAccount !== true && (
					<TableCellX>
						<TextLinkButton
							disabled={item.isLinkedToBillingAccount === false}
							onClick={item.isLinkedToBillingAccount ? () => push(calculateBillingAccountDetailRoute(item.billingAccountId!)) : undefined}>
							{item.vatRecipientName ?? ''}
						</TextLinkButton>
					</TableCellX>
				)}
				<TableCellX align='right'>
					<div className='df-col-ae gap-2'>
						<span>{formatCurrency(item.priceExVat)}</span>
						{item.type === 'Invoice' && <SalesPaymentStatusChip item={item} />}
						{item.isPartlyPaid && (
							<span style={{ fontSize: MinorFontSize, color: theme.palette.text.secondary }}>{`To pay: ${formatCurrency(item.outstandingBalance)}`}</span>
						)}
					</div>
				</TableCellX>
				<TableCellX align='right'>
					<div className='df-row-ac jc-e'>
						{toggle && isExpanded !== undefined && (
							<ColorOnHoverIconButton
								edge='end'
								size='small'
								color='primary'
								onClick={toggle}>
								<ExpandLessOrMoreIcon isExpanded={isExpanded} />
							</ColorOnHoverIconButton>
						)}
						<ColorOnHoverIconButton
							size='small'
							edge='end'
							color='primary'
							onClick={onViewDetails}>
							<VisibilityOutlinedIcon />
						</ColorOnHoverIconButton>
					</div>
				</TableCellX>
			</TableRow>
			{(item.labelIds ?? []).length > 0 && (
				<TableDetailsRowX
					colSpan={5}
					onMouseEnter={enter}
					onMouseLeave={leave}
					style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
					<RowWrappedLabels
						labelIds={item.labelIds}
						style={{ paddingBottom: 8 }}
					/>
				</TableDetailsRowX>
			)}
			<TableDetailsRowX
				colSpan={5}
				onMouseEnter={enter}
				onMouseLeave={leave}
				style={{ backgroundColor: isHovered ? theme.palette.action.hover : 'unset' }}>
				<Collapse in={isExpanded}>
					<div
						className='df-col'
						style={{ paddingBottom: 8 }}>
						<Divider
							variant='inset'
							style={{ marginTop: 8, marginBottom: 4 }}
						/>
						{item.lines.map(line => (
							<div
								className='df-row-ab'
								key={line.id}
								style={{ fontSize: MinorFontSize, gap: 4 }}>
								<span>{`${line.quantity.toString()} x`}</span>
								<span style={{ fontSize: MinorFontSize, whiteSpace: 'pre-line' }}>{line.description}</span>
							</div>
						))}
					</div>
				</Collapse>
			</TableDetailsRowX>
		</React.Fragment>
	);
};
