import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import { CreateSaleForm } from 'app/main/sales/create/CreateSaleForm';
import { CreateLicenseSubscriptionRequestForm } from 'app/main/subscriptions/CreateLicenseSubscriptionRequestForm';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { IBillingAccount } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calculateSalesDetailRoute, calculateSubscriptionDetailRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: IBillingAccount;
	reload: VoidFunction;
}

export const BillingAccountDetailActionsContent = ({ item, reload }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();

	const onAddSubscription = () => {
		open(
			<CreateLicenseSubscriptionRequestForm
				open
				confirm={id => {
					confirm();
					push(calculateSubscriptionDetailRoute(id));
				}}
				cancel={cancel}
				clientId={item.id}
			/>
		);
	};

	const onAddSale = () => {
		open(
			<CreateSaleForm
				open
				confirm={id => {
					confirm();
					push(calculateSalesDetailRoute(id));
				}}
				cancel={cancel}
				billingAccountId={item.id}
			/>
		);
	};

	return (
		<div className='df-col gap-6'>
			{item.isAlsoHamsClient && (
				<TextLinkButton
					startIcon={<SubscriptionsOutlinedIcon />}
					onClick={onAddSubscription}>
					{`Create subscription`}
				</TextLinkButton>
			)}
			<TextLinkButton
				startIcon={<ReceiptOutlinedIcon />}
				onClick={onAddSale}>
				{`Create sale`}
			</TextLinkButton>
		</div>
	);
};
