import { useContext } from "react";
import { useLazyEffect } from "framework/hooks/useLazyEffect";
import { TemplateEditorDialogContext } from "./TemplateEditorDialogContext";

export function useTemplateEditor(reload?: () => void): [(documentId: string, historyId?: string) => void, boolean] {
    const { isOpen, open, trigger } = useContext(TemplateEditorDialogContext);

    useLazyEffect(() => {
        if (reload) {
            reload();
        }
    }, [trigger]);

    return [open, isOpen];
}
