import { Table, TableBody } from '@material-ui/core';
import { SalesTableRow } from 'app/main/sales/list/SalesTableRow';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { salesQuery_forBillingAccount } from 'gen/ApiClient';
import React from 'react';
import { NoRecordsFound } from 'shared/NoRecordsFound';

interface IProps {
	billingAccountId: string;
	reloadTrigger: Trigger;
}

export const BillingAccountDetailSalesCard = ({ billingAccountId, reloadTrigger }: IProps) => {
	const [values, reload] = useApiEffect(salesQuery_forBillingAccount, billingAccountId);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	if (values === undefined) {
		return <></>;
	}

	return (
		<CardX
			className='df-col gap-8'
			removeHorizontalPadding>
			<Typography16pxBold style={{ paddingLeft: 16 }}>{`Sales`}</Typography16pxBold>
			{values.length === 0 && <NoRecordsFound style={{ paddingLeft: 16 }}>{`No sales yet`}</NoRecordsFound>}
			<Table>
				<TableBody>
					{values.map(t => (
						<SalesTableRow
							item={t}
							hideBillingAccount
						/>
					))}
				</TableBody>
			</Table>
		</CardX>
	);
};
