import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import React from 'react';
import { IClientNameWithSelection } from './IClientNameWithSelection';

export const createHeaders = (clients: IClientNameWithSelection[], filtered: IClientNameWithSelection[], setClients: React.Dispatch<React.SetStateAction<IClientNameWithSelection[]>>): IHeader<IClientNameWithSelection>[] => {
    return [
        createSelectionHeader<IClientNameWithSelection>(filtered, (newLines) => {
            const x = clients.map(client => ({ ...client, isSelected: newLines.find(x => x.name === client.name)?.isSelected ?? client.isSelected }));
            setClients(x);
        }),
        createSortableHeader<IClientNameWithSelection>('Name', 'name', t => t.name),
    ];
}