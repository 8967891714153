import { Divider } from '@material-ui/core';
import { AnnotationsCard } from 'app/main/annotations/AnnotationsCard';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageX } from 'framework/components/layout/PageX';
import { HorizontalTabsX } from 'framework/components/tabs/HorizontalTabsX';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { billingAccountsQuery_single } from 'gen/ApiClient';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BillingAccountChips } from '../BillingAccountChips';
import { BillingAccountsPageBreadcrumbs } from '../BillingAccountsPageBreadcrumbs';
import { BillingAccountDetailOverviewHeaderCard } from './BillingAccountDetailOverviewHeaderCard';
import { BillingAccountDetailPageSidebar } from './BillingAccountDetailPageSidebar';
import { BillingAccountDetailSalesCard } from './BillingAccountDetailSalesCard';
import { BillingAccountDetailSubscriptionsCard } from './BillingAccountDetailSubscriptionsCard';
import { BillingAccountDetailTabType, BillingAccountDetailTabTypes } from './BillingAccountDetailTabType';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

const TabTypeRecord: Record<BillingAccountDetailTabType, string> = {
	annotations: 'Annotations',
	sales: 'Sales',
	subscriptions: 'Subscriptions',
};

export const BillingAccountDetailPageX = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [item, reloadSingle] = useApiEffect(billingAccountsQuery_single, id);
	const [trigger, reload] = useTrigger();
	const [selectedTab, setSelectedTab] = useState<BillingAccountDetailTabType>('sales');

	useLazyEffect(() => {
		reloadSingle();
	}, [trigger]);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<PageX
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<BillingAccountsPageBreadcrumbs />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={item.identifier} />
						<BillingAccountChips
							item={item}
							style={{ marginLeft: 4 }}
						/>
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}
			stickySidebar={
				<BillingAccountDetailPageSidebar
					item={item}
					reload={reload}
				/>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
					paddingLeft: 8,
					maxWidth: 1280,
				}}>
				<BillingAccountDetailOverviewHeaderCard
					item={item}
					reload={reload}
					reloadTrigger={trigger}
				/>
				<div
					className='df-col'
					style={{ marginTop: 24, marginBottom: 16 }}>
					<HorizontalTabsX<BillingAccountDetailTabType>
						tabs={item.isAlsoHamsClient ? BillingAccountDetailTabTypes : ['sales', 'annotations']}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						titleRecord={TabTypeRecord}
					/>
					<Divider style={{ marginTop: -1 }} />
				</div>
				{selectedTab === 'sales' && (
					<BillingAccountDetailSalesCard
						billingAccountId={item.id}
						reloadTrigger={trigger}
					/>
				)}
				{selectedTab === 'subscriptions' && (
					<BillingAccountDetailSubscriptionsCard
						billingAccountId={item.id}
						reloadTrigger={trigger}
					/>
				)}
				{selectedTab === 'annotations' && (
					<AnnotationsCard
						contextId={item.id}
						contextType='BillingAccount'
						reload={reload}
						reloadTrigger={trigger}
					/>
				)}
			</div>
		</PageX>
	);
};
