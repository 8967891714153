import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { ISubscriptionsQueryMetadata, SubscriptionPrefilter, SubscriptionPrefilters } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { createSubscriptionsPrefilterBadgeChipXRecord } from './createSubscriptionsPrefilterBadgeChipXRecord';

interface IProps {
	selectedPrefilter: SubscriptionPrefilter;
	setSelectedPrefilter: (key: SubscriptionPrefilter) => void;
	metadata: ISubscriptionsQueryMetadata;
	style?: React.CSSProperties;
}

const SubscriptionsPrefilterRecord: Record<SubscriptionPrefilter, string> = {
	All: 'All',
	Active: 'Active',
	ActiveAndExceedsSoldHearingAids: 'Active & exceeds',
	ActiveWithoutSale: 'Active w/o sale',
	Future: 'Future',
	History: 'History',
};

export const SubscriptionsListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, metadata, style }: IProps) => {
	const endIconRecord = useMemo(() => createSubscriptionsPrefilterBadgeChipXRecord(metadata), [metadata]);

	return (
		<div
			className='df-col'
			style={style}>
			{SubscriptionPrefilters.map(prefilterKeyType => (
				<RadioIsSelectedButton
					key={prefilterKeyType}
					isSelected={prefilterKeyType === selectedPrefilter}
					onClick={() => setSelectedPrefilter(prefilterKeyType)}
					endIcon={endIconRecord[prefilterKeyType]}>
					{SubscriptionsPrefilterRecord[prefilterKeyType]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
