import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { ErrorLabelledProperty } from 'framework/components/labelledProperty/ErrorLabelledProperty';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import {
	IClientDto,
	ICreateLicenseSubscriptionRequest,
	ILicenseDto,
	ISubscription,
	subscriptionsCommand_addLicense,
	subscriptionsQuery_single,
} from 'gen/ApiClient';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { ClientsCacheContext } from '../clients/ClientsCacheContext';
import { LicensesCacheContext } from '../licenses/LicensesCacheContext';

const createSchema = () => {
	return yup
		.object<ICreateLicenseSubscriptionRequest>({
			from: yup.date().required(),
			to: yup.date().required(),
			licenseId: yup.string().required(),
			clientId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	subscriptionId: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const RenewLicenseSubscriptionRequestForm = ({ subscriptionId, confirm, cancel, ...rest }: IProps) => {
	const [subscription] = useApiEffect(subscriptionsQuery_single, subscriptionId);
	const [create, isSubmitting] = useFormSubmit(subscriptionsCommand_addLicense);
	const [licenses] = useCacheContext(LicensesCacheContext);
	const [clients] = useCacheContext(ClientsCacheContext);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: ICreateLicenseSubscriptionRequest, helpers: FormikHelpers<ICreateLicenseSubscriptionRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers)) {
			confirm(r.result.objectId);
			notify(`Subscription succesfully renewed`, 'success');
		}
	};

	if (subscription === undefined || licenses === undefined || clients === undefined) {
		return <></>;
	}

	return (
		<Formik<ICreateLicenseSubscriptionRequest>
			validateOnMount
			initialValues={{
				from: subscription.nextWindow.after,
				to: subscription.nextWindow.before,
				clientId: subscription.clientId,
				licenseId: subscription.licenseId ?? '',
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					cancel={cancel}
					isSubmitting={isSubmitting}
					licenses={licenses}
					clients={clients}
					subscription={subscription}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	licenses: ILicenseDto[];
	clients: IClientDto[];
	subscription: ISubscription;
}

const InnerDialog = ({ cancel, isSubmitting, licenses, clients, subscription, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<ICreateLicenseSubscriptionRequest>();
	const selectedLicense = useMemo(() => licenses.find(t => t.id === props.values.licenseId), [props.values.licenseId, licenses]);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='xs'
			fullWidth>
			<DialogTitle>
				<div>{`Renew subscription`}</div>
			</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<FormDatePicker<ICreateLicenseSubscriptionRequest>
					name='from'
					label='From'
					required
				/>
				<FormDatePicker<ICreateLicenseSubscriptionRequest>
					name='to'
					label='To'
					required
				/>
				<FormAutocompleteField<ICreateLicenseSubscriptionRequest, IClientDto>
					name='clientId'
					label={`Client`}
					options={clients}
					disabled
					// helperText={
					// 	selectedClient &&
					// 	`# users ${selectedClient.registeredUsers.toString()} | # sold HA Y0-1-2 ${selectedClient.soldHearingAidsThisYear.toString()} ${selectedClient.soldHearingAidsYearMinus1.toString()} ${selectedClient.soldHearingAidsYearMinus2.toString()}`
					// }
				/>
				{subscription.hasExceeded && <ErrorLabelledProperty error={`Subscription w/ license: ${subscription.licenseName} was exceeded in previous period`} />}
				<FormAutocompleteField<ICreateLicenseSubscriptionRequest, ILicenseDto>
					name='licenseId'
					label={`License`}
					options={licenses}
					helperText={selectedLicense && `${selectedLicense.maxRegisteredUsersDisplay}/${selectedLicense.maxSoldHADisplay}`}
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions<ICreateLicenseSubscriptionRequest>
				cancel={cancel}
				submitText='Create'
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
