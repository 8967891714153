import { useEffect } from 'react';
import { Trigger, useTrigger } from './useTrigger';

export function useIntervalTrigger(timeOutMs: number = 1000): Trigger {  
    const [trigger, hitTrigger] = useTrigger();
    
    useEffect(() => {
        let interval: NodeJS.Timeout;
        let cancelled = false;       

        if (cancelled === false) {
            interval = setInterval(() => {
                hitTrigger();
            }, timeOutMs);
        }
        return () => {
            clearInterval(interval);
            cancelled = true;
        }
        // eslint-disable-next-line
    }, []);

    return trigger;
}