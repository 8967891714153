import { Divider } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ICreateLabelRequest, IPatchLabelRequest, LinkContextType, labelsCommand_create, labelsCommand_patch } from 'gen/ApiClient';
import React, { useState } from 'react';
import { CreateOrPatchLabelComponent } from './CreateOrPatchLabelComponent';
import { LabelListItem } from './LabelListItem';
import { useLabelsForContextType } from './useLabelsForContext';

interface IProps {
	contextType: LinkContextType;
	style?: React.CSSProperties;
	className?: string;
}

export const LabelsList = ({ style, className, contextType }: IProps) => {
	const [labels, reload] = useLabelsForContextType(contextType);
	const [editId, setEditId] = useState<string | undefined | 'new'>();
	const [patch, isPatching] = useFormSubmit(labelsCommand_patch);
	const [create, isCreating] = useFormSubmit(labelsCommand_create);
	const notify = useSnackbarNotify();

	const onPatch = async (values: IPatchLabelRequest) => {
		await patch(values);
		setEditId(undefined);
		reload();
	};

	const onCreate = async (values: ICreateLabelRequest) => {
		const r = await create(values);
		if (r.isSuccess && r.result.hasError) {
			notify(r.result.error);
		} else {
			reload();
			setEditId(undefined);
		}
	};

	// const onDelete = (item: ILabelDto) => {
	// 	// alert dialog
	// };

	return (
		<div
			className={`df-col gap-4 ${className}`}
			style={style}>
			{labels.map(t => (
				<LabelListItem
					item={t}
					key={t.id}
					editId={editId}
					onEdit={() => setEditId(t.id)}
					clearEdit={() => setEditId(undefined)}
					onPatch={onPatch}
					isSubmitting={isPatching}
				/>
			))}
			<div
				className='df-col gap-8'
				style={{ paddingTop: 8 }}>
				<Divider />
				<TextLinkButton
					startIcon={<AddCircleOutlineIcon />}
					disabled={editId !== undefined}
					onClick={() => setEditId('new')}>
					{`Add new label`}
				</TextLinkButton>
				{editId === 'new' && (
					<CreateOrPatchLabelComponent
						item={{ caption: '', name: '', color: '' }}
						isSubmitting={isCreating}
						onClear={() => setEditId(undefined)}
						onSave={values => onCreate({ ...values, contextType: contextType })}
					/>
				)}
			</div>
		</div>
	);
};
