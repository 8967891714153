import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { DateFilterComponent } from 'framework/filter/date/DateFilterComponent';
import { FilterBar } from 'framework/filter/FilterBar';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { IUpdateScheduledTaskQueryParams, UpdateScheduledTaskStatus, UpdateScheduledTaskStatuses } from 'gen/ApiClient';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { UpdateScheduledTaskStatusRecord } from './UpdateScheduledTaskStatusRecord';

const exclusions: (keyof IUpdateScheduledTaskQueryParams)[] = ['keyFilterStatus'];

interface IProps {
	params: IUpdateScheduledTaskQueryParams;
	onFilter: (params: IUpdateScheduledTaskQueryParams) => void;
	paddingLeft: number;
}

export const Filter = ({ params, onFilter, paddingLeft }: IProps) => {
	const { queryResult } = useContext(QueryContext);
	const { quickFilter } = useContext(ViewContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<DateFilterComponent
				label='Execute After'
				after={params.executeAfterAfter}
				before={params.executeAfterBefore}
				setSelected={(after, before) => onFilter({ ...params, executeAfterAfter: after, executeAfterBefore: before })}
			/>
			{quickFilter === 'all' && (
				<RecordListFilterComponent<UpdateScheduledTaskStatus>
					record={UpdateScheduledTaskStatusRecord}
					options={UpdateScheduledTaskStatuses}
					selected={params.statuses as any}
					setSelected={vals => onFilter({ ...params, statuses: vals })}
					icon={<HelpOutlineIcon />}
					label={'Status'}
				/>
			)}
		</FilterBar>
	);
};
