import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SlowMotionVideoOutlinedIcon from '@material-ui/icons/SlowMotionVideoOutlined';
import { ISubscription } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: ISubscription;
	style?: React.CSSProperties;
}

export const SubscriptionListItemStatusIcon = ({ item, style }: IProps) => {
	if (item.type === 'History') {
		return <PauseCircleOutlineOutlinedIcon style={style} />;
	} else if (item.type === 'Future') {
		return <SlowMotionVideoOutlinedIcon style={style} />;
	} else if (item.hasExceededMaxSoldHearingAids) {
		return <ErrorOutlineIcon style={style} />;
	} else {
		return <PlayCircleOutlineIcon style={style} />;
	}
};
