import { Dialog, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectField } from 'framework/forms/FormSelectField';
import { FormSelectFieldFromStrings } from 'framework/forms/FormSelectFieldFromStrings';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IOption } from 'framework/IOption';
import { IUploadStandardDocumentRequest, LanguageOptions, templatesCommand_newStandardDocument, TemplateTypes } from 'gen/ApiClient';
import { LanguageOption } from 'localization/LanguageOption';
import { fileToBase64 } from 'shared/upload/fileToBase64';
import { UploadFile } from 'shared/upload/UploadFile';

const createSchema = () => {
	return yup
		.object<IUploadStandardDocumentRequest>({
			canEdit: yup.bool().defined(),
			contentAsBase64: yup.string(),
			name: yup.string().required(),
			predefinedType: yup.string(),
			mustSign: yup.bool().defined(),
			canViewOnline: yup.bool().defined(),
			language: yup.string().required(),
			validityEnd: yup.date().nullable() as any,
			validityStart: yup.date().nullable() as any,
			version: yup.string(),
		})
		.defined();
};

const emptyValues: IUploadStandardDocumentRequest = {
	canEdit: true,
	contentAsBase64: '',
	mustSign: false,
	canViewOnline: true,
	name: '',
	predefinedType: '',
	language: 'nl' as LanguageOption,
	validityStart: undefined,
	validityEnd: undefined,
	version: '',
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: () => void;
}

const stepsRecord: Record<number, (keyof IUploadStandardDocumentRequest)[]> = {
	0: ['predefinedType', 'name', 'language', 'version'],
	1: ['mustSign', 'canEdit', 'canViewOnline'],
	2: ['validityStart', 'validityEnd'],
	3: ['contentAsBase64'],
};

export const UploadStandardDocumentRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [create, isSubmitting] = useFormSubmit(templatesCommand_newStandardDocument);
	const [step, setStep] = useState<number>(0);

	const handleSubmit = async (values: IUploadStandardDocumentRequest, helpers: FormikHelpers<IUploadStandardDocumentRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IUploadStandardDocumentRequest>
			validateOnMount
			initialValues={emptyValues}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={createSchema()}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<IUploadStandardDocumentRequest>;
	cancel: () => void;
	stepsRecord: Record<number, (keyof IUploadStandardDocumentRequest)[]>;
}

const InnerDialog = ({ step, setStep, isSubmitting, schema, cancel, stepsRecord, ...rest }: IInnerProps) => {
	const props = useFormikContext<IUploadStandardDocumentRequest>();

	const onSelectFile = async (file: File) => {
		const content = await fileToBase64(file);
		setFormValue<IUploadStandardDocumentRequest>('contentAsBase64', content, props);
		props.submitForm();
	};

	return (
		<Dialog
			{...rest}
			maxWidth='lg'>
			<DialogTitleWithFormStepper
				title={`New standard document`}
				step={step}
				labels={[`Type & Name`, `Metadata`, `Upload`]}
			/>
			<OverflowDialogContent dividers>
				{step === 0 && (
					<>
						<FormTextField<IUploadStandardDocumentRequest>
							name='name'
							label={`Name`}
							required
						/>
						<FormTextField<IUploadStandardDocumentRequest>
							name='version'
							label={`Version`}
						/>
						<FormSelectFieldFromStrings<IUploadStandardDocumentRequest>
							name='language'
							label='Language'
							options={LanguageOptions}
						/>
						<FormAutocompleteField<IUploadStandardDocumentRequest, IOption<string>>
							name='predefinedType'
							label='Type'
							options={TemplateTypes.map<IOption<string>>(t => ({ id: t, identifier: t }))}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormSelectField<IUploadStandardDocumentRequest, boolean>
							label='Must Sign'
							name='mustSign'
							options={[true, false]}
							renderValue={t => (t === true ? 'Must sign' : 'No signature needed')}
							getKey={t => t.toString()}
						/>
						<FormSelectField<IUploadStandardDocumentRequest, boolean>
							label='Can edit'
							name='canEdit'
							options={[true, false]}
							renderValue={t => (t === true ? 'Can edit' : 'Cannot edit')}
							getKey={t => t.toString()}
						/>
						<FormSelectField<IUploadStandardDocumentRequest, boolean>
							label='Can view online'
							name='canViewOnline'
							options={[true, false]}
							renderValue={t => (t === true ? 'Can view online' : 'Cannot view online')}
							getKey={t => t.toString()}
						/>
					</>
				)}
				{step === 2 && (
					<>
						<FormDatePicker<IUploadStandardDocumentRequest>
							name='validityStart'
							label={`Validity Start`}
						/>
						<FormDatePicker<IUploadStandardDocumentRequest>
							name='validityEnd'
							label={`Validity End`}
						/>
					</>
				)}
				{step === 3 && (
					<UploadFile
						isUploading={isSubmitting}
						onFileSelected={onSelectFile}
						accept='word'
					/>
				)}
			</OverflowDialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={`Create`}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
