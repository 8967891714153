import React from 'react';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { auth_info } from 'gen/ApiClient';

export const AppAuthenticatedWall: React.FC = ({ children }: any) => {
    useApiEffect(auth_info);
  
    return (
        <>
            {children}
        </>
    );
  }