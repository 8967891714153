import { CardContent, CardContentProps } from '@material-ui/core';
import React from 'react';

export const DefaultCardContent = (props: CardContentProps) => {
	return (
		<CardContent
			{...props}
			style={{ ...props.style, paddingBottom: 16 }}
		/>
	);
};
