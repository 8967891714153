import React from 'react';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResult,
	IRizivHearingAidDefinitionV2,
	IRizivHearingAidDefinitionsV2QueryParams,
	RizivHearingAidDefinitionV2SearchMode,
	rizivHearingAidDefinitionsV2Query_concurrencyToken_app,
	rizivHearingAidDefinitionsV2Query_query_app,
} from 'gen/ApiClient';
import { rizivRefundableDevicesQueryParamsLsKey } from '../../../../localStorageKeys';
import { QueryContext } from './QueryContext';

const DefaultParams: IRizivHearingAidDefinitionsV2QueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'id' },
	pageParams: { index: 1, size: 10 },
	manufacturers: [],
	distributors: [],
	searchMode: 'AllFields' as RizivHearingAidDefinitionV2SearchMode,
};

export const QueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResult<IRizivHearingAidDefinitionV2>, IRizivHearingAidDefinitionsV2QueryParams>
			defaultParams={DefaultParams}
			queryF={rizivHearingAidDefinitionsV2Query_query_app}
			concurrencyF={rizivHearingAidDefinitionsV2Query_concurrencyToken_app}
			localStorageKey={rizivRefundableDevicesQueryParamsLsKey}
			context={QueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
