import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { LinkContextType, annotationsQuery_allForContext } from 'gen/ApiClient';
import React from 'react';
import AddCommentOutlinedIcon from '@material-ui/icons/AddCommentOutlined';
import { IReloadTriggerProps } from 'framework/components/layout/IReloadTriggerProps';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Divider } from '@material-ui/core';
import { AnnotationListItem } from './AnnotationListItem';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CreateAnnotationRequestForm } from './CreateAnnotationRequestForm';
import { isLast } from 'framework/utils/array/isLast';

interface IProps extends IReloadTriggerProps {
	contextId: string;
	contextType: LinkContextType;
	reload: VoidFunction;
}

export const AnnotationsCard = ({ contextId, contextType, reload, reloadTrigger }: IProps) => {
	const [annotations, reloadAnnotations] = useApiEffect(annotationsQuery_allForContext, contextId);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadAnnotations();
	}, [reloadTrigger]);

	if (annotations === undefined) {
		return <></>;
	}

	const onAdd = () => {
		open(
			<CreateAnnotationRequestForm
				open
				contextId={contextId}
				contextType={contextType}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<CardX className='df-col gap-8'>
			<Typography16pxBold>{`Annotations`}</Typography16pxBold>
			<TextLinkButton
				startIcon={<AddCommentOutlinedIcon />}
				onClick={onAdd}>
				{`Add annotation`}
			</TextLinkButton>
			{annotations.length > 0 && <Divider />}
			{annotations.map(t => (
				<AnnotationListItem
					key={t.id}
					item={t}
					reload={reload}
					withoutBorder={isLast(t, annotations)}
				/>
			))}
		</CardX>
	);
};
