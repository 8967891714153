import React from 'react';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { createSortableHeader } from 'framework/table/createSortableHeader';
import { IHeader } from 'framework/table/IHeader';
import { IClientBuildSummaryWithSelection } from './IClientBuildSummaryWithSelection';

export const createHeaders = (
	clients: IClientBuildSummaryWithSelection[],
	filtered: IClientBuildSummaryWithSelection[],
	setClients: React.Dispatch<React.SetStateAction<IClientBuildSummaryWithSelection[]>>
): IHeader<IClientBuildSummaryWithSelection>[] => {
	return [
		createSelectionHeader<IClientBuildSummaryWithSelection>(filtered, newLines => {
			const x = clients.map(client => ({ ...client, isSelected: newLines.find(x => x.name === client.name)?.isSelected ?? client.isSelected }));
			setClients(x);
		}),
		createSortableHeader<IClientBuildSummaryWithSelection>('Name', 'name', t => t.name),
		createSortableHeader<IClientBuildSummaryWithSelection>('Build', 'buildNumber', t => t.buildNumber.toString()),
	];
};
