import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { ApplyLabelsComponent } from 'app/main/labels/ApplyLabelsComponent';
import { RowWrappedLabelsNoRecordsFoundIfEmpty } from 'app/main/labels/RowWrappedLabelsNoRecordsFoundIfEmpty';
import { DetailPageSidebarCaptionAndIcon } from 'framework/components/layout/DetailPageSidebarCaptionAndIcon';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ISale, salesCommand_patchLabels } from 'gen/ApiClient';
import React from 'react';

const calculateIsDirty = (labelIds: string[], selectedLabelIds: string[]) => {
	if (labelIds.length === 0 && selectedLabelIds.length === 0) {
		return false;
	} else if (labelIds.length !== selectedLabelIds.length) {
		return true;
	} else if (selectedLabelIds.filter(t => labelIds.findIndex(x => x === t) === -1).length > 0) {
		return true;
	} else if (labelIds.filter(t => selectedLabelIds.findIndex(x => x === t) === -1).length > 0) {
		return true;
	} else {
		return false;
	}
};

interface IProps {
	item: ISale;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const SaleDetailPageSidebarLabels = ({ item, reload, style }: IProps) => {
	const [patch] = useFormSubmit(salesCommand_patchLabels);

	const onPatch = async (labelIds: string[]) => {
		if (calculateIsDirty(item.labelIds, labelIds)) {
			await patch({ salesId: item.id, labelIds: labelIds });
			reload();
		}
	};

	return (
		<div
			className='df-col fg1 gap-4'
			style={{ overflowY: 'hidden', ...style }}>
			<ApplyLabelsComponent
				contextType='Sale'
				labelIds={item.labelIds}
				onChange={onPatch}
				title='Apply labels to this sale'>
				<DetailPageSidebarCaptionAndIcon
					caption='Labels'
					icon={<SettingsOutlinedIcon />}
				/>
			</ApplyLabelsComponent>
			<div
				className='fg1'
				style={{ overflowY: 'auto' }}>
				<RowWrappedLabelsNoRecordsFoundIfEmpty labelIds={item.labelIds} />
			</div>
		</div>
	);
};
