import { BadgeChipX } from 'framework/components/chips/BadgeChipX';
import { BillingAccountsPrefilter, IBillingAccountsQueryMetadata } from 'gen/ApiClient';
import React from 'react';

export const createBillingAccountsPrefilterBadgeChipXRecord = (metadata: IBillingAccountsQueryMetadata): Record<BillingAccountsPrefilter, React.ReactNode> => {
	return {
		All: undefined,
		AlsoHamsClient: undefined,
		NotAllFielsFilledIn: metadata.countWithMissingData > 0 && (
			<BadgeChipX
				value={metadata.countWithMissingData}
				color='error'
			/>
		),
		WithOutstandingBalance: metadata.countWithOutstandingBalance > 0 && (
			<BadgeChipX
				value={metadata.countWithOutstandingBalance}
				color='warning'
			/>
		),
	};
};
