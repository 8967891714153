import { Grid } from '@material-ui/core';
import React from 'react';
import { DividerWithCaption } from '../DividerWithCaption';

interface IProps {
    caption: string;
}

export const GridDividerWithCaption = ({ caption }: IProps) => {
    return (
        <Grid item xs={12}>
            <DividerWithCaption caption={caption} />
        </Grid>
    );
}