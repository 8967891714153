import { CardProps, useTheme } from '@material-ui/core';
import { LicensePricingMethodRecord } from 'app/main/licenses/LicensePricingMethodRecord';
import { CardX } from 'framework/components/cards/CardX';
import { StatusIcon } from 'framework/components/icons/StatusIcon';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISubscription } from 'gen/ApiClient';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calcLicenseDetailRoute } from 'routes';
import { SubscriptionSaleContent } from './SubscriptionSaleContent';

const Width: number = 220;

interface IProps extends CardProps {
	item: ISubscription;
}

export const SubscriptionLicenseAndUsageCard = ({ item, ...rest }: IProps) => {
	const theme = useTheme();
	const { push } = useHistory();

	return (
		<CardX
			{...rest}
			className='df-row-wrap gap-16'>
			<div
				className='df-col gap-6'
				style={{ width: Width }}>
				<Typography16pxBold>{`License details`}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX
					label={`License name`}
					link={() => push(calcLicenseDetailRoute(item.licenseId))}>
					{item.license.name}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={`Category`}>{item.license.category}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label='Pricing Method'>{LicensePricingMethodRecord[item.license.pricingMethod]}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label='Fee'>{formatCurrency(item.license.fee)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label='Calculated License Fee'>{formatCurrency(item.calculatedLicenseFee)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label='Calculated Fee (license + costs)'>{formatCurrency(item.calculatedFee)}</CaptionVerticalLabelledPropertyX>
			</div>
			<div
				className='df-col gap-6'
				style={{ width: Width }}>
				<Typography16pxBold>{`License limits`}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX label='Max sold HA'>
					<div className='df-row-ac gap-4'>
						<StatusIcon
							status={item.hasExceededMaxSoldHearingAids ? 'error' : 'success'}
							fontSize='small'
						/>
						<span style={{ color: item.hasExceededMaxSoldHearingAids ? theme.palette.error.main : theme.palette.success.main }}>
							{`${item.license.maxSoldHADisplay} (act.: ${item.soldHearingAids})`}
						</span>
					</div>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label='Max registered users'>
					<div className='df-row-ac gap-4'>
						<StatusIcon
							status={item.hasExceededMaxUsers ? 'error' : 'success'}
							fontSize='small'
						/>
						<span style={{ color: item.hasExceededMaxUsers ? theme.palette.error.main : theme.palette.success.main }}>
							{`${item.license.maxRegisteredUsersDisplay} (act.: ${item.registeredUsers})`}
						</span>
					</div>
				</CaptionVerticalLabelledPropertyX>
			</div>
			<div
				className='df-col gap-6'
				style={{ width: Width }}>
				<Typography16pxBold>{`Usage stats`}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX label='# sold HA this period'>
					<div className='df-row-ac gap-4'>
						<StatusIcon
							status={item.hasExceededMaxSoldHearingAids ? 'error' : 'success'}
							fontSize='small'
						/>
						<span style={{ color: item.hasExceededMaxSoldHearingAids ? theme.palette.error.main : theme.palette.success.main }}>
							{`${item.soldHearingAids} (max: ${item.license.maxSoldHearingAids})`}
						</span>
					</div>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label='Sold HA previous period'>
					<span>{item.soldHearingAidsPreviousPeriod}</span>
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label='# registered users'>
					<div className='df-row-ac gap-4'>
						<StatusIcon
							status={item.hasExceededMaxUsers ? 'error' : 'success'}
							fontSize='small'
						/>
						<span style={{ color: item.hasExceededMaxUsers ? theme.palette.error.main : theme.palette.success.main }}>
							{`${item.registeredUsers} (max: ${item.license.maxRegisteredUsers})`}
						</span>
					</div>
				</CaptionVerticalLabelledPropertyX>
			</div>
			<div
				className='df-col gap-6'
				style={{ width: Width }}>
				<Typography16pxBold>{`Invoice details`}</Typography16pxBold>
				<CaptionVerticalLabelledPropertyX label='Sale'>
					{item.hasSale === true && (
						<SubscriptionSaleContent
							salesId={item.salesId!}
							subscription={item}
						/>
					)}
					{item.hasSale === false && <em>{`No sale created yet`}</em>}
				</CaptionVerticalLabelledPropertyX>
			</div>
		</CardX>
	);
};
