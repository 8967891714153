import { Collapse } from '@material-ui/core';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import { ColorX } from 'framework/components/color/ColorX';
import { TextLinkButtonWithExpandIcon } from 'framework/components/buttons/TextLinkButtonWithExpandIcon';
import { useThemeColor } from 'framework/components/color/useThemeColor';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISalesLine } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const MajorFontSize = 14;
const MinorFontSize = 13;

interface IProps {
	line: ISalesLine;
	withoutBorder?: boolean;
	isExpanded: boolean;
	toggle: VoidFunction;
}

export const SalesLineListItem = ({ line, withoutBorder, isExpanded, toggle }: IProps) => {
	const strings = useLocalization();
	const color: ColorX = 'info';
	const tColor = useThemeColor(color);

	return (
		<div
			className='df-row w100'
			style={{
				borderBottom: withoutBorder === true ? 'unset' : '1px solid rgba(224, 224, 224, 1)',
				padding: '12px 0px',
			}}>
			<div style={{ marginRight: 8, color: tColor, fontSize: 22 }}>
				<BlurCircularIcon style={{ fontSize: 22, color: tColor }} />
			</div>
			<div className='df-col fg1 gap-4'>
				<span style={{ fontSize: MajorFontSize, whiteSpace: 'pre-line' }}>{`${line.quantity} x ${line.description}`}</span>
				<TextLinkButtonWithExpandIcon
					toggle={toggle}
					isExpanded={isExpanded}>
					<span style={{ fontSize: MinorFontSize, fontWeight: 'unset' }}>
						{`${line.vatPercentage}% ${strings.vat}, ${formatCurrency(line.linePriceInVat)} ${strings.inVatShort}`}
					</span>
				</TextLinkButtonWithExpandIcon>
				<Collapse in={isExpanded}>
					<div className='df-col gap-4'>
						<span style={{ fontSize: MinorFontSize }}>
							{`${strings.unitListPrice}: ${formatCurrency(line.unitListPriceExVat)} (${strings.exVatShort}) | ${formatCurrency(line.unitListPriceInVat)} (${
								strings.inVatShort
							})`}
						</span>
						<span style={{ fontSize: MinorFontSize }}>
							{`${strings.discount}: ${line.discountPercentage.toFixed(2)}% | ${formatCurrency(line.lineDiscountValueExVat)} (${
								strings.exVatShort
							}) | ${formatCurrency(line.lineDiscountValueInVat)} (${strings.inVatShort})`}
						</span>
						<span style={{ fontSize: MinorFontSize }}>{`${strings.vat}: ${line.vatPercentage}% | ${formatCurrency(line.lineVatValue)}`}</span>
					</div>
				</Collapse>
			</div>
		</div>
	);
};
