import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormIntegerField } from 'framework/forms/FormIntegerField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IPatchTimeWindowUstRequest, IUpdatedScheduledTaskDto, updateScheduledTasksCommand_patchWindow } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IPatchTimeWindowUstRequest>({
			ustId: yup.string().required(),
			windowAfterHour: yup.number().required(),
			windowBeforeHour: yup.number().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	ust: IUpdatedScheduledTaskDto;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchUstTimeWindowForm = ({ ust, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(updateScheduledTasksCommand_patchWindow);

	const handleSubmit = async (values: IPatchTimeWindowUstRequest, helpers: FormikHelpers<IPatchTimeWindowUstRequest>) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IPatchTimeWindowUstRequest>
			validateOnMount
			validationSchema={createSchema()}
			initialValues={{
				ustId: ust.id,
				windowAfterHour: ust.executionTimeWindow.after.hour,
				windowBeforeHour: ust.executionTimeWindow.before.hour,
			}}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					fullWidth
					{...rest}>
					<DialogTitle>{`PATCH time window`}</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormIntegerField<IPatchTimeWindowUstRequest>
							name='windowAfterHour'
							label='AFTER hour'
						/>
						<FormIntegerField<IPatchTimeWindowUstRequest>
							name='windowBeforeHour'
							label='BEFORE hour'
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions
						submitText={`PATCH`}
						isSubmitting={isSubmitting}
						cancel={cancel}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
