import { Typography, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Color } from '@material-ui/lab';
import React, { ReactElement } from 'react';
import { NoRecordsFound } from 'shared/NoRecordsFound';
import { useStateBoolean } from '../../hooks/useStateBool';
import { colorToThemeColor } from '../color/colorToThemeColor';

export interface ILabelledPropertyProps {
	label?: string;
	property?: string | number | undefined;
	icon?: ReactElement<any, any>;
	edit?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	undefinedText?: string;
	children?: any;
	isUndefined?: boolean;
	navigate?: () => void;
	withoutMargin?: boolean;
	variant?: 'body2' | 'caption';
	color?: Color | 'primary';
	editIcon?: React.ReactNode;
	secondaryAction?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	secondaryActionIcon?: React.ReactNode;
	align?: 'flex-start' | 'center' | 'flex-end';
	spacing?: number;
	style?: React.CSSProperties;
	onClick?: () => void;
	propertyStyle?: React.CSSProperties;
}

export const LabelledProperty = ({
	propertyStyle,
	onClick,
	label,
	property,
	icon,
	edit,
	undefinedText = 'n/a',
	children,
	isUndefined = false,
	navigate,
	withoutMargin = false,
	variant = 'body2',
	color = 'info',
	editIcon,
	secondaryAction,
	secondaryActionIcon,
	align = 'flex-start',
	spacing = 6,
	style,
}: ILabelledPropertyProps) => {
	const [isHover, enter, leave] = useStateBoolean(false);
	const theme = useTheme();
	const iconFontSize: number = variant === 'caption' ? 15 : 20;

	return (
		<div
			className='df-row'
			onMouseEnter={enter}
			onMouseLeave={leave}
			style={{
				marginTop: withoutMargin ? 0 : spacing,
				marginBottom: withoutMargin ? 0 : spacing,
				justifyContent: align,
				cursor: onClick ? 'pointer' : 'inherit',
				...style,
			}}
			onClick={onClick}>
			{/* {icon && <div className="df-col-ac icon-wrapper" style={{ marginRight: theme.spacing(1), fontSize: iconFontSize, color: theme.palette.text.secondary }}>{icon}</div>} */}
			{icon && (
				<div
					className='df-col-ac icon-wrapper jc-c'
					style={{ marginRight: theme.spacing(1), fontSize: iconFontSize, color: colorToThemeColor(color, theme, theme.palette.text.secondary) }}>
					{icon}
				</div>
			)}
			<div
				className='df-row-ab'
				style={{ color: colorToThemeColor(color, theme) }}>
				{label && (
					<Typography
						variant={variant}
						style={{ marginRight: theme.spacing(0.5), color: colorToThemeColor(color, theme, theme.palette.text.secondary) }}>
						{label}:
					</Typography>
				)}
				{property && !children && (
					<Typography
						variant={variant}
						style={{
							whiteSpace: 'pre-line',
							cursor: navigate ? 'pointer' : 'inherit',
							color: colorToThemeColor(color, theme, theme.palette.text.primary),
							...propertyStyle,
						}}
						onClick={navigate && navigate}>
						{property}
					</Typography>
				)}
				{children && children}
				{((!property && !children) || isUndefined) && <NoRecordsFound>{undefinedText}</NoRecordsFound>}
			</div>
			{navigate && (
				<OpenInNewIcon
					fontSize='small'
					style={{ cursor: 'pointer', visibility: isHover ? 'inherit' : 'inherit', color: theme.palette.primary.main, marginLeft: spacing }}
					onClick={navigate}
				/>
			)}
			{edit && (
				<div
					className='df-col-ac icon-wrapper'
					style={{
						marginLeft: spacing,
						fontSize: iconFontSize,
						color: theme.palette.primary.main,
						cursor: 'pointer',
						visibility: isHover ? 'inherit' : 'hidden',
					}}
					onClick={edit}>
					{editIcon ? editIcon : <EditIcon />}
				</div>
			)}
			{secondaryAction && (
				<div
					className='df-col-ac icon-wrapper'
					style={{
						marginLeft: spacing,
						fontSize: iconFontSize,
						color: theme.palette.primary.main,
						cursor: 'pointer',
						visibility: isHover ? 'inherit' : 'hidden',
					}}
					onClick={secondaryAction}>
					{secondaryActionIcon ? secondaryActionIcon : <HelpOutlineIcon />}
				</div>
			)}
		</div>
	);
};
