import { useTheme } from '@material-ui/core';
import { TooltipWithSpan } from 'framework/components/TooltipWithSpan';
import { ILabelDto } from 'gen/ApiClient';
import React from 'react';
import { useMapLabelColor } from './useMapLabelColor';

interface IProps {
	item: ILabelDto;
	style?: React.CSSProperties;
}

export const LabelX = ({ item, style }: IProps) => {
	if (item.caption) {
		return (
			<TooltipWithSpan title={item.caption}>
				<LabelDiv
					item={item}
					style={style}
				/>
			</TooltipWithSpan>
		);
	}

	return (
		<LabelDiv
			item={item}
			style={style}
		/>
	);
};

const LabelDiv = ({ item, style }: IProps) => {
	const theme = useTheme();
	const xColor = useMapLabelColor(item);

	return (
		<div
			style={{
				paddingLeft: 7,
				paddingRight: 7,
				backgroundColor: xColor,
				color: theme.palette.getContrastText(xColor),
				fontSize: 12,
				// lineHeight: 18,
				verticalAlign: 'middle',
				borderRadius: 24,
				...style,
			}}>
			{item.name}
		</div>
	);
};
