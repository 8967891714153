import { FormControl } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { AutocompleteSingle } from '../components/autocomplete/AutocompleteSingle';
import { IOption } from '../IOption';
import { nameOf } from '../utils/nameOf';
import { FormErrorHelper } from './common/FormErrorHelper';
import { IFormProps } from './common/IFormProps';

interface IProps<TModel, TValue extends IOption<string>> extends IFormProps<TModel> {
	options: TValue[];
}

export const FormAutocompleteField = <TModel extends unknown, TValue extends IOption<string>>({ options, ...props }: IProps<TModel, TValue>) => {
	const [field, meta, helpers] = useField<string | undefined>(nameOf(props.name));

	return (
		<FormControl
			variant='filled'
			fullWidth
			disabled={props.disabled}
			error={meta.error && meta.touched ? true : false}>
			<AutocompleteSingle
				style={{ paddingBottom: 0 }}
				options={options}
				selectedId={field.value}
				setSelectedId={id => {
					helpers.setTouched(true);
					helpers.setValue(id);
				}}
				required={props.required}
				disabled={props.disabled}
				label={props.label}
				error={meta.error && meta.touched ? true : false}
				// helperText={props.helperText}
			/>
			<FormErrorHelper
				meta={meta}
				helperText={props.helperText}
			/>
		</FormControl>
	);
};
