import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AscSortPropertyRecord } from 'framework/components/lists/AscSortPropertyRecord';
import { DescSortPropertyRecord } from 'framework/components/lists/DescSortPropertyRecord';
import { SortPropertyDescriptor } from 'framework/components/lists/SortPropertyDescriptor';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { createType } from 'framework/utils/createType';
import { ISale, ISortParams } from 'gen/ApiClient';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	sortParams: ISortParams<ISale>;
	onFilter: (sp: ISortParams<ISale>) => void;
}

const SaleOrderableProperties = createType('date', 'payBefore', 'reference', 'priceExVat');
type SaleOrderableProperty = (typeof SaleOrderableProperties)[number];

const createOrderablePropertyRecord = (strings: IStrings): Record<SaleOrderableProperty, string> => {
	return {
		date: strings.date,
		payBefore: `Pay before`,
		reference: strings.reference,
		priceExVat: strings.priceExVat,
	};
};

const OrderablePropertyToSortPropertyDescriptorRecord: Record<SaleOrderableProperty, SortPropertyDescriptor> = {
	date: 'date',
	reference: 'string',
	payBefore: 'date',
	priceExVat: 'price',
};

export const SalesListOrderBySelect = ({ sortParams, onFilter }: IProps) => {
	const strings = useLocalization();
	const [anchor, open, close] = useAnchorElement();
	const OrderablePropertyRecord = React.useMemo(() => createOrderablePropertyRecord(strings), [strings]);

	const title = React.useMemo(() => {
		if (SaleOrderableProperties.indexOf(sortParams.property as any) > -1) {
			const prop = sortParams.property as SaleOrderableProperty;
			return `${OrderablePropertyRecord[prop]} (${
				sortParams.direction === 'asc'
					? AscSortPropertyRecord[OrderablePropertyToSortPropertyDescriptorRecord[prop]]
					: DescSortPropertyRecord[OrderablePropertyToSortPropertyDescriptorRecord[prop]]
			})`;
		} else {
			return strings.unknown;
		}
		// eslint-disable-next-line
	}, [strings, sortParams]);

	const onSelect = (property: SaleOrderableProperty & keyof ISale, direction: 'asc' | 'desc') => {
		onFilter({ property: property, direction: direction });
		close();
	};

	return (
		<>
			<Button
				size='small'
				endIcon={<ArrowDropDownIcon />}
				onClick={open}
				style={{ textTransform: 'unset' }}>
				{`Sort on ${title}`}
			</Button>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={close}
				keepMounted>
				{SaleOrderableProperties.map(prop => (
					<div key={prop}>
						<MenuItem
							key={`asc-${prop}`}
							onClick={() => onSelect(prop, 'asc')}>
							{`Sort on ${OrderablePropertyRecord[prop]} (${AscSortPropertyRecord[OrderablePropertyToSortPropertyDescriptorRecord[prop]]})`}
						</MenuItem>
						<MenuItem
							key={`desc-${prop}`}
							onClick={() => onSelect(prop, 'desc')}>
							{`Sort on ${OrderablePropertyRecord[prop]} (${DescSortPropertyRecord[OrderablePropertyToSortPropertyDescriptorRecord[prop]]})`}
						</MenuItem>
					</div>
				))}
			</Menu>
		</>
	);
};
