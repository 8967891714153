import { useTrigger } from 'framework/hooks/useTrigger';
import React from 'react';
import { Page } from 'framework/components/layout/Page';
import { SoftwareCard } from './SoftwareCard';

export const SoftwarePage = () => {
	const [trigger, hitTrigger] = useTrigger();

	return (
		<Page
			showHome
			title={`Software`}
			reload={hitTrigger}>
			<>
				<SoftwareCard
					reloadTrigger={trigger}
					name='Client'
					style={{ marginBottom: 16 }}
				/>
				<SoftwareCard
					reloadTrigger={trigger}
					name='Eid'
					style={{ marginBottom: 16 }}
				/>
				<SoftwareCard
					reloadTrigger={trigger}
					name='ImageScanner'
					style={{ marginBottom: 16 }}
				/>
				<SoftwareCard
					reloadTrigger={trigger}
					name='ThermalPrinter'
					style={{ marginBottom: 16 }}
				/>
			</>
		</Page>
	);
};
