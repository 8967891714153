import { DividerWithMargin } from 'framework/components/DividerWithMargin';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { formatDate } from 'framework/utils/date/formatDate';
import { ISubscription } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { calcClientDetailRoute, calcLicenseDetailRoute, calculateSalesDetailRoute } from 'routes';
import { SubscriptionDetailActionsContent } from './SubscriptionDetailActionsContent';
import { SubscriptionHistoryContent } from './SubscriptionHistoryContent';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';

interface IProps {
	item: ISubscription;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const SubscriptionDetailPageSidebar = ({ item, reload, style }: IProps) => {
	const strings = useLocalization();
	const { push } = useHistory();

	return (
		<div
			className='df-col h100'
			style={{
				padding: 16,
				paddingRight: 8,
				...style,
			}}>
			<div className='df-col gap-6'>
				<CaptionVerticalLabelledPropertyX label={strings.from}>{formatDate(item.from)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={strings.to}>{formatDate(item.to)}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={`# months`}>{item.monthsInWindow}</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`Client`}
					link={() => push(calcClientDetailRoute(item.clientId))}>
					{item.clientName}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX
					label={`License`}
					link={() => push(calcLicenseDetailRoute(item.licenseId))}>
					{item.licenseName}
				</CaptionVerticalLabelledPropertyX>
				<CaptionVerticalLabelledPropertyX label={`License-Category`}>{item.licenseCategory}</CaptionVerticalLabelledPropertyX>
				{item.hasSale && (
					<CaptionVerticalLabelledPropertyX
						label={`Links`}
						link={() => push(calculateSalesDetailRoute(item.salesId!))}>
						{`Sale`}
					</CaptionVerticalLabelledPropertyX>
				)}
			</div>
			<DividerWithMargin marginTop={16} />
			<div className='df-col gap-8'>
				<Typography16pxBold>{`History`}</Typography16pxBold>
				<SubscriptionHistoryContent item={item} />
			</div>
			<div className='fg1'></div>
			<DividerWithMargin />
			<SubscriptionDetailActionsContent
				item={item}
				reload={reload}
			/>
		</div>
	);
};
