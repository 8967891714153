import React, { useState } from 'react';
import { v1 as uuid } from 'uuid';
import { useTrigger } from 'framework/hooks/useTrigger';
import { DialogsContext } from './DialogsContext';
import { KeysFormattingProvider } from './templates/KeysFormattingProvider';
import { TemplateEditorDialogProvider } from './templates/TemplateEditorDialogProvider';
import { DocumentEditorDialogProvider } from './DocumentEditorDialogProvider';

interface IDialog {
	uid: string;
	content: JSX.Element;
	reload?: () => void;
}

export const DialogsProvider = ({ children }: any) => {
	const [trigger, hitTrigger] = useTrigger();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const queueRef = React.useRef<IDialog[]>([]);

	const onOpen = (element: JSX.Element, reload?: () => void) => {
		const uid = uuid();
		queueRef.current.push({ uid: uid, content: element, reload: reload });
		processQueue();
	};

	const processQueue = () => {
		hitTrigger(); // needs to do this in order to rerender!
		if (queueRef.current.length > 0) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	};

	const onConfirm = () => {
		const popped = queueRef.current.pop();
		if (popped && popped.reload) {
			popped.reload();
		}
		processQueue();
	};

	const onCancel = () => {
		queueRef.current.pop();
		processQueue();
	};

	return (
		<DialogsContext.Provider
			value={{
				isOpen: queueRef.current.length > 0,
				open: onOpen,
				trigger: trigger,
				confirm: onConfirm,
				cancel: onCancel,
				isStacked: queueRef.current.length > 1,
				stackCount: queueRef.current.length,
			}}>
			<KeysFormattingProvider>
				<TemplateEditorDialogProvider>
					<DocumentEditorDialogProvider>
						<>
							{isOpen && queueRef.current.length > 0 && queueRef.current.map(t => <React.Fragment key={t.uid}>{t.content}</React.Fragment>)}
							{children}
						</>
					</DocumentEditorDialogProvider>
				</TemplateEditorDialogProvider>
			</KeysFormattingProvider>
		</DialogsContext.Provider>
	);
};
