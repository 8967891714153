import { Divider } from '@material-ui/core';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { hifInfoJobCommand_import, hifJobInfoQuery_export } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { UploadFileDialog } from 'shared/upload/UploadFileDialog';

interface IProps {
	style?: React.CSSProperties;
}

export const HifsCardX = ({ style }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();
	const [importF] = useFormSubmit(hifInfoJobCommand_import);
	const [exportF, isExporting] = useDownload(hifJobInfoQuery_export);

	const onImport = () => {
		open(
			<UploadFileDialog
				open
				cancel={cancel}
				confirm={confirm}
				accept='excel'
				title='Upload XLSX file HIFS'
				confirmF={async file => {
					await importF({ ...file, password: '' });
				}}
				isUploading={false}
			/>
		);
	};

	const onExport = async () => {
		await exportF();
	};

	return (
		<CardX>
			<Typography16pxBold>{`HIF's`}</Typography16pxBold>
			<div className='df-row-ac gap-4'>
				<SmallPrimaryTextButton
					onClick={onExport}
					disabled={isExporting}>
					Export
				</SmallPrimaryTextButton>
				<Divider
					orientation='vertical'
					flexItem
					style={{ margin: '4px' }}
				/>
				<SmallPrimaryTextButton onClick={onImport}>Import</SmallPrimaryTextButton>
			</div>
		</CardX>
	);
};
