import { useCallback } from "react";
import { useApi } from "./useApi";
import { Try } from "../Try";

export function useApiCall<TResult>(callback: () => Promise<Try<TResult>>, setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>): () => Promise<Try<TResult>>;
export function useApiCall<TArgs extends any[], TResult>(callback: (...args: TArgs) => Promise<Try<TResult>>, setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>): (...args: TArgs) => Promise<Try<TResult>>;

export function useApiCall<TArgs extends any[], TResult>(callback: (...args: TArgs) => Promise<Try<TResult>>, setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>): (...args: TArgs) => Promise<Try<TResult>> {
    const [setLoading, clearLoading, handleError] = useApi();

    return useCallback(async (...args: TArgs) => 
    {
        // setIsLoading ? setIsLoading(true) : setIsGlobalLoading(true);
        console.log(`load useApiCall ${callback.name}`);
        const loadId = setLoading();
        setIsLoading && setIsLoading(true);
        const r = await callback(...args);
        if (r.isFailure) {
            handleError(r.error);
        }
        clearLoading(loadId);
        setIsLoading && setIsLoading(false);
        // setIsLoading ? setIsLoading(false) : setIsGlobalLoading(false);
        return r;
    // missing deps handleError -> is a function
    // eslint-disable-next-line 
    }, [callback, setIsLoading])
}