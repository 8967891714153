import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IAddBillingAccountEmailRequest, billingAccountsCommand_addEmail } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IAddBillingAccountEmailRequest>({
			email: yup.string().email().required(),
			id: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	billingAccountId: string;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const AddBillingAccountEmailRequestForm = ({ billingAccountId, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(billingAccountsCommand_addEmail);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IAddBillingAccountEmailRequest, helpers: FormikHelpers<IAddBillingAccountEmailRequest>) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`Email succesfully added`, 'success');
		}
	};

	return (
		<Formik<IAddBillingAccountEmailRequest>
			validateOnMount
			initialValues={{
				id: billingAccountId,
				email: '',
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Add e-mail on billing account`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IAddBillingAccountEmailRequest>
							name='email'
							label='Email'
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IAddBillingAccountEmailRequest>
						cancel={cancel}
						submitText='Add'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
