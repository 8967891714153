import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CalculatorIcon = ({ viewBox = "0 0 24 24", ...props }: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox={viewBox}>
            <path d="M7,2H17A2,2 0 0,1 19,4V20A2,2 0 0,1 17,22H7A2,2 0 0,1 5,20V4A2,2 0 0,1 7,2M7,4V8H17V4H7M7,10V12H9V10H7M11,10V12H13V10H11M15,10V12H17V10H15M7,14V16H9V14H7M11,14V16H13V14H11M15,14V16H17V14H15M7,18V20H9V18H7M11,18V20H13V18H11M15,18V20H17V18H15Z" />
        </SvgIcon>
    );
}
