import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { ExtendedRadioGroup } from 'framework/components/ExtendedRadioGroup';
import { RootTemplateType } from 'gen/ApiClient';
import React, { useState } from 'react';

interface IProps extends DialogProps {
	confirm: (val: RootTemplateType) => void;
	cancel: VoidFunction;
}

export const UploadTemplateModeForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [selected, setSelected] = useState<RootTemplateType>('RootTemplate');

	return (
		<Dialog
			{...rest}
			scroll='paper'>
			<DialogTitle>{`Select type`}</DialogTitle>
			<DialogContent dividers>
				<ExtendedRadioGroup<RootTemplateType>
					selected={selected}
					setSelected={setSelected}
					options={[
						{ value: 'ExampleTemplate', label: 'Example template', caption: 'All templates for HAMS clients' },
						{ value: 'StandardDocument', label: 'Standard document', caption: 'cfr. att.12, COSI, ...' },
						{ value: 'RootTemplate', label: 'Root Template', caption: 'Only to be used internally' },
					]}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={cancel}
					color='primary'>
					{`Cancel`}
				</Button>
				<Button
					onClick={() => confirm(selected)}
					color='primary'>
					{`Confirm choice`}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
