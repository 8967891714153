import { Chip, Theme, Tooltip, useTheme } from '@material-ui/core';
import { Color } from '@material-ui/lab';
import React from 'react';
import { StatusChipSize } from './StatusChipSize';

const commonStyle: React.CSSProperties = {
    borderRadius: 0,
    textTransform: 'uppercase',
    fontSize: '10px',
    height: '22px'
}


const toColor = (color: Color, theme: Theme): string => {
    if (color === 'success') {
        return theme.palette.success.main;
    } else if (color === 'warning') {
        return theme.palette.warning.main;
    } else if (color === 'error') {
        return theme.palette.error.main;
    } else {
        return theme.palette.grey[600];
    }
}

export interface IStatusChipProps {
    color?: Color;
    label: string;
    style?: React.CSSProperties;
    fill?: boolean;
    tooltip?: string;
    strikeThrough?: boolean;
    onClick?: () => void;
    size?: StatusChipSize;
}

export const StatusChip = ({ label, color = 'info', style, fill = false, tooltip, strikeThrough = false, onClick, size = 'small' }: IStatusChipProps) => {
    const theme = useTheme();
    const selColor = toColor(color, theme);

    const inner = <Chip
        variant="outlined"
        size="small"
        label={<div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{label}</div>}
        className="remove-inner-span-padding"
        onClick={onClick}
        style={{
            ...commonStyle,
            ...style,
            paddingLeft: size === 'small' ? theme.spacing(1) : theme.spacing(0.5),
            paddingRight: size === 'small' ? theme.spacing(1) : theme.spacing(0.5),
            height: size === 'small' ? '22px' : '20px',
            color: fill ? theme.palette.primary.contrastText : selColor,
            borderColor: selColor,
            backgroundColor: fill ? selColor : 'inherit',
            textDecoration: strikeThrough ? 'line-through' : 'inherit',
            cursor: onClick ? 'pointer' : 'inherit'
        }} />

    return (
        <>
            {tooltip
                ? <Tooltip title={tooltip}>{inner}</Tooltip>
                : inner
            }
        </>
    );
}