import { TimeUnit } from "framework/forms/TimeUnit";
import { ITimeUnitTranslation } from "./ITimeUnitTranslation";

export const renderTimeUnitValue = (value: number, unit: TimeUnit, record: Record<TimeUnit, ITimeUnitTranslation>): string => {
    if (unit === null || unit === undefined) {
        return '';
    } else if (value <= 0) {
        return record[unit].unknown.toLocaleLowerCase();
    } else if (value === 1) {
        return record[unit].singular.toLocaleLowerCase();
    } else {
        return record[unit].plural.toLocaleLowerCase();
    }
}