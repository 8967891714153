import { List } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import { Searchbar } from "framework/components/Searchbar";
import { IDocumentContextKey, IDocumentKeyDto } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import { DocumentContextListComponent } from './DocumentContextListComponent';

interface IFilterData {
    searchString: string;
    contextKeys: string[];
}

interface IDocumentKeyView {
    context: IDocumentContextKey;
    data: IDocumentKeyDto;
}

interface IDocumentKeyGroupedView {
    contextKey: string;
    contextDescription: string;
    keys: IDocumentKeyDto[];
}

interface IProps {
    keys: IDocumentContextKey[];
}

export const DocumentKeysComponent = ({ keys }: IProps) => {
    const strings = useLocalization();
    const [selectedKey, setSelectedKey] = useState<string>();
    const [params, setParams] = useState<IFilterData>({ searchString: '', contextKeys: keys.map(t => t.key!) });
    const [filtered, setFiltered] = useState<IDocumentKeyView[]>([]);
    const keyViews = useMemo(() => keys.flatMap(t => t.keys.filter(t => t.isPublic).map<IDocumentKeyView>(x => ({ context: t, data: x }))), [keys]);
    const mappedFiltered = useMemo(() => {
        const grouped = _.groupBy(filtered, t => t.context.key);
        return Object.keys(grouped).map<IDocumentKeyGroupedView>(t => ({ contextKey: grouped[t][0].context.key!, contextDescription: grouped[t][0].context.description!, keys: grouped[t].map(t => t.data).filter(t => t.isPublic) }));
    }, [filtered]);

    useEffect(() => {
        const x = params.searchString
            .split(' ')
            .reduce((acc, current) => acc.filter(t => `${t.data.key} ${t.data.description}`.toLowerCase().includes(current.toLowerCase())), keyViews);
        setFiltered(x);
    }, [params, keyViews]);

    return (
        <div className="df-col fg1" style={{ width: '500px', overflowY: 'auto' }}>
            <div className="df-col jc-c" style={{
                padding: 16,
                fontSize: '13px',
                lineHeight: '24px',
                color: 'rgba(0,0,0,.54)',
                background: '#f7f7f7',
                fontWeight: 500,
                paddingTop: 0,
                paddingBottom: 0,
                textTransform: 'uppercase',
                minHeight: '38px',
                position: 'sticky',
                top: 0,
                zIndex: 1
            }}>Keys</div>
            <Searchbar
                value={params.searchString}
                onSearch={text => setParams({ ...params, searchString: text })}
                placeholder={strings.searchVerb}
                style={{ width: '100%' }}
                variant="embedded" />
            <DividerWithCaption caption={strings.filteredResults} style={{ paddingTop: 0, marginTop: -1 }} />
            <List dense>
                {mappedFiltered.map(t => <DocumentContextListComponent
                    key={t.contextKey}
                    contextDescription={t.contextDescription}
                    keys={t.keys}
                    isExpanded={t.contextKey === selectedKey}
                    onClick={() => selectedKey === t.contextKey ? setSelectedKey(undefined) : setSelectedKey(t.contextKey)} />)}
            </List>
        </div>
    );
}
