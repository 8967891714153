import { Collapse, Divider, useTheme } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { ExpandLessOrMoreIcon } from 'framework/components/ExpandLessOrMoreIcon';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import { formatDate } from 'framework/utils/date/formatDate';
import { IInvoiceEmail, salesCommand_deleteManualEmail } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';

interface IProps {
	item: IInvoiceEmail;
	salesId: string;
	reload: VoidFunction;
	isLast?: boolean;
	style?: React.CSSProperties;
}

export const InvoiceEmailProcessComponent = ({ item, salesId, reload, isLast, style }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);
	const [isExpanded, toggleIsExpanded] = useStateToggleBoolean(false);
	const theme = useTheme();

	const onDeleteEmail = () => {
		open(
			<AlertDialog
				open
				title={'Delete manual email'}
				content={`Do you want to delete this manual email?`}
				acceptText={'Yes'}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => salesCommand_deleteManualEmail({ salesId: salesId, emailId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<div
			className='df-col gap-4'
			style={style}>
			<TextLinkButton
				onClick={toggleIsExpanded}
				style={{ gap: 4 }}>
				<FiberManualRecordIcon
					style={{
						color: theme.palette.text.secondary,
						fontSize: 12,
					}}
				/>
				<span style={{ fontSize: 12, fontWeight: 'bold' }}>{formatDate(item.dateSent)}</span>
				<span style={{ fontSize: 12 }}>{item.type.toString()}</span>
				{/* <div className='fg1'></div> */}
				<ExpandLessOrMoreIcon
					style={{ fontSize: 16, color: theme.palette.text.secondary }}
					isExpanded={isExpanded}
				/>
			</TextLinkButton>
			<Collapse in={isExpanded}>
				<div className='df-col gap-2'>
					<span style={{ fontSize: 12 }}>{`Method: ${item.method.toString()}`}</span>
					<span style={{ fontSize: 12, fontWeight: 'bold' }}>{`Sent to:`}</span>
					<div className='df-col'>
						{item.recipients.map(t => (
							<span
								style={{ fontSize: 12 }}
								key={t}>
								{`- ${t}`}
							</span>
						))}
					</div>
					{item.method === 'ManualEntry' && (
						<TextLinkButton
							startIcon={<DeleteOutline />}
							onClick={onDeleteEmail}>
							{`Delete registration`}
						</TextLinkButton>
					)}
				</div>
			</Collapse>
			{isLast === false && <Divider variant='inset' />}
		</div>
	);
};
