import { Table, TableBody } from '@material-ui/core';
import { SubscriptionTableRow } from 'app/main/subscriptions/list/SubscriptionTableRow';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { subscriptionsQuery_forBillingAccount } from 'gen/ApiClient';
import React from 'react';
import { NoRecordsFound } from 'shared/NoRecordsFound';

interface IProps {
	billingAccountId: string;
	reloadTrigger: Trigger;
}

export const BillingAccountDetailSubscriptionsCard = ({ billingAccountId, reloadTrigger }: IProps) => {
	const [values, reloadSubscriptions] = useApiEffect(subscriptionsQuery_forBillingAccount, billingAccountId);

	useLazyEffect(() => {
		reloadSubscriptions();
	}, [reloadTrigger]);

	if (values === undefined) {
		return <></>;
	}

	return (
		<CardX
			className='df-col gap-8'
			removeHorizontalPadding>
			<Typography16pxBold style={{ paddingLeft: 16 }}>{`Subscriptions`}</Typography16pxBold>
			{values.length === 0 && <NoRecordsFound style={{ paddingLeft: 16 }}>{`No subscriptions yet`}</NoRecordsFound>}
			<Table>
				<TableBody>
					{values.map(t => (
						<SubscriptionTableRow
							item={t}
							hideClient
						/>
					))}
				</TableBody>
			</Table>
		</CardX>
	);
};
