import { useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';

interface IProps {
    val: boolean;
}

export const ErrorOrSuccessIcon = ({ val }: IProps) => {
    const theme = useTheme();
    if (val === true) {
        return <CheckIcon fontSize="small" style={{ color: theme.palette.success.main }} />
    } else {
        return <ErrorOutlineIcon fontSize="small" style={{ color: theme.palette.error.main }} />
    }
}