// TODO -> make this functional code
export const reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

// export const moveUp = (val: TProp) => {
//     if (ordered.length > 0 && val !== ordered[0]) {
//         const index = ordered.indexOf(val);
//         setOrdered([...ordered.slice(0, index - 1), val, ordered[index - 1], ...ordered.slice(index + 1)]);
//     }
// }

// const moveDown = (val: TProp) => {
//     if (ordered.length > 0 && val !== ordered[ordered.length - 1]) {
//         const index = ordered.indexOf(val);
//         setOrdered([...ordered.slice(0, index), ordered[index + 1], val, ...ordered.slice(index + 2)]);
//     }
// }