import { Card, CardContent } from '@material-ui/core';
import { CardProps } from '@material-ui/core/Card';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { IClientDetailDto } from 'gen/ApiClient';
import React from 'react';
import { ClientNamingsCheckComponent } from './ClientNamingsCheckComponent';

interface IProps extends CardProps {
	client: IClientDetailDto;
}

export const ClientDetailedSanityCheckCard = ({ client, ...rest }: IProps) => {
	return (
		<Card {...rest}>
			<CardContent>
				<CardTitleTypography title='Checks (folders &amp; paths)' />
				<ClientNamingsCheckComponent namingsCheck={client.namingsCheck} />
			</CardContent>
		</Card>
	);
};
