import { Dialog, DialogContent, DialogProps, DialogTitle, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { CancelSubmitDialogActions } from 'framework/forms/CancelSubmitDialogActions';
import { FileType } from './FileType';
import { IFile } from './IFile';
import { readFile } from './readFile';
import { UploadFile } from './UploadFile';

interface IProps extends DialogProps {
	title: string;
	confirm: (file: IFile) => void;
	confirmF?: (file: IFile) => Promise<void>;
	cancel: () => void;
	isUploading: boolean;
	caption?: string;
	accept: FileType;
	previous?: () => void;
}

export const UploadFileDialog = ({ title, confirm, confirmF, cancel, isUploading, caption, accept, previous, ...rest }: IProps) => {
	const theme = useTheme();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const onUpload = async (file: File) => {
		const read = await readFile(file);
		if (confirmF) {
			setIsSubmitting(true);
			await confirmF(read);
			setIsSubmitting(false);
		}
		confirm(read);
	};

	return (
		<Dialog
			fullWidth
			{...rest}>
			<DialogTitle>{title}</DialogTitle>
			{caption && (
				<Typography
					variant='caption'
					style={{ whiteSpace: 'pre-line', paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), paddingBottom: 16 }}>
					{caption}
				</Typography>
			)}
			<DialogContent dividers>
				<UploadFile
					isUploading={isUploading || isSubmitting}
					onFileSelected={onUpload}
					accept={accept}
				/>
			</DialogContent>
			<CancelSubmitDialogActions
				previous={previous}
				cancel={cancel}
				submitText={`Upload`}
				isValid={false}
				onSubmit={() => {}}
				isSubmitting={isUploading}
				hideSubmitButton
			/>
			{/* <DialogActions>
                <Button onClick={cancel} color="primary" disabled={isUploading || isSubmitting}>{strings.cancel}</Button>
            </DialogActions> */}
		</Dialog>
	);
};
