import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchSubscriptionAdditionalCostRequest, ISubscriptionAdditionalCost, subscriptionsCommand_patchAdditionalCost } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IPatchSubscriptionAdditionalCostRequest>({
			amountExVat: yup.number().required(),
			description: yup.string().required(),
			subscriptionId: yup.string().required(),
			additionalCostId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	subscriptionId: string;
	item: ISubscriptionAdditionalCost;
	confirm: () => void;
	cancel: VoidFunction;
}

export const PatchSubscriptionAdditionalRequestForm = ({ item, subscriptionId, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(subscriptionsCommand_patchAdditionalCost);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IPatchSubscriptionAdditionalCostRequest, helpers: FormikHelpers<IPatchSubscriptionAdditionalCostRequest>) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`Subscription add. cost patched`, 'success');
		}
	};

	return (
		<Formik<IPatchSubscriptionAdditionalCostRequest>
			validateOnMount
			initialValues={{
				amountExVat: item.amountExVat,
				description: item.description,
				subscriptionId: subscriptionId,
				additionalCostId: item.id,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Patch additional cost to subscription`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IPatchSubscriptionAdditionalCostRequest>
							name='description'
							label='Description'
							required
						/>
						<FormMoneyField<IPatchSubscriptionAdditionalCostRequest>
							name='amountExVat'
							label='Amount (ex.VAT)'
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IPatchSubscriptionAdditionalCostRequest>
						cancel={cancel}
						submitText='Patch'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
