import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';
import React, { useEffect } from 'react';
import { nameOf } from '../utils/nameOf';
import { FormErrorHelper } from './common/FormErrorHelper';
import { IFormProps } from './common/IFormProps';

export const FormSingleCheckboxField = <TModel extends unknown>({ ...props }: IFormProps<TModel>) => {
    const [field, meta, helpers] = useField<boolean>(nameOf(props.name));

    useEffect(() => {
        if (meta.touched === false) {
            helpers.setTouched(true);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <FormControl
            error={meta.error && meta.touched ? true : false}>
            <FormControlLabel
                style={props.style}
                label={props.label}
                control={<Checkbox checked={field.value} onChange={() => helpers.setValue(!field.value)} />} />
            <FormErrorHelper meta={meta} />
        </FormControl>
    );
}