import { Popover } from '@material-ui/core';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { LinkContextType } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import { ApplyLabelsPopoverContent } from './ApplyLabelsPopoverContent';

interface IProps {
	contextType: LinkContextType;
	title: string;
	labelIds: string[];
	children: React.ReactNode;
	onChange: (ids: string[]) => Promise<void>;
}

export const ApplyLabelsComponent = ({ contextType, children, labelIds, title, onChange }: IProps) => {
	const [anchor, open, close] = useAnchorElement();
	const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);

	useEffect(() => {
		setSelectedLabelIds(labelIds);
	}, [labelIds]);

	const onClose = async () => {
		await onChange(selectedLabelIds);
		close();
	};

	return (
		<React.Fragment>
			{Boolean(anchor) && (
				<Popover
					open
					anchorEl={anchor}
					onClose={onClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}>
					<ApplyLabelsPopoverContent
						selectedLabelIds={selectedLabelIds}
						setSelectedLabelIds={setSelectedLabelIds}
						contextType={contextType}
						title={title}
					/>
				</Popover>
			)}
			<div onClick={open}>{children}</div>
		</React.Fragment>
	);
};
