import React from 'react';
import { ITemplateDto } from 'gen/ApiClient';
import { TemplateInvalidChipOrDefault } from './TemplateInvalidChipOrDefault';
import { TemplateTypeChip } from './TemplateTypeChip';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { useTheme } from '@material-ui/core';

interface IProps {
    item: ITemplateDto,
    style?: React.CSSProperties;
}

export const TemplateChips = ({ item, style }: IProps) => {
    const theme = useTheme();

    return (
        <div className="df-row-ac" style={style}>
            {(item.hasValidityEnd || item.hasValidityStart) && <ReportProblemOutlinedIcon style={{ color: theme.palette.warning.main, marginRight: 8 }} />} 
            <TemplateTypeChip item={item} style={{ marginRight: 8 }} />
            <TemplateInvalidChipOrDefault item={item} />
        </div>
    );
}