import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { addMonths, addYears } from 'date-fns';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { SelectField } from 'framework/components/select/SelectField';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { TimeUnit } from 'framework/forms/TimeUnit';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IClientDto, ICreateLicenseSubscriptionRequest, ILicenseDto, subscriptionsCommand_addLicense } from 'gen/ApiClient';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { ClientsCacheContext } from '../clients/ClientsCacheContext';
import { LicenseCategoriesCacheContext } from '../licenses/LicenseCategoriesCacheContext';
import { LicensesCacheContext } from '../licenses/LicensesCacheContext';

const createSchema = () => {
	return yup
		.object<ICreateLicenseSubscriptionRequest>({
			from: yup.date().required(),
			to: yup.date().required(),
			licenseId: yup.string().required(),
			clientId: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	clientId: string | undefined;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CreateLicenseSubscriptionRequestForm = ({ clientId, confirm, cancel, ...rest }: IProps) => {
	const [create, isSubmitting] = useFormSubmit(subscriptionsCommand_addLicense);
	const [licenses] = useCacheContext(LicensesCacheContext);
	const [clients] = useCacheContext(ClientsCacheContext);
	const [categories] = useCacheContext(LicenseCategoriesCacheContext);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: ICreateLicenseSubscriptionRequest, helpers: FormikHelpers<ICreateLicenseSubscriptionRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers)) {
			confirm(r.result.objectId);
			notify(`Subscription succesfully created`, 'success');
		}
	};

	if (licenses === undefined || clients === undefined || categories === undefined) {
		return <></>;
	}

	return (
		<Formik<ICreateLicenseSubscriptionRequest>
			validateOnMount
			initialValues={{
				from: undefined as any,
				to: undefined as any,
				clientId: clientId ?? '',
				licenseId: '',
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					cancel={cancel}
					isSubmitting={isSubmitting}
					licenses={licenses}
					clients={clients}
					clientId={clientId}
					categories={categories}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
	licenses: ILicenseDto[];
	clients: IClientDto[];
	clientId: string | undefined;
	categories: string[];
}

const InnerDialog = ({ cancel, isSubmitting, licenses, clients, clientId, categories, ...rest }: IInnerDialogProps) => {
	const [fLicenses, setFLicenses] = useState<ILicenseDto[]>(licenses);
	const [category, setCategory] = useState<string>('');
	const props = useFormikContext<ICreateLicenseSubscriptionRequest>();
	const selectedLicense = useMemo(() => licenses.find(t => t.id === props.values.licenseId), [props.values.licenseId, licenses]);
	const [timeUnit, setTimeUnit] = useState<TimeUnit>('Years');

	useEffect(() => {
		setFLicenses(licenses.filter(t => t.category === category));
	}, [category, licenses]);

	useEffect(() => {
		if (props.values.from) {
			props.setFieldValue('to', timeUnit === 'Months' ? addMonths(new Date(props.values.from), 1) : addYears(new Date(props.values.from), 1));
		}
		// eslint-disable-next-line
	}, [timeUnit, props.values.from]);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='xs'
			fullWidth>
			<DialogTitle>
				<div>{`Create new subscription`}</div>
			</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<SelectField<TimeUnit>
					options={['Months', 'Years']}
					value={timeUnit}
					onChange={setTimeUnit}
					getKey={t => t}
					label='Recurrence'
					renderValue={t => (t === 'Months' ? 'Monthly' : 'Yearly')}
					style={{ marginBottom: 10 }}
				/>
				<FormDatePicker<ICreateLicenseSubscriptionRequest>
					name='from'
					label='From'
					required
				/>
				<FormDatePicker<ICreateLicenseSubscriptionRequest>
					name='to'
					label='To'
					required
				/>
				<FormAutocompleteField<ICreateLicenseSubscriptionRequest, IClientDto>
					name='clientId'
					label={`Client`}
					options={clients}
					// renderValue={t => `${t.name} (${t.alias})`}
					// getKey={t => t.id}
					disabled={clientId !== undefined}
					// helperText={
					// 	selectedClient &&
					// 	`# users ${selectedClient.registeredUsers.toString()} | # sold HA Y0-1-2 ${selectedClient.soldHearingAidsThisYear.toString()} ${selectedClient.soldHearingAidsYearMinus1.toString()} ${selectedClient.soldHearingAidsYearMinus2.toString()}`
					// }
				/>
				<SelectField<string>
					options={categories}
					value={category}
					onChange={setCategory}
					getKey={t => t}
					label='Category'
					renderValue={t => t}
					style={{ marginBottom: 10 }}
				/>
				<FormAutocompleteField<ICreateLicenseSubscriptionRequest, ILicenseDto>
					name='licenseId'
					label={`License`}
					options={fLicenses}
					helperText={selectedLicense && `${selectedLicense.maxRegisteredUsersDisplay}/${selectedLicense.maxSoldHADisplay}`}
					// renderValue={t => `${t.name} (${LicenseTypeRecord[t.type as LicenseType]})`}
					// getKey={t => t.id}
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions<ICreateLicenseSubscriptionRequest>
				cancel={cancel}
				submitText='Create'
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
