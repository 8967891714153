import { useTheme } from '@material-ui/core';
import { StatCardX } from 'framework/components/cards/StatCardX';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { ISubscriptionsQueryMetadata, ISubscriptionsQueryParams } from 'gen/ApiClient';
import React from 'react';

const Width: number = 250;

interface IProps {
	params: ISubscriptionsQueryParams;
	setParams: (params: ISubscriptionsQueryParams) => void;
	metadata: ISubscriptionsQueryMetadata;
	style?: React.CSSProperties;
}

export const SubscriptionsListStats = ({ metadata, style, setParams, params }: IProps) => {
	const theme = useTheme();
	const onFilter = (pars: ISubscriptionsQueryParams) => setParams(onResetToPage1(pars));

	return (
		<div
			className='df-row gap-16'
			style={style}>
			<StatCardX
				style={{ width: Width }}
				title={`History`}
				caption={`#`}
				onClick={() => onFilter({ ...params, prefilter: 'History' })}>
				<span style={{ fontSize: 24, color: theme.palette.text.secondary }}>{metadata.countHistory}</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={`Active`}
				caption={`#`}
				onClick={() => onFilter({ ...params, prefilter: 'Active' })}>
				<span style={{ fontSize: 24, color: theme.palette.primary.main }}>{metadata.countActive}</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={`Active w/o sale`}
				caption={`#`}
				onClick={() => onFilter({ ...params, prefilter: 'ActiveWithoutSale' })}>
				<span style={{ fontSize: 24, color: metadata.countActiveWithoutSale > 0 ? theme.palette.error.main : theme.palette.success.main }}>
					{metadata.countActiveWithoutSale}
				</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={`Active and exceeds`}
				caption={`#`}
				onClick={() => onFilter({ ...params, prefilter: 'ActiveAndExceedsSoldHearingAids' })}>
				<span style={{ fontSize: 24, color: metadata.countActiveAndExceeds > 0 ? theme.palette.error.main : theme.palette.success.main }}>
					{metadata.countActiveAndExceeds}
				</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={`Future`}
				caption={`#`}
				onClick={() => onFilter({ ...params, prefilter: 'Future' })}>
				<span style={{ fontSize: 24, color: theme.palette.warning.main }}>{metadata.countFuture}</span>
			</StatCardX>
		</div>
	);
};
