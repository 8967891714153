import { Card, CardContent } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { formatDate } from 'framework/utils/date/formatDate';
import { IClientDetailDto } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	client: IClientDetailDto;
	style?: React.CSSProperties;
}

export const ClientDetailsCard = ({ client, style }: IProps) => {
	return (
		<Card style={style}>
			<CardContent>
				<CardTitleTypography title='Basic info' />
				<LabelledProperty
					label='Name'
					property={client.name}
				/>
				<LabelledProperty
					label='Build'
					property={client.buildNumber?.toString() ?? 'n/a'}
				/>
				<LabelledProperty
					label='EsPos'
					property={client.eventStorePosition?.toString() ?? 'n/a'}
				/>
				<LabelledProperty
					label='Last N.A. Event'
					property={formatDate(client.lastNonAuthenticationEventDate, 'date-and-time')}
				/>
			</CardContent>
		</Card>
	);
};
