import { CardProps, Divider } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { ProcessStepX } from 'framework/components/stepper/ProcessStepX';
import { VerticalStepperX } from 'framework/components/stepper/VerticalStepperX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { isLast } from 'framework/utils/array/isLast';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ILicenseDto, ILicenseFeeDto, licensesCommand_deleteFee } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CreateLicenseFeeRequestForm } from '../CreateLicenseFeeRequestForm';
import { LicensePricingMethodUnitDescriptionRecord } from '../LicensePricingMethodUnitDescriptionRecord';
import { PatchLicenseFeeRequestForm } from '../PatchLicenseFeeRequestForm';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';

interface IProps extends CardProps {
	item: ILicenseDto;
	reload: VoidFunction;
}

export const LicenseFeeHistoryCard = ({ item, reload, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onAdd = () => {
		open(
			<CreateLicenseFeeRequestForm
				open
				licenseId={item.id}
				pricingMethod={item.pricingMethod}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelete = (fee: ILicenseFeeDto) => {
		open(
			<AlertDialog
				open
				title={'Delete license fee'}
				content={`Do you want to delete fee valid from: ${formatDate(fee.validFrom)}?`}
				acceptText={`Yes, delete`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => licensesCommand_deleteFee({ licenseId: item.id, licenseFeeId: fee.id })}
				accept={confirm}
			/>
		);
	};

	const onPatch = (fee: ILicenseFeeDto) => {
		open(
			<PatchLicenseFeeRequestForm
				open
				licenseId={item.id}
				pricingMethod={item.pricingMethod}
				fee={fee}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<CardX
			{...rest}
			className='df-col gap-8'>
			<Typography16pxBold>{`License Fees (active & history)`}</Typography16pxBold>
			<TextLinkButton
				startIcon={<AddCircleOutlineOutlinedIcon />}
				onClick={onAdd}
				color='primary'>
				{`Add fee`}
			</TextLinkButton>
			<Divider />
			<VerticalStepperX>
				{item.orderedFees.map(t => (
					<ProcessStepX
						key={t.id}
						doneText={`${formatDate(t.validFrom)} -- ${formatDate(t.validUntil)}`}
						todoText={`${formatDate(t.validFrom)} -- NOW`}
						date={t.validUntil}
						status={t.isCurrent ? 'active' : isNullOrUndefined(t.validUntil) ? 'todo' : 'done'}
						isLast={isLast(t, item.orderedFees)}>
						<div
							className='df-col gap-4'
							style={{ marginBottom: 16 }}>
							<span>{`${formatCurrency(t.value)} (${LicensePricingMethodUnitDescriptionRecord[item.pricingMethod]})`}</span>
							<div className='df-row-ac gap-4'>
								<TextLinkButton
									startIcon={<EditOutlinedIcon />}
									onClick={() => onPatch(t)}
									color='textSecondary'>
									{`Edit`}
								</TextLinkButton>
								<Divider orientation='vertical' />
								<TextLinkButton
									onClick={() => onDelete(t)}
									startIcon={<DeleteOutlineOutlinedIcon />}
									color='textSecondary'
									hoverColor='secondary'>
									{`Delete`}
								</TextLinkButton>
							</div>
						</div>
					</ProcessStepX>
				))}
			</VerticalStepperX>
		</CardX>
	);
};
