import { DialogProps } from '@material-ui/core';
import React from 'react';
import { CreateDocumentFromTemplateRequestForm } from '../documents/CreateDocumentFromTemplateRequestForm';

interface IProps extends DialogProps {
	salesId: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CreateInvoiceDocumentRequestForm = ({ salesId, ...rest }: IProps) => {
	return (
		<CreateDocumentFromTemplateRequestForm
			{...rest}
			linkViewContextId={salesId}
			linkViewContextType='Sale'
			documentType='Invoice'
		/>
	);
};
