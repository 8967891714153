import { Dialog, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormAutocompleteField } from 'framework/forms/FormAutocompleteField';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { IOption } from 'framework/IOption';
import { DocumentTypes, IUploadRootTemplateRequest, templatesCommand_newRootTemplate } from 'gen/ApiClient';
import React, { useState } from 'react';
import { fileToBase64 } from 'shared/upload/fileToBase64';
import { UploadFile } from 'shared/upload/UploadFile';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IUploadRootTemplateRequest>({
			contentAsBase64: yup.string(),
			name: yup.string().required(),
			documentType: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: () => void;
}

const stepsRecord: Record<number, (keyof IUploadRootTemplateRequest)[]> = {
	0: ['name', 'documentType'],
	1: ['contentAsBase64'],
};

export const UploadRootTemplateRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [create, isSubmitting] = useFormSubmit(templatesCommand_newRootTemplate);
	const [step, setStep] = useState<number>(0);

	const handleSubmit = async (values: IUploadRootTemplateRequest, helpers: FormikHelpers<IUploadRootTemplateRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			confirm(r.result.objectId);
		}
	};

	return (
		<Formik<IUploadRootTemplateRequest>
			validateOnMount
			initialValues={{
				contentAsBase64: '',
				documentType: '',
				name: '',
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={createSchema()}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<IUploadRootTemplateRequest>;
	cancel: () => void;
	stepsRecord: Record<number, (keyof IUploadRootTemplateRequest)[]>;
}

const InnerDialog = ({ step, setStep, isSubmitting, schema, cancel, stepsRecord, ...rest }: IInnerProps) => {
	const props = useFormikContext<IUploadRootTemplateRequest>();

	const onSelectFile = async (file: File) => {
		const content = await fileToBase64(file);
		setFormValue<IUploadRootTemplateRequest>('contentAsBase64', content, props);
		props.submitForm();
	};

	return (
		<Dialog
			{...rest}
			maxWidth='lg'>
			<DialogTitleWithFormStepper
				title={`New root template`}
				step={step}
				labels={[`Type`, `Upload`]}
			/>
			<OverflowDialogContent dividers>
				{step === 0 && (
					<>
						<FormTextField<IUploadRootTemplateRequest>
							name='name'
							label={`Name`}
							required
						/>
						<FormAutocompleteField<IUploadRootTemplateRequest, IOption<string>>
							name='documentType'
							label='Document type'
							options={DocumentTypes.map<IOption<string>>(t => ({ id: t, identifier: t }))}
						/>
					</>
				)}
				{step === 1 && (
					<UploadFile
						isUploading={isSubmitting}
						onFileSelected={onSelectFile}
						accept='word'
					/>
				)}
			</OverflowDialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={`Create`}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
