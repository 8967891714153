import { Card, CardContent, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { whiteListIpsCommand_add, whiteListIpsCommand_delete, whiteListIpsQuery_all } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { Page } from 'framework/components/layout/Page';
import { getLocalIpAddress } from './getLocalIpAddress';

export const WhiteListIpsPage = () => {
	const [data, reload] = useApiEffect(whiteListIpsQuery_all);
	const [currentIp, setCurrentApi] = useState<string>('');
	const { open, confirm, cancel } = useDialogsContext(reload);

	useEffect(() => {
		const load = async () => {
			const r = await getLocalIpAddress();
			setCurrentApi(r);
		};
		load();
	}, []);

	const onAddManually = () => {
		open(
			<StringModelForm
				label={`IP Address (current: ${currentIp})`}
				formTitle='Add IP address to white list'
				submitFunction={val => whiteListIpsCommand_add({ value: val })}
				initialValue={currentIp}
				submitText='Add'
				fullWidth
				maxWidth='sm'
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onDelete = (ip: string) => {
		open(
			<AlertDialog
				open
				title={`Delete IP?`}
				content={`Do you want to remove IP '${ip}' from white list?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => whiteListIpsCommand_delete({ value: ip })}
				accept={confirm}
			/>
		);
	};

	return (
		<Page
			showHome
			title={`White List IP's`}
			reload={reload}>
			<Card>
				<CardTitleTypography
					withPadding
					title={`White listed IP's`}
					action={
						<SmallPrimaryTextButton
							startIcon={<AddIcon />}
							onClick={onAddManually}>
							Add
						</SmallPrimaryTextButton>
					}
				/>
				<CardContent style={{ paddingTop: 0 }}>
					{data && (
						<List dense>
							{data.map(t => (
								<ListItem key={t}>
									<ListItemText primary={t} />
									<ListItemSecondaryAction>
										<IconButton
											edge='end'
											aria-label='delete'
											onClick={() => onDelete(t)}>
											<DeleteIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}
						</List>
					)}
					{data?.length === 0 && <div>no IP's white listed</div>}
				</CardContent>
			</Card>
		</Page>
	);
};
