import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { setFieldError } from 'framework/forms/utils/setFieldError';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { clientsCommand_register, IRegisterClientRequest } from 'gen/ApiClient';
import React, { useEffect } from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IRegisterClientRequest>({
			name: yup.string().required(),
			directory: yup.string().required(),
			ravenDbName: yup.string().required(),
			siteName: yup.string().required(),
			alias: yup.string(),
			httpsUrl: yup.string().required(),
			azureContainerName: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CreateClientRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [register, isSubmitting] = useFormSubmit(clientsCommand_register);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IRegisterClientRequest, helpers: FormikHelpers<IRegisterClientRequest>) => {
		if (values.httpsUrl?.startsWith('https') === false) {
			setFieldError<IRegisterClientRequest>('httpsUrl', 'Must start with HTTPS', helpers);
		} else if (values.httpsUrl?.endsWith('.hams.be') === false) {
			setFieldError<IRegisterClientRequest>('httpsUrl', `Must end with '.hams.be'`, helpers);
		} else {
			const r = await register(values);
			if (handleFormResponse(r, helpers)) {
				confirm(r.result.objectId);
				notify(`Client ${values.name} succesfully created`, 'success');
			}
		}
	};

	return (
		<Formik<IRegisterClientRequest>
			validateOnMount
			initialValues={{
				name: '',
				directory: '',
				ravenDbName: '',
				siteName: '',
				alias: '',
				httpsUrl: '',
				azureContainerName: '',
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					cancel={cancel}
					isSubmitting={isSubmitting}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
}

const createAzureContainerName = (name: string): string => {
	if (name === undefined || name === null || name === '') {
		return '';
	} else {
		return name.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
	}
};

const InnerDialog = ({ cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IRegisterClientRequest>();

	useEffect(() => {
		if (props.values.name) {
			setFormValue<IRegisterClientRequest>('directory', `c:\\inetpub\\wwwroot\\Hams.Web.${props.values.name}\\publish\\`, props);
			setFormValue<IRegisterClientRequest>('ravenDbName', `Hams.${props.values.name}`, props);
			setFormValue<IRegisterClientRequest>('siteName', `Hams.Web.${props.values.name}`, props);
			setFormValue<IRegisterClientRequest>('httpsUrl', `https://${props.values.name.toLowerCase()}.hams.be`, props);
			setFormValue<IRegisterClientRequest>('azureContainerName', createAzureContainerName(props.values.name), props);
		}
		// eslint-disable-next-line
	}, [props.values.name]);

	return (
		<Dialog
			{...rest}
			scroll='paper'
			maxWidth='xs'
			fullWidth>
			<DialogTitle>
				<div>{`Create new client`}</div>
			</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<FormTextField<IRegisterClientRequest>
					name='name'
					label='Name'
					required
				/>
				<FormTextField<IRegisterClientRequest>
					name='alias'
					label='Alias'
				/>
				<FormTextField<IRegisterClientRequest>
					name='httpsUrl'
					label='Https URL'
					required
					helperText={'f.e. https:<client>.hams.be'}
				/>
				<FormTextField<IRegisterClientRequest>
					name='directory'
					label='Publish Directory'
					required
					disabled
				/>
				<FormTextField<IRegisterClientRequest>
					name='ravenDbName'
					label='Db name'
					required
					disabled
				/>
				<FormTextField<IRegisterClientRequest>
					name='siteName'
					label='Site name'
					required
					disabled
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions<IRegisterClientRequest>
				cancel={cancel}
				submitText='Create'
				isSubmitting={isSubmitting}
			/>
		</Dialog>
	);
};
