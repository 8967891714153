import { StatusChip } from 'framework/components/StatusChip';
import { IClientDetailDto } from 'gen/ApiClient';
import React from 'react';

interface IProps {
	item: IClientDetailDto;
	style?: React.CSSProperties;
}

export const ClientSubscriptionChip = ({ item, style }: IProps) => {
	if (item.hasActiveSubscription === false) {
		return (
			<StatusChip
				label='No sub.'
				fill
				color='error'
				style={style}
			/>
		);
	} else if (item.hasActiveSubscriptionAndIsDemo) {
		return (
			<StatusChip
				label='Demo'
				color='error'
				style={style}
			/>
		);
	} else {
		return (
			<StatusChip
				label={item.activeSubsriptionLicenseName!}
				fill
				color='success'
				style={style}
			/>
		);
	}
};
