import React from 'react';

export interface ILoaderContext {
    load: () => string;
    clear: (id: string | undefined) => void;
    count: number;
}

export const LoaderContext = React.createContext<ILoaderContext>(undefined as any);


