import { Divider } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { isLast } from 'framework/utils/array/isLast';
import { auth_endAllSessions, auth_sessions } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AuthSessionListItem } from './AuthSessionListItem';

export const AuthSessionsCard = () => {
	const [sessions, reload] = useApiEffect(auth_sessions);
	const { open, confirm, cancel } = useDialogsContext(reload);

	const onEndAll = () => {
		open(
			<AlertDialog
				open
				title={'End all session'}
				content={`Do you want to end all sessions?`}
				acceptText={`Yes, end all sessions`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => auth_endAllSessions()}
				accept={confirm}
			/>
		);
	};

	if (sessions === undefined) {
		return <></>;
	}

	return (
		<CardX className='df-col gap-16'>
			<Typography16pxBold>{`Auth sessions`}</Typography16pxBold>
			<TextLinkButton
				startIcon={<ExitToAppIcon />}
				hoverColor='secondary'
				color='primary'
				onClick={onEndAll}>
				{`End all sessions`}
			</TextLinkButton>
			<Divider />
			{sessions.map(t => (
				<AuthSessionListItem
					key={t.authId}
					item={t}
					reload={reload}
					withoutBorder={isLast(t, sessions)}
				/>
			))}
		</CardX>
	);
};
