import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { clientsCommand_resetDemoDb, clientsSetupQuery_exists, IClientDetailDto } from 'gen/ApiClient';
import React from 'react';
import { ClientBuildCard } from './ClientBuildCard';
import { ClientDetailedSanityCheckCard } from './ClientDetailedSanityCheckCard';
import { ClientSetupCard } from './ClientSetupCard';
import { CardX } from 'framework/components/cards/CardX';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { AlertDialog } from 'framework/dialogs/AlertDialog';

interface IProps {
	client: IClientDetailDto;
	reloadTrigger: Trigger;
	reload: VoidFunction;
}

export const ClientDetailOverviewPage = ({ client, reloadTrigger, reload }: IProps) => {
	const [setupExists, reloadSetupExists] = useApiEffect(clientsSetupQuery_exists, client.id);
	const { open, confirm, cancel } = useDialogsContext(reload);

	useLazyEffect(() => {
		reloadSetupExists();
	}, [reloadTrigger]);

	const onResetDemoDb = () => {
		if (client.name !== 'Demo') {
			return;
		}
		if (client.ravenDbName !== 'Hams.Demo') {
			return;
		}

		open(
			<AlertDialog
				open
				title={'Reset db'}
				content={`Do you want to reset db?`}
				acceptText={`Yes, reset`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsCommand_resetDemoDb()}
				accept={confirm}
			/>
		);
	};

	return (
		<div className='df-col'>
			{setupExists === true && (
				<ClientSetupCard
					clientId={client.id}
					style={{ marginBottom: 16 }}
					reloadTrigger={reloadTrigger}
					reload={reload}
				/>
			)}
			<ClientDetailedSanityCheckCard
				client={client}
				style={{ marginBottom: 16 }}
			/>
			<ClientBuildCard
				client={client}
				style={{ marginBottom: 16 }}
			/>
			{client.name === 'Demo' && (
				<CardX>
					<Typography16pxBold>{`Actions on DEMO database`}</Typography16pxBold>
					<TextLinkButton
						onClick={onResetDemoDb}
						style={{ marginTop: 16 }}
						startIcon={<RotateLeftIcon />}
						color='secondary'
						hoverColor='secondary'>
						{`Reset database`}
					</TextLinkButton>
				</CardX>
			)}
		</div>
	);
};
