
import { SelectableColumn } from "../SelectableColumn"

export const ColumnRecord: Record<SelectableColumn, string> = {
    created: 'Date created',
    id: 'ID',
    lastUpdated: 'Last updated',
    readMoreLink: 'Link',
    title: 'Title',
    type: 'Type'
}
