import { DialogContent, DialogContentProps, Grid, useTheme } from '@material-ui/core';
import React from 'react';
import { AbsoluteCloseButton } from './AbsoluteCloseButton';

interface IProps extends DialogContentProps {
	close: () => void;
}

export const DialogContentGrid = ({ close, children, style, ...rest }: IProps) => {
	const theme = useTheme();
	return (
		<DialogContent
			{...rest}
			style={{ padding: theme.spacing(4), ...style }}>
			<AbsoluteCloseButton close={close} />
			<Grid
				container
				spacing={2}>
				{children}
			</Grid>
		</DialogContent>
	);
};
