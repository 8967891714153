import { Divider, IconButton, InputBase, useTheme } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useMemo, useState } from 'react';
import { SortableTable } from 'framework/table/SortableTable';
import { createHeaders } from './createHeaders';
import { IClientNameWithSelection } from './IClientNameWithSelection';

interface IProps {
	clients: IClientNameWithSelection[];
	setClients: React.Dispatch<React.SetStateAction<IClientNameWithSelection[]>>;
}

export const ClientsTable = ({ clients, setClients }: IProps) => {
	const theme = useTheme();
	const [search, setSearch] = useState<string>('');
	const [filtered, setFiltered] = useState<IClientNameWithSelection[]>(clients);
	const headers = useMemo(() => createHeaders(clients, filtered, setClients), [clients, filtered, setClients]);

	useEffect(() => {
		const x = clients.filter(t => t.name!.toLowerCase().includes(search.toLowerCase()));
		setFiltered(x);
		// eslint-disable-next-line
	}, [search, clients]);

	return (
		<>
			<div
				className='df-row-ac'
				style={{ padding: theme.spacing(1.5), paddingLeft: 16 }}>
				<SearchIcon style={{ color: theme.palette.text.secondary }} />
				<InputBase
					placeholder={`Search...`}
					style={{ width: '100%', paddingLeft: '26px' }}
					value={search}
					onKeyDown={e => e.stopPropagation()}
					onChange={e => setSearch(e.target.value)}
				/>
				{search && (
					<IconButton
						onClick={() => setSearch('')}
						size='small'>
						<ClearIcon />
					</IconButton>
				)}
			</div>
			<Divider />
			<SortableTable<IClientNameWithSelection>
				padding={theme.spacing(1)}
				headers={headers}
				getKey={t => t.name!}
				values={filtered}
				defaultSortParams={{ property: 'name', direction: 'asc' }}
			/>
		</>
	);
};
