import { Avatar, Grid, useTheme } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import React, { ReactElement } from 'react';

interface IProps {
    icon: ReactElement<any, any>;
    sizeIcon?: number;
    sizeAvatar?: number;
    avatarColor?: string;
    children: any;
    labelGridSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const DialogGridTitle = ({ icon, children, sizeIcon = 60, sizeAvatar = 100, avatarColor = deepOrange[500], labelGridSize = 4 }: IProps) => {
    const theme = useTheme();
    return (
        <>
            <Grid item xs={labelGridSize} className="df-row-ac jc-c">
                <Avatar style={{ width: sizeAvatar, height: sizeAvatar, backgroundColor: deepOrange[500], color: theme.palette.getContrastText(avatarColor) }}>
                    <div className="avatar-icon-wrapper" style={{ width: sizeIcon, height: sizeIcon }}>{icon}</div>
                    {/* <HelpOutlineIcon style={{ width: sizeIcon, height: sizeIcon }} /> */}
                </Avatar>
            </Grid>
            <Grid item xs={(12 - labelGridSize) as any} className="df-col-as jc-c">
                {children}
            </Grid>
        </>
    );
}