import { DialogProps } from '@material-ui/core';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { notificationsCommand_identityPatch, notificationsCommand_removeInvalidIndices } from 'gen/ApiClient';
import React, { useState } from 'react';
import { Mode } from './Mode';
import { ModeDialog } from './ModeDialog';

interface IProps extends DialogProps {
    confirm: () => void;
    cancel: () => void;
}

export const ScriptsDialog = ({ confirm, cancel, ...rest }: IProps) => {
    const [state, setState] = useState<'mode' | Mode>('mode');

    if (state === 'mode') {
        return <ModeDialog
            {...rest}
            confirm={setState}
            cancel={cancel} />
    } else if (state === 'identityPatch') {
        return <AlertDialog
            open
            title={'Identity Patch Notifications'}
            content={`Proceed with identity patch?`}
            acceptText={`Yes`}
            rejectText={`No`}
            reject={cancel}
            acceptF={() => notificationsCommand_identityPatch()}
            accept={confirm} />;
    } else if (state === 'removeIndices') {
        return <AlertDialog
            open
            title={'Remove invalid indices'}
            content={`Proceed remove invalid indices?`}
            acceptText={`Yes`}
            rejectText={`No`}
            reject={cancel}
            acceptF={() => notificationsCommand_removeInvalidIndices()}
            accept={confirm} />;
    } else {
        return <div></div>
    }
}