import { Divider, IconButton, List, ListItem, Menu, TextField } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DateRangeDelimiter, StaticDatePicker, StaticDateRangePicker } from '@material-ui/pickers';
import { DateRange } from '@material-ui/pickers/DateRangePicker/RangeTypes';
import { endOfDay, startOfDay } from 'date-fns';
import { getNow } from 'framework/utils/getNow';
import { useLocalization } from 'localization/useLocalization';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useAnchorElement } from '../../hooks/useAnchorElement';
import { mapToIso } from '../../utils/date/mapToIso';
import { ClearFilterTextLinkButton } from '../ClearFilterTextLinkButton';
import { FilterButton } from '../FilterButton';
import { BrowsableDateRangeTypes } from './BrowsableDateRangeType';
import './DateFilterItem.scss';
import { DateRangeRenderDisplayRecord } from './DateRangeRenderDisplayRecord';
import { IDateRange } from './IDateRange';
import { PickersDayAllAfterOrBefore } from './PickersDayAllAfterOrBefore';
import { QuickFilterListItem, quickFilterListItemStyle } from './QuickFilterListItem';
import { QuickFilterType } from './QuickFilterType';
import { createQuickFilterTypeRecords } from './createQuickFilterTypeRecords';
import { calculateDateRangeType, calculateNextRange, calculatePreviousRange } from './utils';

interface IProps {
	after: Date | undefined;
	before: Date | undefined;
	setSelected: (after: Date | undefined, before: Date | undefined) => void;
	label: string;
	icon?: JSX.Element;
}

const matchQf = (val: IDateRange, records: Record<QuickFilterType, IDateRange>): QuickFilterType | undefined => {
	return _.findKey(records, t => t.after === val.after && t.before === val.before) as any;
};

export const DateFilterComponent = ({ after, before, setSelected, label, icon = <DateRangeIcon /> }: IProps) => {
	// performance wise avoiding re-renders
	const range = useMemo<IDateRange>(() => ({ after: after, before: before }), [after, before]);
	const records = useMemo<Record<QuickFilterType, IDateRange>>(() => createQuickFilterTypeRecords(getNow()), []);
	const [anchor, open, close] = useAnchorElement();
	const [localRange, setLocalRange] = useState<IDateRange>({ after: undefined, before: undefined });
	const [mode, setMode] = useState<'range' | 'all-before' | 'all-after'>('range');
	const [selQf, setQf] = useState<QuickFilterType | undefined>();
	const strings = useLocalization();
	const dateRangeType = useMemo(() => calculateDateRangeType(localRange), [localRange]);
	const language = 'en';
	const renderedDateDisplay = useMemo(
		() => DateRangeRenderDisplayRecord[dateRangeType](localRange, strings, language),
		[dateRangeType, localRange, strings, language]
	);
	const isBrowsableType = useMemo(() => BrowsableDateRangeTypes.indexOf(dateRangeType as any) > -1, [dateRangeType]);
	const isFiltered = useMemo(() => Boolean(range.after || range.before), [range]);
	const isLocallyFiltered = useMemo(() => Boolean(localRange.after || localRange.before), [localRange]);

	const onSelectQf = (value: QuickFilterType) => {
		const vals = records[value];
		setLocalRange(vals);
		setMode('range');
		setQf(value);
	};

	const onSelectManually = (date: DateRange<Date | null>) => {
		const range: IDateRange = { after: mapToIso(startOfDay(date[0] as any)), before: mapToIso(endOfDay(date[1] as any)) };
		setLocalRange(range);
		setQf(matchQf(range, records));
	};

	const onSetAllBefore = () => {
		setMode('all-before');
		setQf(undefined);
		setLocalRange({ before: null, after: null });
	};

	const onSetAllAfter = () => {
		setMode('all-after');
		setQf(undefined);
		setLocalRange({ before: null, after: null });
	};

	useEffect(() => {
		setLocalRange(range);
		if (range.after === null && range.before !== null) {
			setMode('all-before');
		} else if (range.after !== null && range.before === null) {
			setMode('all-after');
		} else {
			setMode('range');
			setQf(matchQf(range, records));
		}
		// eslint-disable-next-line
	}, [range]);

	const handleClose = () => {
		if (localRange.after !== range.after || localRange.before !== range.before) {
			setSelected(localRange.after ?? undefined, localRange.before ?? undefined);
		}
		close();
	};

	const onClear = () => {
		setLocalRange({ after: undefined, before: undefined });
		setSelected(undefined, undefined);
		close();
	};

	const onPrevious = () => {
		setLocalRange(calculatePreviousRange(localRange, dateRangeType as any));
	};

	const onNext = () => {
		setLocalRange(calculateNextRange(localRange, dateRangeType as any));
	};

	return (
		<>
			<FilterButton
				handleOpen={open}
				badgeContent={isFiltered ? 1 : 0}
				label={label}
				icon={icon}
			/>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}>
				<div className='df-row'>
					<List className='df-col'>
						<QuickFilterListItem
							qf='today'
							selQf={selQf}
							setQf={onSelectQf}
							label={strings.today}
						/>
						<QuickFilterListItem
							qf='yesterday'
							selQf={selQf}
							setQf={onSelectQf}
							label={strings.yesterday}
						/>
						<QuickFilterListItem
							qf='this_week'
							selQf={selQf}
							setQf={onSelectQf}
							label={strings.thisWeek}
						/>
						<QuickFilterListItem
							qf='last_week'
							selQf={selQf}
							setQf={onSelectQf}
							label={strings.lastWeek}
						/>
						<QuickFilterListItem
							qf='this_month'
							selQf={selQf}
							setQf={onSelectQf}
							label={strings.thisMonth}
						/>
						<QuickFilterListItem
							qf='last_month'
							selQf={selQf}
							setQf={onSelectQf}
							label={strings.lastMonth}
						/>
						<QuickFilterListItem
							qf='this_quarter'
							selQf={selQf}
							setQf={onSelectQf}
							label={`This quarter`}
						/>
						<QuickFilterListItem
							qf='last_quarter'
							selQf={selQf}
							setQf={onSelectQf}
							label={`Last quarter`}
						/>
						<QuickFilterListItem
							qf='this_year'
							selQf={selQf}
							setQf={onSelectQf}
							label={strings.thisYear}
						/>
						<QuickFilterListItem
							qf='last_year'
							selQf={selQf}
							setQf={onSelectQf}
							label={strings.lastYear}
						/>
						<Divider
							variant='middle'
							light
						/>
						<ListItem
							style={mode === 'all-before' ? { ...quickFilterListItemStyle } : {}}
							button
							onClick={() => onSetAllBefore()}
							selected={mode === 'all-before'}>
							{strings.allBefore}
						</ListItem>
						<ListItem
							style={mode === 'all-after' ? { ...quickFilterListItemStyle } : {}}
							button
							onClick={() => onSetAllAfter()}
							selected={mode === 'all-after'}>
							{strings.allAfter}
						</ListItem>
					</List>
					<Divider
						orientation='vertical'
						flexItem
						light
					/>
					<div className='df-col'>
						<div
							className='df-row-ac'
							style={{ margin: 8, marginRight: 16, height: 32 }}>
							<div className='df-row-ac'>
								{isBrowsableType && (
									<IconButton
										onClick={onPrevious}
										color='primary'
										style={{ marginRight: 8, marginTop: -8, marginBottom: -8 }}>
										<ChevronLeftIcon />
									</IconButton>
								)}
								<div style={{ fontSize: '18px' }}>{`${renderedDateDisplay}`}</div>
								{isBrowsableType && (
									<IconButton
										onClick={onNext}
										color='primary'
										style={{ marginLeft: 8, marginTop: -8, marginBottom: -8 }}>
										<ChevronRightIcon />
									</IconButton>
								)}
							</div>
							<div className='fg1'></div>
							<ClearFilterTextLinkButton
								onClear={onClear}
								isFiltered={isFiltered || isLocallyFiltered}
							/>
						</div>
						<Divider
							orientation='horizontal'
							light
							style={{ marginLeft: 4, marginRight: 4 }}
						/>
						{mode === 'range' && (
							<StaticDateRangePicker
								className='without-borders'
								displayStaticWrapperAs='desktop'
								value={[localRange.after, localRange.before]}
								onChange={onSelectManually}
								open
								renderInput={(startProps, endProps) => (
									<>
										<TextField
											{...startProps}
											className='no-border'
										/>
										<DateRangeDelimiter> to </DateRangeDelimiter>
										<TextField {...endProps} />
									</>
								)}
							/>
						)}
						{mode !== 'range' && (
							<StaticDatePicker
								displayStaticWrapperAs='desktop'
								openTo='date'
								open
								value={mode === 'all-after' ? localRange.after : localRange.before}
								onChange={newValue =>
									setLocalRange({
										after: mode === 'all-after' ? mapToIso(startOfDay(newValue as any)) : undefined,
										before: mode === 'all-before' ? mapToIso(endOfDay(newValue as any)) : undefined,
									})
								}
								views={['year', 'month', 'date']}
								renderDay={(date: any, selectedDates: any, props: any) => (
									<PickersDayAllAfterOrBefore
										date={date}
										selectedDates={selectedDates}
										props={props}
										mode={mode}
									/>
								)}
								renderInput={props => <TextField {...props} />}
							/>
						)}
					</div>
				</div>
			</Menu>
		</>
	);
};
