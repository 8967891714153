import { formatCurrency } from 'framework/utils/formatCurrency';
import { LicensePricingMethod } from 'gen/ApiClient';

export const formatFeeValue = (value: number, pricingMethod: LicensePricingMethod) => {
	if (pricingMethod === 'FixedMonthlyFee') {
		return `${formatCurrency(value)} pm (${formatCurrency(value * 12)} py)`;
	} else if (pricingMethod === 'PerRegisteredUser') {
		return `${formatCurrency(value)} pm pu (${formatCurrency(value * 12)} py pu)`;
	} else {
		return `${formatCurrency(value)} p HA`;
	}
};
