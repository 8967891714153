import { Color } from '@material-ui/lab';
import React from 'react';
import { StatusChip } from 'framework/components/StatusChip';
import { IUpdatedScheduledTaskDto, UpdateScheduledTaskStatus } from 'gen/ApiClient';

interface IProps {
    item: IUpdatedScheduledTaskDto,
    style?: React.CSSProperties;
}

const toStatus = (item: IUpdatedScheduledTaskDto): UpdateScheduledTaskStatus => {
    return item.status as UpdateScheduledTaskStatus;
}

const colRecord: Record<UpdateScheduledTaskStatus, Color> = {
    Executed: 'success',
    InProgress: 'warning',
    Planned: 'info',
}

export const UstStatusChip = ({ item, style }: IProps) => {
    return (
        <StatusChip
            style={style}
            label={item.status ?? ''}
            fill={toStatus(item) !== 'Planned' ? true : false}
            color={colRecord[toStatus(item)]} />
    );
}