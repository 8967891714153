import { Tooltip, useTheme } from '@material-ui/core';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import { IClientDetailDto } from 'gen/ApiClient';
import React from 'react';
import { ClientSubscriptionChip } from './ClientSubscriptionChip';

interface IProps {
	item: IClientDetailDto;
	style?: React.CSSProperties;
}

export const ClientChips = ({ item, style }: IProps) => {
	const theme = useTheme();

	return (
		<div
			className='df-row-ac'
			style={style}>
			{item.isRunningLatestBuild === false && (
				<Tooltip title='Not up to date'>
					<SystemUpdateIcon style={{ color: theme.palette.warning.main }} />
				</Tooltip>
			)}
			<ClientSubscriptionChip
				item={item}
				style={{ marginRight: 4 }}
			/>
		</div>
	);
};
