import { Card, CardContent, CardProps, Typography } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import React from 'react';

interface IProps extends CardProps {
	title: string;
	description: string;
	value: string;
	valueStyle?: React.CSSProperties;
}

export const StatCard = ({ title, description, value, valueStyle, ...rest }: IProps) => {
	return (
		<Card {...rest}>
			<CardContent
				className='df-col'
				style={{ padding: 12, height: '100%' }}>
				<CardTitleTypography
					title={title}
					fontWeight='inherit'
				/>
				<Typography
					variant='caption'
					color='textSecondary'
					style={{ marginTop: '-2px', whiteSpace: 'pre', lineHeight: '14px' }}>
					{description}
				</Typography>
				<div className='fg1'></div>
				<div className='df-col jc-c'>
					<div style={{ fontSize: '24px', ...valueStyle }}>{value}</div>
				</div>
			</CardContent>
		</Card>
	);
};
