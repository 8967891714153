import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormTextField } from 'framework/forms/FormTextField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { billingAccountsCommand_patchAlias, IBillingAccount, IPatchBillingAccountAliasRequest } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const createSchema = () => {
	return yup
		.object<IPatchBillingAccountAliasRequest>({
			alias: yup.string().required(),
			id: yup.string().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	item: IBillingAccount;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const PatchBillingAccountAliasRequestForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(billingAccountsCommand_patchAlias);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IPatchBillingAccountAliasRequest, helpers: FormikHelpers<IPatchBillingAccountAliasRequest>) => {
		const r = await patch({ ...values, id: item.id });
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`Alias succesfully patched`, 'success');
		}
	};

	return (
		<Formik<IPatchBillingAccountAliasRequest>
			validateOnMount
			initialValues={{
				id: item.id,
				alias: item.alias,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Patch alias ${item.name}`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormTextField<IPatchBillingAccountAliasRequest>
							name='alias'
							label='Alias'
							required
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IPatchBillingAccountAliasRequest>
						cancel={cancel}
						submitText='Update'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
