import { Divider } from '@material-ui/core';
import { isLast } from 'framework/utils/array/isLast';
import React, { useMemo } from 'react';
import { HtmlDivProps } from '../HtmlDivProps';

interface IProps extends HtmlDivProps {
	children: JSX.Element | (JSX.Element | undefined)[];
	margin?: number;
}

export const SpreadAndDivideHorizontallyComponent = ({ className, children, margin = 8, ...rest }: IProps) => {
	const fChildren = useMemo(() => (Array.isArray(children) ? (children.filter(t => t !== undefined) as JSX.Element[]) : [children]), [children]);

	return (
		<div
			className={`df-row-ac${className ? ` ${className}` : ''}`}
			{...rest}>
			{fChildren.map((t, index) => (
				<React.Fragment key={index.toString()}>
					{t}
					{isLast(t, fChildren) === false && (
						<Divider
							orientation='vertical'
							flexItem
							style={{ marginLeft: margin, marginRight: margin }}
						/>
					)}
				</React.Fragment>
			))}
		</div>
	);
};
