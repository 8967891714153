import { Theme, useTheme } from '@material-ui/core';
import { mapColorXToThemeColor } from 'framework/components/color/mapColorXToThemeColor';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import React, { useMemo } from 'react';
import { ITextLinkButtonProps } from './ITextLinkButtonProps';
import { TextLinkButtonColor } from './TextLinkButtonColor';

const mapTextLinkButtonColorToThemeColor = (color: TextLinkButtonColor, theme: Theme): string => {
	if (color === 'textPrimary') {
		return theme.palette.text.primary;
	} else if (color === 'textSecondary') {
		return theme.palette.text.secondary;
	} else if (color === 'disabled') {
		return theme.palette.text.disabled;
	} else {
		return mapColorXToThemeColor(color, theme);
	}
};

export const TextLinkButton = ({ children, startIcon, endIcon, disabled, onClick, underline, style, color, hoverColor, addPadding }: ITextLinkButtonProps) => {
	const theme = useTheme();
	const [isHovered, enter, leave] = useStateBoolean(false);
	const colorX = useMemo(() => mapTextLinkButtonColorToThemeColor(color ?? 'textPrimary', theme), [color, theme]);
	const hoverColorX = useMemo(() => mapTextLinkButtonColorToThemeColor(hoverColor ?? 'primary', theme), [hoverColor, theme]);

	return (
		<div
			className='df-row-ac'
			onClick={disabled === true ? undefined : onClick}
			style={{
				color: disabled === true ? theme.palette.text.disabled : isHovered ? hoverColorX : colorX,
				textDecoration: underline === true && isHovered && disabled !== true ? 'underline' : 'unset',
				fontWeight: 500,
				fontSize: 13,
				cursor: disabled ? 'unset' : 'pointer',
				paddingTop: addPadding === true ? 6 : 0,
				paddingBottom: addPadding === true ? 6 : 0,
				opacity: colorX === hoverColorX && isHovered ? 0.7 : 1,
				...style,
			}}
			onMouseEnter={enter}
			onMouseLeave={leave}>
			{startIcon && (
				<div
					className='icon-wrapper'
					style={{ fontSize: 18, display: 'flex', marginRight: 4 }}>
					{startIcon}
				</div>
			)}
			{children}
			{endIcon && (
				<div
					className='icon-wrapper'
					style={{ fontSize: 18, display: 'flex', marginRight: 4 }}>
					{endIcon}
				</div>
			)}
		</div>
	);
};
