import { PreloadContext } from 'app/PreloadContext';
import { PreloadCacheProvider } from 'framework/context/PreloadCacheProvider';
import { ILabelDto, labelsQuery_cache, labelsQuery_concurrencyToken } from 'gen/ApiClient';
import React from 'react';
import { useContext } from 'react';
import { LabelsCacheContext } from './LabelsCacheContext';

export const LabelsCacheProvider = ({ children }: any) => {
	const { labels } = useContext(PreloadContext);

	return (
		<PreloadCacheProvider<ILabelDto[]>
			concurrencyF={labelsQuery_concurrencyToken}
			loadF={labelsQuery_cache}
			context={LabelsCacheContext}
			initialData={labels}>
			{children}
		</PreloadCacheProvider>
	);
};
