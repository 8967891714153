import React from 'react';
import { FilterDataProvider } from './FilterDataProvider';
import { QueryProvider } from './QueryProvider';
import { ViewProvider } from './ViewProvider';

export const RizivRefundableDevicesProviders = ({ children }: any) => {
	return (
		<QueryProvider>
			<ViewProvider>
				<FilterDataProvider>{children}</FilterDataProvider>
			</ViewProvider>
		</QueryProvider>
	);
};
