import React from 'react';
import { IApiErrorStrings } from './localization/IApiErrorString';

export interface IApiContext {
    strings: IApiErrorStrings;
    // showLoader: () => string;
    // clearLoader: (id: string) => void;
    showError: (error: string) => void;
    redirect: () => void;
    // call: <TResult>(fnc: Promise<Try<TResult>>) => Promise<Try<TResult>>;
    // exec: <TArgs extends any[], TResult>(callback: (...args: TArgs) => Promise<Try<TResult>>, ...args: TArgs) => Promise<Try<TResult>>;
}

export const ApiContext = React.createContext<IApiContext>(undefined as any);
