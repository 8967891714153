import { IconButton, useTheme } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { CircleX } from 'framework/components/CircleX';
import { ILabelDto, IPatchLabelRequest } from 'gen/ApiClient';
import React from 'react';
import { CreateOrPatchLabelComponent } from './CreateOrPatchLabelComponent';
import { useMapLabelColor } from './useMapLabelColor';

interface IProps {
	item: ILabelDto;
	editId: string | undefined;
	onEdit: VoidFunction;
	clearEdit: VoidFunction;
	onPatch: (values: IPatchLabelRequest) => void;
	isSubmitting: boolean;
	style?: React.CSSProperties;
}

export const LabelListItem = ({ item, editId, onEdit, clearEdit, onPatch, isSubmitting, style }: IProps) => {
	const theme = useTheme();
	const xColor = useMapLabelColor(item);

	if (editId === item.id) {
		return (
			<CreateOrPatchLabelComponent
				item={item}
				isSubmitting={isSubmitting}
				onClear={clearEdit}
				onSave={values => onPatch({ ...values, id: item.id })}
			/>
		);
	}

	return (
		<div
			className='df-row gap-8'
			style={style}>
			<div style={{ marginTop: 4 }}>
				<CircleX
					color={xColor}
					size={12}
					variant='filled'
				/>
			</div>
			<div className='df-col'>
				<span>{item.name}</span>
				<span style={{ color: theme.palette.text.secondary }}>{item.caption ?? <em>{`n/a`}</em>}</span>
			</div>
			<div className='fg1'></div>
			<div className='df-row-ac gap-2'>
				<IconButton
					size='small'
					disabled={editId !== undefined}
					onClick={onEdit}>
					<EditOutlinedIcon fontSize='small' />
				</IconButton>
				{/* <IconButton
					size='small'
					disabled={editId !== undefined}
					onClick={() => {}}>
					<DeleteOutlineIcon fontSize='small' />
				</IconButton> */}
			</div>
		</div>
	);
};
