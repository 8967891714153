import { useApi } from "./useApi";
import { useState, useEffect } from "react";
import { Try } from "../Try";
import { useTrigger } from "./useTrigger";

export function useApiEffectWithDefer<TArgs, TResult>(f: (args: TArgs) => Promise<Try<TResult>>, args: TArgs, mustLoadF: (args: TArgs) => boolean): [TResult | undefined, () => void] {
    const [setLoading, clearLoading, handleError] = useApi();
    const [data, setData] = useState<TResult>();
    const [trigger, reload] = useTrigger();

    useEffect(() => {
        let cancelled = false;
        const loadX = async () => {
            console.log(`load useApiEffect ${f.name}`);
            const loadId = setLoading();
            const r = await f(args);
            if (r.isFailure) {
                handleError(r.error);
            } else {
                setData(r.result);
            }
            clearLoading(loadId);
        }
        if (cancelled === false && mustLoadF(args) === true) {
            loadX();
        }
        return () => {
            cancelled = true;
        }
        // missing deps handleError -> is a function
        // eslint-disable-next-line 
    }, [trigger, f, args]);

    return [data, reload];
}