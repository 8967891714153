import { billingAccountsQueryParamsLsKey } from 'app/localStorageKeys';
import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	billingAccountsQuery_concurrencyToken,
	billingAccountsQuery_query,
	IBillingAccount,
	IBillingAccountsQueryMetadata,
	IBillingAccountsQueryParams,
	IQueryResultWithMetadata,
} from 'gen/ApiClient';
import React from 'react';
import { BillingAccountsListQueryContext } from './BillingAccountsListQueryContext';

const DefaultParams: IBillingAccountsQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'name' },
	pageParams: { index: 1, size: 10 },
	prefilter: 'All',
};

export const BillingAccountsListQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<IBillingAccount, IBillingAccountsQueryMetadata>, IBillingAccountsQueryParams>
			defaultParams={DefaultParams}
			queryF={billingAccountsQuery_query}
			concurrencyF={billingAccountsQuery_concurrencyToken}
			localStorageKey={billingAccountsQueryParamsLsKey}
			context={BillingAccountsListQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
