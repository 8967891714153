import { IClientNamingsCheckDto } from 'gen/ApiClient';
import React from 'react';
import { NamingCheckLabelledProperty } from './NamingCheckLabelledProperty';

interface IProps {
	namingsCheck: IClientNamingsCheckDto;
	summaryView?: boolean;
}

export const ClientNamingsCheckComponent = ({ namingsCheck, summaryView }: IProps) => {
	return (
		<>
			<NamingCheckLabelledProperty
				check={namingsCheck.ravenDbNameCheck}
				label={'Raven Db Name'}
				summaryView={summaryView ?? false}
			/>
			<NamingCheckLabelledProperty
				check={namingsCheck.siteNameCheck}
				label={'Site name'}
				summaryView={summaryView ?? false}
			/>
			<NamingCheckLabelledProperty
				check={namingsCheck.directoryCheck}
				label={'Directory'}
				summaryView={summaryView ?? false}
			/>
		</>
	);
};
