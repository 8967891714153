import { Collapse, useTheme } from '@material-ui/core';
import React from "react";
import { useLocalization } from 'localization/useLocalization';
import { useStateToggleBoolean } from '../hooks/useStateToggleBoolean';
import { ExpandCollapseComponent } from './ExpandCollapseComponent';

interface IProps {
    defaultState?: boolean;
    children: any;
    style?: React.CSSProperties;
    expandText?: string;
    collapseText?: string;
}

export const ExpandCollapseManagedComponent = ({ children, defaultState = false, expandText, collapseText, style }: IProps) => {
    const [isExpanded, toggle] = useStateToggleBoolean(defaultState);
    const theme = useTheme();
    const strings = useLocalization();

    return (
        <>
            <ExpandCollapseComponent
                toggle={toggle}
                style={{ marginTop: theme.spacing(1.5), marginBottom: theme.spacing(1.5), justifyContent: 'flex-start', ...style }}
                isExpanded={isExpanded}
                expandText={expandText ?? strings.viewMore}
                collapseText={collapseText ?? strings.showLess} />
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </>
    );

}
