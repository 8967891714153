import React, { useMemo, useState } from 'react';
import { SelectableColumn } from '../SelectableColumn';
import { createHeaders } from '../utils/createHeaders';
import { ColumnRecord } from './ColumnRecord';
import { ViewContext } from './ViewContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = ['created', 'title', 'type'];

export const ViewProvider = ({ children }: any) => {
    const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
    const headers = useMemo(() => createHeaders(ColumnRecord), []);

    return (
        <ViewContext.Provider
            value={{
                columns: columns,
                columnsRecord: ColumnRecord,
                headers: headers,
                setColumns: setColumns,
            }}>
            {children}
        </ViewContext.Provider>
    );
}

