import { ColorX } from 'framework/components/color/ColorX';
import { useMemo } from 'react';

interface IItem {
	color: string | undefined;
}

export const useMapLabelColor = (item: IItem) => {
	return useMemo<ColorX | string>(() => item.color ?? 'default', [item]);
};
