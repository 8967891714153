import { createHeaderWithKey } from 'framework/table/createHeaderWithKey';
import { createSortableHeaderWithKey } from 'framework/table/createSortableHeaderWithKey';
import { IHeader } from 'framework/table/IHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import { IRizivHearingAidDefinitionV2 } from 'gen/ApiClient';
import { SelectableColumn } from './SelectableColumn';

export const createHeaders = (colRecord: Record<SelectableColumn, string>): Record<SelectableColumn, IHeader<IRizivHearingAidDefinitionV2>> => {
	return {
		identificationCode: createSortableHeaderWithKey<IRizivHearingAidDefinitionV2, SelectableColumn>(colRecord, 'identificationCode', t => t.id.toString()),
		distributor: createSortableHeaderWithKey<IRizivHearingAidDefinitionV2, SelectableColumn>(colRecord, 'distributor', t => t.distributor),
		manufacturerCommaName: createSortableHeaderWithKey<IRizivHearingAidDefinitionV2, SelectableColumn>(
			colRecord,
			'manufacturerCommaName',
			t => t.manufacturerCommaName
		),
		name: createSortableHeaderWithKey<IRizivHearingAidDefinitionV2, SelectableColumn>(colRecord, 'name', t => t.name),
		reference: createSortableHeaderWithKey<IRizivHearingAidDefinitionV2, SelectableColumn>(colRecord, 'reference', t => t.reference),
		validFrom: createHeaderWithKey<IRizivHearingAidDefinitionV2, SelectableColumn>(colRecord, 'validFrom', t => formatDate(t.validFrom)),
		validTo: createHeaderWithKey<IRizivHearingAidDefinitionV2, SelectableColumn>(colRecord, 'validTo', t => formatDate(t.validTo)),
		version: createSortableHeaderWithKey<IRizivHearingAidDefinitionV2, SelectableColumn>(colRecord, 'version', t => t.version.toString()),
	};
};
