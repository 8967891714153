import { useTheme } from '@material-ui/core';
import { ListFilterSearch } from 'framework/filter/ListFilterSearch';
import { isNullOrUndefined } from 'framework/utils/isNullOrUndefined';
import { ILabelDto, LinkContextType } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import { ApplyLabelListItem } from './ApplyLabelListItem';
import { useLabelsForContextType } from './useLabelsForContext';

interface IProps {
	contextType: LinkContextType;
	selectedLabelIds: string[];
	setSelectedLabelIds: React.Dispatch<React.SetStateAction<string[]>>;
	title: string;
	style?: React.CSSProperties;
}

export const ApplyLabelsPopoverContent = ({ contextType, title, selectedLabelIds, setSelectedLabelIds, style }: IProps) => {
	const [labels] = useLabelsForContextType(contextType);
	const [fLabels, setFLabels] = useState<ILabelDto[]>([]);
	const theme = useTheme();
	const [search, setSearch] = useState<string>('');

	useEffect(() => {
		if (isNullOrUndefined(search)) {
			setFLabels(labels);
		} else {
			setFLabels(labels.filter(t => `${t.name} ${t.caption}`.toLowerCase().includes(search.toLowerCase())));
		}
	}, [search, labels]);

	return (
		<div
			className='df-col'
			style={{
				minWidth: 300,
				...style,
			}}>
			<span
				style={{
					fontSize: 12,
					padding: '8px 10px',
					fontWeight: 'bold',
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}>
				{title}
				{selectedLabelIds.length > 0 && ` (${selectedLabelIds.length} selected)`}
			</span>
			<ListFilterSearch
				search={search}
				setSearch={setSearch}
				style={{
					padding: '4px 10px',
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}
			/>
			{fLabels.map(t => (
				<ApplyLabelListItem
					item={t}
					key={t.id}
					isSelected={selectedLabelIds.indexOf(t.id) > -1}
					onClick={() =>
						selectedLabelIds.indexOf(t.id) > -1
							? setSelectedLabelIds(selectedLabelIds.filter(x => x !== t.id))
							: setSelectedLabelIds([...selectedLabelIds, t.id])
					}
				/>
			))}
		</div>
	);
};
