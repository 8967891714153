import { useTheme } from '@material-ui/core';
import { StatCardX } from 'framework/components/cards/StatCardX';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IBillingAccountsQueryMetadata, IBillingAccountsQueryParams } from 'gen/ApiClient';
import React from 'react';

const Width: number = 250;

interface IProps {
	params: IBillingAccountsQueryParams;
	setParams: (params: IBillingAccountsQueryParams) => void;
	metadata: IBillingAccountsQueryMetadata;
	style?: React.CSSProperties;
}

export const BillingAccountsListStats = ({ metadata, style, setParams, params }: IProps) => {
	const theme = useTheme();
	const onFilter = (pars: IBillingAccountsQueryParams) => setParams(onResetToPage1(pars));

	return (
		<div
			className='df-row gap-16'
			style={style}>
			<StatCardX
				style={{ width: Width }}
				title={`W/ outstanding balance`}
				caption={`#`}
				onClick={() => onFilter({ ...params, prefilter: 'WithOutstandingBalance' })}>
				<span
					style={{
						fontSize: 24,
						color: metadata.countWithOutstandingBalance > 0 ? theme.palette.warning.main : theme.palette.success.main,
					}}>
					{metadata.countWithOutstandingBalance}
				</span>
			</StatCardX>
			<StatCardX
				style={{ width: Width }}
				title={`W/ missing data`}
				caption={`#`}
				onClick={() => onFilter({ ...params, prefilter: 'NotAllFielsFilledIn' })}>
				<span
					style={{
						fontSize: 24,
						color: metadata.countWithMissingData > 0 ? theme.palette.error.main : theme.palette.success.main,
					}}>
					{metadata.countWithMissingData}
				</span>
			</StatCardX>
		</div>
	);
};
