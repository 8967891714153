import { Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import { IDocument } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

interface IProps {
	item: IDocument;
}

export const DocumentDialogGridTitle = ({ item }: IProps) => {
	const strings = useLocalization();

	return (
		<DialogGridTitle icon={<DescriptionIcon />}>
			<div className='df-row-ac'>
				<Typography variant='h1'>{item.display}</Typography>
			</div>
			<LabelledProperty
				label={strings.extension}
				property={item.extension}
				withoutMargin
			/>
		</DialogGridTitle>
	);
};
