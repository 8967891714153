import { Badge, Button, IconButton, Menu, MenuItem, Toolbar } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import NotificationsIcon from '@material-ui/icons/Notifications';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LoaderContext } from 'framework/context/LoaderContext';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { LoginRoute } from '../routes';
import { useLoggedInUser } from './auth/useLoggedInUser';
import { DialogsContext } from 'shared/dialogs/DialogsContext';

interface IProps {
    toggleDrawer: () => void;
    isDrawerOpen: boolean;
}

export const AppBarContent = ({ toggleDrawer, isDrawerOpen }: IProps) => {
    const user = useLoggedInUser();
    const [anchorUser, openUser, closeUser] = useAnchorElement();
    const { push } = useHistory();
    const { stackCount } = useContext(DialogsContext);
    const { count: loadCount } = useContext(LoaderContext);

    return (
        <>
            <Menu
                anchorEl={anchorUser}
                open={Boolean(anchorUser)}
                onClose={closeUser}
                keepMounted>
                <MenuItem
                    onClick={() => push(LoginRoute)}>
                    {`Logout`}
                </MenuItem>
            </Menu>
            <div>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}>
                        {isDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
                    </IconButton>
                    {process.env.NODE_ENV === `development` ? <div>Stack count: {stackCount}</div> : <div></div>}
                    {process.env.NODE_ENV === `development` ? <div>Load count: {loadCount}</div> : <div></div>}
                    <div className="fg1" />
                    <IconButton color="inherit">
                        <Badge badgeContent={0} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Button size="large" variant="text" color="inherit" disableElevation endIcon={<AccountCircle />} onClick={openUser}>
                        <span>{user?.firstName}</span>
                    </Button>
                </Toolbar>
            </div>
        </>
    );
}
