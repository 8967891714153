import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useContext } from 'react';
import { clearParams } from 'framework/utils/clearParams';
import { FilterBar } from 'framework/filter/FilterBar';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { ITemplatesQueryParams, LinkViewContextTypes, TemplateTypes } from 'gen/ApiClient';
import { TemplatesQueryContext } from './TemplatesQueryContext';

const exclusions: (keyof ITemplatesQueryParams)[] = ['rootTemplateType'];

interface IProps {
	params: ITemplatesQueryParams;
	onFilter: (params: ITemplatesQueryParams) => void;
	paddingLeft: number;
}

export const TemplatesFilter = ({ params, onFilter, paddingLeft }: IProps) => {
	const { queryResult } = useContext(TemplatesQueryContext);

	if (queryResult === undefined) {
		return <div></div>;
	}

	return (
		<FilterBar
			isFiltered={isFiltered(params, exclusions)}
			onClear={() => onFilter(clearParams(params, exclusions))}
			paddingLeft={paddingLeft}>
			<StringListFilterComponent
				options={TemplateTypes}
				selected={params.predefinedTypes}
				setSelected={vals => onFilter({ ...params, predefinedTypes: vals })}
				label='Type'
				icon={<HelpOutlineIcon />}
			/>
			<StringListFilterComponent
				options={LinkViewContextTypes}
				selected={params.mainContexts}
				setSelected={vals => onFilter({ ...params, mainContexts: vals })}
				label='Context'
				icon={<HelpOutlineIcon />}
			/>
		</FilterBar>
	);
};
