import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { DividerWithCaption } from 'framework/components/DividerWithCaption';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { CardNavigationLink } from './CardNavigationLink';
import { IPushNavigationLink } from './IPushNavigationLink';

interface IProps {
	links?: (IPushNavigationLink | undefined)[];
	hideCaption?: boolean;
}

export const PushNavigationLinks = ({ links, hideCaption = false }: IProps) => {
	const { push } = useHistory();
	const fLinks = useMemo(() => (links ? (links.filter(t => t !== undefined) as IPushNavigationLink[]) : []), [links]);

	return (
		<>
			{fLinks.length > 0 && (
				<>
					{hideCaption === false && (
						<DividerWithCaption
							spacingBottom={1}
							caption={
								<div className='df-row-ac'>
									<OpenInBrowserIcon
										fontSize='small'
										style={{ marginRight: 4 }}
									/>
									<div>{`Links`}</div>
								</div>
							}
						/>
					)}
					{fLinks.map(t => (
						<CardNavigationLink
							key={t.text}
							isMatch={false}
							onNavigate={() => push(t.route)}
							startIcon={t.icon}
							text={t.text}
						/>
					))}
				</>
			)}
		</>
	);
};
