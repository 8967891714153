import { Dialog, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { DialogTitleWithFormStepper } from 'framework/dialogs/DialogTitleWithFormStepper';
import { OverflowDialogContent } from 'framework/dialogs/OverflowDialogContent';
import { FormTextField } from 'framework/forms/FormTextField';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { billingAccountsCommand_add, ICreateBillingAccountRequest } from 'gen/ApiClient';
import React, { useState } from 'react';
import * as yup from 'yup';

const Schema = yup
	.object<ICreateBillingAccountRequest>({
		addressLine: yup.string(),
		alias: yup.string(),
		city: yup.string(),
		country: yup.string(),
		name: yup.string().required(),
		vatNumber: yup.string().required(),
		zip: yup.string(),
		email: yup.string().email().required(),
	})
	.defined();

interface IProps extends DialogProps {
	confirm: (id: string) => void;
	cancel: () => void;
}

const stepsRecord: Record<number, (keyof ICreateBillingAccountRequest)[]> = {
	0: ['name', 'alias', 'vatNumber'],
	1: ['addressLine', 'city', 'zip', 'country'],
};

export const CreateBillingAccountRequestForm = ({ confirm, cancel, ...rest }: IProps) => {
	const [create, isSubmitting] = useFormSubmit(billingAccountsCommand_add);
	const [step, setStep] = useState<number>(0);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: ICreateBillingAccountRequest, helpers: FormikHelpers<ICreateBillingAccountRequest>) => {
		const r = await create(values);
		if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
			confirm(r.result.objectId);
			notify(`Billing account '${values.name}' successfully created`, 'success');
		}
	};

	return (
		<Formik<ICreateBillingAccountRequest>
			validateOnMount
			initialValues={{
				addressLine: '',
				alias: '',
				city: '',
				country: 'BE',
				name: '',
				vatNumber: 'BE',
				zip: '',
				email: '',
			}}
			validationSchema={Schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={Schema}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<ICreateBillingAccountRequest>;
	cancel: () => void;
	stepsRecord: Record<number, (keyof ICreateBillingAccountRequest)[]>;
}

const InnerDialog = ({ step, setStep, isSubmitting, schema, cancel, stepsRecord, ...rest }: IInnerProps) => {
	return (
		<Dialog
			{...rest}
			maxWidth='lg'>
			<DialogTitleWithFormStepper
				title={`New billing account`}
				step={step}
				labels={[`General`, `Address`]}
			/>
			<OverflowDialogContent dividers>
				{step === 0 && (
					<>
						<FormTextField<ICreateBillingAccountRequest>
							name='name'
							label={`Name`}
							required
						/>
						<FormTextField<ICreateBillingAccountRequest>
							name='alias'
							label={`Alias`}
						/>
						<FormTextField<ICreateBillingAccountRequest>
							name='vatNumber'
							label={`VAT number`}
							required
						/>
						<FormTextField<ICreateBillingAccountRequest>
							name='email'
							label={`Email`}
							required
						/>
					</>
				)}
				{step === 1 && (
					<>
						<FormTextField<ICreateBillingAccountRequest>
							name='addressLine'
							label={`Address line`}
						/>
						<FormTextField<ICreateBillingAccountRequest>
							name='zip'
							label={`Zip`}
						/>
						<FormTextField<ICreateBillingAccountRequest>
							name='city'
							label={`City`}
						/>
						<FormTextField<ICreateBillingAccountRequest>
							name='country'
							label={`Country`}
						/>
					</>
				)}
			</OverflowDialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={`Create`}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</Dialog>
	);
};
