import React, { useMemo, useState } from 'react';
import { SelectableColumn } from './SelectableColumn';
import { createHeaders } from './createHeaders';
import { ColumnRecord } from './ColumnRecord';
import { ViewContext } from './ViewContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = ['identificationCode', 'manufacturerCommaName', 'distributor', 'validFrom', 'validTo'];

export const ViewProvider = ({ children }: any) => {
	const headers = useMemo(() => createHeaders(ColumnRecord), []);
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: ColumnRecord,
				headers: headers,
				setColumns: setColumns,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
