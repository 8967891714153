import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { getNow } from 'framework/utils/getNow';
import { IRegisterPaymentRequest, ISale, salesCommand_registerPayment } from 'gen/ApiClient';
import React from 'react';
import * as yup from 'yup';

const Now = getNow();

const createSchema = () => {
	return yup
		.object<IRegisterPaymentRequest>({
			amount: yup.number().required(),
			salesId: yup.string().required(),
			paymentDate: yup.date().required(),
		})
		.defined();
};

interface IProps extends DialogProps {
	item: ISale;
	confirm: VoidFunction;
	cancel: VoidFunction;
}

export const RegisterPaymentRequestForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [register, isSubmitting] = useFormSubmit(salesCommand_registerPayment);
	const notify = useSnackbarNotify();

	const handleSubmit = async (values: IRegisterPaymentRequest, helpers: FormikHelpers<IRegisterPaymentRequest>) => {
		const r = await register({ ...values, salesId: item.id });
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`Payment registered`, 'success');
		}
	};

	return (
		<Formik<IRegisterPaymentRequest>
			validateOnMount
			initialValues={{
				salesId: item.id,
				amount: item.outstandingBalance,
				paymentDate: Now,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='xs'
					fullWidth>
					<DialogTitle>
						<div>{`Register payment for ${item.reference}`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormMoneyField<IRegisterPaymentRequest>
							name='amount'
							label={`Amount`}
							allowNegative
						/>
						<FormDatePicker<IRegisterPaymentRequest>
							name='paymentDate'
							label='Payment date'
						/>
					</DialogContent>
					<CancelSubmitFormDialogActions<IRegisterPaymentRequest>
						cancel={cancel}
						submitText='Register'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
