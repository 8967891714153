import { CardProps } from '@material-ui/core';
import { StringValueOrNotApplicable } from 'framework/components/StringValueOrNotApplicable';
import { CardX } from 'framework/components/cards/CardX';
import { CaptionVerticalLabelledPropertyX } from 'framework/components/labelledProperty/CaptionVerticalLabelledPropertyX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISale } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { SaleDetailProcessComponent } from './SaleDetailProcessComponent';

interface IProps extends CardProps {
	item: ISale;
	reload: VoidFunction;
}

export const SalesDetailOverviewHeaderCard = ({ item, reload, ...rest }: IProps) => {
	const strings = useLocalization();

	return (
		<CardX
			{...rest}
			className='df-row gap-24'>
			<SaleDetailProcessComponent
				item={item}
				reload={reload}
			/>
			<div className='df-row gap-16 jc-sb fg1'>
				<div className='df-col gap-6'>
					<Typography16pxBold>{`Payment details`}</Typography16pxBold>
					<CaptionVerticalLabelledPropertyX label={strings.amountToPay}>{formatCurrency(item.amountToPay)}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label={strings.amountPaid}>{formatCurrency(item.amountPaid)}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label={strings.outstandingBalance}>{formatCurrency(item.outstandingBalance)}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label={`Structured reference`}>
						<StringValueOrNotApplicable>{item.belgianStructuredCreditorReference}</StringValueOrNotApplicable>
					</CaptionVerticalLabelledPropertyX>
				</div>
				<div className='df-col gap-6'>
					<Typography16pxBold>{`Totals`}</Typography16pxBold>
					<CaptionVerticalLabelledPropertyX label={strings.totalInVat}>{formatCurrency(item.priceInVat)}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label={strings.totalExVat}>{formatCurrency(item.priceExVat)}</CaptionVerticalLabelledPropertyX>
					<CaptionVerticalLabelledPropertyX label={strings.vatAmount}>{formatCurrency(item.vatAmount)}</CaptionVerticalLabelledPropertyX>
				</div>
				<div className='df-col gap-6'>
					<Typography16pxBold>{`VAT splits`}</Typography16pxBold>
					{item.vatSplits.map(t => (
						<CaptionVerticalLabelledPropertyX
							key={t.percentage}
							label={`@${t.percentage}% - ${strings.inVatShort} | ${strings.exVatShort} | ${strings.vatAmount}`}>
							{`${formatCurrency(t.amountInVat)} | ${formatCurrency(t.amountExVat)} | ${formatCurrency(t.value)}`}
						</CaptionVerticalLabelledPropertyX>
					))}
				</div>
			</div>
		</CardX>
	);
};
