import { PropTypes } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { CancelSubmitDialogActions } from './CancelSubmitDialogActions';

interface IProps {
    cancel: () => void;
    submitText: string;
    isSubmitting: boolean;
    colorSubmitButton?: PropTypes.Color;
    previous?: () => void;
    cancelText?: string;
}

export const CancelSubmitFormDialogActions = <TModel extends object>({ ...rest }: IProps) => {
    const props = useFormikContext<TModel>();
    return <CancelSubmitDialogActions {...rest} submitButtonType="submit" onSubmit={() => props.submitForm()} isValid={props.isValid} />
}