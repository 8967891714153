import { IUpdateScheduledTaskForClientQueryParams } from 'gen/ApiClient';

export const DefaultParams: IUpdateScheduledTaskForClientQueryParams = {
    sortParams: { direction: 'desc', property: 'created' },
    pageParams: { index: 1, size: 10 },
    createdAfter: undefined,
    createdBefore: undefined,
    executeAfterAfter: undefined,
    executeAfterBefore: undefined,
    statuses: [],
}