import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useMemo } from 'react';
import { ButtonGroupWithSelection } from 'framework/components/ButtonGroupWithSelection';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { createEmptyHeader } from 'framework/table/createEmptyHeader';
import { PagebleTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createType } from 'framework/utils/createType';
import { createRecordFromStrings } from 'framework/utils/record/createRecordFromStrings';
import { IMedicalTreatmentNomenclatureForAudiology, IMedicalTreatmentNomenclaturesFilterParams, MedicalTreatmentNomenclatureExportProp, MedicalTreatmentNomenclatureExportProps, medicalTreatmentNomenclaturesQuery_export } from 'gen/ApiClient';
import { ExportButtonMenuAndDialog } from 'shared/export/ExportButtonMenuAndDialog';
import { NoRecordsFound } from 'shared/NoRecordsFound';
import { SelectColumnsButtonAndDialog } from 'shared/SelectColumnsButtonAndDialog';
import { QueryContext } from './context/QueryContext';
import { ViewContext } from './context/ViewContext';
import { DetailsHeader } from './DetailsHeader';
import { Filter } from './Filter';
import { MtnSearchBar } from './MtnSearchBar';
import { SelectableColumn } from './SelectableColumn';

const QuickFilters = createType('all', 'isCurrentlyValid');
export type QuickFilter = typeof QuickFilters[number];

interface IProps {
    reloadTrigger: Trigger;
}

export const MtnsPage = ({ reloadTrigger }: IProps) => {
    const theme = useTheme();
    const { params, setParams, queryResult, reload, init } = useContext(QueryContext);
    const { headers, columns, setColumns, columnsRecord, quickFilter, setQuickFilter, quickFilterRecord } = useContext(ViewContext);
    const exportRecord = useMemo(() => createRecordFromStrings(MedicalTreatmentNomenclatureExportProps), []);

    useEffect(() => {
        init();
    }, [init]);

    useLazyEffect(() => {
        reload();
    }, [reloadTrigger]);

    if (queryResult === undefined) {
        return <div></div>
    }

    return (
        <>
            <div className="df-row-ac jc-sb" style={{ marginTop: 16, marginBottom: '24px' }}>
                <MtnSearchBar
                    params={params}
                    setParams={setParams} />
                <div></div>
            </div>
            {queryResult.unfilteredCount > 0 &&
                <Card>
                    <div className="df-row-ac jc-sb" style={{ padding: theme.spacing(1) }}>
                        <ButtonGroupWithSelection<QuickFilter>
                            options={QuickFilters}
                            selected={quickFilter}
                            setSelected={setQuickFilter}
                            renderOption={t => quickFilterRecord[t]} />
                        <div>
                            <ExportButtonMenuAndDialog<IMedicalTreatmentNomenclaturesFilterParams, MedicalTreatmentNomenclatureExportProp>
                                params={params}
                                filteredCount={queryResult.filteredCount}
                                unfilteredCount={queryResult.unfilteredCount}
                                downloadF={medicalTreatmentNomenclaturesQuery_export}
                                fileName={`mtns`}
                                record={exportRecord}
                                allProps={MedicalTreatmentNomenclatureExportProps} />
                            <SelectColumnsButtonAndDialog
                                selectedColumns={columns}
                                setSelectedColumns={setColumns}
                                record={columnsRecord} />
                        </div>
                    </div>
                    <Divider />
                    <div className="df-row jc-sb">
                        <Filter
                            params={params}
                            onFilter={p => setParams({ ...p, pageParams: { index: 1, size: params.pageParams.size } })}
                            paddingLeft={theme.spacing(1.5)} />
                    </div>
                    <Divider />
                    <CardMedia>
                        <PagebleTableWithColumnSelection<IMedicalTreatmentNomenclatureForAudiology, SelectableColumn>
                            queryResult={queryResult}
                            headers={headers}
                            sortParams={params.sortParams}
                            pageParams={params.pageParams}
                            setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
                            getKey={t => t.code!}
                            selectedColumns={columns}
                            appendColumn={createEmptyHeader<IMedicalTreatmentNomenclatureForAudiology>('details', t => <DetailsHeader mtn={t} />, 'right')}
                        />
                    </CardMedia>
                </Card>
            }
            {queryResult.unfilteredCount === 0 && <NoRecordsFound>{`No medical treatment nomenclatures found`}</NoRecordsFound>}
        </>
    );
}
