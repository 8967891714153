import React from 'react';
import { Searchbar } from 'framework/components/Searchbar';
import { IMedicalTreatmentNomenclaturesQueryParams, MedicalTreatmentNomenclatureSearchMode, MedicalTreatmentNomenclatureSearchModes } from 'gen/ApiClient';
import { SelectSearchMode } from 'shared/SelectSearchMode';

interface IProps {
    params: IMedicalTreatmentNomenclaturesQueryParams;
    setParams: React.Dispatch<React.SetStateAction<IMedicalTreatmentNomenclaturesQueryParams>>;
}

export const MtnSearchBar = ({ params, setParams }: IProps) => {
    return (
        <Searchbar
            value={params.searchString}
            onSearch={text => setParams({ ...params, searchString: text, pageParams: { ...params.pageParams, index: 1 } })}
            placeholder={params.searchMode ?? ''}
            style={{ width: '520px' }}
            variant="paper">
            <SelectSearchMode<MedicalTreatmentNomenclatureSearchMode>
                mode={params.searchMode as any}
                setMode={mode => setParams({ ...params, searchMode: mode, pageParams: { ...params.pageParams, index: 1 } })}
                modes={MedicalTreatmentNomenclatureSearchModes} />
        </Searchbar>
    );
}