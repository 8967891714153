import { Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip, useTheme } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import React from 'react';
import { ExpandLessOrMoreIcon } from 'framework/components/ExpandLessOrMoreIcon';
import { IDocumentKeyDto } from 'gen/ApiClient';
import { useDialogsContext } from '../useDialogsContext';
import { DocumentKeyDialog } from './DocumentKeyDialog';

interface IProps {
    contextDescription: string;
    keys: IDocumentKeyDto[];
    isExpanded: boolean;
    onClick: () => void;
}

export const DocumentContextListComponent = ({ keys, isExpanded, contextDescription, onClick }: IProps) => {
    const theme = useTheme();
    const { open, cancel } = useDialogsContext();

    const onDetail = (documentKey: IDocumentKeyDto) => {
        open(<DocumentKeyDialog
            open
            documentKey={documentKey}
            close={cancel} />)
    }

    return (
        <>
            <ListItem button onClick={onClick}>
                <ListItemText primary={
                    <Tooltip title={contextDescription}>
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{contextDescription}</div>
                    </Tooltip>
                } />
                <ExpandLessOrMoreIcon isExpanded={isExpanded} style={{ color: theme.palette.text.secondary }} />
            </ListItem>
            <Collapse in={isExpanded}>
                <List dense>
                    {keys.map(t =>
                        <ListItem key={t.key} button style={{ paddingLeft: 24, paddingTop: 0, paddingBottom: 0 }} onClick={() => onDetail(t)}>
                            <ListItemText
                                primary={
                                    <Tooltip title={t.description ?? ''}>
                                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{t.description}</div>
                                    </Tooltip>}
                                secondary={t.keyWithBrackets} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={() => navigator.clipboard.writeText(t.keyWithBrackets)}>
                                    <FileCopyOutlinedIcon fontSize="small" />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>)}
                </List>
            </Collapse>
        </>
    );
}