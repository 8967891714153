import { CardProps, Divider } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { clearParams } from 'framework/utils/clearParams';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { NumberListFilterComponent } from 'framework/filter/NumberListFilterComponent';
import { isFiltered } from 'framework/utils/isFiltered';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { IClientQueryMetadata, IClientsQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { ClientsListPrefilter } from './ClientsListPrefilter';

interface IProps extends CardProps {
	params: IClientsQueryParams;
	setParams: React.Dispatch<React.SetStateAction<IClientsQueryParams>>;
	metadata: IClientQueryMetadata;
}

const exclusions: (keyof IClientsQueryParams)[] = ['includeDetails'];

export const ClientsListFilterCard = ({ params, setParams, metadata, ...rest }: IProps) => {
	const strings = useLocalization();
	const isFilteredX = useMemo(() => isFiltered(params, exclusions), [params]);

	const onFilter = (pars: IClientsQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params, exclusions)));

	return (
		<CardX
			className='df-col gap-8'
			{...rest}>
			<div className='df-row-ac'>
				<span style={{ fontWeight: 500 }}>{strings.filter}</span>
			</div>
			<ClientsListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col-gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				<NumberListFilterComponent
					options={metadata.builds}
					selected={params.builds}
					setSelected={builds => onFilter({ ...params, builds: builds })}
					label='Build'
					icon={<HelpOutlineIcon />}
				/>
			</div>
		</CardX>
	);
};
