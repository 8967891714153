import { Typography, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { ColorX } from 'framework/components/color/ColorX';
import { formatDate } from 'framework/utils/date/formatDate';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import React from 'react';
import { ProcessStepXStatus } from './ProcessStepXStatus';
import { StepX } from './StepX';

interface IProps {
	doneText: string;
	todoText: string;
	status: ProcessStepXStatus;
	date?: Date;
	isLast?: boolean;
	children?: React.ReactNode;
}

export const ProcessStepX = ({ status, doneText, todoText, date, isLast, children }: IProps) => {
	const theme = useTheme();
	const color = React.useMemo<ColorX>(() => (status === 'cancelled' ? 'default' : status === 'active' ? 'secondary' : 'primary'), [status]);

	return (
		<StepX
			status={status === 'cancelled' ? 'active' : status}
			color={color}
			isLast={isLast}>
			<span style={{ fontWeight: 500 }}>{status === 'done' ? doneText : todoText}</span>
			{status === 'done' && (
				<div className='df-row-ac gap-4'>
					{isNotNullNorUndefined(date) && <Typography variant='caption'>{formatDate(date)}</Typography>}
					<CheckIcon style={{ fontSize: 16, color: theme.palette.success.main }} />
				</div>
			)}
			{children}
		</StepX>
	);
};
