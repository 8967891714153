import { Checkbox, Divider, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';
import { PageX } from 'framework/components/layout/PageX';
import { QueryResultPaginationX } from 'framework/components/pagination/QueryResultPaginationX';
import { SearchbarX } from 'framework/components/search/SearchbarX';
import { Typography16pxBold } from 'framework/components/typography/Typography16pxBold';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useQueryContext } from 'framework/hooks/useQueryContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { clientsCommand_setMetadata } from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { CreateClientRequestForm } from '../CreateClientRequestForm';
import { ClientListItem } from './ClientListItem';
import { ClientsListFilterCard } from './ClientsListFilterCard';
import { ClientsListOrderBySelect } from './ClientsListOrderBySelect';
import { ClientsQueryContext } from './ClientsQueryContext';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';

export const ClientsListPage = () => {
	const { params, setParams, queryResult, reload } = useQueryContext(ClientsQueryContext);
	const { open, confirm, cancel } = useDialogsContext(reload);
	const theme = useTheme();

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onNew = () => {
		open(
			<CreateClientRequestForm
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onSetMetadata = () => {
		open(
			<AlertDialog
				open
				title={'Set client metadata'}
				content={`Force job set client metadata?`}
				acceptText={`Yes, proceed`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => clientsCommand_setMetadata()}
				accept={confirm}
			/>
		);
	};

	return (
		<PageX
			stickyHeader={
				<div
					className='df-col gap-8 fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<PageBreadcrumbTitle title={`Clients`} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
					<SearchbarX
						placeholder={`Search...`}
						value={params.searchString}
						onSearch={val => setParams(onResetToPage1({ ...params, searchString: val }))}
						style={{ width: 500 }}
					/>
				</div>
			}
			stickySidebar={
				<div
					className='df-col gap-16 h100'
					style={{ minWidth: 250, padding: 16, paddingRight: 8 }}>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<AddIcon />}
						onClick={onNew}
						style={{ borderRadius: 12 }}>
						{`New client`}
					</Button>
					<ClientsListFilterCard
						params={params}
						setParams={setParams}
						metadata={queryResult.metadata}
					/>
					<div className='fg1'></div>
					<Divider />
					<div className='df-col gap-4'>
						<TextLinkButton
							startIcon={<SyncOutlinedIcon />}
							onClick={onSetMetadata}>{`Set metadata on clients`}</TextLinkButton>
						<span style={{ fontSize: 12, color: theme.palette.text.secondary, whiteSpace: 'pre' }}>
							{`Laatst metadata geplaatst op:\n${formatDateX(queryResult.metadata.metadataJobLastRun, 'dd/MM/yyyy HH:mm')}`}
						</span>
					</div>
				</div>
			}>
			<div
				className='df-col gap-16 h100'
				style={{
					padding: 16,
					paddingLeft: 8,
				}}>
				<CardX>
					<div
						className='df-row'
						style={{ gap: 8 }}>
						<Typography16pxBold>{`Results`}</Typography16pxBold>
						<div className='fg1'></div>
						<div
							className='df-col'
							style={{ alignItems: 'end' }}>
							<Button
								size='small'
								endIcon={
									<Checkbox
										checked={params.includeDetails}
										style={{ padding: 0 }}
									/>
								}
								style={{ marginRight: -2 }}
								onClick={() => setParams(onResetToPage1({ ...params, includeDetails: !params.includeDetails }))}>
								{`Include details`}
							</Button>
							<ClientsListOrderBySelect
								sortParams={params.sortParams}
								onFilter={sortParams => setParams(onResetToPage1({ ...params, sortParams: sortParams }))}
								isLoadedWithDetails={params.includeDetails}
							/>
						</div>
					</div>
					<Divider style={{ marginTop: 8 }} />
					{queryResult.values.map(t => (
						<ClientListItem
							key={t.id}
							isLoadedWithDetails={params.includeDetails}
							item={t}
						/>
					))}
					<QueryResultPaginationX
						queryResult={queryResult}
						setPageParams={pageParams => setParams({ ...params, pageParams: pageParams })}
						style={{ justifyContent: 'end', paddingTop: 12, marginBottom: -4 }}
					/>
				</CardX>
			</div>
		</PageX>
	);
};
