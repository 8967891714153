import { QueryProvider as FrameworkQueryProvider } from 'framework/context/QueryProvider';
import {
	clientsQuery_concurrencyToken,
	clientsQuery_query,
	IClientDto,
	IClientQueryMetadata,
	IClientsQueryParams,
	IQueryResultWithMetadata,
} from 'gen/ApiClient';
import React from 'react';
import { clientsQueryParamsLsKey } from '../../../localStorageKeys';
import { ClientsQueryContext } from './ClientsQueryContext';

const DefaultParams: IClientsQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'name' },
	pageParams: { index: 1, size: 10 },
	builds: [],
	includeDetails: false,
	prefilter: 'All',
};

export const ClientsQueryProvider = ({ children }: { children: any }) => {
	return (
		<FrameworkQueryProvider<IQueryResultWithMetadata<IClientDto, IClientQueryMetadata>, IClientsQueryParams>
			defaultParams={DefaultParams}
			queryF={clientsQuery_query}
			concurrencyF={clientsQuery_concurrencyToken}
			localStorageKey={clientsQueryParamsLsKey}
			context={ClientsQueryContext}>
			{children}
		</FrameworkQueryProvider>
	);
};
