import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { ILicenseFeeDto, IPatchLicenseFeeRequest, LicensePricingMethod, licensesCommand_patchFee, subscriptionsQuery_activeForLicense } from 'gen/ApiClient';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { ISubscriptionWithIsSelected } from './ISubscriptionWithIsSelected';
import { PatchLicenseFeeSubscriptionsTable } from './PatchLicenseFeeSubscriptionsTable';
import { PricingMethodFeeDescriptionRecord } from './PricingMethodFeeDescriptionRecord';

const createSchema = () => {
	return yup
		.object<IPatchLicenseFeeRequest>({
			value: yup.number().required(),
			validFrom: yup.date().required(),
			licenseId: yup.string().required(),
			licenseFeeId: yup.string().required(),
			patchSubscriptionIds: yup.mixed(),
		})
		.defined();
};

interface IProps extends DialogProps {
	licenseId: string;
	pricingMethod: LicensePricingMethod;
	fee: ILicenseFeeDto;
	confirm: () => void;
	cancel: VoidFunction;
}

export const PatchLicenseFeeRequestForm = ({ licenseId, pricingMethod, fee, confirm, cancel, ...rest }: IProps) => {
	const [add, isSubmitting] = useFormSubmit(licensesCommand_patchFee);
	const [activeSubscriptions] = useApiEffect(subscriptionsQuery_activeForLicense, licenseId);
	const [subscriptions, setSubscriptions] = useState<ISubscriptionWithIsSelected[]>([]);
	const notify = useSnackbarNotify();

	useEffect(() => {
		if (activeSubscriptions) {
			setSubscriptions(activeSubscriptions.map<ISubscriptionWithIsSelected>(t => ({ ...t, isSelected: true })));
		}
	}, [activeSubscriptions]);

	const handleSubmit = async (values: IPatchLicenseFeeRequest, helpers: FormikHelpers<IPatchLicenseFeeRequest>) => {
		const r = await add({ ...values, patchSubscriptionIds: subscriptions.filter(t => t.isSelected).map(t => t.id) });
		if (handleFormResponse(r, helpers)) {
			confirm();
			notify(`License fee succesfully patched`, 'success');
		}
	};

	if (activeSubscriptions === undefined) {
		return <></>;
	}

	return (
		<Formik<IPatchLicenseFeeRequest>
			validateOnMount
			initialValues={{
				value: fee.value,
				validFrom: fee.validFrom,
				licenseId: licenseId,
				licenseFeeId: fee.id,
				patchSubscriptionIds: [],
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			<Form>
				<Dialog
					{...rest}
					scroll='paper'
					maxWidth='md'
					fullWidth>
					<DialogTitle>
						<div>{`Patch fee`}</div>
					</DialogTitle>
					<DialogContent
						dividers
						className='df-col'>
						<FormDatePicker<IPatchLicenseFeeRequest>
							name='validFrom'
							label='Valid From'
						/>
						<FormMoneyField<IPatchLicenseFeeRequest>
							name='value'
							label={PricingMethodFeeDescriptionRecord[pricingMethod]}
							required
						/>
						{subscriptions.length > 0 && (
							<PatchLicenseFeeSubscriptionsTable
								subscriptions={subscriptions}
								setSubscriptions={setSubscriptions}
							/>
						)}
					</DialogContent>
					<CancelSubmitFormDialogActions<IPatchLicenseFeeRequest>
						cancel={cancel}
						submitText='Update'
						isSubmitting={isSubmitting}
					/>
				</Dialog>
			</Form>
		</Formik>
	);
};
