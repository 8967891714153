import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { Trigger } from 'framework/hooks/useTrigger';
import { PagebleTableWithColumnSelection } from 'framework/table/PageableTableWithColumnSelection';
import { createType } from 'framework/utils/createType';
import { IRizivHearingAidDefinitionV2 } from 'gen/ApiClient';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { createDetailsHeader } from 'shared/createDetailsHeader';
import { NoRecordsFound } from 'shared/NoRecordsFound';
import { SelectColumnsButtonAndDialog } from 'shared/SelectColumnsButtonAndDialog';
import { QueryContext } from './QueryContext';
import { ViewContext } from './ViewContext';
import { FilterRizivHearingAidDefinitionsV2 } from './FilterRizivHearingAidDefinitionsV2';
import { SelectableColumn } from './SelectableColumn';
import { XSearchBar } from './XSearchBar';
import { RizivRefundableDeviceDialog } from '../RizivRefundableDeviceDialog';

const QuickFilters = createType('all', 'isCurrentlyValid');
export type QuickFilter = (typeof QuickFilters)[number];

interface IProps {
	reloadTrigger: Trigger;
}

export const RizivHearingAidDefinitionsV2ListPage = ({ reloadTrigger }: IProps) => {
	const theme = useTheme();
	const { params, setParams, queryResult, reload, init } = useContext(QueryContext);
	const { headers, columns, setColumns, columnsRecord } = useContext(ViewContext);
	const { open, cancel: close } = useDialogsContext();

	useEffect(() => {
		init();
	}, [init]);

	useLazyEffect(() => {
		reload();
	}, [reloadTrigger]);

	if (queryResult === undefined) {
		return <div></div>;
	}

	const onViewDetails = (item: IRizivHearingAidDefinitionV2) => {
		open(
			<RizivRefundableDeviceDialog
				open
				identificationCode={item.id!}
				close={close}
			/>
		);
	};

	return (
		<>
			<div
				className='df-row-ac jc-sb'
				style={{ marginTop: 16, marginBottom: '24px' }}>
				<XSearchBar
					params={params}
					setParams={setParams}
				/>
				<div></div>
			</div>
			{queryResult.unfilteredCount > 0 && (
				<Card>
					<div className='df-row jc-sb'>
						<FilterRizivHearingAidDefinitionsV2
							params={params}
							onFilter={p => setParams({ ...p, pageParams: { index: 1, size: params.pageParams.size } })}
							paddingLeft={theme.spacing(1.5)}
						/>
						<div>
							<SelectColumnsButtonAndDialog
								selectedColumns={columns}
								setSelectedColumns={setColumns}
								record={columnsRecord}
							/>
						</div>
					</div>
					<Divider />
					<CardMedia>
						<PagebleTableWithColumnSelection<IRizivHearingAidDefinitionV2, SelectableColumn>
							queryResult={queryResult}
							headers={headers}
							sortParams={params.sortParams}
							pageParams={params.pageParams}
							setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
							getKey={t => t.id.toString()}
							selectedColumns={columns}
							appendColumn={createDetailsHeader<IRizivHearingAidDefinitionV2>(onViewDetails)}
							// appendColumn={createEmptyHeader<IRizivRefundableHearingInstrument>('details', t => <DetailsHeader mtn={t} />, 'right')}
						/>
					</CardMedia>
				</Card>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{`No Riziv Refundable Devices found`}</NoRecordsFound>}
		</>
	);
};
