import { useTheme } from '@material-ui/core';
import { IHeader } from 'framework/table/IHeader';
import { SortableTable } from 'framework/table/SortableTable';
import { createHeader } from 'framework/table/createHeader';
import { createSelectionHeader } from 'framework/table/createSelectionHeader';
import { formatDate } from 'framework/utils/date/formatDate';
import React, { useMemo } from 'react';
import { ISubscriptionWithIsSelected } from './ISubscriptionWithIsSelected';

interface IProps {
	subscriptions: ISubscriptionWithIsSelected[];
	setSubscriptions: React.Dispatch<React.SetStateAction<ISubscriptionWithIsSelected[]>>;
}

export const createHeaders = (
	subscriptions: ISubscriptionWithIsSelected[],
	setSubscriptions: React.Dispatch<React.SetStateAction<ISubscriptionWithIsSelected[]>>
): IHeader<ISubscriptionWithIsSelected>[] => {
	return [
		createSelectionHeader<ISubscriptionWithIsSelected>(subscriptions, newLines => {
			const x = subscriptions.map(subscription => ({
				...subscription,
				isSelected: newLines.find(x => x.id === subscription.id)?.isSelected ?? subscription.isSelected,
			}));
			setSubscriptions(x);
		}),
		createHeader<ISubscriptionWithIsSelected>('From', t => formatDate(t.from)),
		createHeader<ISubscriptionWithIsSelected>('To', t => formatDate(t.to)),
		createHeader<ISubscriptionWithIsSelected>('Client name', t => t.clientName),
	];
};

export const PatchLicenseFeeSubscriptionsTable = ({ subscriptions, setSubscriptions }: IProps) => {
	const theme = useTheme();
	const headers = useMemo(() => createHeaders(subscriptions, setSubscriptions), [subscriptions, setSubscriptions]);

	return (
		<SortableTable<ISubscriptionWithIsSelected>
			padding={theme.spacing(1)}
			headers={headers}
			getKey={t => t.id}
			values={subscriptions}
			defaultSortParams={{ property: 'from', direction: 'asc' }}
		/>
	);
};
