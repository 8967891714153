import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PageBreadcrumbDivider } from 'framework/components/breadcrumbs/PageBreadcrumbDivider';
import { PageBreadcrumbTitle } from 'framework/components/breadcrumbs/PageBreadcrumbTitle';
import { PageX } from 'framework/components/layout/PageX';
import { PageXHeaderActions } from 'framework/components/layout/PageXHeaderActions';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { useAccordionState } from 'framework/utils/useAccordionState';
import { salesQuery_settings } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LabelsList } from '../labels/LabelsList';
import { SalesPageBreadcrumbs } from './SalesPageBreadcrumbs';
import { PageReloadButtonX } from 'framework/components/layout/PageReloadButtonX';

type AccordionType = 'labels';

export const SalesSettingsPage = () => {
	const strings = useLocalization();
	const [trigger, reload] = useTrigger();
	const [settings, reloadSettings] = useApiEffect(salesQuery_settings);
	const [expandedAccordion, setExpandedAccordion] = useAccordionState<AccordionType>('labels');
	const { goBack } = useHistory();

	useLazyEffect(() => {
		reloadSettings();
	}, [trigger]);

	if (settings === undefined) {
		return <div></div>;
	}

	return (
		<PageX
			maxWidth={1280}
			stickyHeader={
				<div
					className='df-col fg1'
					style={{ paddingBottom: 16 }}>
					<div className='df-row-ac'>
						<SalesPageBreadcrumbs />
						<PageBreadcrumbDivider />
						<PageBreadcrumbTitle title={strings.settings} />
						<div className='fg1'></div>
						<PageXHeaderActions>
							<Button
								size='small'
								variant='outlined'
								startIcon={<CloseIcon />}
								onClick={() => goBack()}>
								{`Close this window`}
							</Button>
							<PageReloadButtonX reload={reload} />
						</PageXHeaderActions>
					</div>
				</div>
			}>
			<div
				className='df-col'
				style={{
					padding: 16,
				}}>
				<Accordion
					expanded={expandedAccordion === 'labels'}
					onChange={() => setExpandedAccordion('labels')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<span style={{ fontWeight: 500 }}>{`Labels`}</span>
					</AccordionSummary>
					<AccordionDetails>
						<LabelsList
							className='w100'
							contextType='Sale'
						/>
					</AccordionDetails>
				</Accordion>
			</div>
		</PageX>
	);
};
