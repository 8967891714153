import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormSelectField } from 'framework/forms/FormSelectField';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { ITemplateDto, IPatchTemplateMetadataRequest, templatesCommand_patchMetadata } from 'gen/ApiClient';

const createSchema = () => {
	// TODO translations
	return yup
		.object<IPatchTemplateMetadataRequest>({
			templateId: yup.string().required(),
			canEdit: yup.bool().defined(),
			mustSign: yup.bool().defined(),
			canViewOnline: yup.bool().defined(),
			validityEnd: yup.date().nullable() as any,
			validityStart: yup.date().nullable() as any,
		})
		.defined();
};

interface IProps extends DialogProps {
	template: ITemplateDto;
	confirm: () => void;
	cancel: () => void;
}

export const TemplateMetadataForm = ({ template, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(templatesCommand_patchMetadata);

	const handleSubmit = async (values: IPatchTemplateMetadataRequest, helpers: FormikHelpers<IPatchTemplateMetadataRequest>) => {
		const r = await patch(values);
		if (handleFormResponse(r, helpers)) {
			confirm();
		}
	};

	return (
		<Formik<IPatchTemplateMetadataRequest>
			validateOnMount
			initialValues={{
				templateId: template.id,
				mustSign: template.mustSign,
				canViewOnline: template.canViewOnline,
				canEdit: template.canEdit,
				validityStart: template.validityStart,
				validityEnd: template.validityEnd,
			}}
			validationSchema={createSchema()}
			onSubmit={handleSubmit}>
			{props => (
				<Form>
					<Dialog
						{...rest}
						fullWidth>
						<DialogTitle>Template metadata</DialogTitle>
						<DialogContent
							dividers
							className='df-col'>
							<FormSelectField<IPatchTemplateMetadataRequest, boolean>
								label='Must Sign'
								name='mustSign'
								options={[true, false]}
								renderValue={t => (t === true ? 'Must sign' : 'No signature needed')}
								getKey={t => t.toString()}
							/>
							<FormSelectField<IPatchTemplateMetadataRequest, boolean>
								label='Can edit'
								name='canEdit'
								options={[true, false]}
								renderValue={t => (t === true ? 'Can edit' : 'Cannot edit')}
								getKey={t => t.toString()}
							/>
							<FormSelectField<IPatchTemplateMetadataRequest, boolean>
								label='Can view online'
								name='canViewOnline'
								options={[true, false]}
								renderValue={t => (t === true ? 'Can view online' : 'Cannot view online')}
								getKey={t => t.toString()}
							/>
							<FormDatePicker<IPatchTemplateMetadataRequest>
								name='validityStart'
								label={`Validity Start`}
							/>
							<FormDatePicker<IPatchTemplateMetadataRequest>
								name='validityEnd'
								label={`Validity End`}
							/>
						</DialogContent>
						<CancelSubmitFormDialogActions<IPatchTemplateMetadataRequest>
							cancel={cancel}
							submitText='Patch'
							isSubmitting={isSubmitting}
						/>
					</Dialog>
				</Form>
			)}
		</Formik>
	);
};
