import React, { useMemo, useState } from 'react';
import { NavigationLink } from '../NavigationLink';
import { NavigationContext } from './NavigationContext';

const Record: Record<NavigationLink, string> = {
    overview: 'Overview',
    mtns: 'Medical Treatment Nomenclatures',
    rizivRefundableDevices: 'Riziv Refundable Devices'
}

export const NavigationProvider = ({ children }: any) => {
    const [link, setLink] = useState<NavigationLink>('overview');
    const record = useMemo(() => Record, []);
    
    return (
        <NavigationContext.Provider
            value={{
                record: record,
                link: link,
                setLink: setLink,                
            }}>
            {children}
        </NavigationContext.Provider>
    );
}

