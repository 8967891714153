import AddIcon from '@material-ui/icons/Add';
import SyncIcon from '@material-ui/icons/Sync';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DragAndDropContainer } from 'framework/components/dnd/DragAndDropContainer';
import { LeftRightDividedContentScrollYRight } from 'framework/components/LeftRightDividedContentScrollYRight';
import { updateArray } from 'framework/utils/array/updateArray';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { v1 as uuid } from 'uuid';
import { AddRecurringFeeLineForm } from './AddRecurringFeeLineForm';
import { ISalesLineRequestX } from './ISalesLineRequestX';
import { SalesLineComponent } from './SalesLineComponent';
import { TotalsExVatTable } from './TotalsExVatTable';
import RemoveIcon from '@material-ui/icons/Remove';

interface IProps {
	lines: ISalesLineRequestX[];
	setLines: React.Dispatch<React.SetStateAction<ISalesLineRequestX[]>>;
}

export const CreateSaleRequestFormLinesComponent = ({ lines, setLines }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();

	const onAddLine = (vatPercentage: 0 | 6 | 21) => {
		setLines([
			...lines,
			{ id: uuid(), canChangeQuantity: true, description: 'click here', quantity: 1, unitListPrice: 1, unitPrice: 1, vatPercentage: vatPercentage },
		]);
	};

	const onAddDiscount = () => {
		setLines([...lines, { id: uuid(), canChangeQuantity: true, description: 'Korting', quantity: 1, unitListPrice: -1, unitPrice: -1, vatPercentage: 21 }]);
	};

	const onAddRecurringLine = () => {
		open(
			<AddRecurringFeeLineForm
				open
				confirm={line => {
					confirm();
					setLines([...lines, line]);
				}}
				cancel={cancel}
			/>
		);
	};

	return (
		<LeftRightDividedContentScrollYRight
			leftContent={
				<div className='df-col-as'>
					<SmallPrimaryTextButton
						startIcon={<AddIcon />}
						onClick={() => onAddLine(0)}>
						{`Add line 0% (inline)`}
					</SmallPrimaryTextButton>
					<SmallPrimaryTextButton
						startIcon={<AddIcon />}
						onClick={() => onAddLine(6)}>
						{`Add line 6% (inline)`}
					</SmallPrimaryTextButton>
					<SmallPrimaryTextButton
						startIcon={<AddIcon />}
						onClick={() => onAddLine(21)}>
						{`Add line 21% (inline)`}
					</SmallPrimaryTextButton>
					<SmallPrimaryTextButton
						startIcon={<RemoveIcon />}
						onClick={() => onAddDiscount()}>
						{`Add discount (inline)`}
					</SmallPrimaryTextButton>
					<SmallPrimaryTextButton
						startIcon={<SyncIcon />}
						onClick={() => onAddRecurringLine()}>
						{`Add recurring fee`}
					</SmallPrimaryTextButton>
				</div>
			}
			rightContent={
				<DragAndDropContainer<ISalesLineRequestX>
					lines={lines}
					setLines={setLines}
					getKey={t => t.id}
					render={(line, index, props) => (
						<SalesLineComponent
							line={line}
							index={index + 1}
							dragHandleProps={props}
							setLine={val => setLines(updateArray(line, lines, val))}
							onDelete={() => setLines(lines.filter(x => x !== line))}
							lines={lines}
							setLines={setLines}
						/>
					)}
				/>
			}
			rightBottomFixedContent={
				<div className='df-col-ae'>
					<TotalsExVatTable
						lines={lines}
						isShiftVat={false}
					/>
					{/* {setIsShiftVat && (
						<>
							<FormControlLabelSwitch
								isChecked={isShiftVat}
								toggle={() => setIsShiftVat(!isShiftVat)}
								label={strings.shiftVat}
								style={{ marginTop: 16 }}
							/>
							<Typography variant='caption'>{strings.intraCommCaption}</Typography>
						</>
					)} */}
				</div>
			}
		/>
	);
};
