import { CardProps, Divider } from '@material-ui/core';
import { LicenseCategoriesCacheContext } from 'app/main/licenses/LicenseCategoriesCacheContext';
import { LicensesCacheContext } from 'app/main/licenses/LicensesCacheContext';
import { formatFeeValue } from 'app/main/licenses/formatFeeValue';
import { IOption } from 'framework/IOption';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { CardX } from 'framework/components/cards/CardX';
import { FilterOffOutlineIcon } from 'framework/components/icons/FilterOffOutlineIcon';
import { BooleanRadioFilterComponent } from 'framework/filter/BooleanRadioFilterComponent';
import { ListFilterComponent } from 'framework/filter/ListFilterComponent';
import { RecordListFilterComponent } from 'framework/filter/RecordListFilterComponent';
import { StringListFilterComponent } from 'framework/filter/StringListFilterComponent';
import { useCacheContext } from 'framework/hooks/useCacheContext';
import { onResetToPage1 } from 'framework/types/onResetToPage1';
import { clearParams } from 'framework/utils/clearParams';
import { isFiltered } from 'framework/utils/isFiltered';
import { ISubscriptionsQueryMetadata, ISubscriptionsQueryParams, SubscriptionType, SubscriptionTypes } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React, { useMemo } from 'react';
import { SubscriptionsListPrefilter } from './SubscriptionsListPrefilter';

const Exclusions: (keyof ISubscriptionsQueryParams)[] = [];

interface IProps extends CardProps {
	params: ISubscriptionsQueryParams;
	setParams: (params: ISubscriptionsQueryParams) => void;
	metadata: ISubscriptionsQueryMetadata;
}

export const SubscriptionsListFilterCard = ({ params, setParams, metadata, style, ...rest }: IProps) => {
	const strings = useLocalization();
	const isFilteredX = useMemo(() => isFiltered(params, Exclusions), [params]);
	const onFilter = (pars: ISubscriptionsQueryParams) => setParams(onResetToPage1(pars));
	const onClear = () => setParams(onResetToPage1(clearParams(params)));
	const [licenses] = useCacheContext(LicensesCacheContext);
	const [licenseCategories] = useCacheContext(LicenseCategoriesCacheContext);

	return (
		<CardX
			{...rest}
			style={style}
			className='df-col gap-8'>
			<div className='df-row-ac'>
				<span style={{ fontWeight: 500 }}>{strings.filter}</span>
			</div>
			<SubscriptionsListPrefilter
				selectedPrefilter={params.prefilter}
				setSelectedPrefilter={key => onFilter({ ...params, prefilter: key })}
				metadata={metadata}
			/>
			<Divider />
			<TextLinkButton
				startIcon={<FilterOffOutlineIcon />}
				color='secondary'
				hoverColor='secondary'
				disabled={isFilteredX === false}
				onClick={onClear}>
				{strings.clearFilter}
			</TextLinkButton>
			<Divider />
			<div
				className='df-col gap-4'
				style={{ alignItems: 'start', marginLeft: -5 }}>
				{/* <DateFilterComponent
					label='Date-window from'
					after={params.fromAfter}
					before={params.fromBefore}
					setSelected={(after, before) => onFilter({ ...params, fromAfter: after, fromBefore: before })}
				/>
				<DateFilterComponent
					label='Date-window to'
					after={params.toAfter}
					before={params.toBefore}
					setSelected={(after, before) => onFilter({ ...params, toAfter: after, toBefore: before })}
				/> */}
				<RecordListFilterComponent<SubscriptionType>
					options={SubscriptionTypes}
					selected={params.types as any}
					setSelected={vals => onFilter({ ...params, types: vals })}
					label={strings.type}
				/>
				<BooleanRadioFilterComponent
					selected={params.hasSale}
					setSelected={val => onFilter({ ...params, hasSale: val })}
					falseText='No'
					label='Has sale?'
					trueText='Yes'
				/>
				<BooleanRadioFilterComponent
					selected={params.hasExceeded}
					setSelected={val => onFilter({ ...params, hasExceeded: val })}
					falseText='No'
					label='Has exceeded?'
					trueText='Yes'
				/>
				<BooleanRadioFilterComponent
					selected={params.hasExceededMaxSoldHearingAids}
					setSelected={val => onFilter({ ...params, hasExceededMaxSoldHearingAids: val })}
					falseText='No'
					label='Has exceeded max. sold HA?'
					trueText='Yes'
				/>
				<BooleanRadioFilterComponent
					selected={params.withAdditionalCosts}
					setSelected={val => onFilter({ ...params, withAdditionalCosts: val })}
					falseText='No'
					label='With additional costs?'
					trueText='Yes'
				/>
				<BooleanRadioFilterComponent
					selected={params.withAnnotations}
					setSelected={val => onFilter({ ...params, withAnnotations: val })}
					falseText='No'
					label='With annotations?'
					trueText='Yes'
				/>
				{licenses && (
					<ListFilterComponent<string>
						options={licenses.map<IOption<string>>(t => ({
							id: t.id,
							identifier: t.name!,
							caption: formatFeeValue(t.currentFeeValue, t.pricingMethod),
						}))}
						label='License'
						selected={params.licenseIds}
						setSelected={vals => onFilter({ ...params, licenseIds: vals })}
					/>
				)}
				{licenseCategories && (
					<StringListFilterComponent
						options={licenseCategories}
						label='License category'
						selected={params.licenseCategories}
						setSelected={vals => onFilter({ ...params, licenseCategories: vals })}
					/>
				)}
			</div>
		</CardX>
	);
};
