import React, { useContext, useEffect, useMemo, useState } from 'react';
import { UpdateScheduledTaskStatus } from 'gen/ApiClient';
import { SelectableColumn } from '../SelectableColumn';
import { QuickFilter } from '../UpdateScheduledTasksListPage';
import { createHeaders } from '../utils/createHeaders';
import { ColumnRecord } from './ColumnRecord';
import { QueryContext } from './QueryContext';
import { QuickFilterRecord } from './QuickFilterRecord';
import { ViewContext } from './ViewContext';

// enkel hier nodig
const DefaultColumns: SelectableColumn[] = ['clientName', 'executeAfter', 'executeBefore', 'nextWindowAfter', 'executionTimeWindowStr'];

export const ViewProvider = ({ children }: any) => {
	const [qf, setQf] = useState<QuickFilter>('all');
	const [columns, setColumns] = useState<SelectableColumn[]>(DefaultColumns);
	const headers = useMemo(() => createHeaders(ColumnRecord), []);
	const { setParams } = useContext(QueryContext);

	useEffect(() => {
		setParams(params => ({
			...params,
			keyFilterStatus: qf === 'planned' ? ('Planned' as UpdateScheduledTaskStatus) : undefined,
			statuses: qf === 'planned' ? [] : params.statuses,
			pageParams: { ...params.pageParams, index: 1 },
		}));
		// eslint-disable-next-line
	}, [qf]);

	return (
		<ViewContext.Provider
			value={{
				columns: columns,
				columnsRecord: ColumnRecord,
				headers: headers,
				setColumns: setColumns,
				quickFilter: qf,
				setQuickFilter: setQf,
				quickFilterRecord: QuickFilterRecord,
			}}>
			{children}
		</ViewContext.Provider>
	);
};
