import { DialogContent, DialogProps } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { FullScreenDialogWithStepper } from 'framework/dialogs/FullScreenDialogWithStepper';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormDatePickerWithUnit } from 'framework/forms/FormDatePickerWithUnit';
import { PageableFormDialogActions } from 'framework/forms/PageableFormDialogActions';
import { handleFormResponse } from 'framework/forms/utils/handleFormResponse';
import { useFormSubmit } from 'framework/hooks/useFormSubmit';
import { useSnackbarNotify } from 'framework/hooks/useSnackbarNotify';
import { IPatchBudgetedSaleRequest, ISale, salesCommand_patchBudget } from 'gen/ApiClient';
import React, { useState } from 'react';
import * as yup from 'yup';
import { CreateSaleRequestFormLinesComponent } from './CreateSaleRequestFormLinesComponent';
import { ISalesLineRequestX } from './ISalesLineRequestX';
import { mapSalesLineRequest } from './mapSalesLineRequest';
import { mapSalesLineToSalesLineRequestX } from './mapSalesLineToSalesLineRequestX';

const Schema = yup
	.object<IPatchBudgetedSaleRequest>({
		date: yup.date().required(),
		lines: yup.mixed(),
		payBefore: yup.date().required(),
		budgetedSalesId: yup.string().required(),
	})
	.defined();

const stepsRecord: Record<number, (keyof IPatchBudgetedSaleRequest)[]> = {
	0: ['date', 'payBefore', 'budgetedSalesId'],
	1: ['lines'],
};

interface IProps extends DialogProps {
	item: ISale;
	confirm: () => void;
	cancel: () => void;
}

export const PatchSaleRequestForm = ({ item, confirm, cancel, ...rest }: IProps) => {
	const [patch, isSubmitting] = useFormSubmit(salesCommand_patchBudget);
	const [step, setStep] = useState<number>(0);
	const notify = useSnackbarNotify();
	const [lines, setLines] = useState<ISalesLineRequestX[]>(item.lines.map(mapSalesLineToSalesLineRequestX));

	const handleSubmit = async (values: IPatchBudgetedSaleRequest, helpers: FormikHelpers<IPatchBudgetedSaleRequest>) => {
		if (lines.length === 0) {
			notify('Select at least one line', 'warning');
			setStep(1);
		} else {
			const r = await patch({ ...values, lines: lines.map((t, index) => mapSalesLineRequest(t, index)), budgetedSalesId: item.id });
			if (handleFormResponse(r, helpers, stepsRecord, setStep)) {
				confirm();
				notify(`Budgetted sale successfully patched`, 'success');
			}
		}
	};

	return (
		<Formik<IPatchBudgetedSaleRequest>
			validateOnMount
			initialValues={{
				date: item.date,
				payBefore: item.payBefore,
				lines: [],
				budgetedSalesId: item.id,
			}}
			validationSchema={Schema}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					step={step}
					setStep={setStep}
					schema={Schema}
					cancel={cancel}
					isSubmitting={isSubmitting}
					stepsRecord={stepsRecord}
					lines={lines}
					setLines={setLines}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerProps extends DialogProps {
	step: number;
	setStep: (step: number) => void;
	isSubmitting: boolean;
	schema: yup.ObjectSchema<IPatchBudgetedSaleRequest>;
	cancel: () => void;
	stepsRecord: Record<number, (keyof IPatchBudgetedSaleRequest)[]>;
	lines: ISalesLineRequestX[];
	setLines: React.Dispatch<React.SetStateAction<ISalesLineRequestX[]>>;
}

const InnerDialog = ({ step, setStep, isSubmitting, schema, cancel, stepsRecord, lines, setLines, ...rest }: IInnerProps) => {
	return (
		<FullScreenDialogWithStepper
			{...rest}
			fullScreenStep={1}
			title={`Patch Sale`}
			step={step}
			labels={[`General`, `Lines`]}
			maxWidth='lg'>
			<DialogContent
				dividers
				className='df-col'>
				{step === 0 && (
					<>
						<FormDatePicker<IPatchBudgetedSaleRequest>
							name='date'
							label='Date'
						/>
						<FormDatePickerWithUnit<IPatchBudgetedSaleRequest>
							name='payBefore'
							label={`Pay before`}
							required
							units={['Days', 'Weeks', 'Months']}
							defaultUnit='Weeks'
							defaultValue={{ unit: 'Weeks', value: 2 }}
						/>
					</>
				)}
				{step === 1 && (
					<>
						<CreateSaleRequestFormLinesComponent
							lines={lines}
							setLines={setLines}
						/>
					</>
				)}
			</DialogContent>
			<PageableFormDialogActions
				step={step}
				setStep={setStep}
				cancel={cancel}
				isSubmitting={isSubmitting}
				submitText={`Patch`}
				schema={schema}
				stepsRecord={stepsRecord}
			/>
		</FullScreenDialogWithStepper>
	);
};
