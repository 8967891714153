import { Dialog, DialogProps, Typography } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { DialogContentGrid } from 'framework/components/detailDialog/DialogContentGrid';
import { DialogGridTitle } from 'framework/components/detailDialog/DialogGridTitle';
import { GridDividerWithCaption } from 'framework/components/detailDialog/GridDividerWithCaption';
import { PropertyInGrid } from 'framework/components/detailDialog/PropertyInGrid';
import { StopCircleOutlineIcon } from 'framework/components/icons/StopCircleOutlineIcon';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { formatDate } from 'framework/utils/date/formatDate';
import {
	updateScheduledTasksCommand_delete,
	updateScheduledTasksCommand_forceComplete,
	updateScheduledTasksCommand_forceRevert,
	updateScheduledTasksCommand_forceStart,
	updateScheduledTasksQuery_single,
	UpdateScheduledTaskStatus,
} from 'gen/ApiClient';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { PatchUstTimeWindowForm } from '../forms/PatchUstTimeWindowForm';

interface IProps extends DialogProps {
	id: string;
	close: () => void;
}

export const UstDialog = ({ id, close, ...rest }: IProps) => {
	const [ust, reload] = useApiEffect(updateScheduledTasksQuery_single, id);
	const { open, confirm, cancel, isStacked } = useDialogsContext(reload);

	if (ust === undefined) {
		return <div></div>;
	}

	const onForceStart = () => {
		open(
			<AlertDialog
				open
				title={'Force update'}
				content={`Force start update?\nDo not wait for auto start?`}
				acceptText={'Yes, start'}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => updateScheduledTasksCommand_forceStart({ ustId: id })}
				accept={() => {
					confirm();
					close();
				}}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={'Delete ust'}
				content={`Do you want to delete this UST for client ${ust.clientName}?`}
				acceptText={'Yes, delete'}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => updateScheduledTasksCommand_delete({ ustId: id })}
				accept={close}
			/>
		);
	};

	const onForceComplete = () => {
		open(
			<AlertDialog
				open
				title={'Force complete ust'}
				content={`Do you want to force complete this UST for client ${ust.clientName}?`}
				acceptText={'Yes, force complete'}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => updateScheduledTasksCommand_forceComplete({ ustId: id })}
				accept={close}
			/>
		);
	};

	const onForceRevert = () => {
		open(
			<AlertDialog
				open
				title={'Force revert ust'}
				content={`Do you want to force revert this UST for client ${ust.clientName}?`}
				acceptText={'Yes, force revert'}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => updateScheduledTasksCommand_forceRevert({ ustId: id })}
				accept={close}
			/>
		);
	};

	const onPatchWindow = () => {
		open(
			<PatchUstTimeWindowForm
				open
				ust={ust}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	return (
		<Dialog
			{...rest}
			open={rest.open && isStacked === false}
			onClose={close}
			fullWidth>
			<DialogContentGrid close={close}>
				<DialogGridTitle icon={<LocalShippingIcon />}>
					<Typography variant='h1'>{`${ust.clientName}-${ust.toBuild.toString()}`}</Typography>
				</DialogGridTitle>
				<GridDividerWithCaption caption={`Details`} />
				<PropertyInGrid
					label='Client name'
					value={ust.clientName}
				/>
				<PropertyInGrid
					label='To build'
					value={ust.toBuild}
				/>
				<PropertyInGrid
					label='Status'
					value={ust.status}
				/>
				<GridDividerWithCaption caption={`Execution planning`} />
				<PropertyInGrid
					label='After'
					value={formatDate(ust.executeAfter, 'date-and-time')}
				/>
				<PropertyInGrid
					label='before'
					value={formatDate(ust.executeBefore, 'date-and-time')}
				/>
				<PropertyInGrid
					label='Time Window'
					value={ust.executionTimeWindowStr}
					edit={onPatchWindow}
				/>
				<PropertyInGrid
					label='NEXT Window AFTER'
					value={formatDate(ust.nextWindowAfter, 'date-and-time')}
				/>
				<PropertyInGrid
					label='NEXT Window BEFORE'
					value={formatDate(ust.nextWindowBefore, 'date-and-time')}
				/>
				<GridDividerWithCaption caption={`Execution timings`} />
				<PropertyInGrid
					label='Start'
					value={formatDate(ust.startExecution, 'date-and-time')}
				/>
				<PropertyInGrid
					label='End '
					value={formatDate(ust.endExecution, 'date-and-time')}
				/>
				<PropertyInGrid
					label='Duration '
					value={ust.isExecuted ? `${ust.durationInSeconds?.toFixed(2)} seconds` : undefined}
				/>
				<GridDividerWithCaption caption={'Actions'} />
				<PropertyInGrid
					value={
						<div className='df-col-as'>
							<SmallPrimaryTextButton
								startIcon={<PlayCircleOutlineIcon />}
								onClick={onForceStart}
								disabled={(ust.status as UpdateScheduledTaskStatus) !== 'Planned'}>
								{`Force Update`}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<HistoryOutlinedIcon />}
								onClick={onForceRevert}
								color='secondary'
								disabled={(ust.status as UpdateScheduledTaskStatus) !== 'InProgress'}>
								{`Force Revert`}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<StopCircleOutlineIcon />}
								onClick={onForceComplete}
								color='secondary'
								disabled={(ust.status as UpdateScheduledTaskStatus) !== 'InProgress'}>
								{`Force Complete`}
							</SmallPrimaryTextButton>
							<SmallPrimaryTextButton
								startIcon={<DeleteOutlineOutlinedIcon />}
								onClick={onDelete}
								color='secondary'
								disabled={(ust.status as UpdateScheduledTaskStatus) !== 'Planned'}>
								{`Delete`}
							</SmallPrimaryTextButton>
						</div>
					}
				/>
			</DialogContentGrid>
		</Dialog>
	);
};
