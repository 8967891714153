import { DialogProps } from '@material-ui/core';
import { NotificationDialog } from 'framework/dialogs/NotificationDialog';
import { useApiEffectWithDefer } from 'framework/hooks/useApiEffectWithDefer';
import { isNotNullNorUndefined } from 'framework/utils/nullNorUndefinedCheck';
import { SalesType, subscriptionsQuery_single } from 'gen/ApiClient';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { calculateSalesDetailRoute } from 'routes';
import { CreateBudgetedSaleRequestForm } from './CreateBudgetedSaleRequestForm';
import { CreateSaleModeForm } from './CreateSaleModeForm';
import { CreateSaleRequestForm } from './CreateSaleRequestForm';
import { CreateTenderRequestForm } from './CreateTenderRequestForm';

interface IProps extends DialogProps {
	subscriptionId?: string;
	billingAccountId?: string;
	confirm: (id: string) => void;
	cancel: VoidFunction;
}

export const CreateSaleForm = ({ subscriptionId, billingAccountId, confirm, ...rest }: IProps) => {
	const [mode, setMode] = useState<SalesType | 'select'>('select');
	const [subscription] = useApiEffectWithDefer(subscriptionsQuery_single, subscriptionId, isNotNullNorUndefined);
	const { push } = useHistory();

	const confirmAndNavigate = (id: string) => {
		confirm(id);
		push(calculateSalesDetailRoute(id));
	};

	if (subscriptionId !== undefined && (subscription === undefined || subscription === null)) {
		return <></>;
	} else if (subscriptionId !== undefined && subscription !== undefined && subscription.isDemo) {
		return (
			<NotificationDialog
				open
				title={`Create sale for DEMO subscription`}
				content={`Not possible to create a sale for a DEMO subscription`}
				close={rest.cancel}
			/>
		);
	} else if (mode === 'select') {
		return (
			<CreateSaleModeForm
				{...rest}
				confirm={setMode}
			/>
		);
	} else if (mode === 'Invoice') {
		return (
			<CreateSaleRequestForm
				{...rest}
				subscription={subscription}
				billingAccountId={billingAccountId ?? subscription?.clientId}
				confirm={confirmAndNavigate}
			/>
		);
	} else if (mode === 'Budget') {
		return (
			<CreateBudgetedSaleRequestForm
				{...rest}
				subscription={subscription}
				billingAccountId={billingAccountId ?? subscription?.clientId}
				confirm={confirmAndNavigate}
			/>
		);
	} else if (mode === 'Tender') {
		return (
			<CreateTenderRequestForm
				{...rest}
				billingAccountId={billingAccountId ?? subscription?.clientId}
				confirm={confirmAndNavigate}
			/>
		);
	} else {
		return <></>;
	}
};
