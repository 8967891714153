import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import React from 'react';

interface IProps {
	caption: string;
	icon: React.ReactNode;
	disabled?: boolean;
	onClick?: VoidFunction;
	style?: React.CSSProperties;
}

export const DetailPageSidebarCaptionAndIcon = ({ caption, icon, disabled, onClick, style }: IProps) => {
	return (
		<TextLinkButton
			style={{ fontSize: 12, ...style }}
			color='textSecondary'
			disabled={disabled}
			onClick={onClick}>
			<div>{caption}</div>
			<div className='fg1'></div>
			<div
				className='icon-wrapper'
				style={{ fontSize: 16 }}>
				{icon}
			</div>
		</TextLinkButton>
	);
};
