import _ from 'lodash';

interface ILine {
	unitPrice: number;
	quantity: number;
	vatPercentage: number;
}

export function calculateTotalsFromAmountExVat(lines: ILine[], isShiftVat: boolean): { totalInVat: number; totalExVat: number } {
	const exVat = _.sum(lines.map(t => t.unitPrice * t.quantity));
	const inVat = _.sum(lines.map(t => t.unitPrice * t.quantity * (1 + t.vatPercentage / 100)));

	return {
		totalInVat: isShiftVat ? exVat : inVat,
		totalExVat: exVat,
	};
}
