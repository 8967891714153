import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import SyncIcon from '@material-ui/icons/Sync';
import { ExpandLessOrMoreIcon } from 'framework/components/ExpandLessOrMoreIcon';
import { CollapseOnDisabledTextLinkButton } from 'framework/components/buttons/CollapseOnDisabledTextLinkButton';
import { CashPlusIcon } from 'framework/components/icons/CashPlusIcon';
import { DetailPageSidebarCaptionAndIcon } from 'framework/components/layout/DetailPageSidebarCaptionAndIcon';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { StringModelForm } from 'framework/forms/StringModelForm';
import { useStateToggleBoolean } from 'framework/hooks/useStateToggleBoolean';
import {
	ISale,
	salesCommand_deleteBudget,
	salesCommand_markAsExceptional,
	salesCommand_markAsNotExceptional,
	salesCommand_markAsSent,
	salesCommand_markPaymentReminderAsSent,
} from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SalesRoute, calculateSalesDetailRoute } from 'routes';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { SendInvoiceRequestForm } from '../SendInvoiceRequestForm';
import { SendPaymentReminderRequestForm } from '../SendPaymentReminderRequestForm';
import { ConvertBudgetedSaleToSaleRequestForm } from '../create/ConvertBudgetedSaleToSaleRequestForm';
import { PatchSaleRequestForm } from '../create/PatchSaleRequestForm';
import { RegisterPaymentRequestForm } from './RegisterPaymentRequestForm';

interface IProps {
	item: ISale;
	reload: VoidFunction;
}

export const SaleDetailActions = ({ item, reload }: IProps) => {
	const strings = useLocalization();
	const { open, confirm, cancel } = useDialogsContext(reload);
	const { push } = useHistory();
	const [viewAll, toggleViewAll] = useStateToggleBoolean(false);

	const onPayment = () => {
		open(
			<RegisterPaymentRequestForm
				open
				item={item}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};
	const onConvertToSale = () => {
		open(
			<ConvertBudgetedSaleToSaleRequestForm
				open
				confirm={() => {
					confirm();
					push(calculateSalesDetailRoute(item.id));
				}}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onEdit = () => {
		open(
			<PatchSaleRequestForm
				open
				confirm={confirm}
				cancel={cancel}
				item={item}
			/>
		);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				title={'Delete budgeted sale'}
				content={`Do you want to delet this budgeted sale?`}
				acceptText={`Yes, delete`}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => salesCommand_deleteBudget({ budgetedSalesId: item.id }) as any}
				accept={() => {
					cancel();
					push(SalesRoute);
				}}
			/>
		);
	};

	const onSendViaMail = () => {
		open(
			<SendInvoiceRequestForm
				open
				salesId={item.id}
				billingAccountId={item.billingAccountId!}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onSendReminderViaMail = () => {
		open(
			<SendPaymentReminderRequestForm
				open
				salesId={item.id}
				billingAccountId={item.billingAccountId!}
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onMarkAsSent = () => {
		open(
			<StringModelForm
				initialValue=''
				label={`Mark as sent`}
				formTitle='Mark as sent (CSV -> comma separate values to who email is/was sent)'
				submitFunction={val => salesCommand_markAsSent({ salesId: item.id, recipientsCsv: val })}
				submitText='Register'
				fullWidth
				maxWidth='sm'
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onMarkPaymentReminderAsSent = () => {
		open(
			<StringModelForm
				initialValue=''
				label={`Mark payment reminder as sent`}
				formTitle='Mark as sent (CSV -> comma separate values to who email is/was sent)'
				submitFunction={val => salesCommand_markPaymentReminderAsSent({ salesId: item.id, recipientsCsv: val })}
				submitText='Register'
				fullWidth
				maxWidth='sm'
				open
				confirm={confirm}
				cancel={cancel}
			/>
		);
	};

	const onMarkAsExceptional = () => {
		open(
			<AlertDialog
				open
				title={'Mark as exceptional'}
				content={`Do you want to mark sale as exceptional?`}
				acceptText={'Yes'}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => salesCommand_markAsExceptional({ salesId: item.id })}
				accept={confirm}
			/>
		);
	};

	const onMarkAsNotExceptional = () => {
		open(
			<AlertDialog
				open
				title={'Mark as not exceptional'}
				content={`Do you want to mark sale as not exceptional?`}
				acceptText={'Yes'}
				rejectText={'No, go back'}
				reject={cancel}
				acceptF={() => salesCommand_markAsNotExceptional({ salesId: item.id })}
				accept={confirm}
			/>
		);
	};

	return (
		<div className='df-col gap-6'>
			<DetailPageSidebarCaptionAndIcon
				caption={viewAll ? 'Hide actions' : 'Show all actions'}
				icon={<ExpandLessOrMoreIcon isExpanded={!viewAll} />}
				onClick={toggleViewAll}
			/>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<CashPlusIcon />}
				disabled={item.isFullyPaid}
				onClick={onPayment}>
				{strings.registerPayment}
			</CollapseOnDisabledTextLinkButton>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<SyncIcon />}
				onClick={onConvertToSale}
				disabled={item.type === 'Invoice'}>
				{`Convert to sale`}
			</CollapseOnDisabledTextLinkButton>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<EditOutlinedIcon />}
				onClick={onEdit}
				disabled={item.type === 'Invoice'}>
				{`Edit`}
			</CollapseOnDisabledTextLinkButton>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<MailOutlineOutlinedIcon />}
				onClick={onSendViaMail}
				disabled={item.type !== 'Invoice' || item.hasInvoiceDocument === false || item.isInvoiceSent}>
				{`Send invoice via email`}
			</CollapseOnDisabledTextLinkButton>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<CheckBoxOutlinedIcon />}
				onClick={onMarkAsSent}
				disabled={item.isInvoiceSent}>
				{`Mark as sent`}
			</CollapseOnDisabledTextLinkButton>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<MailOutlineOutlinedIcon />}
				onClick={onSendReminderViaMail}
				disabled={item.isInvoiceSent === false || item.isFullyPaid === true}>
				{`Send payment reminder via email`}
			</CollapseOnDisabledTextLinkButton>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<CheckBoxOutlinedIcon />}
				onClick={onMarkPaymentReminderAsSent}
				disabled={item.isInvoiceSent === false || item.isFullyPaid === true}>
				{`Mark payment reminder as sent`}
			</CollapseOnDisabledTextLinkButton>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<StarIcon />}
				onClick={onMarkAsExceptional}
				disabled={item.isExceptional}>
				{`Mark as exceptional`}
			</CollapseOnDisabledTextLinkButton>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<StarOutlineIcon />}
				onClick={onMarkAsNotExceptional}
				disabled={item.isExceptional === false}>
				{`Mark as not exceptional`}
			</CollapseOnDisabledTextLinkButton>
			<CollapseOnDisabledTextLinkButton
				show={viewAll}
				startIcon={<DeleteOutlineOutlinedIcon />}
				onClick={onDelete}
				disabled={item.type === 'Invoice'}
				color='secondary'>
				{`Delete`}
			</CollapseOnDisabledTextLinkButton>
		</div>
	);
};
