import { Dialog, DialogContent, DialogProps } from '@material-ui/core';
import { AbsoluteCloseButton } from 'framework/components/detailDialog/AbsoluteCloseButton';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { rizivHearingAidDefinitionsV2Query_single_app } from 'gen/ApiClient';
import React from 'react';
import { CodeBlock } from 'shared/CodeBlock';

interface IProps extends DialogProps {
	identificationCode: number;
	close: () => void;
}

export const RizivRefundableDeviceDialog = ({ identificationCode, close, ...rest }: IProps) => {
	const [item] = useApiEffect(rizivHearingAidDefinitionsV2Query_single_app, identificationCode);

	if (item === undefined) {
		return <div></div>;
	}

	return (
		<Dialog
			{...rest}
			onClose={close}
			fullWidth>
			<AbsoluteCloseButton close={close} />
			<DialogContent>
				<CodeBlock value={item} />
			</DialogContent>
		</Dialog>
	);
};
