import { IDraggableItem } from 'framework/components/dnd/IDraggableItem';
import { LineDescriptionQuantityAndLinePrice } from 'framework/components/dnd/LineDescriptionQuantityAndLinePrice';
import { LinePricingLabelledProperties } from 'framework/components/dnd/LinePricingLabelledProperties';
import { PriceOrDiscountForm } from 'framework/components/PriceOrDiscountForm';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { ISalesLineRequestX } from './ISalesLineRequestX';
import { LineXComponent } from './LineXComponent';

interface IProps extends IDraggableItem {
	line: ISalesLineRequestX;
	index: number;
	setLine: (val: ISalesLineRequestX) => void;
	onDelete: VoidFunction | undefined;
	lines: ISalesLineRequestX[];
	setLines: React.Dispatch<React.SetStateAction<ISalesLineRequestX[]>>;
}

export const SalesLineComponent = ({ line, setLine, lines, setLines, ...rest }: IProps) => {
	const { open, confirm, cancel } = useDialogsContext();

	const onEditPrice = () => {
		open(
			<PriceOrDiscountForm
				open
				listPrice={line.unitListPrice}
				unitPrice={line.unitPrice}
				confirm={(up, lp) => {
					confirm();
					setLine({ ...line, unitPrice: up, unitListPrice: lp });
				}}
				cancel={cancel}
				forPurchase={false}
			/>
		);
	};

	return (
		<LineXComponent
			{...rest}
			line={line}
			setLine={setLine}>
			<LineDescriptionQuantityAndLinePrice<ISalesLineRequestX>
				line={line}
				setLine={setLine}
				onClickPrice={onEditPrice}
				disableQuantity={line.canChangeQuantity === false}
			/>
			<div className='fg1'></div>
			<LinePricingLabelledProperties<ISalesLineRequestX>
				line={line}
				onClick={onEditPrice}
				style={{ marginTop: 8 }}
			/>
		</LineXComponent>
	);
};
