import React from 'react';
import { ReactComponent } from './hams_logo.svg';

interface IProps {
    // size: 'small' | 'large',
    className?: string,
    style?: React.CSSProperties,
    width?: number,
}

export const Logo = ({ className, style, width = 150 }: IProps) => {
    const calcStyle: React.CSSProperties = {
        ...style,
        // width: size === 'large' ? '180px' : '60px', 
        // height: size === 'large' ? '180px' : '60px', 
        width: `${width}px`,
    }
    return (
        <ReactComponent className={className} style={calcStyle} />
    );
}