import { SvgIconProps, useTheme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';

type Status = 'error' | 'warning' | 'success' | 'info';

interface IProps extends SvgIconProps {
	status: Status;
	filled?: boolean;
}

export const StatusIcon = ({ status, filled, style, ...rest }: IProps) => {
	const theme = useTheme();
	return (
		<>
			{status === 'info' && filled !== true && (
				<InfoOutlinedIcon
					style={{ color: theme.palette.primary.main, ...style }}
					{...rest}
				/>
			)}
			{status === 'info' && filled === true && (
				<InfoIcon
					style={{ color: theme.palette.primary.main, ...style }}
					{...rest}
				/>
			)}
			{status === 'warning' && filled !== true && (
				<ReportProblemOutlinedIcon
					style={{ color: theme.palette.warning.main, ...style }}
					{...rest}
				/>
			)}
			{status === 'warning' && filled === true && (
				<WarningIcon
					style={{ color: theme.palette.warning.main, ...style }}
					{...rest}
				/>
			)}
			{status === 'error' && filled !== true && (
				<ErrorOutlineIcon
					style={{ color: theme.palette.error.main, ...style }}
					{...rest}
				/>
			)}
			{status === 'error' && filled === true && (
				<ErrorIcon
					style={{ color: theme.palette.error.main, ...style }}
					{...rest}
				/>
			)}
			{status === 'success' && filled !== true && (
				<CheckCircleOutlineIcon
					style={{ color: theme.palette.success.main, ...style }}
					{...rest}
				/>
			)}
			{status === 'success' && filled === true && (
				<CheckCircleIcon
					style={{ color: theme.palette.success.main, ...style }}
					{...rest}
				/>
			)}
		</>
	);
};
