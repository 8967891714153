import { useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { CircleX } from 'framework/components/CircleX';
import { useStateBoolean } from 'framework/hooks/useStateBool';
import { ILabelDto } from 'gen/ApiClient';
import React from 'react';
import { useMapLabelColor } from './useMapLabelColor';

interface IProps {
	item: ILabelDto;
	isSelected: boolean;
	onClick: VoidFunction;
	style?: React.CSSProperties;
}

export const ApplyLabelListItem = ({ item, isSelected, style, onClick }: IProps) => {
	const theme = useTheme();
	const xColor = useMapLabelColor(item);
	const [isHovered, enter, leave] = useStateBoolean(false);

	return (
		<div
			onMouseEnter={enter}
			onMouseLeave={leave}
			className='df-row'
			style={{
				cursor: 'pointer',
				borderBottom: `1px solid ${theme.palette.divider}`,
				backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.04)' : 'unset',
				padding: 4,
				...style,
			}}
			onClick={onClick}>
			<div style={{ width: 24 }}>
				{isSelected && (
					<CheckIcon
						style={{ color: xColor }}
						fontSize='small'
					/>
				)}
			</div>
			<div className='df-col fg1'>
				<div className='df-row-ac gap-4'>
					<CircleX
						color={xColor}
						size={12}
					/>
					<span style={{ fontSize: 14 }}>{item.name}</span>
				</div>
				<span style={{ fontSize: 13, color: theme.palette.text.secondary }}>{item.caption ?? <em>{`n/a`}</em>}</span>
			</div>
			<div className='fg1'></div>
			{isSelected && (
				<CloseIcon
					fontSize='small'
					style={{ color: theme.palette.text.secondary }}
				/>
			)}
		</div>
	);
};
