import { ProcessStepX } from 'framework/components/stepper/ProcessStepX';
import { ProcessStepXStatus } from 'framework/components/stepper/ProcessStepXStatus';
import { VerticalStepperX } from 'framework/components/stepper/VerticalStepperX';
import { isLast } from 'framework/utils/array/isLast';
import { ISale } from 'gen/ApiClient';
import React from 'react';
import { InvoiceEmailProcessComponent } from './InvoiceEmailProcessComponent';

type SaleProcessStep = 'create' | 'invoice' | 'send' | 'fully-paid';

const SaleProcessStepNumberRecord: Record<SaleProcessStep, number> = {
	create: 1,
	invoice: 2,
	send: 3,
	'fully-paid': 4,
};

const isGreater = (step: SaleProcessStep | undefined, then: SaleProcessStep) => {
	return step === undefined ? true : SaleProcessStepNumberRecord[step] > SaleProcessStepNumberRecord[then];
};

const mapStatus = (forStep: SaleProcessStep, currentStep: SaleProcessStep | undefined): ProcessStepXStatus => {
	if (forStep === currentStep) {
		return 'active';
	} else if (isGreater(currentStep, forStep)) {
		return 'done';
	} else {
		return 'todo';
	}
};

const calculateActiveStep = (item: ISale): SaleProcessStep | undefined => {
	if (item.type !== 'Invoice') {
		return 'invoice';
	} else if (item.isInvoiceSent === false) {
		return 'send';
	} else if (item.isFullyPaid === false) {
		return 'fully-paid';
	} else {
		return undefined;
	}
};

interface IProps {
	item: ISale;
	reload: VoidFunction;
	style?: React.CSSProperties;
}

export const SaleDetailProcessComponent = ({ item, reload, style }: IProps) => {
	const activeStep = React.useMemo(() => calculateActiveStep(item), [item]);

	return (
		<VerticalStepperX style={style}>
			<ProcessStepX
				doneText={`Created`}
				todoText={`Create`}
				date={item.created}
				status={activeStep === 'create' ? 'active' : 'done'}
			/>
			<ProcessStepX
				doneText={`Invoiced`}
				todoText={`Invoice`}
				date={item.date}
				status={mapStatus('invoice', activeStep)}
			/>
			<ProcessStepX
				doneText={`Sent via e-mail`}
				todoText={`Send via e-mail`}
				status={mapStatus('send', activeStep)}>
				{item.invoiceEmails.map(t => (
					<InvoiceEmailProcessComponent
						key={t.id}
						item={t}
						reload={reload}
						salesId={item.id}
						isLast={isLast(t, item.invoiceEmails)}
						style={{ marginBottom: 4 }}
					/>
				))}
			</ProcessStepX>
			<ProcessStepX
				doneText={`Fully paid`}
				todoText={`Receive payment(s)`}
				date={item.lastPaymentDate}
				status={mapStatus('fully-paid', activeStep)}
				isLast
			/>
		</VerticalStepperX>
	);
};
