import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { createEmptyHeader } from "framework/table/createEmptyHeader";
import { IHeader } from 'framework/table/IHeader';

export const createSmallPrimaryTextButtonHeader = <T extends unknown>(key: string, content: string | JSX.Element, onClick: (val: T, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void, disabledF: (val: T) => boolean = (val: T) => false, onlyShowOnHover: boolean = false): IHeader<T> => {
    return createEmptyHeader<T>(key, t => <ButtonX onClick={(e) => onClick(t, e)} disabled={disabledF(t)}>{content}</ButtonX>, 'right', onlyShowOnHover);
}

const ButtonX = ({ ...props }: ButtonProps) => {
    return (        
        <SmallPrimaryTextButton
            {...props} />
    );
}