import { DialogContent, DialogContentProps } from '@material-ui/core';
import React from 'react';

export const OverflowDialogContent = (props: DialogContentProps) => {
    return (
        <DialogContent
            className="df-col"
            style={{ overflow: 'visible' }}
            dividers
            {...props} />
    );
}