import { subscriptionsQueryParamsLsKey } from 'app/localStorageKeys';
import { QueryProvider } from 'framework/context/QueryProvider';
import {
	IQueryResultWithMetadata,
	ISubscription,
	ISubscriptionsQueryMetadata,
	ISubscriptionsQueryParams,
	subscriptionsQuery_query,
	subscriptionStatesQuery_concurrencyToken,
} from 'gen/ApiClient';
import React from 'react';
import { SubscriptionsListQueryContext } from './SubscriptionsListQueryContext';

const DefaultSubscriptionsQueryParams: ISubscriptionsQueryParams = {
	searchString: '',
	sortParams: { direction: 'asc', property: 'clientName' },
	pageParams: { index: 1, size: 10 },
	// fromAfter: undefined,
	// fromBefore: undefined,
	licenseIds: [],
	// toAfter: undefined,
	// toBefore: undefined,
	hasSale: undefined,
	hasExceeded: undefined,
	hasExceededMaxSoldHearingAids: undefined,
	prefilter: 'Active',
	types: [],
	licenseCategories: [],
	withAdditionalCosts: undefined,
	withAnnotations: undefined,
};

export const SubscriptionsListQueryProvider = ({ children }: { children: any }) => {
	return (
		<QueryProvider<IQueryResultWithMetadata<ISubscription, ISubscriptionsQueryMetadata>, ISubscriptionsQueryParams>
			defaultParams={DefaultSubscriptionsQueryParams}
			queryF={subscriptionsQuery_query}
			concurrencyF={subscriptionStatesQuery_concurrencyToken}
			localStorageKey={subscriptionsQueryParamsLsKey}
			context={SubscriptionsListQueryContext}>
			{children}
		</QueryProvider>
	);
};
