import { useTheme } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { ILabelledPropertyProps, LabelledProperty } from './LabelledProperty';

interface IProps extends ILabelledPropertyProps {
    error: string;
}

export const ErrorLabelledProperty = ({ error, ...rest }: IProps) => {
    const theme = useTheme();

    return (
        <LabelledProperty
            property={error}
            icon={<ErrorIcon style={{ color: theme.palette.error.main }} />}
            color="error"
            {...rest} />
    );
}