import { BillingAccountDetailSubscriptionsCard } from 'app/main/billingAccounts/detail/BillingAccountDetailSubscriptionsCard';
import { Trigger } from 'framework/hooks/useTrigger';
import { IClientDetailDto } from 'gen/ApiClient';
import React from 'react';
import { PublishPage } from '../publish/PublishPage';
import { SubscriptionStatesPage } from '../subscriptionStates/SubscriptionStatesPage';
import { UpdateScheduledTasksPage } from '../update/UpdateScheduledTasksPage';
import { ClientDetailNavigationLink } from './ClientDetailNavigationLink';
import { ClientDetailOverviewPage } from './ClientDetailOverviewPage';
import { IisPage } from './IisPage';
import { ClientDetailSoldHearingAidsCard } from './soldHearingAids/ClientDetailSoldHearingAidsCard';

interface IProps {
	item: IClientDetailDto;
	link: ClientDetailNavigationLink;
	reload: VoidFunction;
	reloadTrigger: Trigger;
}

export const ClientDetailNavigationSwitch = ({ item, link, reload, reloadTrigger }: IProps) => {
	return (
		<>
			{link === 'overview' && (
				<ClientDetailOverviewPage
					client={item}
					reloadTrigger={reloadTrigger}
					reload={reload}
				/>
			)}
			{link === 'iis' && (
				<IisPage
					client={item}
					reload={reload}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{link === 'states' && (
				<SubscriptionStatesPage
					client={item}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{link === 'publish' && (
				<PublishPage
					client={item}
					reload={reload}
				/>
			)}
			{link === 'update' && (
				<UpdateScheduledTasksPage
					client={item}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{link === 'subscriptions' && (
				<BillingAccountDetailSubscriptionsCard
					billingAccountId={item.id}
					reloadTrigger={reloadTrigger}
				/>
			)}
			{link === 'soldHearingAids' && (
				<ClientDetailSoldHearingAidsCard
					clientId={item.id}
					reloadTrigger={reloadTrigger}
				/>
			)}
		</>
	);
};
