import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { endOfMonth, endOfYear, isValid, startOfMonth, startOfYear } from 'date-fns';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { SelectField } from 'framework/components/select/SelectField';
import { CancelSubmitFormDialogActions } from 'framework/forms/CancelSubmitFormDialogActions';
import { FormDatePicker } from 'framework/forms/FormDatePicker';
import { FormMoneyField } from 'framework/forms/FormMoneyField';
import { FormTextField } from 'framework/forms/FormTextField';
import { TimeUnit } from 'framework/forms/TimeUnit';
import { setFormValue } from 'framework/forms/utils/setFormValue';
import { formatDate } from 'framework/utils/date/formatDate';
import { getNow } from 'framework/utils/getNow';
import { IStrings } from 'localization/IStrings';
import { useLocalization } from 'localization/useLocalization';
import React, { useEffect, useState } from 'react';
import { v1 as uuid } from 'uuid';
import * as yup from 'yup';
import { ISalesLineRequestX } from './ISalesLineRequestX';

interface IModel {
	unitPrice: number;
	description: string;
	generatedDescription: string;
	date: Date;
}

const Now = getNow();

const createSchema = (strings: IStrings) => {
	return yup
		.object<IModel>({
			unitPrice: yup.number().min(0).required(),
			description: yup.string().required(),
			generatedDescription: yup.string().required(),
			date: yup.date().required(),
		})
		.defined();
};

const convertModelToSalesLine = (model: IModel): ISalesLineRequestX => {
	return {
		id: uuid(),
		canChangeQuantity: false,
		description: model.generatedDescription,
		quantity: 1,
		unitListPrice: model.unitPrice,
		unitPrice: model.unitPrice,
		vatPercentage: 21,
	};
};
interface IProps extends DialogProps {
	confirm: (line: ISalesLineRequestX) => void;
	cancel: VoidFunction;
}

export const AddRecurringFeeLineForm = ({ confirm, cancel, ...rest }: IProps) => {
	const strings = useLocalization();

	const handleSubmit = async (values: IModel, helpers: FormikHelpers<IModel>) => {
		confirm(convertModelToSalesLine(values));
	};

	return (
		<Formik<IModel>
			validateOnMount
			validationSchema={createSchema(strings)}
			initialValues={{
				unitPrice: 0,
				description: 'Maandelijkse support',
				date: Now,
				generatedDescription: '',
			}}
			onSubmit={handleSubmit}>
			<Form>
				<InnerDialog
					{...rest}
					cancel={cancel}
					isSubmitting={false}
				/>
			</Form>
		</Formik>
	);
};

interface IInnerDialogProps extends DialogProps {
	cancel: VoidFunction;
	isSubmitting: boolean;
}

const InnerDialog = ({ cancel, isSubmitting, ...rest }: IInnerDialogProps) => {
	const props = useFormikContext<IModel>();
	const [timeUnit, setTimeUnit] = useState<TimeUnit>('Months');

	useEffect(() => {
		const date = new Date(props.values.date);
		if (isValid(date)) {
			const from = timeUnit === 'Months' ? startOfMonth(date) : startOfYear(date);
			const to = timeUnit === 'Months' ? endOfMonth(date) : endOfYear(date);
			const generatedDescription = `${props.values.description}\nVan: ${formatDate(from)} tot: ${formatDate(to)}`;
			setFormValue<IModel>('generatedDescription', generatedDescription, props);
		}
		// eslint-disable-next-line
	}, [props.values.date, timeUnit, props.values.description]);

	return (
		<Dialog
			fullWidth
			{...rest}>
			<DialogTitle>{`Add recurring fee line`}</DialogTitle>
			<DialogContent
				dividers
				className='df-col'>
				<FormTextField<IModel>
					name='description'
					label={`Description`}
					multiline
				/>
				<FormMoneyField<IModel>
					name='unitPrice'
					label={`Unit price`}
				/>
				<SelectField<TimeUnit>
					options={['Months', 'Years']}
					value={timeUnit}
					onChange={setTimeUnit}
					getKey={t => t}
					label='Recurrence'
					renderValue={t => (t === 'Months' ? 'Monthly' : 'Yearly')}
					style={{ marginBottom: 10 }}
				/>
				<FormDatePicker<IModel>
					name='date'
					label='Date'
				/>
				<FormTextField<IModel>
					name='generatedDescription'
					label={`Gen. description`}
					disabled
					multiline
				/>
			</DialogContent>
			<CancelSubmitFormDialogActions
				submitText={`Add`}
				isSubmitting={false}
				cancel={cancel}
			/>
		</Dialog>
	);
};
