import React from 'react';
import { TemplatesQueryProvider } from './list/TemplatesQueryProvider';
import { TemplatesViewProvider } from './list/TemplatesViewProvider';

export const TemplatesProviders = ({ children }: any) => {
	return (
		<TemplatesQueryProvider>
			<TemplatesViewProvider>{children}</TemplatesViewProvider>
		</TemplatesQueryProvider>
	);
};
