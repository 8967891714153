import { ISubscription } from 'gen/ApiClient';
import React, { useEffect } from 'react';
import { mapSubscriptionToSalesLineRequestX } from './mapSubscriptionToSalesLineRequestX';
import { ISalesLineRequestX } from './ISalesLineRequestX';

export const useConvertSubscriptionToLine = (subscription: ISubscription | undefined, setLines: React.Dispatch<React.SetStateAction<ISalesLineRequestX[]>>) => {
	useEffect(() => {
		if (subscription) {
			setLines(lines => [...lines, mapSubscriptionToSalesLineRequestX(subscription)]);
		}
		// eslint-disable-next-line
	}, [subscription]);
};
