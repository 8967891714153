import { useTheme } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';

interface IProps {
    style?: React.CSSProperties;
}

export const WarningIconWithWarningColor = ({ style }: IProps) => {
    const theme = useTheme();
    return (
        <WarningIcon style={{ color: theme.palette.warning.main, ...style }} />
    )
}