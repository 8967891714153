import React from 'react';
import { NavigationLink } from '../NavigationLink';

interface IContext {
    record: Record<NavigationLink, string>;
    link: NavigationLink;
    setLink: React.Dispatch<React.SetStateAction<NavigationLink>>;
}

export const NavigationContext = React.createContext<IContext>(undefined as any);
