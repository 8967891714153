import { Card, CardMedia, Divider, useTheme } from '@material-ui/core';
import { CardTitleTypography } from 'framework/components/CardTitleTypography';
import { useApiEffectWithParams } from 'framework/hooks/useApiEffectWithParams';
import { Trigger } from 'framework/hooks/useTrigger';
import { createHeader } from 'framework/table/createHeader';
import { IHeader } from 'framework/table/IHeader';
import { PageableTable } from 'framework/table/PageableTable';
import { formatDate } from 'framework/utils/date/formatDate';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { ISoldHearingAidDto, ISoldHearingAidsForClientQueryParams, soldHearingAidsQuery_query } from 'gen/ApiClient';
import React from 'react';
import { NoRecordsFound } from 'shared/NoRecordsFound';
import { ClientDetailSoldHearingAidsStats } from './ClientDetailSoldHearingAidsStats';
import { SoldHearingAidsFilter } from './SoldHearingAidsFilter';

const DefaultParams: ISoldHearingAidsForClientQueryParams = {
	clientId: '',
	dateSoldAfter: undefined,
	dateSoldBefore: undefined,
	pageParams: { index: 1, size: 10 },
	sortParams: { direction: 'desc', property: 'dateSold' },
};

const Headers: IHeader<ISoldHearingAidDto>[] = [
	createHeader<ISoldHearingAidDto>('Date sold', t => formatDate(t.dateSold)),
	createHeader<ISoldHearingAidDto>('Mfr/Model', t => `${t.manufacturer} ${t.model}`),
	createHeader<ISoldHearingAidDto>('Sales pric', t => formatCurrency(t.salesPrice)),
];

interface IProps {
	clientId: string;
	reloadTrigger: Trigger;
}

export const ClientDetailSoldHearingAidsCard = ({ clientId, reloadTrigger }: IProps) => {
	const theme = useTheme();
	const [params, setParams, queryResult] = useApiEffectWithParams(soldHearingAidsQuery_query, { ...DefaultParams, clientId: clientId }, reloadTrigger);

	if (queryResult === undefined) {
		return <></>;
	}

	return (
		<>
			{queryResult.unfilteredCount > 0 && (
				<>
					<ClientDetailSoldHearingAidsStats item={queryResult.metadata} />
					<Card>
						<CardTitleTypography
							title='Sold hearing aids'
							withPadding
						/>
						<Divider />
						<div className='df-row jc-sb'>
							<SoldHearingAidsFilter
								params={params}
								onFilter={p => setParams({ ...p, pageParams: { index: 1, size: params.pageParams.size } })}
								paddingLeft={theme.spacing(1.5)}
							/>
						</div>
						<Divider />
						<CardMedia>
							<PageableTable<ISoldHearingAidDto>
								queryResult={queryResult}
								headers={Headers}
								sortParams={params.sortParams}
								pageParams={params.pageParams}
								setParams={(sp, pp) => setParams({ ...params, sortParams: sp, pageParams: pp })}
								getKey={inst => inst.id}
							/>
						</CardMedia>
					</Card>
				</>
			)}
			{queryResult.unfilteredCount === 0 && <NoRecordsFound>{`No sold hearing aids registered yet for this client`}</NoRecordsFound>}
		</>
	);
};
