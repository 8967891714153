import { ILabelledPropertyProps, LabelledProperty } from 'framework/components/labelledProperty/LabelledProperty';
import React from 'react';
import { ErrorOrSuccessIcon } from 'shared/ErrorOrSuccessIcon';

interface IProps extends ILabelledPropertyProps {
	isSuccess: boolean;
}

export const SuccessOrErrorLabelledProperty = ({ isSuccess, ...props }: IProps) => {
	return (
		<LabelledProperty
			{...props}
			icon={<ErrorOrSuccessIcon val={isSuccess} />}
		/>
	);
};
