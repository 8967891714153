import { useTheme } from '@material-ui/core';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import { ChipX } from 'framework/components/chips/ChipX';
import { ISubscription } from 'gen/ApiClient';
import React from 'react';
import { SubscriptionTypeChip } from './list/SubscriptionTypeChip';

interface IProps {
	item: ISubscription;
	style?: React.CSSProperties;
}

export const SubscriptionChips = ({ item, style }: IProps) => {
	const theme = useTheme();

	return (
		<div
			className='df-row-ac gap-4'
			style={style}>
			<SubscriptionTypeChip item={item} />
			{item.isDemo && (
				<ChipX
					fill
					color='error'
					label='Demo'
				/>
			)}
			{item.hasExceededMaxSoldHearingAids && (
				<ChipX
					fill
					color='error'
					label='Exceeds!'
				/>
			)}
			{item.license.isFree && (
				<ChipX
					fill
					color='warning'
					label='Free'
				/>
			)}
			{item.needsSale && item.hasSale === false && (
				<ChipX
					fill
					color='error'
					label='w/o Sale'
				/>
			)}
			{item.hasAdditionalCosts && (
				<ChipX
					fill
					color='error'
					label='Add. cost(s)'
				/>
			)}
			{item.hasAnnotations && <AnnouncementOutlinedIcon style={{ fontSize: 22, color: theme.palette.error.main }} />}
		</div>
	);
};
