import { RadioIsSelectedButton } from 'framework/components/buttons/RadioIsSelectedButton';
import { ISalesQueryMetadata, SalesPrefilter, SalesPrefilters } from 'gen/ApiClient';
import React, { useMemo } from 'react';
import { createSalesPrefilterBadgeChipXRecord } from './createSalesPrefilterBadgeChipXRecord';

interface IProps {
	selectedPrefilter: SalesPrefilter;
	setSelectedPrefilter: (key: SalesPrefilter) => void;
	metadata: ISalesQueryMetadata;
	style?: React.CSSProperties;
}

const SalesPrefilterRecord: Record<SalesPrefilter, string> = {
	All: 'All',
	Budgeted: 'Budgeted',
	Invoiced: 'Invoiced',
	InvoicedAndNotPaid: 'Invoiced but not paid',
	InvoicedAndPaid: 'Invoiced and paid',
	Overdue: 'Overdue',
	Tendered: 'Tendered',
};

export const SalesListPrefilter = ({ selectedPrefilter, setSelectedPrefilter, metadata, style }: IProps) => {
	const endIconRecord = useMemo(() => createSalesPrefilterBadgeChipXRecord(metadata), [metadata]);

	return (
		<div
			className='df-col'
			style={style}>
			{SalesPrefilters.map(prefilterKeyType => (
				<RadioIsSelectedButton
					key={prefilterKeyType}
					isSelected={prefilterKeyType === selectedPrefilter}
					onClick={() => setSelectedPrefilter(prefilterKeyType)}
					endIcon={endIconRecord[prefilterKeyType]}>
					{SalesPrefilterRecord[prefilterKeyType]}
				</RadioIsSelectedButton>
			))}
		</div>
	);
};
