import { Menu } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { SmallPrimaryTextButton } from 'framework/components/buttons/SmallPrimaryTextButton';
import { FileDocumentEditOutlineIcon } from 'framework/components/icons/FileDocumentEditOutlineIcon';
import { FileDownloadOutlineIcon } from 'framework/components/icons/FileDownloadOutlineIcon';
import { FileSearchOutlineIcon } from 'framework/components/icons/FileSearchOutlineIcon';
import { AlertDialog } from 'framework/dialogs/AlertDialog';
import { useAnchorElement } from 'framework/hooks/useAnchorElement';
import { DocumentExportFormat, documentsCommand_delete, documentsQuery_download, IDocument } from 'gen/ApiClient';
import { useDownload } from 'gen/useDownload';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { useDialogsContext } from 'shared/dialogs/useDialogsContext';
import { useDocumentEditor } from 'shared/dialogs/useDocumentEditor';
import { usePdfViewerWithDocument } from 'shared/dialogs/usePdfViewerWithDocument';

interface IProps {
	item: IDocument;
	reload: VoidFunction;
	prependActions?: JSX.Element;
	appendActions?: JSX.Element;
	close: VoidFunction;
}

export const DocumentDetailDialogActions = ({ item, reload, close, prependActions, appendActions }: IProps) => {
	const [download] = useDownload(documentsQuery_download);
	const [openEditor] = useDocumentEditor(reload);
	const openPdf = usePdfViewerWithDocument();
	// const replace = useReplaceDocument(reload);
	const { open, cancel, confirm } = useDialogsContext(reload);
	const [anchor, openMenu, closeMenu] = useAnchorElement();
	const strings = useLocalization();

	if (item === undefined) {
		return <div></div>;
	}

	const onViewPdf = () => {
		openPdf(item.display ?? '', item.id);
	};

	const onViewOrEdit = async () => {
		openEditor(item.id);
	};

	const onDelete = () => {
		open(
			<AlertDialog
				open
				content={strings.deleteWhatQuestion(strings.document)}
				acceptText={strings.yesWhatExclamation(strings.delete)}
				rejectText={strings.noCommaGoBack}
				reject={cancel}
				acceptF={(() => documentsCommand_delete({ documentId: item.id })) as any}
				accept={() => {
					confirm();
					close();
				}}
			/>
		);
	};

	const onDownload = (format: DocumentExportFormat) => {
		download(item.id, format);
	};

	return (
		<>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={closeMenu}
				keepMounted>
				<div
					className='df-col'
					style={{
						alignItems: 'flex-start',
						paddingTop: 8,
						paddingBottom: 8,
						paddingLeft: 16,
						paddingRight: 16,
					}}>
					{item.isPdf === false && item.canConvertToPdf && (
						<SmallPrimaryTextButton
							onClick={onViewPdf}
							startIcon={<FileSearchOutlineIcon />}>
							{`${strings.viewDocument} (.pdf, ${strings.inBrowser})`}
						</SmallPrimaryTextButton>
					)}
					<SmallPrimaryTextButton
						onClick={() => onDownload('Native')}
						startIcon={<FileDownloadOutlineIcon />}>
						{`${strings.downloadDocument} (${item.extension})`}
					</SmallPrimaryTextButton>
					{item.isPdf === false && item.canConvertToPdf && (
						<SmallPrimaryTextButton
							onClick={() => onDownload('Pdf')}
							startIcon={<FileDownloadOutlineIcon />}>
							{`${strings.downloadDocument} (.pdf)`}
						</SmallPrimaryTextButton>
					)}
					{appendActions && appendActions}
					<SmallPrimaryTextButton
						color='secondary'
						startIcon={<DeleteOutlineIcon />}
						onClick={onDelete}>
						{strings.deleteWhat(strings.document)}
					</SmallPrimaryTextButton>
				</div>
			</Menu>
			<div className='df-col-as'>
				{prependActions && prependActions}
				<DocumentPrimaryAction
					document={item}
					onViewOrEdit={onViewOrEdit}
					onDownload={onDownload}
					onViewPdf={onViewPdf}
				/>
				<SmallPrimaryTextButton
					startIcon={<MoreHorizOutlinedIcon />}
					onClick={openMenu}>
					{strings.moreActions}
				</SmallPrimaryTextButton>
			</div>
		</>
	);
};

interface IDocumentPrimaryActionProps {
	document: IDocument;
	onViewPdf: VoidFunction;
	onViewOrEdit: VoidFunction;
	onDownload: (format: DocumentExportFormat) => void;
}

const DocumentPrimaryAction = ({ document, onViewPdf, onViewOrEdit, onDownload }: IDocumentPrimaryActionProps) => {
	const strings = useLocalization();

	if (document.isPdf) {
		return (
			<SmallPrimaryTextButton
				onClick={onViewPdf}
				startIcon={<FileSearchOutlineIcon />}>
				{strings.viewDocument}
			</SmallPrimaryTextButton>
		);
	} else if (document.isDocx) {
		return (
			<SmallPrimaryTextButton
				onClick={onViewOrEdit}
				startIcon={<FileDocumentEditOutlineIcon />}>
				{strings.editWhat(strings.document)}
			</SmallPrimaryTextButton>
		);
	} else {
		return (
			<SmallPrimaryTextButton
				onClick={() => onDownload('Native')}
				startIcon={<FileDownloadOutlineIcon />}>
				{`${strings.downloadDocument} (${document.extension})`}
			</SmallPrimaryTextButton>
		);
	}
};
