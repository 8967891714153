import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { addYears, endOfYear, startOfYear } from 'date-fns';
import { TextLinkButton } from 'framework/components/buttons/TextLinkButton';
import { StatCardX } from 'framework/components/cards/StatCardX';
import { SigmaIcon } from 'framework/components/icons/SigmaIcon';
import { LabelledPropertyX } from 'framework/components/labelledProperty/LabelledPropertyX';
import { formatDateX } from 'framework/utils/date/formatDateX';
import { mapToIso } from 'framework/utils/date/mapToIso';
import { formatCurrency } from 'framework/utils/formatCurrency';
import { getNow } from 'framework/utils/getNow';
import { IAmountPerSalesType, ISalesQueryMetadata, ISalesQueryParams } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';

const Now = getNow();
const Width: number = 250;

interface IProps {
	params: ISalesQueryParams;
	onFilter: (params: ISalesQueryParams) => void;
	metadata: ISalesQueryMetadata;
	style?: React.CSSProperties;
	onToggleShowExceptionalSalesInStats: VoidFunction;
}

export const SalesListStats = ({ metadata, style, onFilter, params, onToggleShowExceptionalSalesInStats }: IProps) => {
	const strings = useLocalization();

	return (
		<div className='df-col gap-8'>
			<div
				className='df-row gap-16'
				style={style}>
				<StatCardX
					style={{ width: Width }}
					title={strings.outstandingBalance}
					caption={strings.totalAmount}
					onClick={() => onFilter({ ...params, prefilter: 'InvoicedAndNotPaid' })}>
					<span style={{ fontSize: 24 }}>{formatCurrency(metadata.outstandingBalanceExVat)}</span>
				</StatCardX>
				<ForecastStatCardX
					title={`Forecast ${formatDateX(Now, 'yyyy')}`}
					caption={`${strings.exVatShort}`}
					forecast={metadata.forecastThisYear}
					onClick={() => onFilter({ ...params, prefilter: 'All', dateAfter: mapToIso(startOfYear(Now))!, dateBefore: mapToIso(endOfYear(Now))! })}
				/>
				<ForecastStatCardX
					title={`Forecast ${formatDateX(addYears(Now, 1), 'yyyy')}`}
					caption={`${strings.exVatShort}`}
					forecast={metadata.forecastNextYear}
					onClick={() =>
						onFilter({ ...params, prefilter: 'All', dateAfter: mapToIso(addYears(startOfYear(Now), 1))!, dateBefore: mapToIso(addYears(endOfYear(Now), 1))! })
					}
				/>
				<ForecastStatCardX
					title={`Forecast filtered data`}
					caption={`${strings.exVatShort}`}
					forecast={metadata.forecastFromFilter}
				/>
			</div>
			<TextLinkButton
				color={params.includeExceptionalInStats ? 'error' : 'textPrimary'}
				startIcon={params.includeExceptionalInStats ? <ErrorIcon color='error' /> : <ErrorOutlineIcon color='primary' />}
				onClick={onToggleShowExceptionalSalesInStats}
				hoverColor={params.includeExceptionalInStats ? 'error' : 'primary'}>
				{params.includeExceptionalInStats === true ? `Exceptional sales are included, click to change` : `Exceptional sales are NOT included, click to change`}
			</TextLinkButton>
		</div>
	);
};

interface IForecastStatCardXProps {
	title: string;
	caption: string;
	forecast: IAmountPerSalesType;
	onClick?: VoidFunction;
}

const ForecastStatCardX = ({ title, caption, forecast, onClick }: IForecastStatCardXProps) => {
	return (
		<StatCardX
			style={{ width: Width }}
			title={title}
			caption={caption}
			onClick={onClick}>
			<>
				<LabelledPropertyX label='Tendered'>{formatCurrency(forecast.amountTendered)}</LabelledPropertyX>
				<LabelledPropertyX label='Budgeted'>{formatCurrency(forecast.amountBudgeted)}</LabelledPropertyX>
				<LabelledPropertyX label='Invoiced'>{formatCurrency(forecast.amountInvoiced)}</LabelledPropertyX>
				<LabelledPropertyX
					icon={<SigmaIcon />}
					label='Total'>
					<span style={{ fontWeight: 500 }}>{formatCurrency(forecast.total)}</span>
				</LabelledPropertyX>
			</>
		</StatCardX>
	);
};
