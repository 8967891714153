import React from 'react';
import { LicenseCategoriesCacheProvider } from './LicenseCategoriesCacheProvider';
import { LicensesCacheProvider } from './LicensesCacheProvider';

export const LicensesProviders = ({ children }: any) => {
	return (
		<LicenseCategoriesCacheProvider>
			<LicensesCacheProvider>{children}</LicensesCacheProvider>
		</LicenseCategoriesCacheProvider>
	);
};
