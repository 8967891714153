import { NavigateIconRecord } from 'app/NavigateIconRecord';
import { useApiEffect } from 'framework/hooks/useApiEffect';
import { useLazyEffect } from 'framework/hooks/useLazyEffect';
import { useTrigger } from 'framework/hooks/useTrigger';
import { IRouteParamsWithId } from 'framework/router/IRouteParamsWithId';
import { clientsQuery_detail } from 'gen/ApiClient';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from 'framework/components/layout/Page';
import { ClientsRoute } from '../../../../routes';
import { ClientChips } from '../ClientChips';
import { ClientDetailActionsCard } from './ClientDetailActionsCard';
import { ClientDetailNavigationCard } from './ClientDetailNavigationCard';
import { ClientDetailNavigationLink } from './ClientDetailNavigationLink';
import { ClientDetailNavigationSwitch } from './ClientDetailNavigationSwitch';
import { ClientDetailsCard } from './ClientDetailsCard';
import { ClientDetailUsageStatsCard } from './ClientDetailUsageStatsCard';
import { SanityCheckCard } from './SanityCheckCard';

export const ClientDetailPage = () => {
	const { id } = useParams<IRouteParamsWithId>();
	const [client, reload] = useApiEffect(clientsQuery_detail, id);
	const [trigger, hitTrigger] = useTrigger();
	const [link, setLink] = useState<ClientDetailNavigationLink>('overview');

	useLazyEffect(() => {
		reload();
	}, [trigger]);

	if (client === undefined) {
		return <div></div>;
	}

	return (
		<Page
			showHome
			breadcrumbs={[{ text: 'Clients', route: ClientsRoute, icon: NavigateIconRecord['clients'] }]}
			reload={hitTrigger}
			title={client.name}
			nav={
				<ClientDetailNavigationCard
					item={client}
					link={link}
					setLink={setLink}
				/>
			}
			secondaryTitle={<ClientChips item={client} />}
			details={
				<>
					<ClientDetailsCard
						client={client}
						style={{ marginBottom: 16 }}
					/>
					<SanityCheckCard
						client={client}
						style={{ marginBottom: 16 }}
					/>
					{client.clientUsageStats && (
						<ClientDetailUsageStatsCard
							item={client.clientUsageStats}
							style={{ marginBottom: 16 }}
						/>
					)}
					<ClientDetailActionsCard
						item={client}
						reload={hitTrigger}
						style={{ marginBottom: 16 }}
					/>
				</>
			}>
			<ClientDetailNavigationSwitch
				item={client}
				reload={hitTrigger}
				reloadTrigger={trigger}
				link={link}
			/>
		</Page>
	);
};
