import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useLocalization } from 'localization/useLocalization';
import { LoaderButton } from '../components/buttons/LoaderButton';
import { useFormSubmit } from '../hooks/useFormSubmit';
import { useSnackbarNotify } from '../hooks/useSnackbarNotify';
import { Try } from '../Try';
import { ICommonValidateResponse } from './ICommonValidateResponse';

interface IProps extends DialogProps {
	title?: string;
	content: string | JSX.Element;
	acceptText: string;
	rejectText?: string;
	acceptF?: () => Promise<Try<ICommonValidateResponse>>;
	accept: () => void;
	reject: () => void;
	// accept: () => void | Promise<void> | Unit | Promise<Unit>;
	// reject: () => void | Promise<void> | Unit | Promise<Unit>;
}

// const defaultFunction = () => Promise.resolve(new Try({ error: '', hasError: false }));

export const AlertDialog = ({
	title,
	content,
	acceptText,
	rejectText,
	acceptF = () => Promise.resolve(new Try({ error: '', hasError: false })),
	accept,
	reject,
	...rest
}: IProps) => {
	const strings = useLocalization();
	const [submit, isSubmitting] = useFormSubmit(acceptF);
	const notify = useSnackbarNotify();

	const onSubmit = async () => {
		const r = await submit();
		if (r.isSuccess) {
			if (r.result.hasError) {
				notify(r.result.error, 'error');
			} else {
				accept();
			}
		}
	};

	return (
		<Dialog
			fullWidth
			onClose={reject}
			{...rest}>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent dividers>
				<DialogContentText style={{ whiteSpace: 'pre-line' }}>{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={reject}
					color='primary'>
					{rejectText ?? strings.cancel}
				</Button>
				<LoaderButton
					isLoading={isSubmitting}
					disabled={isSubmitting}
					onClick={onSubmit}
					color='primary'
					autoFocus>
					{acceptText}
				</LoaderButton>
			</DialogActions>
		</Dialog>
	);
};
