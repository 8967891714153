import { Trigger } from 'framework/hooks/useTrigger';
import React from 'react';
import { CheckMedicalTreatmentNomenclaturesCard } from './CheckMedicalTreatmentNomenclaturesCard';
import { CheckRizivHearingAidDefinitionsV2Card } from './CheckRizivHearingAidDefinitionsV2Card';
import { HifsCardX } from './HifsCardX';

interface IProps {
	reloadTrigger: Trigger;
}

export const DataOverviewPage = ({ reloadTrigger }: IProps) => {
	return (
		<>
			<CheckRizivHearingAidDefinitionsV2Card
				reloadTrigger={reloadTrigger}
				style={{ marginBottom: 16 }}
			/>
			<CheckMedicalTreatmentNomenclaturesCard
				reloadTrigger={reloadTrigger}
				style={{ marginBottom: 16 }}
			/>
			<HifsCardX style={{ marginBottom: 16 }} />
		</>
	);
};
