import { useApiEffect } from 'framework/hooks/useApiEffect';
import { dashboardQuery_data } from 'gen/ApiClient';
import { useLocalization } from 'localization/useLocalization';
import React from 'react';
import { Page } from 'framework/components/layout/Page';
import { useLoggedInUser } from '../../auth/useLoggedInUser';
import { NotificationsCard } from './NotificationsCard';
import { AuthSessionsCard } from './sessions/AuthSessionsCard';

export const Dashboard = () => {
	const strings = useLocalization();
	const [data, reload] = useApiEffect(dashboardQuery_data);
	const user = useLoggedInUser();

	if (data === undefined) {
		return <div></div>;
	}

	return (
		<Page
			showHome
			reload={reload}
			title={`${strings.welcomeBack}, ${user?.firstName}`}
			styleRightContent={{ width: '100%' }}
			detailsFixedWidth={0}>
			<div
				className='df-col gap-16'
				style={{ maxWidth: 1280 }}>
				<NotificationsCard
					notifications={data.notifications}
					style={{ marginBottom: 16 }}
				/>
				<AuthSessionsCard />
			</div>
		</Page>
	);
};
